import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import TableCellContainer from '@libComponents/table/TableCellContainer';
import TableContainer from '@libComponents/table/TableContainer';
import TableHeaderContainer from '@libComponents/table/TableHeaderContainer';

import {
    TableBody,
    TableRow,
} from '@mui/material';


const propTypes = {
    TABLE_DATA: PropTypes.object.isRequired,
    handleClick: PropTypes.func.isRequired,
};

function EmployeeListView({ TABLE_DATA, handleClick, rows, ActionComponent }) {
    return (
        <TableContainer sx={{ boxShadow: 'none' }}>
            <TableHeaderContainer>
                {Object.values(TABLE_DATA).map((th, i) => {
                    switch (th) {
                        case 'ignore':
                            return null;
                        case 'Resignation Date':
                                return null;
                        default:
                            return (
                                <TableCellContainer sx={{ pl: i === 0 ? 4 : 2, fontWeight: 'bold'}} typography='caption' key={th} >
                                    {th}
                                </TableCellContainer>
                            );
                    }
                })}
                <TableCellContainer
                    align='right'
                    sx={{ pr:  4, fontWeight: 'bold'}} typography='caption'
                >
                    Action
                </TableCellContainer>
            </TableHeaderContainer>
            <TableBody>
                {rows.map((row, i) => (
                    <TableRow key={i} sx={{
                        opacity: row.resignationDate ? 0.5 : 1
                    }}>
                        {Object.keys(TABLE_DATA).map((key, index) => {
                            let columnData = row[key];

                            switch (key) {
                                case 'lastname':
                                    return null;

                                case 'firstname':
                                    return (
                                        <TableCellContainer
                                            key={index}
                                            sx={{
                                                pl: index === 0 ? 4 : 2,
                                                overflowWrap: 'break-word'
                                            }}
                                        >
                                            {columnData} {row['lastname']}
                                        </TableCellContainer>
                                    );
                                case 'joiningDate':
                                    return (
                                        <TableCellContainer
                                            key={index}
                                            sx={{
                                                pl: index === 0 ? 4 : 2,
                                                overflowWrap: 'break-word'
                                            }}
                                        >
                                            {dayjs(columnData).format(
                                                'YYYY-MM-DD'
                                            )}
                                        </TableCellContainer>
                                    );

                                case 'resignationDate':
                                    return null;

                                default:
                                    return (
                                        <TableCellContainer
                                            key={index}
                                            sx={{
                                                pl: index === 0 ? 4 : 2,
                                                overflowWrap: 'break-word'
                                            }}
                                        >
                                            {columnData}
                                        </TableCellContainer>
                                    );
                            }
                        })}
                        <TableCellContainer align='right'>
                            <ActionComponent row={row} handleClick={handleClick}/>
                        </TableCellContainer>
                    </TableRow>
                ))}
            </TableBody>
        </TableContainer>
    );
}

EmployeeListView.propTypes = propTypes;

export default EmployeeListView;
