import { takeLatest } from 'redux-saga/effects';

// Workers
import { CREATE_SHIFT, DELETE_SHIFT, UPDATE_SHIFT } from '../redux.actionTypes';
import createShiftWorker from './createShiftWorker';
import updateShiftWorker from './updateShiftWorker';
import deleteShiftWorker from './deleteShiftWorker';


// Watchers
export default function* () {
    yield takeLatest(CREATE_SHIFT, createShiftWorker);
    yield takeLatest(UPDATE_SHIFT, updateShiftWorker);
    yield takeLatest(DELETE_SHIFT, deleteShiftWorker);
}