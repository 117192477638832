import { calculateSecondsToHoursAndMinutes } from '@libs/dateTime';

const calculateDuration = ({ inTime, duration }) => {
    const oneDayInSeconds = 24 * 60 * 60;
    const isWithinLast24Hours =
        (new Date() - new Date(inTime)) / 1000 < oneDayInSeconds;

    let updatedDuration;
    if (duration) {
        updatedDuration = duration;
    } else if (isWithinLast24Hours) {
        updatedDuration = (new Date() - new Date(inTime)) / 1000;
    } else {
        updatedDuration = duration;
    }
    return calculateSecondsToHoursAndMinutes(updatedDuration);
};

export default calculateDuration;