import structureGeneralInformation from './structureGeneralInformation';
import structureContactInformation from './structureContactInformation';
import structureFamilyInformation from './structureFamilyInformation';
import structureIdentificationInformation from './structureIdentificationInformation';
import structureAcademicInformation from './structureAcademicInformation';
import structureOtherInformation from './structureOtherInformation';
import dayjs from 'dayjs';

const getGeneralInformation = (data) => {
    return structureGeneralInformation.map((info) => {
        if (data[info.key]) {
            switch (info.key) {
                case 'father':
                    return {
                        ...info,
                        value: data[info.key]?.firstname ? data[info.key]?.firstname : '' + ' ' + data[info.key]?.middlename ? data[info.key]?.middlename : '' + ' ' + data[info.key]?.lastname ? data[info.key]?.lastname : '' || 'N/A',
                    }
                case 'mother':
                    return {
                        ...info,
                        value: data[info.key]?.firstname ? data[info.key]?.firstname : '' + ' ' + data[info.key]?.middlename ? data[info.key]?.middlename : '' + ' ' + data[info.key]?.lastname ? data[info.key]?.lastname : '' || 'N/A',
                    }
                case 'joiningDate':
                    return {
                        ...info,
                        value: dayjs(data[info.key]).format("YYYY-MM-DD") || 'N/A',
                    }
                case 'dateOfBirth':
                    return {
                        ...info,
                        value: dayjs(data[info.key]).format("YYYY-MM-DD") || 'N/A',
                    }

                default:
                    return {
                        ...info,
                        value: typeof (data[info.key]) === 'object' ? data[info.key]?.name || 'N/A' : data[info.key] || 'N/A',
                    }
            }

        }
        return info;
    })
};

const getContactInformation = (data) => {
    return structureContactInformation.map((info) => {
        switch (info.key) {
            case 'permanentAddress':
                info.value = [
                    data?.contact[info.key]?.address,
                    data?.contact[info.key]?.thana,
                    data?.contact[info.key]?.district,
                    data?.contact[info.key]?.city,
                    data?.contact[info.key]?.country
                ].filter(Boolean).join(', ') || 'N/A';
                break;
            case 'presentAddress':
                info.value = [
                    data?.contact[info.key]?.address,
                    data?.contact[info.key]?.thana,
                    data?.contact[info.key]?.district,
                    data?.contact[info.key]?.city,
                    data?.contact[info.key]?.country
                ].filter(Boolean).join(', ') || 'N/A';
                break;
            case 'emergencyContact':
                const emergencyContact = data?.contact?.emergencyContact ?? {};
                const emergencyContactNumber = emergencyContact?.contact ?? 'N/A';
                info.value = emergencyContactNumber;
                break;
            default:
                info.value = data?.contact[info.key] || 'N/A';
                break;
        }
        return info;
    })
};

const getFamilyInformation = (data) => {
    return structureFamilyInformation.map((info) => {
        switch (info.key) {
            case 'isMarried':
                info.value = data?.family && data?.family[info.key] ? 'Yes' : 'No';
                break;
            case 'spouse':
                info.value = data?.family && data?.family[info.key] ? data?.family && data?.family[info.key]?.firstname ? data?.family && data?.family[info.key]?.firstname : '' + ' ' + data?.family && data?.family[info.key]?.middlename ? data?.family && data?.family[info.key]?.middlename : '' + ' ' + data?.family && data?.family[info.key]?.lastname ? data?.family && data?.family[info.key]?.lastname : '' : 'N/A';
                break;
            case 'contact':
                info.value = data?.family && data?.family['spouse']?.contact || 'N/A';
                break;
            case 'occupation':
                info.value = data?.family && data?.family['spouse']?.occupation || 'N/A';
                break;
            case 'children':
                info.value = data?.family && data?.family[info.key]?.length || 'N/A';
                break;
            case 'marriageDate':
                info.value = data?.family && data?.family[info.key] ? dayjs(data?.family && data?.family[info.key]).format("YYYY-MM-DD") : 'N/A';
                break;
            default:
                info.value = data?.family && data?.family[info.key] || 'N/A';
                break;
        }
        return info;
    })
};

const getIdentificationInformation = (data) => {
    return structureIdentificationInformation.map((info) => {
        switch (info.key) {
            case 'photograph':
                info.value = data?.identification && data?.identification[info.key] ? 'link' : 'N/A';
                info.link = data?.identification && data?.identification[info.key] ? data?.identification && data?.identification[info.key] : '';
                break;
            case 'signature':
                info.value = data?.identification && data?.identification[info.key] ? 'link' : 'N/A';
                info.link = data?.identification && data?.identification[info.key] ? data?.identification && data?.identification[info.key] : '';
                break;
            case 'nid':
                info.value = data?.identification && data?.identification[info.key] ? 'link' : 'N/A';
                info.link = data?.identification && data?.identification[info.key] ? data?.identification && data?.identification[info.key] : '';
                break;
            case 'etin':
                info.value = data?.identification && data?.identification[info.key] ? 'link' : 'N/A';
                info.link = data?.identification && data?.identification[info.key] ? data?.identification && data?.identification[info.key] : '';
                break;
            case 'passport':
                info.value = data?.identification && data?.identification[info.key] ? data?.identification && data?.identification[info.key].number : 'N/A';
                break;
            default:
                info.value = data?.identification && data?.identification[info.key] || 'N/A';
                break;
        }
        return info;
    })
};

const getAcademicInformation = (data) => {
    return structureAcademicInformation.map((info) => {
        switch (info.key) {
            case 'institutions':
                info.value = data?.academic?.institutions ? data?.academic?.institutions.map((institution, index) => {
                    return {
                        key: `Academic Qualifications ${index + 1}`,
                        label: `Academic Qualifications ${index + 1}`,
                        value: {
                            'Qualification': institution.qualification || 'N/A',
                            'Discipline / Department': institution.department || 'N/A',
                            'Institute Name': institution.name || 'N/A',
                            'Institute / Board': institution.board || 'N/A',
                            'Passing Year': dayjs(institution.passingYear).format("YYYY") || 'N/A',
                            'Result (Marks/CGPA)': institution.result || 'N/A',
                        }
                    }
                }) || 'N/A' : 'N/A';
                break;
            case 'workExperiences':
                info.value = data?.workExperiences.map((workExperience, index) => {
                    return {
                        key: `Work Experiences ${index + 1}`,
                        label: `Work Experiences ${index + 1}`,
                        value: {
                            'Company Name': workExperience.companyName || 'N/A',
                            'Location': workExperience.location || 'N/A',
                            'Designation': workExperience.designation || 'N/A',
                            'Years of Experience': workExperience.yearsOfExperience || 'N/A',
                            'Skills': workExperience.skills.join(', ') || 'N/A',
                            'Contributions': workExperience.contributions.join(', ') || 'N/A',
                            'Achievements': workExperience.achievements.join(', ') || 'N/A',
                        }
                    }
                }) || 'N/A';
                break;
            default:
                info.value = 'N/A';
                break;
        }
        return info;
    })
};

const getOtherInformation = (data) => {
    return structureOtherInformation.map((info) => {
        switch (info.key) {
            case 'bank':
                info.value = {
                    'Bank name': data?.bank?.name || 'N/A',
                    'Branch': data?.bank?.branch || 'N/A',
                    'A/C no': data?.bank?.accountNumber || 'N/A',
                }
                break;
            case 'nominees':
                info.value = data?.nominees.map((nominee, index) => {
                    return {
                        key: `Nominees ${index + 1}`,
                        label: `Nominees ${index + 1}`,
                        value: {
                            'Nomination purpose': nominee.nominationPurpose || 'N/A',
                            'Nomination Date': dayjs(nominee.nominationDate).format("YYYY-MM-DD") || 'N/A',
                            'Name of the nominee': nominee.information?.firstname || 'N/A',
                            'Relation with employee': nominee.information?.relation || 'N/A',
                            'Percentage amount': nominee.percentage || 'N/A',
                            'Date of Birth': dayjs(nominee.dateOfBirth).format("YYYY-MM-DD") || 'N/A',
                            'Occupation': nominee.information?.occupation || 'N/A',
                            'Address': nominee.information?.address?.address || 'N/A',
                            'Contact No': nominee.information?.contact || 'N/A',
                            'E-Mail': nominee.information?.email || 'N/A',
                            'Nominee’s Photograph': { link: nominee.photograph } || 'N/A',
                            'Nominee’s Signature': { link: nominee.signature } || 'N/A',
                        }
                    }
                }) || 'N/A';
                break;
            case 'references':
                info.value = data?.references.map((reference, index) => {
                    return {
                        key: `References ${index + 1}`,
                        label: `References ${index + 1}`,
                        value: {
                            'Name': reference.firstname || 'N/A',
                            'Occupation': reference.occupation || 'N/A',
                            'Relation with employee': reference.relation || 'N/A',
                            'Relation Contact No': reference.contact || 'N/A',
                            'Email Address': reference.email || 'N/A',
                        }
                    }
                }) || 'N/A';
                break;
            case 'insurances':
                info.value = data?.insurances.map((insurance, index) => {
                    return {
                        key: `Insurances ${index + 1}`,
                        label: `Insurances ${index + 1}`,
                        value: {
                            'Name of family member': insurance.information?.firstname || 'N/A',
                            'Nature of Insurance': insurance.natureOfInsurance || 'N/A',
                            'Insurance amount': insurance.insuranceAmount || 'N/A',
                            'Relation with employee': insurance.information?.relation || 'N/A',
                            'Registration Date': dayjs(insurance.registrationDate).format("YYYY-MM-DD") || 'N/A',
                            'Date of Birth': dayjs(insurance.dateOfBirth).format("YYYY-MM-DD") || 'N/A',
                            'Occupation': insurance.information?.occupation || 'N/A',
                            'Photograph': { link: insurance.photograph } || 'N/A',
                        }
                    }
                }) || 'N/A';
                break;
            default:
                info.value = 'N/A';
                break;
        }
        return info;
    })
};

export { getGeneralInformation, getContactInformation, getFamilyInformation, getIdentificationInformation, getAcademicInformation, getOtherInformation };