import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';

const StyledDatePickerTextField = styled(TextField)(
    ({ theme: { spacing, shape, palette } }) => ({
        width: '100%',
        height: spacing(5),
        '& .MuiInputBase-root': {
            height: '100%',
            borderColor: palette.border.main,
            borderRadius: shape.borderRadius,
            '& .MuiOutlinedInput-notchedOutline': {
                border: '1px solid',
                borderColor: palette.border.main,
                borderRadius: shape.borderRadius
            },
            '&:hover': {
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: palette.border.dark
                }
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: palette.grey[500],

                '&:hover': {
                    borderColor: palette.grey[700]
                }
            },
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                borderColor: `${palette.error.light}`,
                '&:hover': {
                    borderColor: `${palette.error.dark}`
                }
            },
            '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                backgroundColor: palette.background.disabled,
                color: palette.secondary.main
            }
        }
    })
);

const DateTimePickerInput = (props) => {
    return <StyledDatePickerTextField label='' {...props} />;
};

export default DateTimePickerInput;
