import { GridContainer, GridItem } from '@formComponents';
import { Button } from '@libComponents';
import { email } from '@libs/validationRules';
import { Typography } from '@mui/material';
import { Field } from 'redux-form';
import { renderField } from './renderFields/renderInputField';


const renderReferences = (member, index, fields) => (
    <GridContainer key={index} sx={{ p: 1, pl: 0, pr: 0, m: 0, mt:1, mb:1, width: '100%' }}>
        <GridItem
            md={12}
            sx={{
                pl: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
        >
            <Typography sx={{textTransform:"capitalize"}}>
                {fields.name} {index + 1}
            </Typography>
            <Button
                variant='outlined'
                sx={{ textTransform: 'capitalize', width: 'auto' }}
                type='button'
                onClick={() => fields.remove(index)}
                color='error'
            >
                Remove {fields.name} {index + 1}
            </Button>
        </GridItem>

        <GridItem md={3} sx={{ pl: 0 }}>
            <Field
                name={`${member}.firstname`}
                id={`${member}.referenceName`}
                type='text'
                component={renderField}
                placeholder='Name'
                label='Name'
            />
        </GridItem>
        <GridItem md={3} sx={{ pl: 0 }}>
            <Field
                name={`${member}.occupation`}
                type='text'
                id={`${member}.referenceOccupation`}
                component={renderField}
                placeholder='Occupation'
                label='Occupation'
            />
        </GridItem>

        <GridItem md={3} sx={{ pl: 0 }}>
            <Field
                name={`${member}.relation`}
                id={`${member}.relationWithEmployee`}
                type='text'
                component={renderField}
                placeholder='Relation with employee'
                label='Relation with employee'
            />
        </GridItem>
        <GridItem md={3} sx={{ pl: 0 }}>
            <Field
                name={`${member}.contact`}
                id={`${member}.referenceContactNo`}
                label='Relation Contact No'
                type='text'
                component={renderField}
            />
        </GridItem>
        <GridItem md={3} sx={{ pl: 0 }}>
            <Field
                name={`${member}.email`}
                id={`${member}.referenceEmailAddress`}
                type='text'
                component={renderField}
                placeholder='Email Address'
                label='Email Address'
                validate={email}
            />
        </GridItem>
    </GridContainer>
);

export default renderReferences;
