import { styled } from '@mui/system';
import { Box } from '@mui/material';

import Header from './Header';
import Row from './Row';
import { useDailyTimesheetsState } from '../useDailyTimesheets';
import { TimesheetsNoDataFound } from '../../../components';

const StyledDataTableContainer = styled(Box)(({ theme: { spacing } }) => ({
    width: `calc(100% - ${spacing(8)})`,
    margin: `${spacing(2)} ${spacing(4)}`
}));

const DataTable = () => {
    const { dailyTimesheetData: { data: attendances } } = useDailyTimesheetsState();
    return (
        <StyledDataTableContainer>
            <Header />
            {attendances.length === 0 ? (
                <TimesheetsNoDataFound />
            ) : (
                attendances.map((attendance, idx) => (
                    <Row key={`daily-all-timesheets-${idx}`} {...attendance} />
                ))
            )}
        </StyledDataTableContainer>
    );
};

export default DataTable;
