import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { styled, useTheme } from '@mui/material';

import {
    renderedComponentPropType,
    functionalComponentPropType,
} from '@libs/propTypes';
import stripPX from '@libs/stripPX';

import Drawer from './Drawer';
import AppBar from './AppBar';
import Content from './Content';
import { NavigationProvider } from './useNavigation';
import { useFlattenedRoutes } from './hooks';

const propTypes = {
    FullLogoComponent: renderedComponentPropType.isRequired,
    CompactLogoComponent: functionalComponentPropType.isRequired,
    routes: PropTypes.array.isRequired, // Todo better PropTypes check
    FooterComponent: renderedComponentPropType,
    NotFoundPage: functionalComponentPropType,
    EmptyRoutePage: functionalComponentPropType,
    adornments: PropTypes.arrayOf(renderedComponentPropType),
};

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
});

const NavigationContainer = ({
    FullLogoComponent,
    CompactLogoComponent,
    FooterComponent,
    NotFoundPage,
    EmptyRoutePage,
    adornments,
    routes,
}) => {
    const { spacing } = useTheme();
    const [flatRoutes, flatHashes] = useFlattenedRoutes(routes);
    const [barWidth, setBarWidth] = useState(256); 

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            if (width <= 767) {
                setBarWidth(window.innerWidth);
            } else {
                setBarWidth(256);
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);



    const initialize = useCallback(
        (state) => ({
            ...state,
            routes,
            flatRoutes,
            flatHashes,
            drawerWidth: barWidth,
            appBarHeight: stripPX(spacing(8)),
            footerHeight: stripPX(spacing(6)),
        }),
        [flatRoutes, flatHashes, spacing, barWidth]
    );

    return (
        <Container>
            <NavigationProvider initialize={initialize}>
                <Drawer
                    FullLogoComponent={FullLogoComponent}
                    CompactLogoComponent={CompactLogoComponent}
                />
                <AppBar adornments={adornments} />
                <Content
                    FooterComponent={FooterComponent}
                    NotFoundPage={NotFoundPage}
                    EmptyRoutePage={EmptyRoutePage}
                />
            </NavigationProvider>
        </Container>
    );
};

NavigationContainer.propTypes = propTypes;

export { NavigationContainer as default, NavigationContainer };
