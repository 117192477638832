import TableCellContainer from '@libComponents/table/TableCellContainer';
import TableContainer from '@libComponents/table/TableContainer';
import TableHeaderContainer from '@libComponents/table/TableHeaderContainer';
import { Box, DialogContent, DialogTitle, IconButton, TableBody, TableRow, styled } from '@mui/material';
import { useOverviewState } from '../useOverviewProvider';
import { DOMAIN_NAME } from 'dashboard/common';
import { DASHBOARD_OVERVIEW_ATTENDANCE, DASHBOARD_OVERVIEW_LEAVE } from 'dashboard/redux.datasets';
import { useSelector } from 'react-redux';
import { datasetLoadingSelector } from '@libs/datasets';
import { ContentCircularLoader } from '@libComponents';
import { theme } from 'app/Theme';
import CloseIcon from '@mui/icons-material/Close';

const TABLE_DATA = {
    'name': 'Name',
    'designation': 'Designation',
    'branch': 'Branch'
};


const StyledBox = styled(Box)(
    ({ theme: { palette, typography, shape } }) => ({
        width: '100%',
        overflow: 'auto',
        minHeight: 300,
        position: "relative",
        maxWidth: "50vw",
        '& table': {
            tableLayout: 'fixed'
        }
    })
);

const LEAVE_STATUS = {
    'all': {
        label: 'Total Employee',
        color: 'db_overview_1'
    },
    'in': {
        label: 'Present',
        color: 'db_overview_2'
    },
    'absent': {
        label: 'Absent',
        color: 'db_overview_3'
    },
    'arrivedLate': {
        label: 'Late',
        color: 'db_overview_4'
    },
    'sick': {
        label: 'Sick Leave',
        color: 'db_overview_5'
    },
    'other': {
        label: 'Other Leave',
        color: 'db_overview_6'
    },
    "remote": {
        label: 'Remotely Joined',
        color: "db_overview_7"
    }
}

const DataTable = (({ onClose, ...props }) => {
    const { attendanceList, leaveList, status, joinedRemotely } = useOverviewState();

    let isLoading = useSelector((state) =>
        datasetLoadingSelector(state, DOMAIN_NAME, DASHBOARD_OVERVIEW_ATTENDANCE) ||
        datasetLoadingSelector(state, DOMAIN_NAME, DASHBOARD_OVERVIEW_LEAVE)
    );

    console.log(attendanceList, status)
    let data = [];
    if (status === 'sick' || status === 'other') {
        data = leaveList.data;
    } else {
        data = attendanceList.data;
    }
    const currentStatus = status === 'in' && joinedRemotely === true ? 'remote' : status;
    return (
        <StyledBox>

            {isLoading && <ContentCircularLoader />}
            {!isLoading &&
                (<>
                    <DialogTitle sx={{
                        textAlign: "left",
                        backgroundColor: theme.palette[LEAVE_STATUS[currentStatus].color].light,
                        color: theme.palette[LEAVE_STATUS[currentStatus].color].dark,
                    }} >
                        {LEAVE_STATUS[currentStatus].label}
                        {" (" + data.length + ")"}
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent sx={{
                        maxHeight: '70vh',
                        overflow: 'auto'
                    }}>
                        <TableContainer >
                            <TableHeaderContainer sx={{ backgroundColor: theme.palette.background.default }}>
                                {Object.values(TABLE_DATA).map((th, i) => (
                                    <TableCellContainer
                                        sx={{ pl: 2, fontWeight: 'bold' }}
                                        typography="caption" key={th}
                                    >
                                        {th}
                                    </TableCellContainer>
                                ))}
                            </TableHeaderContainer>
                            <TableBody>
                                {data?.map((row, i) => (
                                    <TableRow key={i}>
                                        {Object.keys(TABLE_DATA).map((key, index) => {
                                            switch (key) {
                                                default:
                                                    return (
                                                        <TableCellContainer key={key}>
                                                            {row[key]}
                                                        </TableCellContainer>
                                                    )
                                            }
                                        })}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </TableContainer>
                    </DialogContent>
                </>)
            }
        </StyledBox>
    )
});

export default DataTable
