import PropTypes from 'prop-types';

import { styled } from '@mui/system';
import Tooltip from '@mui/material/Tooltip';

import {
    useOneMonthTimesheet,
    usePresentTypeColor
} from '../../../../../hooks';

import TooltipContent from './TooltipContent';

const propTypes = {
    entries: PropTypes.array.isRequired,
    month: PropTypes.string
};

const StyledDayRangeContainer = styled('div')({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
});

const StyledDaySquare = styled('div')(
    ({ theme: { palette }, bgColor, totalDays }) => {
        const sizePercentage = `calc(100% / ${totalDays})`;

        return {
            width: sizePercentage,
            paddingBottom: sizePercentage,
            position: 'relative',
            borderRight: '2px solid',
            borderRightColor: '#fff',
            backgroundColor: bgColor,

            ':last-child': {
                borderRight: 'none'
            },

            ':hover': {
                cursor: 'pointer',
                border: '1px solid',
                borderColor: palette.secondary.main
            }
        };
    }
);

const Day = ({ day, ...restProps }) => {
    const bgColor = usePresentTypeColor(day.presentType, true);
    return (
        <Tooltip title={<TooltipContent {...day} />}>
            <StyledDaySquare bgColor={bgColor} {...restProps} />
        </Tooltip>
    );
};

const DayRange = ({ entries, yearMonth, ...rest }) => {
    const daysSheet = useOneMonthTimesheet(entries, yearMonth);
    return (
        <StyledDayRangeContainer {...rest}>
            {daysSheet.map((day) => (
                <Day
                    key={`day-${day.date}`}
                    day={day}
                    totalDays={daysSheet.length}
                />
            ))}
        </StyledDayRangeContainer>
    );
};

DayRange.propTypes = propTypes;

export default DayRange;
