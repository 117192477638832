import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const withLocalizationProvider = (Component) => (props) =>
    (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Component {...props} />
        </LocalizationProvider>
    );

export default withLocalizationProvider;
