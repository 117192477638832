import React, { useState } from 'react';
import useEventsCalender, {
    SET_MONTH,
    useEventsCalenderState
} from '../useEventsCalenderProvider';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CalendarPicker, PickersDay } from '@mui/x-date-pickers';
import {
    Badge,
    DialogContent,
    DialogTitle,
    IconButton,
    TableBody,
    styled,
    TableRow
} from '@mui/material';
import { useCallback } from 'react';
import DialogWrapper from '@libComponents/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import TableHeaderContainer from '@libComponents/table/TableHeaderContainer';
import { TableCellContainer } from '@libComponents/table/TableCellContainer';
import { theme } from 'app/Theme';
import TableContainer from '@libComponents/table/TableContainer';

const StyledBox = styled('div')(({ theme }) => ({
    background: 'white',
    height: 'fit-content'
}));

const StyledBadge = styled(Badge)(({ custombackground, theme }) => ({
    '& > span': {
        right: '50%',
        bottom: '0',
        transform: 'translateX(50%)',
        height: 'auto',
        display: 'flex',
        flexDirection: 'row',
        gap: 2,
        padding: '0 0 4px 0',
        '& > span': {
            maxWidth: 4,
            minWidth: 4,
            maxHeight: 4,
            minHeight: 4,
            overflow: 'hidden',
            background: custombackground,
            margin: 'unset',
            padding: 'unset',
            boxSizing: 'border-box',
            display: 'block',
            borderRadius: '50%'
        }
    }
}));

let isArray = function (a) {
    return !!a && a.constructor === Array;
};

const TABLE_DATA = {
    'name': 'Name',
    'type': 'Event Type',
    'description': 'Description'
};

const DataTable = () => {
    const [date, setDate] = React.useState(dayjs());
    const { eventData } = useEventsCalenderState();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogData, setDialogData] = useState(null);

    const [_, dispatch] = useEventsCalender();

    const handleMonthChange = useCallback((value) => {
        dispatch({
            type: SET_MONTH,
            payload: value
        });
    }, []);

    const handleDateChange = function (day) {
        const events =
            isArray(eventData) &&
            eventData.length > 0 &&
            eventData.find(
                (event) =>
                    dayjs(event.date).format('YYYY-MM-DD') ===
                    dayjs(day).format('YYYY-MM-DD')
            );
        if (events) {
            setDialogData(events);
            setDialogOpen(true);
        }
        setDate(day);
    };

    function handleDialogClose() {
        setDialogOpen(false);
    }

    return (
        <StyledBox>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <CalendarPicker
                    sx={{ backgroundColor: 'white' }}
                    date={date}
                    onChange={handleDateChange}
                    onMonthChange={handleMonthChange}
                    views={['day']}
                    view={'day'}
                    renderDay={(day, _value, DayComponentProps) => {
                        const events =
                            isArray(eventData) &&
                            eventData.length > 0 &&
                            eventData.find(
                                (event) =>
                                    dayjs(event.date).format('YYYY-MM-DD') ===
                                    dayjs(day).format('YYYY-MM-DD')
                            )?.events;

                        return (
                            <StyledBadge
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right'
                                }}
                                key={day.toString()}
                                overlap='circular'
                                custombackground={
                                    dayjs(day).isSame(dayjs(date), 'day')
                                        ? theme.palette.background.white
                                        : theme.palette.quaternary.main
                                }
                                badgeContent={
                                    events?.length > 0 ? (
                                        <>
                                            {events.map(
                                                (_, i) =>
                                                    i < 3 && (
                                                        <span key={i}></span>
                                                    )
                                            )}
                                        </>
                                    ) : undefined
                                }
                            >
                                <PickersDay {...DayComponentProps} />
                            </StyledBadge>
                        );
                    }}
                />
            </LocalizationProvider>
            <DialogWrapper
                open={dialogOpen}
                onClose={handleDialogClose}
                dialogPadding={0}
                sx={{ textAlign: 'center' }}
            >
                <DialogTitle
                    sx={{
                        textAlign: 'left',
                        backgroundColor: theme.palette.db_overview_2.main
                    }}
                >
                    Event on{' '}
                    {dialogData?.date &&
                        dayjs(dialogData?.date).format('YYYY-MM-DD')}
                </DialogTitle>
                <IconButton
                    aria-label='close'
                    onClick={handleDialogClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.secondary.light
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent
                    sx={{
                        maxHeight: '70vh',
                        overflow: 'auto',
                        padding: 0
                    }}
                >
                    <TableContainer sx={{ padding: 0 }}>
                        <TableHeaderContainer
                            sx={{
                                backgroundColor:
                                    theme.palette.background.default
                            }}
                        >
                            {Object.values(TABLE_DATA).map((th, i) => (
                                <TableCellContainer
                                    sx={{ pl: 2, fontWeight: 'bold' }}
                                    typography='caption'
                                    key={th}
                                >
                                    {th}
                                </TableCellContainer>
                            ))}
                        </TableHeaderContainer>
                        <TableBody>
                            {dialogData &&
                                dialogData?.events?.map((row, i) => (
                                    <TableRow key={i}>
                                        {Object.keys(TABLE_DATA).map(
                                            (key, index) => {
                                                switch (key) {
                                                    default:
                                                        return (
                                                            <TableCellContainer
                                                                key={key}
                                                            >
                                                                {row[key]}
                                                            </TableCellContainer>
                                                        );
                                                }
                                            }
                                        )}
                                    </TableRow>
                                ))}
                        </TableBody>
                    </TableContainer>
                </DialogContent>
            </DialogWrapper>
        </StyledBox>
    );
};

export default DataTable;
