import React from 'react';

const wrapperStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 24,
    height: '100vh',
};

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        console.log('ErrorBoundary', error);
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        /**
         * TODO: Error reporting service implementation
         */
        console.log(error, errorInfo);
    }

    handleRefresh = () => {
        window.location.reload();
    };

    render() {
        const { state, handleRefresh } = this;
        if (state.hasError) {
            return (
                <div style={wrapperStyle}>
                    SOMETHING WENT WRONG. PLEASE
                    <span onClick={handleRefresh}>
                        &nbsp;<b>REFRESH</b>&nbsp;
                    </span>
                    THE PAGE AND TRY AGAIN.
                </div>
            );
        }

        return this.props.children;
    }
}

export { ErrorBoundary as default, ErrorBoundary };
