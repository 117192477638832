import Label from '@baseComponents/Label';
import { withField } from '@libs/reduxForm';
import {
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    styled,
} from '@mui/material';

const StyledFormControl = styled(FormControl)({
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    ' label': {
        paddingTop: '10px',
    },
});
const StyledRadioGroup = styled(RadioGroup)(
    ({ theme: { spacing, palette, typography, shape } }) => ({
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: spacing(6),
    })
);

const InputRadio = withField()(
    ({
        fieldName,
        input,
        disabled,
        required,
        label,
        loading,
        staticValue,
        meta: { form, touched, error = null, asyncValidating },
        placeholder,
        options,
        defaultValue,
        ...restProps
    }) => {
        const id = `${form}-${fieldName}`;
        const isDisabled = disabled || loading;
        const hasError = !isDisabled && touched && !!error;
        const inputBaseProps = {
            id,
            disabled: isDisabled,
            ...(staticValue ? { value: staticValue } : input),
            ...restProps,
        };


        if ((defaultValue !== null && defaultValue !== undefined) && inputBaseProps.value == '') {
            inputBaseProps.value = defaultValue;
        }

        return (
            <StyledFormControl>
                <Label
                    label={label}
                    disabled={isDisabled}
                    required={required}
                    error={hasError}
                    htmlFor={id}
                />
                <StyledRadioGroup {...inputBaseProps}>
                    {options.map(item => (
                        <FormControlLabel
                            key={item.label}
                            value={item.value}
                            control={<Radio />}
                            label={item.label}
                        />
                    ))}
                </StyledRadioGroup>
            </StyledFormControl>
        );
    }
);

export default InputRadio;
