import PropTypes from 'prop-types';

import { styled } from '@mui/system';
import { Box } from '@mui/material';

import ManageShifts from './ManageShifts';
import ManageContainer from './ManageContainer';

const StyledManageContainer = styled(Box)(({ theme: { spacing } }) => ({
    width: `100%`,
    position: 'relative',
    minHeight: spacing(20)
}));

const ManageContent = ({ currentView }) => {
    return (
        <StyledManageContainer>
            {currentView === 'shifts' && <ManageShifts />}
        </StyledManageContainer>
    );
};

ManageContent.propTypes = {
    currentView: PropTypes.oneOf(['shifts']).isRequired
};


const _ManageEmployee = () => {
    return (
        <ManageContainer
            ContentComponent={ManageContent}
            title='Timesheet'
            loading={false}
        />
    );
};

export default _ManageEmployee;
