import { GridContainer, GridItem } from '@formComponents';
import { Button } from '@libComponents';
import { Typography } from '@mui/material';
import { Field } from 'redux-form';
import { renderDatepicker } from './renderFields/renderDatePicker';
import { renderFile } from './renderFields/renderFile';
import { renderField } from './renderFields/renderInputField';

const renderInsuranceOptions = (member, index, fields) => (
    <GridContainer key={index} sx={{ p: 1, pl: 0, pr: 0, m: 0, mt:1, mb:1, width: '100%' }}>
        <GridItem
            md={12}
            sx={{
                pl: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
        >
            <Typography sx={{textTransform:"capitalize"}}>
                {fields.name} {index + 1}
            </Typography>
            <Button
                variant='outlined'
                sx={{
                    textTransform: 'capitalize',
                    width: 'auto',
                    height: '35px',
                }}
                type='button'
                onClick={() => fields.remove(index)}
                color='error'
            >
                Remove {fields.name} {index + 1}
            </Button>
        </GridItem>

        <GridItem md={3} sx={{ pl: 0 }}>
            <Field
                name={`${member}.firstname`}
                type='text'
                component={renderField}
                placeholder='Name of family member'
                label='Name of family member'
            />
        </GridItem>

        <GridItem md={3} sx={{ pl: 0 }}>
            <Field
                name={`${member}.natureOfInsurance`}
                type='text'
                component={renderField}
                placeholder='Nature of Insurance'
                label='Nature of Insurance'
            />
        </GridItem>

        <GridItem md={3} sx={{ pl: 0 }}>
            <Field
                name={`${member}.insuranceAmount`}
                type='number'
                component={renderField}
                placeholder='Insurance amount'
                label='Insurance amount'
            />
        </GridItem>

        <GridItem md={3} sx={{ pl: 0 }}>
            <Field
                name={`${member}.relation`}
                type='dropdown'
                component={renderField}
                placeholder='Relation with employee'
                label='Relation with employee'
            />
        </GridItem>

        <GridItem md={3} sx={{ pl: 0 }}>
            <Field
                name={`${member}.registrationDate`}
                type='dropdown'
                component={renderDatepicker}
                minDate='2022-01-01'
                label='Registration Date'
                placeholder='Registration Date'
            />
        </GridItem>

        <GridItem md={3} sx={{ pl: 0 }}>
            <Field
                name={`${member}.dateOfBirth`}
                type='dropdown'
                component={renderDatepicker}
                label='Date of Birth'
                minDate='1900-01-01'
                placeholder='Date of Birth'
            />
        </GridItem>

        <GridItem md={3} sx={{ pl: 0 }}>
            <Field
                name={`${member}.occupation`}
                type='text'
                component={renderField}
                placeholder='Occupation'
                label='Occupation'
            />
        </GridItem>

        <GridItem md={3} sx={{ pl: 0 }}>
            <Field
                name={`${member}.photograph`} //this field need to be file
                type='file'
                component={renderFile}
                placeholder='Photograph'
                label='Photograph'
            />
        </GridItem>

    </GridContainer>
);

export default renderInsuranceOptions;
