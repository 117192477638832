import { GridContainer, GridItem } from '@formComponents';
import { Button } from '@libComponents';
import { Typography } from '@mui/material';
import { NOMINATION_PURPOSE_OPTIONS } from 'employee/common';
import { Field } from 'redux-form';
import { renderAutoComplete } from './renderFields/renderAutocomplete';
import { renderDatepicker } from './renderFields/renderDatePicker';
import { renderFile } from './renderFields/renderFile';
import { renderField } from './renderFields/renderInputField';


const renderNominationOptions = (member, index, fields) => (
    <GridContainer key={index} sx={{ p: 1, pl: 0, pr: 0, m: 0, mt:1, mb:1, width: '100%' }}>
        <GridItem
            md={12}
            sx={{
                pl: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
        >
            <Typography sx={{textTransform:"capitalize"}}>
                {fields.name} {index + 1}
            </Typography>
            <Button
                variant='outlined'
                sx={{
                    textTransform: 'capitalize',
                    width: 'auto',
                    height: '35px',
                }}
                type='button'
                onClick={() => fields.remove(index)}
                color='error'
            >
                Remove {fields.name} {index + 1}
            </Button>
        </GridItem>

        <GridItem md={3} sx={{ pl: 0 }}>
            <Field
                name={`${member}.nominationPurpose`}
                type='dropdown'
                component={renderAutoComplete}
                options={NOMINATION_PURPOSE_OPTIONS}
                placeholder='Nomination purpose'
                label='Nomination purpose'
            />
        </GridItem>

        <GridItem md={3} sx={{ pl: 0 }}>
            <Field
                name={`${member}.nominationDate`}
                type='dropdown'
                component={renderDatepicker}
                label='Nomination Date'
                placeholder='Nomination Date'
                minDate='2023-01-01'
            />
        </GridItem>

        <GridItem md={3} sx={{ pl: 0 }}>
            <Field
                name={`${member}.firstname`}
                type='text'
                component={renderField}
                placeholder='Name of the nominee'
                label='Name of the nominee'
            />
        </GridItem>

        <GridItem md={3} sx={{ pl: 0 }}>
            <Field
                name={`${member}.relation`}
                type='text'
                component={renderField}
                placeholder='Relation with employee'
                label='Relation with employee'
            />
        </GridItem>

        <GridItem md={3} sx={{ pl: 0 }}>
            <Field
                name={`${member}.percentage`}
                type='number'
                component={renderField}
                placeholder='Percentage amount'
                label='Percentage amount'
            />
        </GridItem>

        <GridItem md={3} sx={{ pl: 0 }}>
            <Field
                name={`${member}.dateOfBirth`}
                type='dropdown'
                component={renderDatepicker}
                label='Date of Birth'
                placeholder='Date of Birth'
                minDate='1900-01-01'
            />
        </GridItem>

        <GridItem md={3} sx={{ pl: 0 }}>
            <Field
                name={`${member}.occupation`}
                type='text'
                component={renderField}
                placeholder='Occupation'
                label='Occupation'
            />
        </GridItem>

        <GridItem md={3} sx={{ pl: 0 }}>
            <Field
                name={`${member}.address`}
                type='text'
                component={renderField}
                placeholder='Address'
                label='Address'
            />
        </GridItem>
        <GridItem md={3} sx={{ pl: 0 }}>
            <Field
                name={`${member}.contact`}
                type='text'
                component={renderField}
                placeholder='Contact No'
                label='Contact No'
            />
        </GridItem>

        <GridItem md={3} sx={{ pl: 0 }}>
            <Field
                name={`${member}.email`}
                type='text'
                component={renderField}
                placeholder='E-Mail'
                label='E-Mail'
                // validate={email}
            />
        </GridItem>

        <GridItem md={3} sx={{ pl: 0 }}>
            <Field
                name={`${member}.photograph`} //this field need to be file
                type='file'
                component={renderFile}
                label='Nominee’s Photograph'
                placeholder='Nominee’s Photograph'
            />
        </GridItem>



        <GridItem md={3} sx={{ pl: 0 }}>
            <Field
                name={`${member}.signature`} //this field need to be file
                type='file'
                component={renderFile}
                placeholder='Nominee’s Signature'
                label='Nominee’s Signature'
            />
        </GridItem>

    </GridContainer>
);

export default renderNominationOptions;
