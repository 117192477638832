import ContentContainer from '@libComponents/ContentContainer';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { useLocation } from 'react-router';
import { FORM_NAME, } from '../common';
// Libs
import { GridContainer, GridItem, Input } from '@formComponents';
import AutocompleteFetch from '@formComponents/AutocompleteFetch';
import { Button } from '@libComponents';
import { required } from '@libs/validationRules';
import { styled } from '@mui/system';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createHoliday, getHoliday, updateHoliday } from "../redux.actions";
import { useNavigate } from "react-router-dom";

import { DOMAIN_NAME } from 'common/common';

import {
    initialize as formInitialize,
    reduxForm
} from 'redux-form';
import { hasFormErrorSelector } from 'common/redux.selectors';
import DatePickerBase from '@formComponents/DatePicker';
import { CALENDER } from 'common/redux.datasets';
import Calender from 'common/components/calender';
import { getAllCalender } from 'common/components/calender/redux.selectors';
import dayjs from 'dayjs';

const propTypes = {
    mode: PropTypes.oneOf(['create', 'update']),
};
const defaultProps = {
    mode: 'create',
};

/**
 * ReduxForm stepper rules
 * * Connect each step with reduxForm() to the same form name.
 * * Specify the destroyOnUnmount: false flag to preserve form data across form component unmounts.
 */
const ButtonWrapper = styled('div')({
    position: 'absolute',
    bottom: 0,
    right: '50%',
    transform: 'translate(50%, 50%)',
    zIndex: 4,
});

const StyledButton = styled(Button)(({ theme: { spacing, typography } }) => ({
    height: spacing(4.75),
    width: spacing(25),
    fontSize: typography.subtitle1.fontSize,
    marginLeft: spacing(0.75),
    marginRight: spacing(0.75),
}));


const Holiday = reduxForm({
    form: FORM_NAME, // Form name
    destroyOnUnmount: false, // Preserve data
    onSubmit: (v) => v,
})(({ mode, handleSubmit: handleReduxFormSubmit, ...rest }) => {
    const { state } = useLocation();
    const { id } = state ? state : "";
    const RXDispatch = useDispatch();

    useEffect(() => {
        if (id && mode === "update") {
            RXDispatch(getHoliday({ handleSubmit: handleGetHolidays, id }));
        } else {
            RXDispatch(formInitialize(FORM_NAME, {}));
        }
    }, [id]);

    const handleGetHolidays = (payload) => {
        RXDispatch(formInitialize(FORM_NAME, payload));
    }
    const hasError = useSelector((state) =>
        hasFormErrorSelector(state, FORM_NAME)
    );

    const calenders = useSelector((state) => getAllCalender(state));

    const handleCreate = () => {
        if (hasError) {
            handleReduxFormSubmit();
            return;
        }
        if (mode === "create")
            RXDispatch(createHoliday({ payload: { calenders }, handleSubmit }));
        else if (mode === "update") {
            RXDispatch(updateHoliday({ payload: { calenders }, handleSubmit }))
        }
    }
    const navigate = useNavigate();

    const handleSubmit = (payload) => {
        if (mode === "create")
            RXDispatch(formInitialize(FORM_NAME));

        setTimeout(() => {
            navigate("/holidays/all-holidays");
        }, 1000)
    }

    return (
        <ContentContainer
            title={mode + ' Holiday'}
            sx={{ textTransform: 'capitalize' }}
            AdornmentComponent={<Fragment />}
        >
            <GridContainer>
                <GridItem md={4}>
                    <Input
                        name='name'
                        placeholder='Name'
                        label='Name'
                        required={true}
                        validate={required}
                    />
                </GridItem>

                <GridItem md={4}>
                    <Input
                        name='description'
                        placeholder='Description'
                        label='Description'
                    />
                </GridItem>

                <GridItem md={4}>
                    <DatePickerBase
                        name='date'
                        placeholder='Date'
                        label='Date'
                        maxDate={dayjs().add(2, 'year')}
                        required={true}
                        validate={required}
                    />
                </GridItem>
                <GridItem md={4}>

                    <AutocompleteFetch
                        name='calender'
                        placeholder='Calender'
                        label='Calender'
                        domain={DOMAIN_NAME}
                        entity={CALENDER}
                        required={true}
                        validate={required}
                        ContainerComponent={Calender}
                    />
                </GridItem>

            </GridContainer>

            <ButtonWrapper>
                <StyledButton onClick={handleCreate}>
                    {mode === "create" ? "CREATE" : "UPDATE"}
                </StyledButton>
            </ButtonWrapper>
        </ContentContainer>
    );
});

Holiday.propTypes = propTypes;
Holiday.defaultProps = defaultProps;

export { Holiday as default, Holiday };

