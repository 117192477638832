import PropTypes from 'prop-types';

import { styled } from '@mui/system';
import Box from '@mui/material/Box';

import { ContentContainer, ContentCircularLoader } from '@libComponents';

import TimesheetViewSwitcher from './TimesheetViewSwitcher';
import { useTimesheetViewSwitcher } from './hooks';

const propTypes = {
    ContentComponent: PropTypes.elementType.isRequired,
    title: PropTypes.string,
    loading: PropTypes.bool
};

const StyledBox = styled(Box)({
    position: 'relative'
});

const TimesheetsContainer = ({ ContentComponent, loading }) => {
    const { viewType, handleTimesheetViewChange, selectedTimesheetView } = useTimesheetViewSwitcher();

    return (
        <StyledBox>
            {loading && <ContentCircularLoader />}
            <ContentContainer
                title={selectedTimesheetView}
                headerBg='transparent'
                AdornmentComponent={
                    <TimesheetViewSwitcher
                        selectedView={viewType}
                        onViewChange={handleTimesheetViewChange}
                    />
                }
            >
                <ContentComponent currentView={viewType} />
            </ContentContainer>
        </StyledBox>
    );
};

TimesheetsContainer.propTypes = propTypes;

export { TimesheetsContainer as default, TimesheetsContainer };
