import PropTypes from 'prop-types';
import { Fragment, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { initialize as formInitialize, reduxForm, change } from 'redux-form';
import { useEffect } from 'react';
import { styled } from '@mui/system';

import ContentContainer from '@libComponents/ContentContainer';
import { GridContainer, GridItem, Input } from '@formComponents';
import AutocompleteFetch from '@formComponents/AutocompleteFetch';
import InputRadio from '@formComponents/InputRadio';
import { Button } from '@libComponents';
import { isEmpty, required } from '@libs/validationRules';

import { DOMAIN_NAME } from '../../leaveCategory/common';
import { DOMAIN_NAME as COMMON_DOMAIN } from 'common/common';
import { FORM_NAME, IS_EMERGENCY, LEAVE_TYPE } from '../common';
import TextArea from '@formComponents/TextArea';
import DatePickerBase from '@formComponents/DatePicker';
import { LEAVE_CATEGORY } from 'leaveCategory/redux.datasets';
import InputFile from '@formComponents/InputFile';

// Libs
import { createLeave } from "../redux.actions";
import { hasFormErrorSelector } from 'common/redux.selectors';
import dayjs from 'dayjs';
import { GET_ALL_EMPLOYEE_NAME_EMAIL } from 'common/redux.datasets';
import { getAllEmployeeSelector } from 'leaveManagement/redux.selectors';


const propTypes = {
  mode: PropTypes.oneOf(['create', 'update']),
};
const defaultProps = {
  mode: 'create',
};

/**
 * ReduxForm stepper rules
 * * Connect each step with reduxForm() to the same form name.
 * * Specify the destroyOnUnmount: false flag to preserve form data across form component unmounts.
 */
const ButtonWrapper = styled('div')({
  position: 'absolute',
  bottom: 0,
  right: '50%',
  transform: 'translate(50%, 50%)',
  zIndex: 4,
});

const StyledButton = styled(Button)(({ theme: { spacing, typography } }) => ({
  height: spacing(4.75),
  width: spacing(25),
  fontSize: typography.subtitle1.fontSize,
  marginLeft: spacing(0.75),
  marginRight: spacing(0.75),
}));


const CreateLeave = reduxForm({
  form: FORM_NAME, // Form name
  destroyOnUnmount: false, // Preserve data
  onSubmit: (v) => v,
})(({ mode, handleSubmit: handleReduxFormSubmit, dispatch, ...rest }) => {

  const [dropDownValue, setDropDownValue] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [leaveType, setLeaveType] = useState(0);

  const RXDispatch = useDispatch();

  useEffect(() => {
    RXDispatch(formInitialize(FORM_NAME, {
      isEmergency: "false",
      leaveType: "0"
    }));
  }, [mode]);


  const handleCreate = () => {
    if (hasError) {
      handleReduxFormSubmit();
      return;
    }

    if (mode === "create") {
      RXDispatch(createLeave({ handleSubmit }));
    }
  }


  const navigate = useNavigate();

  const handleSubmit = (payload) => {
    RXDispatch(formInitialize(FORM_NAME, {
      isEmergency: false
    }));

    setTimeout(() => {
      navigate("/leave-management/applied-leaves");
    }, 1000)
  }

  const hasError = useSelector((state) =>
    hasFormErrorSelector(state, FORM_NAME)
  );


  const setTotalNumberOfDays = (startDate, endDate, leaveType) => {
    const totalNumberOfDays = dayjs(endDate).diff(dayjs(startDate), 'day') + 1 - leaveType;
    dispatch(change(FORM_NAME, 'totalNumberOfDays', totalNumberOfDays));
  }

  const inputFileHandler = ()=>{
    return dropDownValue && (dropDownValue.toLowerCase().includes("sick") || dropDownValue.toLowerCase().includes("paternity"))
  }
  return (
    <ContentContainer
      title={mode + ' Leave'}
      sx={{ textTransform: 'capitalize' }}
      AdornmentComponent={<Fragment />}
    >
      <GridContainer>
        <GridItem md={4}>
          <AutocompleteFetch
            name='employeeEmail'
            placeholder='Employee Email'
            label='E-Mail'
            domain={COMMON_DOMAIN}
            required={true}
            validate={[required]}
            disabled={mode === "update" ? true : false}
            entity={GET_ALL_EMPLOYEE_NAME_EMAIL}
            customselector={getAllEmployeeSelector}
          />

        </GridItem>
        <GridItem md={4}>
          <AutocompleteFetch
            name='category'
            placeholder='Category Name'
            label='Category Name'
            domain={DOMAIN_NAME}
            entity={LEAVE_CATEGORY}
            required={true}
            validate={[required, isEmpty]}
            onChange={(_, value) => setDropDownValue(value)}
          />
        </GridItem>
        <GridItem md={4}>
          <AutocompleteFetch
            name='employeesToNotify'
            placeholder='Emails'
            label='Employees To Notify'
            domain={COMMON_DOMAIN}
            entity={GET_ALL_EMPLOYEE_NAME_EMAIL}
            multiple
            customselector={getAllEmployeeSelector}
          />
        </GridItem>
        <GridItem md={4}>
          <DatePickerBase
            name='startDate'
            placeholder='Start Date'
            label='Start Date'
            required={true}
            validate={required}
            maxDate={dayjs().add(1, 'year')}
            minDate={dayjs().subtract(1, 'year')}
            onChange={(value) => {
              setStartDate(value);
              if (endDate) {
                setTotalNumberOfDays(value, endDate, leaveType);
              }
            }}
          />
        </GridItem>
        <GridItem md={4}>
          <DatePickerBase
            name='endDate'
            placeholder='End Date'
            label='End Date'
            required={true}
            validate={required}
            maxDate={dayjs().add(1, 'year')}
            disabled={startDate ? false : true}
            minDate={dayjs(startDate)}
            onChange={(value) => {
              setEndDate(value);
              setTotalNumberOfDays(startDate, value, leaveType);
            }}

          />
        </GridItem>
        <GridItem md={4}>
          <Input
            name='totalNumberOfDays'
            placeholder='Total Number Of Days'
            label='Total Number Of Days'
            required={true}
            validate={[required]}
            type="number"
            disabled
          />
        </GridItem>
        <GridItem md={12}>
          <InputRadio
            name='leaveType'
            label='Leave Type'
            options={LEAVE_TYPE}
            required={true}
            validate={required}
            onChange={(e, value) => {
              setLeaveType(value);
              setTotalNumberOfDays(startDate, endDate, value);
            }}
          />
        </GridItem>

        {inputFileHandler() && (
          <GridItem md={4}>
            <InputFile
              name='requiredDocuments'
              label='Required Document'
            />
          </GridItem>)
        }
        <GridItem md={12}>
          <TextArea
            name='reason'
            label='Reason'
            rows='2'
            required={true}
            validate={[required]}
          />
        </GridItem>
        <GridItem md={12}>
          <InputRadio
            name='isEmergency'
            label='Is Emergency?'
            options={IS_EMERGENCY}
            required={true}
            validate={required}
          />
        </GridItem>
      </GridContainer>
      <ButtonWrapper>
        <StyledButton onClick={handleCreate}>
          {mode === "create" ? "CREATE" : "UPDATE"}
        </StyledButton>
      </ButtonWrapper>
    </ContentContainer>
  );
});

CreateLeave.propTypes = propTypes;
CreateLeave.defaultProps = defaultProps;

export { CreateLeave as default, CreateLeave };

