import { GridContainer, GridItem, Input } from '@formComponents';
import { Button } from '@libComponents';
import { ContentContainer } from '@libComponents/ContentContainer';
import { Box, styled } from '@mui/material';
import { theme } from 'app/Theme';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { initialize as formInitialize, reduxForm } from 'redux-form';
import { createRole, getPermission, updateRole } from 'roles/redux.actions';
import SelectAllCheckbox from './SelectAllCheckbox';
import { FORM_NAME } from './common';

const StyledBox = styled(Box)(
    ({ theme: { spacing, palette, typography, shape } }) => ({
        width: '100%',
        minWidth: '100%',
        textTransform: 'capitalize',
    })
);

const propTypes = {};

const ButtonWrapper = styled('div')({
    position: 'absolute',
    bottom: 0,
    right: '50%',
    transform: 'translate(50%, 50%)',
    zIndex: 4,
});

const StyledButton = styled(Button)(({ theme: { spacing, typography } }) => ({
    height: spacing(4.75),
    width: spacing(25),
    fontSize: typography.subtitle1.fontSize,
    marginLeft: spacing(0.75),
    marginRight: spacing(0.75),
}));

const CreateRole = reduxForm({
    form: FORM_NAME, // Form name
    destroyOnUnmount: false, // Preserve data
    onSubmit: (v) => v,
})(({ mode, ...rest }) => {
    const RXDispatch = useDispatch();
    const [data, setData] = React.useState([]);
    const { state } = useLocation();
    const { name } = state ? state : '';
    const navigate = useNavigate();
    const action = name ? 'Update' : 'Create';

    useEffect(() => {
        if (name && mode === 'update') {
            RXDispatch(
                getPermission({
                    handleSubmit: getPermissionsList,
                    name,
                })
            );
        } else {
            RXDispatch(
                getPermission({ handleSubmit: getPermissionsList, payload: {} })
            );
        }
    }, [name]);

    const getPermissionsList = (data) => {
        setData(data);
        let formData = { ...data };
        if (name) {
            formData.name = name;
        }
        RXDispatch(formInitialize(FORM_NAME, formData));
    };

    const handleCreate = () => {
        if (action === 'Create') {
            RXDispatch(createRole({ handleComplete: handleCreateUpdateResponse }));
        }
        if (action === 'Update') {
            RXDispatch(updateRole({ handleComplete: handleCreateUpdateResponse }));
        }
    };


    const handleCreateUpdateResponse = (payload) => {
        try {
            setTimeout(() => {
                navigate("/roles/all-roles");
            }, 2000);
        } catch (error) {
            
        }
    };

    if (!Object.entries(data).length) return null;

    return (
        <Box pt={4} pb={4}>
            <ContentContainer
                title={action + ' Role'}
                headerBg={theme.palette.background.mainHeader}
            >
                <GridContainer sx={{ pb: 1, pl: 2, width: '100%' }}>
                    <GridItem md={3}>
                        <Input
                            name='name'
                            placeholder='Employee'
                            label='Role'
                            disabled={name && mode === 'update' ? true : false}
                        />
                    </GridItem>
                </GridContainer>
                <Box sx={{ width: '100%', p: (2, 4), fontSize: '16px' }}>
                    <ContentContainer
                        title='Role Permission'
                        sx={{
                            width: '100%',
                            textAlign: 'start',
                            display: 'flex',
                            justifyContent: 'flex-start',
                        }}
                    >
                        <StyledBox>
                            {Object.entries(data).map(
                                ([name, checkboxOptions], index) => (
                                    <SelectAllCheckbox
                                        name={name}
                                        checkboxOptions={checkboxOptions}
                                        key={index}
                                    />
                                )
                            )}
                        </StyledBox>
                    </ContentContainer>
                </Box>
                <ButtonWrapper>
                    <StyledButton onClick={handleCreate}>{action}</StyledButton>
                </ButtonWrapper>
            </ContentContainer>
        </Box>
    );
});

CreateRole.propTypes = propTypes;

export default CreateRole;
