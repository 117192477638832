import { useMemo } from 'react';

import { useNavigationState } from '../useNavigation';

export const useAppBarStyle = () => {
    const {
        drawerWidth,
        appBarHeight,
        isDrawerVisible,
        isDrawerMinified,
        isDrawerFloating,
    } = useNavigationState();

    return useMemo(() => {
        const appBarPadding = isDrawerVisible
            ? isDrawerMinified && !isDrawerFloating
                ? appBarHeight
                : drawerWidth
            : 0;

        return {
            '--appBarPadding': `${appBarPadding}px`,
            '--appBarHeight': `${appBarHeight}px`,
        };
    }, [
        drawerWidth,
        appBarHeight,
        isDrawerVisible,
        isDrawerMinified,
        isDrawerFloating,
    ]);
};

export const useBreadcrumbs = ({ pathname }) => {
    const { flatHashes } = useNavigationState();

    return useMemo(() => {
        const { parents = [], name = '' } = flatHashes[pathname] || {};
        return [...parents, { route: pathname, name }];
    }, [flatHashes, pathname]);
};
