import { calculateSecondsToHoursAndMinutes } from '@libs/dateTime';

import {
    WeekdayHours,
    TimesheetDataCell,
    TimesheetDataRowWrapper,
    TimesheetsUser
} from '../../../components';

const Row = ({ entries, totalDuration, totalShiftDuration, isOdd, email }) => {
    return (
        <TimesheetDataRowWrapper isOdd={isOdd}>
            <TimesheetsUser sx={{ gridColumn: 'span 3' }} name={email} />
            <WeekdayHours sx={{ gridColumn: 'span 8' }} entries={entries} />
            <TimesheetDataCell
                sx={{
                    gridColumn: 'span 1',
                    textAlign: 'center',
                    opacity: 0.8
                }}
            >
                {calculateSecondsToHoursAndMinutes(totalDuration)}/
                {calculateSecondsToHoursAndMinutes(totalShiftDuration)}
            </TimesheetDataCell>
        </TimesheetDataRowWrapper>
    );
};

export default Row;
