import { styled } from '@mui/system';
import { Box } from '@mui/material';

import Header from './Header';
import Row from './Row';
import { useWeeklyTimesheetsState } from '../useWeeklyTimesheets';
import { TimesheetsNoDataFound } from '../../../components';

const StyledDataTableContainer = styled(Box)(({ theme: { spacing } }) => ({
    width: `calc(100% - ${spacing(4)})`,
    margin: spacing(2)
}));

const TimesheetsDailyDataTable = () => {
    const { data } = useWeeklyTimesheetsState();
    return (
        <StyledDataTableContainer>
            <Header />
            {data.length === 0 ? (
                <TimesheetsNoDataFound />
            ) : (
                data.map((user, idx) => (
                    <Row {...user} key={idx} isOdd={(idx + 1) % 2 === 1} />
                ))
            )}
        </StyledDataTableContainer>
    );
};

export default TimesheetsDailyDataTable;
