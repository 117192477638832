import makeContext from '@libs/makeContext';
import { DASHBOARD_OVERVIEW_EVENTS_CALENDER_INITIAL_API_PARAMS } from '../../common';


import { SET_MONTH } from '../../context.actionTypes';
import { getCurrentMonthDate } from '@libs/dateTime';

const initialState = {
    ...DASHBOARD_OVERVIEW_EVENTS_CALENDER_INITIAL_API_PARAMS
};

const reducer = (state, { type, payload }) => {
    switch (type) {
        case SET_MONTH: {
            return {
                ...state,
                start: getCurrentMonthDate(payload).startOfMonth,
                end: getCurrentMonthDate(payload).endOfMonth
            };
        }
        default:
            return state;
    }
};

const middleware = (state, action, { fetchData }) => {
    switch (action.type) {
        case SET_MONTH: {
            const { payload } = action;

            fetchData({
                start: getCurrentMonthDate(payload).startOfMonth,
                end: getCurrentMonthDate(payload).endOfMonth
            });

            return action;
        }
        default:
            return action;
    }
};

const [
    EventsCalenderProvider,
    useEventsCalenderState,
    useEventsCalenderDispatch,
    useEventsCalender
] = makeContext(reducer, initialState, {
    name: 'EventsCalender',
    middleware
});

export {
    useEventsCalender as default,
    EventsCalenderProvider,
    useEventsCalenderState,
    useEventsCalenderDispatch,
    SET_MONTH
};
