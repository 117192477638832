import {useState, Fragment} from 'react';
import { DepartmentCreateUpdate } from './DepartmentCreateUpdate';
import ViewDepartment from './ViewDepartment';
import { DeleteDepartment } from './DeleteDepartment';

export default function Department() {
    const [viewType, setViewType] = useState('default');
    const [data, setData] = useState({ mode: 'create', row: {} });

    return (
        <Fragment>
            {viewType === 'default' && (
                <ViewDepartment setViewType={setViewType} setData={setData} />
            )}
            {viewType === 'createDepartment' && (
                <DepartmentCreateUpdate
                    mode={data.mode}
                    row={data.row}
                    setViewType={setViewType}
                />
            )}
            {viewType === 'deleteDepartment' && (
                <DeleteDepartment
                    row={data.row}
                    setViewType={setViewType}
                />
            )}
        </Fragment>
    );
}
