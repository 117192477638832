import PropTypes from 'prop-types';

import { Box, styled } from '@mui/material';

import Button from '@libComponents/Button';
import { capitalizeFirstLetter } from '@libs/string';

const propTypes = {
    selectedView: PropTypes.oneOf(['daily', 'weekly', 'monthly']).isRequired,
    onViewChange: PropTypes.func.isRequired
};

const StyledBox = styled(Box)(({ theme }) => ({
    padding: `${theme.spacing(0.5)} 0px`
}));

const StyledCustomButton = styled(Button)({
    borderRadius: 0
});

const ViewAdornment = ({ selectedView, onViewChange }) => {
    const viewOptions = ['daily', 'weekly', 'monthly'];

    return (
        <StyledBox>
            {viewOptions.map((option) => (
                <StyledCustomButton
                    key={option}
                    color='quaternary'
                    variant={option === selectedView ? 'contained' : 'outlined'}
                    onClick={() => onViewChange(option)}
                >
                    {capitalizeFirstLetter(option)}
                </StyledCustomButton>
            ))}
        </StyledBox>
    );
};

ViewAdornment.propTypes = propTypes;

export default ViewAdornment;
