import PropTypes from 'prop-types';
import { FORM_NAME } from './common';
import { Button } from '@libComponents';
import { useDispatch } from 'react-redux';
import { reduxForm } from 'redux-form';
import { deleteBranch } from './redux.actions';
import { Box, Typography } from '@mui/material';

const propTypes = {
    mode: PropTypes.oneOf(['create', 'update']),
};
const defaultProps = {
    mode: 'create',
};

const DeleteBranch = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
    onSubmit: (v) => v,
})(({ row, setViewType, ...rest }) => {
    const RXDispatch = useDispatch();

    const handleClose = () => {
        setViewType("default");
    }

    const handleDelete = () => {
        RXDispatch(deleteBranch({ handleSubmit: handleClose, payload: row }));
    }

    return (
        <>
            <Typography variant='h6' sx={{ mb: 4, mt: 4, textAlign: "center" }}>
                Do you want to delete the branch?
            </Typography>

            <Box sx={{ mb: 2, display: "flex", alignItems: "center", justifyContent: "center", gap: 2 }}>
                <Button onClick={handleClose} color="tertiary">Cancel</Button>
                <Button onClick={handleDelete}>Delete</Button>
            </Box>
        </>
    );
});

DeleteBranch.propTypes = propTypes;
DeleteBranch.defaultProps = defaultProps;

export { DeleteBranch as default, DeleteBranch };

