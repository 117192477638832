import { Box, Table, TableContainer } from '@mui/material';

const CustomTableContainer = (
    ({ className, ...props }) => (
        <TableContainer
            component={Box}
            className={className}
            {...props}
        >
            <Table  aria-label='table'>
                {props.children}
            </Table>
        </TableContainer>
    )
);

export { CustomTableContainer as default, CustomTableContainer };