import PropTypes from 'prop-types';

import { styled } from '@mui/system';
import { Typography } from '@mui/material';

import UserAvatar from './UserAvatar';

const propTypes = {
    name: PropTypes.string.isRequired,
    avatarUrl: PropTypes.string
};

const StyledContainer = styled('div')({
    display: 'flex',
    alignItems: 'center'
});

const StyledTypography = styled(Typography)(({ theme: { spacing } }) => ({
    margin: `0px ${spacing(1.5)}`,
    fontSize: spacing(2),
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
}));

const TimesheetsUser = ({ name, avatarUrl, ...rest }) => {
    return (
        <StyledContainer {...rest}>
            <UserAvatar name={name} avatarUrl={avatarUrl} />
            <StyledTypography>{name}</StyledTypography>
        </StyledContainer>
    );
};

TimesheetsUser.propTypes = propTypes;

export { TimesheetsUser as default, TimesheetsUser };
