import { initializeDatasets } from '@libs/datasets';

import datasets from './redux.datasets';

const initialState = {
    ...initializeDatasets(datasets),
};

export default (state = initialState, { type, payload, ...action }) => {
    switch (type) {
        case "updateWeeklyActivities": {
            const { entity, value } = payload;
            return { ...state, [entity]: value };
        }

        case "updateAttendance": {
            const { entity, value } = payload;
            if (state[entity] && state[entity].data) {
                value.data = [...value.data]
            }
            return { ...state, [entity]: value };
        }

        case "pushAttendanceRow": {
            const { entity, value } = payload;
            const { data } = state[entity];
            let allEntries = [...data];
            allEntries.unshift(value);
            return { ...state, [entity]: { ...state[entity], data: allEntries } }
        }
        default: {
            return state;
        }
    }
};
