const DOMAIN_NAME = 'leaveManagement';
const LEAVE_MANAGEMENT_ROUTE_ID = 21;
const CREATE_LEAVE_MANAGEMENT_ROUTE_ID = 22;
const APPLIED_LEAVES_MANAGEMENT_ROUTE_ID = 23;
// const HOLIDAYS_ROUTE_ID = 24;
const ROWS_PER_PAGE = 10;
const FORM_NAME = "leaveManagement";
const REJECT_REASON_FORM_NAME = "rejectReason";

const IS_EMERGENCY = [
    { label: "Yes", value: true },
    { label: "No", value: false }
];

const LEAVE_TYPE = [
    { label: "Full Day", value: 0 },
    { label: "Half Day", value: 0.5 },
    { label: "Quarter day", value: 0.75 }
];
export { DOMAIN_NAME, LEAVE_MANAGEMENT_ROUTE_ID, ROWS_PER_PAGE, CREATE_LEAVE_MANAGEMENT_ROUTE_ID, FORM_NAME, APPLIED_LEAVES_MANAGEMENT_ROUTE_ID,  IS_EMERGENCY, REJECT_REASON_FORM_NAME, LEAVE_TYPE };
