import { Accordion, AccordionSummary, AccordionDetails, Grid, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/material/styles';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { getFileURL } from 'common/redux.actions';
import { Link } from 'react-router-dom';


const StyledTitle = styled(Typography)(({ theme: { typography } }) => ({
  display: 'flex',
  ...typography.h6,
  fontWeight: typography.fontWeightBold,
  whiteSpace: 'nowrap',
}));


const CustomAccordion = ({ expanded, onChange, panelId, title, dataInfo }) => {
  const theme = useTheme();

  const RXDispatch = useDispatch();

  const handleLink = async (e) => {
    e.preventDefault();
    RXDispatch(getFileURL({
      handleSubmit, payload: { url: e.target.href }
    }));
  };

  const handleSubmit = (resp) => {
    if (resp.type === "success") {
      window.open(resp.url, "_blank");
    }
  };

  return (
    <Accordion expanded={expanded === panelId} onChange={onChange(panelId)} sx={{ m: 0 }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${panelId}bh-content`}
        id={`${panelId}bh-header`}
        sx={{ bgcolor: theme.palette.background.mainHeader, borderBottom: `1px solid ${theme.palette.border.secondary}`, margin: 0, "&>div": { margin: "0 !important" } }}
      >
        <StyledTitle>{title}</StyledTitle>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 4 }}>
        <Grid container spacing={1} sx={{ justifyContent: 'space-between' }}>
          {dataInfo.map((info) => (
            <Grid item xs={5} key={info.key}>
              <Grid container>
                <Grid item xs={5}>
                  <Typography sx={{ fontWeight: "Bold" }}>{info?.label}</Typography>
                </Grid>
                <Grid item xs={7}>
                  {info.link ? (
                    <Link target='_blank' to={info.link} onClick={handleLink}> Preview</Link>
                  ) : (
                    <Typography>: {info.value || "N/A"}</Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default CustomAccordion;