import { useCallback } from 'react';

import Pagination from '@libComponents/Pagination';

import useWeeklyTimesheets, { SET_PAGE_NUMBER, SET_PAGE_SIZE } from './useWeeklyTimesheets';

const _Pagination = () => {
    const [{ currentPage, totalPages, pageSize }, dispatch] = useWeeklyTimesheets();

    const handlePageChange = useCallback(
        (event, value) => {
            dispatch({
                type: SET_PAGE_NUMBER,
                payload: value
            });
        },
        [dispatch]
    );

    const handleRowsPerPageChange = useCallback(
        (value) => {
            dispatch({
                type: SET_PAGE_SIZE,
                payload: value
            });
        },
        [dispatch]
    )

    return (
        !!totalPages && (
            <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                sx={{ display: 'flex', justifyContent: 'center' }}
                isRowsPerPageVarient={true}
                rowsPerPageProps={{
                    name: 'adminWeeklyTimesheet',
                    onChange: handleRowsPerPageChange,
                    value: pageSize
                }}
            />
        )
    );
};

export default _Pagination;
