import { DESIGNATION } from 'common/redux.datasets';
import { commonSelector } from 'common/redux.selectors';
import { createSelector } from 'reselect';


const getAllDesignation = createSelector(
    commonSelector,
    data => {
        return data && data[DESIGNATION] && data[DESIGNATION].data.map((item) => {
            return {
                name: item.name,
                description: item.description,
            }
        })
    }
);

export { getAllDesignation };

