import PropTypes from 'prop-types';

import { styled } from '@mui/system';
import Tooltip from '@mui/material/Tooltip';

import { convertToMinutes } from '@libs/dateTime';

import TimeRangeBarTooltipContent from './TimeRangeBarTooltipContent';
import { usePresentTypeColor } from '../../hooks';

const propTypes = {
    inTime: PropTypes.string,
    outTime: PropTypes.string
};

const StyledTimeRangeBarContainer = styled('div')(({ theme }) => ({
    width: '100%',
    minWidth: theme.spacing(50),
    position: 'relative',
    height: theme.spacing(1.25)
}));

const StyledBaseBar = styled('div')(({ theme }) => ({
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundColor: theme.palette.background.dark,
    borderRadius: theme.spacing(1.25)
}));

const StyledOverlayBar = styled('div')(
    ({ theme, width, left, backgroundColor }) => {
        return {
            position: 'absolute',
            height: '100%',
            borderRadius: theme.spacing(1.25),
            backgroundColor: backgroundColor,
            width: width,
            left: left
        };
    }
);

const TimeRangeBar = ({ inTime, outTime, status, ...rest }) => {
    const barBackgroundColor = usePresentTypeColor(status);
    const oneDayInSeconds = 24 * 60 * 60;
    const isWithinLast24Hours =
        (new Date() - new Date(inTime)) / 1000 < oneDayInSeconds;

    let lastTime;
    if (outTime) {
        lastTime = outTime;
    } else if (isWithinLast24Hours) {
        lastTime = new Date().toString();
    } else {
        lastTime = outTime;
    }

    const overlayBarStyles = () => {
        const loginMinutes = convertToMinutes(inTime);
        let logoutMinutes = convertToMinutes(lastTime);

        if(logoutMinutes < loginMinutes)
            logoutMinutes += 24*60;

        const totalMinutes = 36 * 60; // 36 hours * 60 minutes

        const overlayBarWidth =
            Math.round(((logoutMinutes - loginMinutes) / totalMinutes) * 100) +
            '%';

        return {
            width: overlayBarWidth,
            left: Math.round((loginMinutes / totalMinutes) * 100) + '%',
            backgroundColor: barBackgroundColor
        };
    };

    return (
        <StyledTimeRangeBarContainer {...rest}>
            <StyledBaseBar />
            {inTime && lastTime && (
                <Tooltip
                    title={
                        <TimeRangeBarTooltipContent
                            inTime={inTime}
                            outTime={outTime}
                        />
                    }
                    arrow
                >
                    <StyledOverlayBar {...overlayBarStyles()} />
                </Tooltip>
            )}
        </StyledTimeRangeBarContainer>
    );
};

TimeRangeBar.propTypes = propTypes;

export { TimeRangeBar as default, TimeRangeBar };
