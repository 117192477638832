import {
    RESET_SNACK,
    SET_SNACK,
    RESET_LOADING,
    SET_LOADING,
    SET_LOADING_PROGRESS,
    SET_COMPONENT_LOADING,
} from './redux.actionTypes';

const initialState = {
    loading: false,
    loadingProgress: 0,
    snack: null,
};

export default (state = initialState, { type, payload, ...action }) => {
    switch (type) {
        case RESET_LOADING: {
            return {
                ...state,
                loading: initialState.loading,
                loadingProgress: initialState.loadingProgress,
            };
        }

        case SET_LOADING: {
            return { ...state, loading: payload };
        }

        case SET_LOADING_PROGRESS: {
            return { ...state, loadingProgress: payload };
        }

        case RESET_SNACK: {
            return { ...state, snack: initialState.snack };
        }

        case SET_SNACK: {
            return { ...state, snack: payload };
        }

        case SET_COMPONENT_LOADING: {
            const { entity, loading } = payload;
            return { ...state, [entity]: loading };
        }

        default: {
            return state;
        }
    }
};
