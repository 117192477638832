export const rowsToShowInDetails = {
    'employeeEmail': {
        label: 'Employee Email',
        type: "string"
    },
    'status': {
        label: 'Status',
        type: "string"
    },
    "category": {
        label: "Category",
        type: "object",
        key: "name"
    },
    'totalNumberOfDays': {
        label: 'Number Of Days',
        type: "string"
    },
    'startDate': {
        label: "Start Date",
        type: "date"
    },
    'endDate': {
        label: 'End Date',
        type: "date"
    },
    "employeesToNotify": {
        label: "Employees To Notify",
        type: "array"
    },
    "isEmergency": {
        label: "Is Emergency",
        type: "boolean"
    },
    "createdAt": {
        label: "Created date",
        type: "date"
    },
    "updatedAt": {
        label: "Updated date",
        type: "date"
    },
    "updatedBy": {
        label: "Updated By",
        type: "string",
    },
    "requiredDocuments": {
        label: "Documents",
        type: "file"
    },
    "reason": {
        label: 'Reason',
        type: "string"
    },
    "comment": {
        label: 'Admin Comment',
        type: "string"
    },
};


export const TABLE_DATA = {
    'employeeEmail': 'Employee Email',
    'categoryName': 'Leave Type',
    'totalNumberOfDays': 'Number Of Days',
    'startDate': "Start Date",
    'endDate': 'End Date',
    'status': 'Status',
};