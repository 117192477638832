import { initializeDatasets } from '@libs/datasets';

import datasets from './redux.datasets';

const initialState = {
    ...initializeDatasets(datasets),
};

export default (state = initialState, { type, payload, ...action }) => {
    switch (type) {

        case "deleteRole": {
            const { entity, value } = payload;
            const { data } = state[entity];
            let roles = [...data];
            roles = roles.filter((item) => (item.name !== value.name));
            return { ...state, [entity]: { ...state[entity], data: roles } };

        }
        default: {
            return state;
        }
    }
};
