import { useMemo } from 'react';
import { alpha } from '@mui/material';

const useSwitchColor = ({ palette }, switchColor) =>
    useMemo(() => {
        const color = palette[switchColor];
        return {
            '--disabledColor': color.light,
            '--color': color.main,
            '--hoverColor': color.dark,
            '--outlinedDisabledColor': alpha(color.light, 0.1),
            '--outlinedHoverColor': alpha(color.dark, 0.1),
        };
    }, [switchColor]);

export { useSwitchColor };
