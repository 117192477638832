import { useCallback } from 'react';
import { useTheme } from '@mui/material';

import { GridContainer, GridItem } from '@formComponents';
import { normalizeIso8601Date } from '@libs/dateTime';

import useMonthlyTimesheets, { SET_YEAR_MONTH } from './useMonthlyTimesheets';
import { TimesheetsMonthPicker } from '../../components';

const Filters = () => {
    const { spacing } = useTheme();
    const [{ date }, dispatch] = useMonthlyTimesheets();

    const handleYearMonth = useCallback((value) => {
        try {
            if (value) {
                const normalizeValue = normalizeIso8601Date(value);
                dispatch({
                    type: SET_YEAR_MONTH,
                    payload: normalizeValue
                });
            }
        } catch {}
    }, []);

    return (
        <GridContainer sx={{ paddingBottom: spacing(2) }}>
            <GridItem md={3}>
                <TimesheetsMonthPicker
                    input={{
                        value: date,
                        onChange: handleYearMonth
                    }}
                />
            </GridItem>
        </GridContainer>
    );
};

export default Filters;
