import InputBase from '@mui/material/InputBase';

import { withField } from '@libs/reduxForm';
import { Autocomplete, FormControl, styled } from '@mui/material';

// import { districts } from './allDistrict';
import Label from '@baseComponents/Label';

import FieldProgressBar from '@baseComponents/FieldProgressBar';
import HelperText from '@baseComponents/HelperText';
import { ArrowDropDown } from '@mui/icons-material';

const StyledInput = styled(InputBase)(
    ({ theme: { spacing, palette, typography, shape } }) => ({
        width: '100%',
        minHeight: '35px',
        border: '1px solid',
        borderColor: palette.border.main,
        borderRadius: shape.borderRadius,
        paddingLeft: spacing(2),
        // paddingRight: spacing(2),
        fontSize: typography.subtitle2.fontSize,
        '&:hover': {
            borderColor: palette.border.dark,
        },
        '&.Mui-focused': {
            borderColor: palette.grey[500],
            '&:hover': {
                borderColor: palette.grey[700],
            },
        },
        '&.Mui-error': {
            borderColor: `${palette.error.light}`,
            '&:hover': {
                borderColor: `${palette.error.dark}`,
            },
        },
        '&.Mui-disabled': {
            display: 'flex',
            alignItems: 'center',
            backgroundColor: palette.background.disabled,
            color: palette.secondary.main,

            '&:hover': {
                borderColor: palette[300],
            },
        },

        '& .MuiChip-root': {
            height: 'auto',
            paddingTop: '2px',
            paddingBottom: '2px',
        },
        '& .MuiSvgIcon-root[data-testid="ArrowDropDownIcon"]': {
            position: 'absolute',
            top: '5px',
            right: '10px',
        },
    })
);

const StyledBox = styled('div')(
    ({ theme: { spacing, palette, typography } }) => ({
        '& .MuiAutocomplete-option': {
            color: palette.text.primary,
            fontSize: '12px',
        },
    })
);
const StyledFormControl = styled(FormControl)({
    width: '100%',
    ' label': {
        paddingTop: '10px',
        textTransform: 'initial',
    },
});

const AutocompleteMultiple = withField()(
    ({
        fieldName,
        input,
        disabled,
        required,
        label,
        loading,
        staticValue,
        meta: { form, touched, error = null, asyncValidating },
        placeholder,
        options,
        multiple,
        ...restProps
    }) => {
        const id = `${form}-${fieldName}`;
        const isDisabled = disabled || loading;
        const hasError = !isDisabled && touched && !!error;
        const { onChange } = input;

        if (multiple) {
            if (!input.value) {
                input.value = [];
            }
        }
        const inputBaseProps = {
            id,
            disabled: isDisabled,
            ...(staticValue ? { value: staticValue } : input),
            ...restProps,
        };

        options = options.map(({ label }) => ({ name: label }));

        return (
            <StyledFormControl>
                <Label
                    label={label}
                    disabled={isDisabled}
                    required={required}
                    error={hasError}
                    htmlFor={id}
                />
                <StyledBox>
                    <Autocomplete
                        freeSolo
                        multiple
                        clearOnBlur
                        {...inputBaseProps}
                        options={options}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, newValue) => {
                            onChange(newValue);
                        }}

                        isOptionEqualToValue={(option, value) => option.value === value}
                        onBlur={(e) => {
                        }}
                        renderInput={(params) => {
                            const { InputLabelProps, InputProps, ...rest } =
                                params;
                            return (
                                <StyledInput
                                    {...params.InputProps}
                                    {...rest}
                                    endAdornment={<ArrowDropDown />}
                                />
                            );
                        }}
                    />
                </StyledBox>
                {(loading || asyncValidating) && (
                    <FieldProgressBar asyncValidating={asyncValidating} />
                )}
                {hasError && <HelperText msg={error} required={required} />}
            </StyledFormControl>
        );
    }
);

export default AutocompleteMultiple;
