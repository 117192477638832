import apiRequest from '@libs/apiRequest';
import { APPLIED_LEAVES } from 'leaveManagement/redux.datasets';
import { call, put } from 'redux-saga/effects';
import { setLoading, setSnack } from 'utilities/redux.actions';

export default function* (props) {
    const {
        meta: { handleSubmit = () => { } },
        payload: { email }
    } = props;

    const url = `leave/get/remaining?email=${email}`;
    yield put(setLoading(true));

    try {
        const { data } = yield call(
            apiRequest,
            {
                url,
                method: "get"
            }
        );
        yield put(setLoading(false));

        return handleSubmit(data);
    } catch (error) {
        yield put(setLoading(false));
        yield put(setSnack({
            message: error?.response?.data?.message || error?.message,
            type: 'error',
        }));
        return handleSubmit(error);
    }
}
