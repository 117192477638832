// import { getFormValues } from 'redux-form';

import { apiRequest } from "@libs/apiRequest";
import { call, put } from "redux-saga/effects";
import { setLoading, setSnack } from "utilities/redux.actions";

export default function* (action) {
    const {
        meta: { handleSubmit = () => { } },
        payload,
        ...rest
    } = action;
    try {
        const { data } = yield call(
            apiRequest,
            {
                url: `attendance/status`,
                method: 'GET'
            }
        ); 
        return handleSubmit(data);

    } catch (error) {
        yield put(setSnack({
            message: error?.response?.data?.message || error?.message,
            type: 'error',
        }));
        return handleSubmit(error);
    }
}

