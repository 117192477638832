import { SelectBase } from '@baseComponents/Select';

const statusDatsets = [
    { value: null, label: 'All Status' },
    {
        value: 'approved',
        label: 'Approved'
    },
    {
        value: 'rejected',
        label: 'Rejected'
    },
    {
        value: 'pending',
        label: 'Pending'
    }
];

const defaultProps = {
    fieldName: 'status',
    placeholder: 'Select Status',
    meta: { form: 'leave-status' },
    data: statusDatsets
};

const SelectLeaveStatus = (props) => <SelectBase {...props} />;

SelectLeaveStatus.defaultProps = defaultProps;

export default SelectLeaveStatus;
