import FormArray from '@formComponents/FormArray';
import React from 'react';
import renderReferences from './ReferencesInformationFields';

const ReferencesInformation = ({ ...restProps }) => {
    return (
        <React.Fragment>
            <FormArray name="references" label="Add Reference" renderSubFields={renderReferences}/>
        </React.Fragment>
    );
};

export default ReferencesInformation;
