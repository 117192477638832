import { useMemo } from 'react';
import PropTypes from 'prop-types';

import {
    styled,
    LinearProgress as MuiLinearProgress,
    useTheme,
} from '@mui/material';

const propTypes = {
    asyncValidating: PropTypes.bool,
};

const defaultProps = {
    asyncValidating: false,
};

const StyledProgressBar = styled(MuiLinearProgress)(
    ({ theme: { spacing } }) => ({
        backgroundColor: 'var(--secondary)',
        height: spacing(0.5),
        marginTop: `${spacing(-0.5)} !important`,
        marginLeft: 1,
        marginRight: 1,
        borderBottomLeftRadius: spacing(0.25) + 1,
        borderBottomRightRadius: spacing(0.25) + 1,
        '& .MuiLinearProgress-colorPrimary': {
            backgroundColor: 'var(--primary)',
            borderBottomLeftRadius: spacing(0.25) + 1,
            borderBottomRightRadius: spacing(0.25) + 1,
        },
    })
);

const FieldProgressBar = ({ asyncValidating, ...props }) => {
    const { palette } = useTheme();
    const progressStyle = useMemo(() => {
        const primary = asyncValidating
            ? palette.primary.main
            : palette.secondary.main;
        const secondary = asyncValidating
            ? palette.primary.light
            : palette.secondary.light;

        return { '--primary': primary, '--secondary': secondary };
    }, [asyncValidating]);
    return <StyledProgressBar style={progressStyle} {...props} />;
};

FieldProgressBar.propTypes = propTypes;
FieldProgressBar.defaultProps = defaultProps;

export default FieldProgressBar;
