import { CREATE_AND_UPDATE_DEPARTMENT, DELETE_DEPARTMENT, UPDATE_DEPARTMENT } from './redux.actionTypes';

export const createDepartment = ({ handleSubmit, mode, payload }) => ({
    type: CREATE_AND_UPDATE_DEPARTMENT,
    meta: { handleSubmit },
    mode,
    payload,
});

export const createAndUpdateDepartment = ({ handleSubmit, mode, payload }) => ({
    type: CREATE_AND_UPDATE_DEPARTMENT,
    meta: { handleSubmit },
    mode,
    payload,
});

export const deleteDepartment = ({ handleSubmit, payload }) => ({
    type: DELETE_DEPARTMENT,
    meta: { handleSubmit },
    payload,
});

export const updateDepartment = ({ handleSubmit, mode, ...payload }) => ({
    type: UPDATE_DEPARTMENT,
    meta: { handleSubmit },
    mode,
    payload,
});