import styled from '@emotion/styled';
import { theme } from "app/Theme"
import { Accordion, AccordionSummary, AccordionDetails, Grid, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch } from 'react-redux';
import { getFileURL } from 'common/redux.actions';
import { Link } from 'react-router-dom';

const StyledTitle = styled(Typography)(({ theme: { typography } }) => ({
    display: 'flex',
    ...typography.h6,
    fontWeight: typography.fontWeightBold,
    whiteSpace: 'nowrap',
}));

const OtherInformation = ({ expanded, onChange, panelId, title, dataInfo }) => {

    const RXDispatch = useDispatch();

    const handleLink = async (e) => {
        e.preventDefault();
        RXDispatch(getFileURL({
            handleSubmit, payload: { url: e.target.href }
        }));
    };

    const handleSubmit = (resp) => {
        if (resp.type === "success") {
            window.open(resp.url, "_blank");
        }
    };

    return (
        <Accordion expanded={expanded === panelId} onChange={onChange(panelId)} sx={{ m: 0 }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel6bh-content"
                id="panel6bh-header"
                sx={{ bgcolor: theme.palette.background.mainHeader, borderBottom: `1px solid ${theme.palette.border.secondary}`, margin: 0, "&>div": { margin: "0 !important" } }}
            >
                <StyledTitle>
                    {title}
                </StyledTitle>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 4 }}>
                <Grid container spacing={1} sx={{ justifyContent: 'space-between' }}>
                    <Grid item xs={12}>
                        <Typography variant="h5" sx={{ fontWeight: "Bold", borderBottom: `1px solid ${theme.palette.border.light}`, marginBottom: 2, paddingBottom: .5 }}>Bank Account Details</Typography>
                    </Grid>
                    {
                        <Grid item xs={6} >
                            <Grid container>
                                <Grid item xs={10} sx={{ marginBottom: 2 }}>
                                    <Typography sx={{ fontWeight: "Bold", borderBottom: `1px solid ${theme.palette.border.light}`, marginBottom: 1, paddingBottom: .5 }}>{dataInfo?.find((info) => info.key === 'bank')?.label} </Typography>
                                    {
                                        Object.keys(dataInfo?.find((info) => info.key === 'bank')?.value).map((key) => (
                                            <Grid container key={key}>
                                                <Grid item xs={5}>
                                                    <Typography sx={{ fontWeight: "Bold" }}>{key}</Typography>
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <Typography>: {dataInfo?.find((info) => info.key === 'bank')?.value[key]}</Typography>
                                                </Grid>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Typography variant="h5" sx={{ fontWeight: "Bold", borderBottom: `1px solid ${theme.palette.border.light}`, marginBottom: 2, paddingBottom: .5 }}>Nominee Information</Typography>
                    </Grid>
                    {dataInfo?.find((info) => info.key === 'nominees')?.value?.map((info) => (
                        <Grid item xs={6} key={info.key}>
                            <Grid container>
                                <Grid item xs={10} sx={{ marginBottom: 2 }}>
                                    <Typography sx={{ fontWeight: "Bold", borderBottom: `1px solid ${theme.palette.border.light}`, marginBottom: 1, paddingBottom: .5 }}>{info?.label} </Typography>
                                    {Object.keys(info.value).map((key) => (
                                        <Grid container key={key}>
                                            <Grid item xs={5}>
                                                <Typography sx={{ fontWeight: "Bold" }}>{key}</Typography>
                                            </Grid>
                                            <Grid item xs={7}>
                                                {typeof info.value[key] === 'object' && info.value[key].link
                                                    ? <Link target='_blank' to={info.value[key].link} onClick={handleLink}> Preview</Link>
                                                    : <Typography>{info.value[key]}</Typography>
                                                }
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
                <Grid container spacing={1} sx={{ justifyContent: 'space-between' }}>
                    <Grid item xs={12}>
                        <Typography variant="h5" sx={{ fontWeight: "Bold", borderBottom: `1px solid ${theme.palette.border.light}`, marginBottom: 2, paddingBottom: .5 }}>References</Typography>
                    </Grid>
                    {dataInfo?.find((info) => info.key === 'references')?.value?.map((info) => (
                        <Grid item xs={6} key={info.key}>
                            <Grid container>
                                <Grid item xs={10} sx={{ marginBottom: 2 }}>
                                    <Typography sx={{ fontWeight: "Bold", borderBottom: `1px solid ${theme.palette.border.light}`, marginBottom: 1, paddingBottom: .5 }}>{info?.label} </Typography>
                                    {Object.keys(info.value).map((key) => (
                                        <Grid container key={key}>
                                            <Grid item xs={5}>
                                                <Typography sx={{ fontWeight: "Bold" }}>{key}</Typography>
                                            </Grid>
                                            <Grid item xs={7}>
                                                <Typography>: {info.value[key]}</Typography>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
                <Grid container spacing={1} sx={{ justifyContent: 'space-between' }}>
                    <Grid item xs={12}>
                        <Typography variant="h5" sx={{ fontWeight: "Bold", borderBottom: `1px solid ${theme.palette.border.light}`, marginBottom: 2, paddingBottom: .5 }}>Insurance Information</Typography>
                    </Grid>
                    {dataInfo?.find((info) => info.key === 'insurances')?.value?.map((info) => (
                        <Grid item xs={6} key={info.key}>
                            <Grid container>
                                <Grid item xs={10} sx={{ marginBottom: 2 }}>
                                    <Typography sx={{ fontWeight: "Bold", borderBottom: `1px solid ${theme.palette.border.light}`, marginBottom: 1, paddingBottom: .5 }}>{info?.label} </Typography>
                                    {Object.keys(info.value).map((key) => (
                                        <Grid container key={key}>
                                            <Grid item xs={5}>
                                                <Typography sx={{ fontWeight: "Bold" }}>{key}</Typography>
                                            </Grid>
                                            <Grid item xs={7}>
                                                {typeof info.value[key] === 'object' && info.value[key].link
                                                    ? <Link target='_blank' to={info.value[key].link} onClick={handleLink}> Preview</Link>
                                                    : <Typography>{info.value[key]}</Typography>
                                                }
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

export default OtherInformation;