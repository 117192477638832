import { useState } from "react"
import useUserData from "./hooks/useUserData";
import CustomAccordion from "./components/CustomAccordion";
import AcademicAndWorkInfo from "./components/AcademicAndWorkInfo";
import OtherInformation from "./components/OtherInformation";
import HeaderCard from "./components/HeaderCard";

function Profile() {
    const [expanded, setExpanded] = useState(false);

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const { dataGeneralInfo, dataContactInfo, dataFamilyInfo, dataIdentificationInfo, dataAcademicInfo, dataOtherInfo } = useUserData();

    if (!dataGeneralInfo || !dataContactInfo || !dataFamilyInfo || !dataIdentificationInfo || !dataAcademicInfo || !dataOtherInfo) return null;

    return (
        <div>
            <HeaderCard
                dataInfo={dataGeneralInfo}
                dataIdentificationInfo={dataIdentificationInfo}
            />
            <CustomAccordion
                expanded={expanded}
                onChange={handleAccordionChange}
                panelId="panel1"
                title="General Information"
                dataInfo={dataGeneralInfo}
            />
            <CustomAccordion
                expanded={expanded}
                onChange={handleAccordionChange}
                panelId="panel2"
                title="Contact Information"
                dataInfo={dataContactInfo}
            />
            <CustomAccordion
                expanded={expanded}
                onChange={handleAccordionChange}
                panelId="panel3"
                title="Family Information"
                dataInfo={dataFamilyInfo}
            />
            <CustomAccordion
                expanded={expanded}
                onChange={handleAccordionChange}
                panelId="panel4"
                title="Identification Information"
                dataInfo={dataIdentificationInfo}
            />
            <AcademicAndWorkInfo
                expanded={expanded}
                onChange={handleAccordionChange}
                panelId="panel5"
                title="Academic Information & Work Experiences"
                dataInfo={dataAcademicInfo}
            />
            <OtherInformation
                expanded={expanded}
                onChange={handleAccordionChange}
                panelId="panel6"
                title="Other Information"
                dataInfo={dataOtherInfo}
            />
        </div>
    )
}

export default Profile
