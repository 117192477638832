import { CREATE_BRANCH, DELETE_BRANCH, UPDATE_BRANCH } from './redux.actionTypes';

export const createBranch = ({ handleSubmit, payload }) => ({
    type: CREATE_BRANCH,
    meta: { handleSubmit },
    payload,
});

export const deleteBranch = ({ handleSubmit, payload }) => ({
    type: DELETE_BRANCH,
    meta: { handleSubmit },
    payload,
});

export const updateBranch = ({ handleSubmit, ...payload }) => ({
    type: UPDATE_BRANCH,
    meta: { handleSubmit },
    payload,
});