import PropTypes from 'prop-types';
import { Fragment } from 'react';

import { Paper, styled, Typography } from '@mui/material';

const propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
    styles: PropTypes.object,
    AdornmentComponent: PropTypes.object,
    headerBg: PropTypes.string
};

const defaultProps = {
    AdornmentComponent: <Fragment />
};

const StyledPaper = styled(Paper)(
    ({ theme: { palette, spacing, shadows } }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',

        borderRadius: 0,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: palette.background.dark,
        boxShadow: shadows[0],

        marginBottom: spacing(4)
    })
);

const StyledHeader = styled('div')(({ theme: { palette, spacing } }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    width: '100%',
    height: spacing(6.5),
    padding: spacing(),
    paddingLeft: spacing(4),

    backgroundColor: palette.background.childHeader,

    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: palette.background.dark,

    '@media screen and (max-width: 768px)': {
    paddingLeft: spacing(1),
    paddingRight: spacing(1),
    }


    
}));

const StyledTitle = styled(Typography)(({ theme: { typography } }) => ({
    display: 'flex',
    ...typography.h6,
    fontWeight: typography.fontWeightBold,
    whiteSpace: 'nowrap'
}));

const ContentContainer = ({
    styles,
    children,
    title,
    AdornmentComponent,
    headerBg,
    ...restProps
}) => {
    return (
        <StyledPaper data-testid={title.toLowerCase().split(' ').join('-').concat('-container')} {...restProps}>
            <StyledHeader sx={{ backgroundColor: headerBg || 'defaultColor', flexWrap: 'wrap', height: 'unset' }}>
                {title && <StyledTitle data-testid="title">{title}</StyledTitle>}
                {AdornmentComponent}
            </StyledHeader>
            {children}
        </StyledPaper>
    );
};

ContentContainer.propTypes = propTypes;
ContentContainer.defaultProps = defaultProps;

export { ContentContainer as default, ContentContainer };
