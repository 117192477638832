import { useCallback } from 'react';

export default ({ amountOfSteps, dispatch, mode, activeStep, publish, publishPartial }) => {
    const handleComplete = useCallback(({ error }) => {
        if (!error) {
            const locked = Array(amountOfSteps)
                .fill(false)
                .map((lock, index) => index < amountOfSteps - 1);

            dispatch({ type: 'lock', payload: locked });
            dispatch({ type: 'forceMove', payload: amountOfSteps - 1 });
        }
    }, [activeStep, amountOfSteps, dispatch]);

    const handlePartialError = useCallback(({ error, direction }) => {
        if (!error) {
            dispatch({ type: direction })
        }
    }, [activeStep, amountOfSteps, dispatch]);

    return useCallback(
        ({ direction, ...submitProps }) => {
            // On publish
            if ((activeStep === amountOfSteps - 2 && direction !== 'back') || direction === 'exit') {
                publish({ mode, handleComplete, ...submitProps });
            } else if (direction !== 'back') {
                if (mode === "update") {
                    publishPartial({
                        mode,
                        handleComplete: ({ error }) => handlePartialError({ error, direction }),
                        ...submitProps
                    })
                }
            } else dispatch({ type: direction });
        },
        [activeStep, amountOfSteps, publish, mode, handleComplete]
    );
};
