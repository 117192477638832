import ContentContainer from '@libComponents/ContentContainer';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { FORM_NAME } from './common';
import { GridContainer, GridItem, Input } from '@formComponents';
import { Button } from '@libComponents';
import { styled } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import {
    initialize as formInitialize,
    reduxForm,
    destroy as destroyForm,
} from 'redux-form';
import { createBranch, updateBranch } from './redux.actions';
import { required } from '@libs/validationRules';
import { CancelOutlined } from '@mui/icons-material';
import SelectDistrict from '../SelectDistrict';
import { hasFormErrorSelector } from 'common/redux.selectors';

const propTypes = {
    mode: PropTypes.oneOf(['create', 'update']),
};
const defaultProps = {
    mode: 'create',
};

const ButtonWrapper = styled('div')({
    position: 'absolute',
    bottom: 0,
    right: '50%',
    transform: 'translate(50%, 50%)',
    zIndex: 4,
});

const StyledButton = styled(Button)(({ theme: { spacing, typography } }) => ({
    height: spacing(4.75),
    width: spacing(25),
    fontSize: typography.subtitle1.fontSize,
    marginLeft: spacing(0.75),
    marginRight: spacing(0.75),
}));

const BranchCreateUpdate = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
    onSubmit: (v) => v,
})(({ mode, row, setViewType, handleSubmit: handleReduxFormSubmit, ...rest }) => {
    const RXDispatch = useDispatch();

    useEffect(() => {
        RXDispatch(formInitialize(FORM_NAME, row));
        return () => {
            return RXDispatch(destroyForm(FORM_NAME));
        };
    }, [mode]);

    const handleSubmit = (data) => {
        setViewType("default");
    }

    const hasError = useSelector((state) =>
        hasFormErrorSelector(state, FORM_NAME)
    );

    const handleCreate = () => {
        if (hasError) {
            handleReduxFormSubmit();
            return;
        }
        if (mode === "create")
            RXDispatch(createBranch({ handleSubmit }));
        else if (mode === "update") {
            RXDispatch(updateBranch({ handleSubmit }));
        }
    }

    return (
        <ContentContainer
            key={`${mode}-branch`}
            title={`${mode} Branch`}
            sx={{ textTransform: 'capitalize', p: "0 !important", mb: 2 }}
            AdornmentComponent={
                <CancelOutlined onClick={() => {
                    setViewType("default");
                }}
                    sx={{ cursor: "pointer", width: 32, height: 32, mr: 1 }}
                />}
        >
            <GridContainer>
                <GridItem md={12} >
                    <Input
                        name='name'
                        placeholder='Branch name'
                        label='Name'
                        required={true}
                        validate={required}
                        disabled={mode === 'update' ? true : false}
                    />
                </GridItem>
                <GridItem md={12} >
                    <Input
                        name='address'
                        placeholder='Address'
                        label='Address'
                        required={true}
                        validate={required}
                    />
                </GridItem>
                <GridItem md={12} >
                    <SelectDistrict
                        name='city'
                        placeholder='City name'
                        label='City'
                        required={true}
                        validate={required}
                    />
                </GridItem>
                <GridItem md={12} >
                    <Input
                        name='country'
                        placeholder='Country name'
                        label='Country'
                        required={true}
                        validate={required}
                    />
                </GridItem>
                <GridItem md={12} >
                    <Input
                        name='emails'
                        placeholder='Emails address'
                        label='Emails'
                        required={true}
                        validate={required}
                    />
                </GridItem>
                <GridItem md={12} >
                    <Input
                        name='contacts'
                        placeholder='Contact number'
                        label='Contacts'
                        required={true}
                        validate={required}
                    />
                </GridItem>

            </GridContainer>

            <ButtonWrapper>
                <StyledButton onClick={handleCreate}>
                    {mode === "create" ? "CREATE" : "UPDATE"}
                </StyledButton>
            </ButtonWrapper>
        </ContentContainer>
    );
});

BranchCreateUpdate.propTypes = propTypes;
BranchCreateUpdate.defaultProps = defaultProps;

export { BranchCreateUpdate as default, BranchCreateUpdate };

