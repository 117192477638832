import { LEAVE_MANAGEMENT_ROUTE_ID, CREATE_LEAVE_MANAGEMENT_ROUTE_ID, APPLIED_LEAVES_MANAGEMENT_ROUTE_ID } from './common';
import Dashboard from './LeaveManagement/Dashboard';
import CreateLeave from './LeaveManagement/CreateLeave';
// import Holidays from './LeaveManagement/Holidays';
import AppliedLeaves from './LeaveManagement/AppliedLeaves';

export default {
    [LEAVE_MANAGEMENT_ROUTE_ID]: () => <Dashboard />,
    [CREATE_LEAVE_MANAGEMENT_ROUTE_ID]: () => <CreateLeave />,
    [APPLIED_LEAVES_MANAGEMENT_ROUTE_ID]: () => <AppliedLeaves />,
    // [HOLIDAYS_ROUTE_ID]: () => <Holidays />,
};
