import { useCallback } from 'react';

import Pagination from '@libComponents/Pagination';

import useDailyTimesheets, { SET_PAGE_NUMBER, SET_PAGE_SIZE } from './useDailyTimesheets';
import { Box } from '@mui/material';

const _Pagination = () => {
    const [{ currentPage, totalPages, pageSize }, dispatch] = useDailyTimesheets();

    const handlePageChange = useCallback(
        (event, value) => {
            dispatch({
                type: SET_PAGE_NUMBER,
                payload: value
            });
        },
        [dispatch]
    );
    const handleRowsPerPageChange = useCallback(
        (value) => {
            dispatch({
                type: SET_PAGE_SIZE,
                payload: value
            });
        },
        [dispatch]
    )

    return (
        !!totalPages && (
            <Box sx={{pl:2, pr:2}}>
                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    sx={{ display: 'flex', justifyContent: 'center' }}
                    isRowsPerPageVarient={true}
                    rowsPerPageProps={{
                        name: 'adminDailyTimesheet',
                        onChange: handleRowsPerPageChange,
                        value: pageSize
                    }}
                />
            </Box>
        )
    );
};

export default _Pagination;
