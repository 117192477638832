import { takeLatest } from 'redux-saga/effects';

// Workers
import { GET_FILE_URL } from 'common/redux.actionTypes';
import getFileURLWorker from './getFileURLWorker';


// Watchers
export default function* () {
    yield takeLatest(GET_FILE_URL, getFileURLWorker);
}