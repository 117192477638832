import { withField } from '@libs/reduxForm';
import { normalizeIso8601Date } from '@libs/dateTime';

import DatePickerBase from './DatePickerBase';

const DatePicker = withField({ normalize: normalizeIso8601Date })((props) => {
    return <DatePickerBase {...props} />;
});

export { DatePicker as default, DatePicker, DatePickerBase };
