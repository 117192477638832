import { apiRequest } from "@libs/apiRequest";
import { call, put } from "redux-saga/effects";
import { setComponentLoading, setSnack } from "utilities/redux.actions";
import { LOADING_WEEKLY_ACTIVITIES, WEEKLY_ACTIVITIES } from "timeAndAttendance/common";
import { getCurrentStartAndEndOfWeek } from "@libs/dateTime";
function secondToHour(seconds) {
    return parseFloat(seconds/3600).toFixed(2);
}

function convertToMs(number) {
    var sign = (number >= 0) ? 1 : -1;
    number = number * sign;
    var hour = Math.floor(number);
    var decpart = number - hour;
    var min = 1 / 60;
    decpart = min * Math.round(decpart / min);
    var minute = Math.floor(decpart * 60) + '';
    if (minute.length < 2) {
        minute = '0' + minute;
    }
    sign = sign == 1 ? '' : '-';
    return sign + hour + ':' + minute;
}

function toTime(seconds) {
    var date = new Date(null);
    date.setSeconds(seconds);
    return date.toISOString().substr(11, 5);
}

const WEEKDAYS = [
    { value: 'Monday', name: 'Mon', at: 24, timeStrampHour: "00:00", hour: "0.00" },
    { value: 'Tuesday', name: 'Tue', at: 24, timeStrampHour: "00:00", hour: "0.00" },
    { value: 'Wednesday', name: 'Wed', at: 24, timeStrampHour: "00:00", hour: "0.00" },
    { value: 'Thursday', name: 'Thu', at: 24, timeStrampHour: "00:00", hour: "0.00" },
    { value: 'Friday', name: 'Fri', at: 24, timeStrampHour: "00:00", hour: "0.00" },
    { value: 'Saturday', name: 'Sat', at: 24, timeStrampHour: "00:00", hour: "0.00" },
    { value: 'Sunday', name: 'Sun', at: 24, timeStrampHour: "00:00", hour: "0.00" }
];

export default function* (action) {
    const {
        meta: { handleSubmit = () => { } },
        payload,
        ...rest
    } = action;

    try {
        yield put(setComponentLoading({
            entity: LOADING_WEEKLY_ACTIVITIES,
            loading: true
        }));

        const { startOfWeek, endOfWeek } = getCurrentStartAndEndOfWeek();
        const { data: { entries, totalDuration } } = yield call(
            apiRequest,
            {
                url: `attendance/weekly-status?startOfWeek=${startOfWeek}&endOfWeek=${endOfWeek}`,
                method: 'GET'
            }
        );

        let rows = [];
        WEEKDAYS.map((item) => {
            let elem = entries.find(entry => item.value === entry.day);
            if (elem) {
                item.timeStrampHour = toTime(elem.duration);
                item.hour = secondToHour(elem.duration);
                item.presentType = elem.presentType
            }
            rows.push(item);
        });

        yield put({
            type: "updateWeeklyActivities",
            payload: {
                entity: WEEKLY_ACTIVITIES,
                value: { rows, totalHours: convertToMs(secondToHour(totalDuration)) }
            },
        });
        yield put(setComponentLoading({
            entity: LOADING_WEEKLY_ACTIVITIES,
            loading: false
        }));

    } catch (error) {
        yield put(setComponentLoading({
            entity: LOADING_WEEKLY_ACTIVITIES,
            loading: false
        }));
        yield put(setSnack({
            message: error?.response?.data?.message || error?.message,
            type: 'error',
        }));
        return handleSubmit(error);
    }
}

