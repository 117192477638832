import { takeLatest } from 'redux-saga/effects';

// Workers
import { CREATE_AND_UPDATE_EMPLOYEE_CATEGORY, DELETE_EMPLOYEE_CATEGORY } from '../redux.actionTypes';
import deleteEmployeeCategoryWorker from './deleteEmployeeCategoryWorker';
import createAndUpdateEmployeeCategoryWorker from './createAndUpdateEmployeeCategoryWorker';

// Watchers
export default function* () {
    yield takeLatest(DELETE_EMPLOYEE_CATEGORY, deleteEmployeeCategoryWorker);
    yield takeLatest(CREATE_AND_UPDATE_EMPLOYEE_CATEGORY, createAndUpdateEmployeeCategoryWorker);
}