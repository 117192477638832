import {useState, Fragment} from 'react';
import { DesignationCreateUpdate } from './DesignationCreateUpdate';
import ViewDesignation from './ViewDesignation';
import { DeleteDesignation } from './DeleteDesignation';

export default function Designation() {
    const [viewType, setViewType] = useState('default');
    const [data, setData] = useState({ mode: 'create', row: {} });

    return (
        <Fragment>
            {viewType === 'default' && (
                <ViewDesignation setViewType={setViewType} setData={setData} />
            )}
            {viewType === 'createDesignation' && (
                <DesignationCreateUpdate
                    mode={data.mode}
                    row={data.row}
                    setViewType={setViewType}
                />
            )}
            {viewType === 'deleteDesignation' && (
                <DeleteDesignation
                    row={data.row}
                    setViewType={setViewType}
                />
            )}
        </Fragment>
    );
}
