import apiRequest from '@libs/apiRequest';
import { BRANCH } from 'common/redux.datasets';
import { getFormValues } from 'redux-form';
import { call, put, select } from 'redux-saga/effects';
import { FORM_NAME } from '../../common';
import { setLoading, setSnack } from 'utilities/redux.actions';

export default function* (action) {
    const {
        meta: { handleSubmit = () => { } },
        ...rest
    } = action;

    yield put(setLoading(true));

    const allSelector = yield select(
        getFormValues(FORM_NAME)
    );

    const modifiedSelector = {
        address: [allSelector.address],
        city: allSelector.city,
        contacts: [allSelector.contacts],
        country: allSelector.country,
        emails: [allSelector.emails],
        id: allSelector.id,
        name: allSelector.name.trim(),
    }

    const payloadData = JSON.stringify(modifiedSelector)
    try {
        const { data } = yield call(
            apiRequest,
            {
                url: "branch/create",
                method: 'post',
                data: payloadData,
            }
        );
        yield put(setLoading(false));
        yield put(setSnack({
            message: "Branch created successfully",
            type: 'success',
        }));
        yield put({
            type: "handleCreate",
            payload: {
                entity: BRANCH,
                value: data,
                key: "name"
            },
        });
        return handleSubmit(data);
    } catch (error) {
        yield put(setLoading(false));
        yield put(setSnack({
            message: error?.response?.data?.message || error?.message,
            type: 'error',
        }));
        return handleSubmit(error);
    }
}
