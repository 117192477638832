import composeSelector from './libs.selectors';
import composeNormalizer from './libs.normalizers';

const composeParams = ({ params = null, fields = null }) => {
    // If is paginated
    if (!!fields) {
        const isParamsFn = typeof params === 'function';
        const pageParams = { pageNumber: 1, pageSize: 10 };

        if (isParamsFn) return (state) => ({ ...pageParams, ...params(state) });
        else return { ...pageParams, ...params };
    } else return params;
};

const composeDataset = (dataset, domain, entity) => {
    return {
        domain,
        entity,
        ...dataset,
        params: composeParams(dataset),
        normalizer: composeNormalizer(dataset),
        selector: composeSelector({ domain, entity, ...dataset }),
    };
};

export default (datasets) =>
    Object.entries(datasets).reduce(
        // Reduce to entries from object for propagation
        (acm, [domain, domainDatasets]) => ({
            ...acm,
            ...Object.entries(domainDatasets).reduce(
                // Flatten domain object
                (acm, [entity, dataset]) => ({
                    ...acm,
                    [entity]: composeDataset(dataset, domain, entity), // Inject top level domain into each dataset
                }),
                {}
            ),
        }),
        {}
    );
