import { GridView, ViewList } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';

export default function ChangeView({setView}) {
    return (
        <Box>
            <IconButton color='secondary' onClick={()=>{setView("listView")}}>
                <ViewList />
            </IconButton>
            <IconButton color='secondary' onClick={()=>{setView("gridView")}}>
                <GridView />
            </IconButton>
        </Box>
    );
}
