export const SET_DATE = 'set-date';
export const SET_MODAL_CLOSE = 'set-modal-close';
export const SET_FILTERS = 'set-filters';
export const SET_FILTERS_OVERVIEW_ATTENDANCE = 'set-filters-overview-attendance';
export const SET_FILTERS_OVERVIEW_LEAVE = 'set-filters-overview-leave';
export const SET_BRANCH = 'set-branch';
export const SET_LIMIT = 'set-limit';
export const SET_MONTH = 'set-month';

export const SET_DATE_ATTENDANCE = 'set-date-attendance';
export const SET_FILTERS_ATTENDANCE = 'set-filters-attendance';

export const SET_EMAIL = 'set-email';
export const SET_WEEK = 'set-week';
export const SET_PAGE_NUMBER = 'set-page-number';
export const SET_YEAR_MONTH = 'set-year-month';
export const TIMESHEET_NEXT = 'timesheet-next';
export const TIMESHEET_PREV = 'timesheet-prev';
export const SET_ROWS_PER_PAGE_NUMBER = 'set-rows-per-page-number';
