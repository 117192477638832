import {
    WeekdayHeader,
    TimesheetDataCell,
    TimesheetDataRowWrapper
} from '../../../components';

const TimesheetsDataTableHeader = () => {
    return (
        <TimesheetDataRowWrapper>
            <WeekdayHeader sx={{ gridColumn: 'span 10' }} />
            <TimesheetDataCell
                sx={{ gridColumn: 'span 2', textAlign: 'center' }}
            >
                Total
            </TimesheetDataCell>
        </TimesheetDataRowWrapper>
    );
};

export default TimesheetsDataTableHeader;
