import { initializeDatasets } from '@libs/datasets';

import { SET_REDIRECT } from './redux.actionTypes';
import datasets from './redux.datasets';

const initialState = {
    isRedirected: false,
    ...initializeDatasets(datasets),
};

export default (state = initialState, { type, payload, ...action }) => {
    switch (type) {
        case SET_REDIRECT: {
            return {
                ...state,
                isRedirected: payload,
            };
        }

        default: {
            return state;
        }
    }
};
