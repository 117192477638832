import { SelectBase } from '@baseComponents/Select';

const branches = [
    { label: 'All Branches', value: null },
    { label: 'Uttara', value: 'Uttara' },
    { label: 'Dhanmondi', value: 'Dhanmondi' },
    { label: 'Chittagong', value: 'Chittagong' }
];

const defaultProps = {
    fieldName: 'branch',
    placeholder: 'Select Branch',
    meta: { form: 'daily-view' },
    data: branches
};

const SelectBranch = (props) => <SelectBase {...props} />;

SelectBranch.defaultProps = defaultProps;

export { SelectBranch as default, SelectBranch };
