import PropTypes from 'prop-types';
import { Fragment, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { initialize as formInitialize, reduxForm, change } from 'redux-form';
import { useEffect } from 'react';
import { styled } from '@mui/system';

import ContentContainer from '@libComponents/ContentContainer';
import { GridContainer, GridItem, Input } from '@formComponents';
import AutocompleteFetch from '@formComponents/AutocompleteFetch';
import InputRadio from '@formComponents/InputRadio';
import { Button } from '@libComponents';
import { required } from '@libs/validationRules';

import { DOMAIN_NAME } from '../../leaveCategory/common';
import { FORM_NAME, IS_EMERGENCY } from '../common';
import TextArea from '@formComponents/TextArea';
import DatePickerBase from '@formComponents/DatePicker';
import { LEAVE_CATEGORY } from 'leaveCategory/redux.datasets';
import InputFile from '@formComponents/InputFile';

import { getEmplopyeeRemainingLeaves } from 'leaveAndHolidays/redux.actions';

// Libs
import { createLeave } from "../redux.actions";
import { hasFormErrorSelector } from 'common/redux.selectors';
import dayjs from 'dayjs';
import { Alert, Box } from '@mui/material';
import { TableBody, TableRow } from '@mui/material';
import TableCellContainer from '@libComponents/table/TableCellContainer';
import TableContainer from '@libComponents/table/TableContainer';
import TableHeaderContainer from '@libComponents/table/TableHeaderContainer';

import { DOMAIN_NAME as COMMON_DOMAIN } from 'common/common';
import { GET_ALL_EMPLOYEE_NAME_EMAIL } from 'common/redux.datasets';
import { getAllEmployeeSelector } from 'leaveManagement/redux.selectors';
import { LEAVE_TYPE } from 'leaveManagement/common';
import { scrollToTop } from '@libs/helpers';

const propTypes = {
  mode: PropTypes.oneOf(['create', 'update']),
};
const defaultProps = {
  mode: 'create',
};

/**
 * ReduxForm stepper rules
 * * Connect each step with reduxForm() to the same form name.
 * * Specify the destroyOnUnmount: false flag to preserve form data across form component unmounts.
 */
const ButtonWrapper = styled('div')({
  position: 'absolute',
  bottom: 0,
  right: '50%',
  transform: 'translate(50%, 50%)',
  zIndex: 4,
});

const StyledButton = styled(Button)(({ theme: { spacing, typography } }) => ({
  height: spacing(4.75),
  width: spacing(25),
  fontSize: typography.subtitle1.fontSize,
  marginLeft: spacing(0.75),
  marginRight: spacing(0.75),
}));

const StyledBox = styled(Box)(
  ({ theme: { spacing, palette, typography, shape } }) => ({
    width: 'auto',
    margin: spacing(2.5),
    background: palette.background.paper,
    overflow: 'auto',
    '& table': {
      tableLayout: 'fixed'
    }
  })
);

const TABLE_DATA = {
  'category': 'category',
  'remainingDays': 'remaining Days',
  'totalDays': "total Days",
};



const CreateLeave = reduxForm({
  form: FORM_NAME, // Form name
  destroyOnUnmount: false, // Preserve data
  onSubmit: (v) => v,
})(({ mode, handleSubmit: handleReduxFormSubmit, dispatch, ...rest }) => {
  const RXDispatch = useDispatch();
  const [data, setData] = useState([]);
  const [dropDownValue, setDropDownValue] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const navigate = useNavigate();
  const [leaveType, setLeaveType] = useState(0);
  const [isEmergency, setIsEmergency] = useState(false);

  useEffect(() => {
    RXDispatch(formInitialize(FORM_NAME, {
      isEmergency: isEmergency.toString(),
      leaveType: "0"
    }));
  }, [mode]);

  useEffect(() => {
    RXDispatch(
      getEmplopyeeRemainingLeaves({
        payload: {},
        handleSubmit: handleRemainingLeaves
      })
    );
  }, []);


  const handleRemainingLeaves = (data) => {
    if (data.length) {
      setData(data);
    }
  }

  const handleCreate = () => {
    if (hasError) {
      scrollToTop();
      handleReduxFormSubmit();
      return;
    }

    if (mode === "create") {
      RXDispatch(createLeave({ handleSubmit }));
    }
  }

  const handleSubmit = (payload) => {
    RXDispatch(formInitialize(FORM_NAME, {
      isEmergency
    }));

    setTimeout(() => {
      if (payload?.response?.status !== 400) {
        navigate("/leave-&-holidays/all-leaves");
      }
    }, 1000)
  }

  const hasError = useSelector((state) =>
    hasFormErrorSelector(state, FORM_NAME)
  );

  const setTotalNumberOfDays = (startDate, endDate, leaveType) => {
    const totalNumberOfDays = dayjs(endDate).diff(dayjs(startDate), 'day') + 1 - leaveType;
    dispatch(change(FORM_NAME, 'totalNumberOfDays', totalNumberOfDays));
  }

  const inputFileHandler = () => {
    return dropDownValue && (dropDownValue.toLowerCase().includes("sick") || dropDownValue.toLowerCase().includes("paternity"))
  }

  const selectedLeaveInfo = data.find((item) => item.category.name === dropDownValue);
  let alertText;
  let severityType;
  if (dropDownValue) {
    switch (selectedLeaveInfo?.remainingDays) {
      case undefined:
        alertText = `You are not eligible for this leave`
        severityType = "error"
        break;
      case -1:
        alertText = `You are not applicable for ${dropDownValue ? selectedLeaveInfo?.category?.name : this}`
        severityType = "error"
        break;
      case 0:
        alertText = `Your are out of balance! You have 0 days remaining out of ${dropDownValue ? selectedLeaveInfo?.totalDays : 0}`
        severityType = "warning"
        break;
      case null:
        alertText = `Your are out of balance!`
        severityType = "warning"
        break;
      default:
        alertText = `You have ${dropDownValue ? selectedLeaveInfo?.remainingDays : 0} remaining days available out of ${dropDownValue ? selectedLeaveInfo?.totalDays : 0}`
        severityType = "success"
        break;
    }
  } else {
    alertText = `Please Select A Category`
    severityType = "info"
  }

  const getMinDate = () => {
    return dropDownValue?.toLowerCase().includes('sick') ? dayjs().subtract(1, 'year') : isEmergency ? dayjs() : dayjs().add(15, 'day');
  };

  const getMaxDate = () => {
    return dropDownValue?.toLowerCase().includes('sick') ? dayjs() : dayjs().add(1, 'year');
  };


  return (
      <ContentContainer
          title={mode + ' Leave'}
          sx={{ textTransform: 'capitalize' }}
          AdornmentComponent={<Fragment />}
      >
          <GridContainer>
              <GridItem md={4} xs={12}>
                  <AutocompleteFetch
                      name='category'
                      placeholder='Category Name'
                      label='Category Name'
                      domain={DOMAIN_NAME}
                      entity={LEAVE_CATEGORY}
                      required={true}
                      validate={[required]}
                      onChange={(_, value) => setDropDownValue(value)}
                  />
              </GridItem>
              <GridItem md={4} xs={12}>
                  <DatePickerBase
                      name='startDate'
                      placeholder='Start Date'
                      label='Start Date'
                      required={true}
                      validate={required}
                      disabled={dropDownValue ? false : true}
                      maxDate={getMaxDate()}
                      minDate={getMinDate()}
                      onChange={(value) => {
                          setStartDate(value);
                          if (endDate) {
                              setTotalNumberOfDays(value, endDate, leaveType);
                          }
                      }}
                  />
              </GridItem>
              <GridItem md={4} xs={12}>
                  <DatePickerBase
                      name='endDate'
                      placeholder='End Date'
                      label='End Date'
                      required={true}
                      validate={required}
                      maxDate={dayjs().add(1, 'year')}
                      disabled={startDate ? false : true}
                      minDate={dayjs(startDate)}
                      onChange={(value) => {
                          setEndDate(value);
                          setTotalNumberOfDays(startDate, value, leaveType);
                      }}
                  />
              </GridItem>
              <GridItem md={4} xs={12}>
                  <Input
                      name='totalNumberOfDays'
                      placeholder='Total Number of Days'
                      label='Total Number of Days'
                      required={true}
                      validate={[required]}
                      autoComplete='do-not-autofill'
                      type='number'
                      disabled
                  />
              </GridItem>
              <GridItem md={4} xs={12}>
                  <AutocompleteFetch
                      name='employeesToNotify'
                      placeholder='Emails'
                      label='Employees to Notify'
                      domain={COMMON_DOMAIN}
                      entity={GET_ALL_EMPLOYEE_NAME_EMAIL}
                      multiple
                      customselector={getAllEmployeeSelector}
                  />
              </GridItem>
              {inputFileHandler() && (
                  <GridItem md={4} xs={12}>
                      <InputFile
                          name='requiredDocuments'
                          label='Required Documents'
                      />
                  </GridItem>
              )}
              <GridItem md={12} xs={12}>
                  <InputRadio
                      name='leaveType'
                      label='Leave Type'
                      options={LEAVE_TYPE}
                      required={true}
                      validate={required}
                      onChange={(e, value) => {
                          setLeaveType(value);
                          setTotalNumberOfDays(startDate, endDate, value);
                      }}
                  />
              </GridItem>

              <GridItem md={12} xs={12}>
                  <TextArea
                      name='reason'
                      label='Reason'
                      rows='2'
                      required={true}
                      validate={[required]}
                  />
              </GridItem>
              <GridItem md={6} xs={12}>
                  <InputRadio
                      name='isEmergency'
                      label='Is Emergency?'
                      options={IS_EMERGENCY}
                      required={true}
                      value={isEmergency}
                      validate={required}
                      onChange={(e) => {
                          setIsEmergency(
                              e.target.value === 'true' ? true : false
                          );
                      }}
                  />
              </GridItem>

              <GridItem md={12} sx={{ mt: 2 }} xs={12}>
                  <Alert severity={severityType}>{alertText}</Alert>
              </GridItem>
              <StyledBox>
                  <TableContainer>
                      <TableHeaderContainer>
                          {Object.values(TABLE_DATA).map((th, i) => (
                              <TableCellContainer
                                  sx={{
                                      pl: i === 0 ? 4 : 2,
                                      fontWeight: 'bold'
                                  }}
                                  typography='caption'
                                  key={th}
                              >
                                  {th}
                              </TableCellContainer>
                          ))}
                      </TableHeaderContainer>
                      <TableBody>
                          {data.map((row, i) => (
                              <TableRow key={i}>
                                  {Object.keys(TABLE_DATA).map((key, index) => {
                                      switch (key) {
                                          case 'category':
                                              return (
                                                  <TableCellContainer
                                                      key={key}
                                                      sx={{
                                                          pl:
                                                              index === 0
                                                                  ? 4
                                                                  : 2
                                                      }}
                                                  >
                                                      {row[key].name}
                                                  </TableCellContainer>
                                              );

                                          default:
                                              return (
                                                  <TableCellContainer
                                                      key={index}
                                                      sx={{
                                                          pl:
                                                              index === 0
                                                                  ? 4
                                                                  : 2
                                                      }}
                                                  >
                                                      {row[key]}
                                                  </TableCellContainer>
                                              );
                                      }
                                  })}
                              </TableRow>
                          ))}
                      </TableBody>
                  </TableContainer>
              </StyledBox>
          </GridContainer>
          <ButtonWrapper>
              <StyledButton
                  disabled={severityType !== 'success'}
                  onClick={handleCreate}
              >
                  {mode === 'create' ? 'CREATE' : 'UPDATE'}
              </StyledButton>
          </ButtonWrapper>
      </ContentContainer>
  );
});

CreateLeave.propTypes = propTypes;
CreateLeave.defaultProps = defaultProps;

export { CreateLeave as default, CreateLeave };

