import dashboard from '../dashboard/routes';
import employee from '../employee/routes';
import roles from '../roles/routes';
import timeAndAttendance from '../timeAndAttendance/routes';
import user from '../user/routes';
import leaveCategory from '../leaveCategory/routes';
import leaveManagement from '../leaveManagement/routes'
import employeeLeaveManagement from '../leaveAndHolidays/routes';
import holiday from 'holidays/routes';

export default {
    ...dashboard,
    ...employee,
    ...timeAndAttendance,
    ...user,
    ...roles,
    ...leaveCategory,
    ...leaveManagement,
    ...employeeLeaveManagement,
    ...holiday
};
