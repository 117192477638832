import apiRequest from '@libs/apiRequest';
import { call, put } from 'redux-saga/effects';
import { setLoading } from 'utilities/redux.actions';

function formatedEmployeeShiftData({ employeeEmail, shifts }) {
    let shiftObj = {}
    shifts.forEach(({ shiftName, days }) => {
        days.forEach(day => {
            shiftObj[`shifts${day}`] = shiftName
        });
    });
    return { employeeEmail, ...shiftObj }
}

export default function* (action) {
    const {
        meta: { handleSubmit = () => { } },
        payload: { employeeEmail }
    } = action;

    yield put(setLoading(true));

    try {
        const employeeShiftData = yield call(
            apiRequest,
            {
                url: `employee/get-shifts?&filters[employeeEmail]=${employeeEmail}`,
                method: 'get'
            }
        );

        let enhancedEmployeeShiftData = formatedEmployeeShiftData(employeeShiftData.data.data[0]);
        yield put(setLoading(false));
        return handleSubmit({ enhancedEmployeeShiftData });

    } catch (error) {
        yield put(setLoading(false));
    }
}