import { takeLatest } from 'redux-saga/effects';

// Workers
import { CREATE_AND_UPDATE_DESIGNATION, DELETE_DESIGNATION } from '../redux.actionTypes';
import deleteDesignationWorker from './deleteDesignationWorker';
import createAndUpdateDesignationWorker from './createAndUpdateDesignationWorker';

// Watchers
export default function* () {
    yield takeLatest(DELETE_DESIGNATION, deleteDesignationWorker);
    yield takeLatest(CREATE_AND_UPDATE_DESIGNATION, createAndUpdateDesignationWorker);
}