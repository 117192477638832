export default [
    {
        key: 'initial',
        label: 'Initial',
    },
    {
        key: 'calenderId',
        label: 'Select Calender',
    },
    {
        key: 'firstname',
        label: 'First Name (In English)',
    },
    {
        key: 'joiningDate',
        label: 'Joining Date',
    },
    {
        key: 'middlename',
        label: 'Middle Name (In English)',
    },
    {
        key: 'gender',
        label: 'Gender',
    },
    {
        key: 'lastname',
        label: 'Last Name (In English)',
    },
    {
        key: 'bloodGroup',
        label: 'Blood Group',
    },
    {
        key: 'nameInBengali',
        label: 'Name (In Bengali)',
    },
    {
        key: 'dateOfBirth',
        label: 'Date of Birth',
    },
    {
        key: 'shift',
        label: 'Shift',
    },
    {
        key: 'placeOfBirth',
        label: 'Place of Birth',
    },
    {
        key: 'reportingManager',
        label: 'Reporting Manager',
    },
    {
        key: 'religion',
        label: 'Religion',
    },
    {
        key: 'maritalStatus',
        label: 'Marital Status',
    },
    {
        key: 'department',
        label: 'Department',
    },
    {
        key: 'employeeId',
        label: 'Employee ID',
    },
    {
        key: 'branch',
        label: 'Branch',
    },
    {
        key: 'father',
        label: 'Father Name (In English)',
    },
    {
        key: 'designation',
        label: 'Designation',
    },
    {
        key: 'mother',
        label: 'Mother Name (In English)',
    },
    {
        key: 'employeeCategory',
        label: 'Employee Category',
    },
]