import {
    LOGIN,
    SET_AUTH_DATA,
    SET_AUTH_FORM_LOADING,
    LOGOUT,
    FORGET_PASSWORD,
    RESET_PASSWORD,
    REQUEST_LOGIN,
} from './redux.actionTypes';

export const login = () => ({
    type: LOGIN,
});
export const requestLogin = (handleSubmit: Function) => ({
    type: REQUEST_LOGIN,
    meta: { handleSubmit },
});

export const setAuthFormLoading = (payload: boolean) => ({
    type: SET_AUTH_FORM_LOADING,
    payload,
});
export const setAuthData = (payload: object) => ({
    type: SET_AUTH_DATA,
    payload,
});
export const logout = () => ({ type: LOGOUT });
export const forgetPassword = (navigateToLogin: Function) => ({
    type: FORGET_PASSWORD,
    meta: { navigateToLogin },
});
export const resetPassword = ( navigateToLogin: Function) => ({
    type: RESET_PASSWORD,
    meta: { navigateToLogin },
});