import { Button } from '@libComponents';
import React from 'react';
import { FieldArray } from 'redux-form';

const renderFields = ({
    fields,
    meta: { touched, error, submitFailed },
    renderSubField,
    label,
    ...restProps
}) => {
    return (
        <React.Fragment>
            <Button
                variant='outlined'
                sx={{ textTransform: 'capitalize', width:"auto" }}
                type='button'
                onClick={() => fields.push({})}
            >
                {label ? label : "Add Field"}
            </Button>
            {(touched || submitFailed) && error && <span>{error}</span>}
            {fields.map(renderSubField)}
        </React.Fragment>
    );
};

const FieldArraysForm = ({ label, name, renderSubFields, ...restProps }) => {
    return (
        <FieldArray
            name={name}
            label={label}
            renderSubField={renderSubFields}
            component={renderFields}
        />
    );
};

export default FieldArraysForm;
