import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getRemainingLeaves } from './redux.actions';
import { useLocation, useNavigate } from "react-router-dom";
import { theme } from 'app/Theme';
import CloseIcon from '@mui/icons-material/Close';
import { Typography, IconButton } from '@mui/material';
import { Box, TableBody, TableRow, styled } from '@mui/material';

import ContentContainer from '@libComponents/ContentContainer';
import TableCellContainer from '@libComponents/table/TableCellContainer';
import TableContainer from '@libComponents/table/TableContainer';
import TableHeaderContainer from '@libComponents/table/TableHeaderContainer';
import DialogWrapper from '@libComponents/Dialog';
import { Button } from '@libComponents';
import { ArrowBack } from '@mui/icons-material';

import Leaves from './Leaves';
import { DOMAIN_NAME } from "./common";


const propTypes = {};

const defaultProps = {
    domain: DOMAIN_NAME,
    loading: true,
};

const TABLE_DATA = {
    'category': 'Category',
    'remainingDays': 'Remaining Days',
    'totalDays': "Total Days",
};

const RemainingLeaves = (({ ...props }) => {

    const [data, setData] = useState([]);
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();

    const { state: { email, searchTerm } } = useLocation();

    const RXDispatch = useDispatch();
    useEffect(() => {
        RXDispatch(
            getRemainingLeaves({
                payload: {
                    email
                },
                handleSubmit
            })
        );
    }, []);

    const handleSubmit = (data) => {
        if (data.length) {
            setData(data);
        }
    }

    const handleClose = (value) => {
        setOpen(false);
    };

    const onHandleDetails = () => {
        setOpen(true);
    }

    if (!data) return null;

    const BackButtonContainer = () => (
        <Button
            startIcon={<ArrowBack />}
            onClick={() => {
                const url = searchTerm ? `/leave-management/all-leaves?searchTerm=${searchTerm}` : '/leave-management/all-leaves';
                navigate(url);
            }}>
            Back
        </Button>
    );

    return (
        <>
            <ContentContainer title={`Leaves summary for ${email}`} headerBg={theme.palette.background.mainHeader} AdornmentComponent={<BackButtonContainer />}>
                <Leaves data={data} onHandleDetails={onHandleDetails} />
            </ContentContainer>
            <DialogWrapper open={open} onClose={handleClose} sx={{ textAlign: "center" }}>
                <Typography variant='h6' sx={{ mb: 2 }}>
                    Leave Details
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon
                        sx={{
                            color: (theme) => theme.palette.primary.main,
                        }}
                    />
                </IconButton>
                <Box  >
                    <TableContainer >
                        <TableHeaderContainer>
                            {Object.values(TABLE_DATA).map((th, i) => (
                                <TableCellContainer
                                    sx={{ pl: i === 0 ? 4 : 2, fontWeight: 'bold' }}
                                    typography="caption" key={th}
                                >
                                    {th}
                                </TableCellContainer>
                            ))}
                        </TableHeaderContainer>
                        <TableBody>
                            {data.map((row, i) => (
                                <TableRow key={i}>
                                    {Object.keys(TABLE_DATA).map((key, index) => {
                                        switch (key) {
                                            case 'category':
                                                return (
                                                    <TableCellContainer
                                                        sx={{ pl: index === 0 ? 4 : 2 }}
                                                        key={key}>
                                                        {row[key].name}
                                                    </TableCellContainer>
                                                )

                                            default:
                                                return (
                                                    <TableCellContainer
                                                        key={index}
                                                        sx={{ pl: index === 0 ? 4 : 2 }}
                                                    >
                                                        {row[key] === -1 ? "No Leave Applicable" : row[key]}
                                                    </TableCellContainer>
                                                );
                                        }
                                    })}

                                </TableRow>
                            ))}
                        </TableBody>
                    </TableContainer>
                </Box>
            </DialogWrapper>
        </>
    );
});

RemainingLeaves.propTypes = propTypes;
RemainingLeaves.defaultProps = defaultProps;

export default RemainingLeaves;
