import { apiRequest } from "@libs/apiRequest";
import { call, put } from "redux-saga/effects";
import { setLoading, setSnack } from "utilities/redux.actions";

export default function* (action) {
    const {
        meta: { handleSubmit = () => { } },
        payload: { email, date, description, isTerminated }
    } = action;

    yield put(setLoading(true));
    try {
        const { data } = yield call(
            apiRequest,
            {
                url: `employee/resign?email=${email}&date=${date}&description=${description}&isTerminated=${isTerminated}`,
                method: 'POST'
            }
        );
        yield put(setLoading(false));
        yield put(setSnack({
            message: "Employee resigned successfully",
            type: 'success',
        }));

        return handleSubmit(data);
    } catch (error) {
        yield put(setLoading(false));
        yield put(setSnack({
            message: error?.response?.data?.message || error?.message,
            type: 'error',
        }));
        return handleSubmit(error);
    }
}

