import UserTimesheet from './UserTimesheet';
import { useMonthlyTimesheetsState } from '../useMonthlyTimesheets';
import { TimesheetsNoDataFound } from '../../../components';

const UsersTimesheet = () => {
    const { data: users, year, month } = useMonthlyTimesheetsState();
    return users.length === 0 ? (
        <TimesheetsNoDataFound />
    ) : (
        users.map((user, idx) => (
            <UserTimesheet
                {...user}
                yearMonth={`${year}-${month}`}
                key={idx}
                isOdd={(idx + 1) % 2 === 1}
            />
        ))
    );
};

export default UsersTimesheet;
