import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import { composeDatasetReducers } from '@libs/datasets';

// Reducers
import auth from 'auth/redux.reducer.js';
import navigation from 'navigation/redux.reducer';
import utilities from 'utilities/redux.reducer';
import dashboard from 'dashboard/redux.reducer';
import common from 'common/redux.reducer';
import employee from 'employee/redux.reducer';
import roles from 'roles/redux.reducer';
import user from 'user/redux.reducer';
import timeAndAttendance from 'timeAndAttendance/redux.reducer';
import leaveCategory from 'leaveCategory/redux.reducer';
import leaveManagement from 'leaveManagement/redux.reducer';
import employeeLeaveManagement from 'leaveAndHolidays/redux.reducer';
import employeeLeaveManagementForUser from 'common/components/leaveDetails/redux.reducer';
import holiday from 'holidays/redux.reducer';

export default combineReducers(
    composeDatasetReducers({
        auth,
        form: formReducer,
        utilities,
        navigation,
        dashboard,
        employee,
        common,
        user,
        roles,
        timeAndAttendance,
        leaveCategory,
        leaveManagement,
        employeeLeaveManagement,
        employeeLeaveManagementForUser,
        holiday
    })
);
