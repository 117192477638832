import { CREATE_SHIFT, DELETE_SHIFT, UPDATE_SHIFT } from './redux.actionTypes';

export const createShift = ({ handleSubmit, payload }) => ({
    type: CREATE_SHIFT,
    meta: { handleSubmit },
    payload,
});

export const deleteShift = ({ handleSubmit, payload }) => ({
    type: DELETE_SHIFT,
    meta: { handleSubmit },
    payload,
});

export const updateShift = ({ handleSubmit, ...payload }) => ({
    type: UPDATE_SHIFT,
    meta: { handleSubmit },
    payload,
});