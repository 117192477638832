import { useEffect, useState } from 'react';
import React from 'react';
import { Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


import { useDispatch } from 'react-redux';

import { Box, TableBody, TableRow, styled } from '@mui/material';
import { theme } from 'app/Theme';
import ContentContainer from '@libComponents/ContentContainer';
import TableCellContainer from '@libComponents/table/TableCellContainer';
import TableContainer from '@libComponents/table/TableContainer';
import TableHeaderContainer from '@libComponents/table/TableHeaderContainer';

import AdornmentLeave from './AdornmentLeave';
import Leaves from './Leaves';

import { DOMAIN_NAME } from "../common";
import { getEmplopyeeRemainingLeaves } from 'leaveAndHolidays/redux.actions';
import DialogWrapper from '@libComponents/Dialog';
import { useNavigate } from "react-router-dom";

const propTypes = {
};

const defaultProps = {
    domain: DOMAIN_NAME,
    loading: true,
};

const StyledBox = styled(Box)(
    ({ theme: { spacing, palette, typography, shape } }) => ({
        width: '100%',
        overflow: 'auto',
        '& table': {
            tableLayout: 'fixed'
        }
    })
);

const TABLE_DATA = {
    'category': 'Category',
    'remainingDays': 'Remaining Days',
    'totalDays': "Total Days",
};

const RemainingLeaves = (({ ...props }) => {

    const [data, setData] = useState([]);
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();

    const RXDispatch = useDispatch();
    useEffect(() => {
        RXDispatch(
            getEmplopyeeRemainingLeaves({
                payload: {},
                handleSubmit
            })
        );
    }, []);

    const handleSubmit = (data) => {
        if (data.length) {
            setData(data);
        }
    }

    const handleClick = () => {
        navigate(`/leave-&-holidays/create-leave`);
    }

    const handleClose = (value) => {
        setOpen(false);
    };

    const onHandleDetails = () => {
        setOpen(true);
    }

    if (!data) return null;
    return (
        <>
            <ContentContainer title="Leaves" headerBg={theme.palette.background.mainHeader} AdornmentComponent={<AdornmentLeave
                handleClick={handleClick} />}>
                <Leaves data={data} onHandleDetails={onHandleDetails} />
            </ContentContainer>
            <DialogWrapper open={open} onClose={handleClose} sx={{ textAlign: "center" }}>
                <Typography variant='h6' sx={{ mb: 2 }}>
                    Leave Details
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon
                        sx={{
                            color: (theme) => theme.palette.primary.main,
                        }}
                    />
                </IconButton>
                <Box  >
                    <TableContainer >
                        <TableHeaderContainer>
                            {Object.values(TABLE_DATA).map((th, i) => (
                                <TableCellContainer
                                    sx={{ pl: i === 0 ? 4 : 2, fontWeight: 'bold' }}
                                    typography="caption" key={th}
                                >
                                    {th}
                                </TableCellContainer>
                            ))}
                        </TableHeaderContainer>
                        <TableBody>
                            {data.map((row, i) => (
                                <TableRow key={i}>
                                    {Object.keys(TABLE_DATA).map((key, index) => {
                                        switch (key) {
                                            case 'category':
                                                return (
                                                    <TableCellContainer
                                                        sx={{ pl: index === 0 ? 4 : 2 }}
                                                        key={key}>
                                                        {row[key].name}
                                                    </TableCellContainer>
                                                )

                                            default:
                                                return (
                                                    <TableCellContainer
                                                        key={index}
                                                        sx={{ pl: index === 0 ? 4 : 2 }}
                                                    >
                                                        {row[key] === -1 ? "No Leave Applicable" : row[key]}
                                                    </TableCellContainer>
                                                );
                                        }
                                    })}

                                </TableRow>
                            ))}
                        </TableBody>
                    </TableContainer>
                </Box>
            </DialogWrapper>
            {/* <ContentContainer title={"Remaining Leaves "} headerBg={theme.palette.background.mainHeader}>
                <StyledBox>
                    <TableContainer >
                        <TableHeaderContainer>
                            {Object.values(TABLE_DATA).map((th, i) => (
                                <TableCellContainer
                                    sx={{ pl: i === 0 ? 4 : 2, fontWeight: 'bold' }}
                                    typography="caption" key={th}
                                >
                                    {th}
                                </TableCellContainer>
                            ))}
                        </TableHeaderContainer>
                        <TableBody>
                            {data.map((row, i) => (
                                <TableRow key={i}>
                                    {Object.keys(TABLE_DATA).map((key, index) => {
                                        switch (key) {
                                            case 'category':
                                                return (
                                                    <TableCellContainer
                                                        sx={{ pl: index === 0 ? 4 : 2 }}
                                                        key={key}>
                                                        {row[key].name}
                                                    </TableCellContainer>
                                                )

                                            default:
                                                return (
                                                    <TableCellContainer
                                                        key={index}
                                                        sx={{ pl: index === 0 ? 4 : 2 }}
                                                    >
                                                        {row[key] === -1 ? "No Leave Applicable" : row[key]}
                                                    </TableCellContainer>
                                                );
                                        }
                                    })}

                                </TableRow>
                            ))}
                        </TableBody>
                    </TableContainer>
                </StyledBox>
            </ContentContainer> */}

        </>

    );
});

RemainingLeaves.propTypes = propTypes;
RemainingLeaves.defaultProps = defaultProps;

export default RemainingLeaves;
