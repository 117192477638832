const normalizeData = ({ data }) => data;

const composeNormalizePageData =
    (fields) =>
    ({ data: { data = [], nextPage = false } = {} }) => {
        const fieldEntries = Object.entries(fields);

        const normalizedData = data.map((datum) => {
            return fieldEntries.reduce((acm, [key, label]) => {
                return { ...acm, [label]: datum[key] };
            }, {});
        });

        return {
            data: normalizedData,
            nextPage
        };
    };

export default ({ normalizer = null, fields = null }) => {
    // If normalizer doesn't exist
    if (!normalizer) {
        return !!fields ? composeNormalizePageData(fields) : normalizeData;
    } else {
        return normalizer;
    }
};
