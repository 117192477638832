import { takeLatest } from 'redux-saga/effects';

import { DELETE_EMPLOYEE, EMPLOYEE_PUBLISH, GET_EMPLOYEE, GET_EMPLOYEE_FIELD_VALUE, RESIGN_EMPLOYEE } from '../../redux.actionTypes';
import deleteEmployeeWorker from './deleteEmployeeWorker';
import employeePublishWorker from './employeePublishWorker';
import getEmployeeWorker from './getEmployeeWorker';
import getSingleFieldValueWorker from './getSingleFieldValueWorker';
import resignEmployeeWorker from './resignEmployeeWorker';

// Workers

// Watchers
export default function* () {
    yield takeLatest(EMPLOYEE_PUBLISH, employeePublishWorker);
    yield takeLatest(GET_EMPLOYEE, getEmployeeWorker);
    yield takeLatest(DELETE_EMPLOYEE, deleteEmployeeWorker);
    yield takeLatest(GET_EMPLOYEE_FIELD_VALUE, getSingleFieldValueWorker);
    yield takeLatest(RESIGN_EMPLOYEE, resignEmployeeWorker);
}
