import { useCallback, useMemo } from 'react';

import { LABELS } from './common';
import General from './content/General';
import Contact from './content/Contact';
import Family from './content/Family';
import Identification from './content/Identification';
import Academic from './content/Academic';
import Others from './content/Others';
import Complete from './content/Complete';

import useStepperHandle from '@libHooks/useStepperHandle';

const useContent = (mode) => {
    return useMemo(() => {
        return {
            [LABELS.general]: General,
            [LABELS.contact]: Contact,
            [LABELS.family]: Family,
            [LABELS.identification]: Identification,
            [LABELS.academic]: Academic,
            [LABELS.others]: Others,
            [LABELS.complete]: Complete,
        };
    }, [mode]);
};

const useHandles = ({ dispatch, mode, activeStep, publish, publishPartial }) => {
    const amountOfSteps = useMemo(() => Object.keys(LABELS).length, [LABELS]);

    const reset = useCallback(() => {
        dispatch({ type: 'reset' });
    }, [dispatch]);

    const handlePublishStepper = useStepperHandle({
        amountOfSteps,
        dispatch,
        mode,
        publish,
        activeStep: amountOfSteps - 2, // Forces the handle to think it's about to publish,
    });

    const handlePublish = useCallback(
        (submitProps) => handlePublishStepper({ submitProps }),
        [handlePublishStepper]
    );

    const handleStepper = useStepperHandle({
        amountOfSteps,
        dispatch,
        mode,
        activeStep,
        publish,
        publishPartial
    });

    const handleNext = useCallback(
        (submitProps = {}) =>
            handleStepper({ ...submitProps, direction: 'next' }),
        [handleStepper]
    );

    const handleBack = useCallback(
        () => handleStepper({ direction: 'back' }),
        [handleStepper]
    );

    const handleSaveAndExit = useCallback(
        () => handleStepper({ direction: 'exit' }),
        [handleStepper]
    );

    return [handleNext, handleBack, handlePublish, reset, handleSaveAndExit];
};

export { useContent, useHandles };
