import AppliedLeaves from './AppliedLeaves';
import RemainingLeaves from './RemainingLeaves';

const LeaveManagement = () => {
    return (
        <>
            <RemainingLeaves />
            <AppliedLeaves />
        </>
    )
}

export default LeaveManagement;