export const createShadow = ({
    hOffset = 0,
    vOffset = 0,
    blur = 0,
    spread = 0,
    color = 'black',
    inset = false,
}) => {
    const insetText = inset ? 'inset' : '';

    return `${hOffset} ${vOffset} ${blur} ${spread} ${color} ${insetText}`;
};
