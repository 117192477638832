import makeContext from '@libs/makeContext';
import { getCurrentStartAndEndOfWeek, modifyWeek } from '@libs/dateTime';

import {
    SET_WEEK,
    TIMESHEET_NEXT,
    TIMESHEET_PREV,
} from '../../context.actionTypes';

const initialState = {
    entries: [],
    isNextDisabled: true,
    ...getCurrentStartAndEndOfWeek()
};

const reducer = (state, { type, payload }) => {
    switch (type) {
        case SET_WEEK: {
            const currentWeek = getCurrentStartAndEndOfWeek();
            return {
                ...state,
                ...payload,
                isNextDisabled: currentWeek.startOfWeek === payload.startOfWeek
            };
        }
        default:
            return state;
    }
};

const middleware = (state, action, { fetchData }) => {
    switch (action.type) {
        case SET_WEEK: {
            const { payload } = action;
            // TODO: extract start and end of week from payload
            const startOfWeek = 'payload start of week';
            const endOfWeek = 'payload end of week';
            fetchData({
                startOfWeek,
                endOfWeek
            });

            return { ...action, payload: { startOfWeek, endOfWeek } };
        }

        case TIMESHEET_NEXT: {
            const {  startOfWeek, endOfWeek } = state;

            const newWeek = modifyWeek({ startOfWeek, endOfWeek }, true);

            fetchData({
                ...newWeek
            });

            return {
                type: SET_WEEK,
                payload: newWeek
            };
        }
        case TIMESHEET_PREV: {
            const {  startOfWeek, endOfWeek } = state;

            const prevWeek = modifyWeek({ startOfWeek, endOfWeek }, false);

            fetchData({
                ...prevWeek
            });

            return {
                type: SET_WEEK,
                payload: prevWeek
            };
        }

        default:
            return action;
    }
};

const [
    WeeklyTimesheetsProvider,
    useWeeklyTimesheetsState,
    useWeeklyTimesheetsDispatch,
    useWeeklyTimesheets
] = makeContext(reducer, initialState, {
    name: 'WeeklyTimesheets',
    middleware
});

export {
    useWeeklyTimesheets as default,
    WeeklyTimesheetsProvider,
    useWeeklyTimesheetsState,
    useWeeklyTimesheetsDispatch,
    SET_WEEK,
    TIMESHEET_NEXT,
    TIMESHEET_PREV
};
