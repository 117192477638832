import { fork } from 'redux-saga/effects';

// Local
import employeeSagas from './employeeSagas';
import manageEmployeeSagas from './manageEmployeeSagas';

// Workers
export default function* () {
    yield fork(employeeSagas);
    yield fork(manageEmployeeSagas);
}