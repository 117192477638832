// import { getFormValues } from 'redux-form';

import { apiRequest } from "@libs/apiRequest";
import { EMPLOYEES } from "employee/redux.datasets";
import { call, put } from "redux-saga/effects";
import { setLoading, setSnack } from "utilities/redux.actions";

export default function* (action) {
    const {
        meta: { handleSubmit = () => { } },
        payload,
        ...rest
    } = action;
    
    yield put(setLoading(true));
    try {
        const { data } = yield call(
            apiRequest,
            {
                url: `employee/delete?email=${payload.email}`,
                method: 'DELETE'
            }
        );
        yield put(setLoading(false));
        yield put(setSnack({
            message: "Employee deleted successfully",
            type: 'success',
        }));
        yield put({
            type: "deleteEmployee",
            payload: {
                entity: EMPLOYEES,
                value: data
            },
        })
        return handleSubmit(data);
    } catch (error) {
        yield put(setLoading(false));
        yield put(setSnack({
            message: error?.response?.data?.message || error?.message,
            type: 'error',
        }));
        return handleSubmit(error);
    }
}

