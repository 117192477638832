import ContentContainer from '@libComponents/ContentContainer';
import PropTypes from 'prop-types';
import { Fragment, useState } from 'react';
import { useLocation } from 'react-router';
import { DOMAIN_NAME, FORM_NAME, IS_ACCOUNT_ACTIVE_OPTION, IS_EMPLOYEE_OPTIONS } from '../common';
// Libs
import { GridContainer, GridItem, Input } from '@formComponents';
import AutocompleteFetch from '@formComponents/AutocompleteFetch';
import InputRadio from '@formComponents/InputRadio';
import { Button } from '@libComponents';
import { email as emailValidator, isEmpty, maxLength, minLength, required } from '@libs/validationRules';
import { styled } from '@mui/system';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ROLES } from 'user/redux.datasets';
import { createUser, getUser, updateUser } from "../redux.actions";
import { useNavigate } from "react-router-dom";

import {
    initialize as formInitialize,
    reduxForm
} from 'redux-form';
import { InputPassword } from '@formComponents/InputPassword';
import { hasFormErrorSelector } from 'common/redux.selectors';

const propTypes = {
    mode: PropTypes.oneOf(['create', 'update']),
};
const defaultProps = {
    mode: 'create',
};

/**
 * ReduxForm stepper rules
 * * Connect each step with reduxForm() to the same form name.
 * * Specify the destroyOnUnmount: false flag to preserve form data across form component unmounts.
 */
const ButtonWrapper = styled('div')({
    position: 'absolute',
    bottom: 0,
    right: '50%',
    transform: 'translate(50%, 50%)',
    zIndex: 4,
});

const StyledButton = styled(Button)(({ theme: { spacing, typography } }) => ({
    height: spacing(4.75),
    width: spacing(25),
    fontSize: typography.subtitle1.fontSize,
    marginLeft: spacing(0.75),
    marginRight: spacing(0.75),
}));

const minLength8 = minLength(8);
const maxLength20 = maxLength(20);

const User = reduxForm({
    form: FORM_NAME, // Form name
    destroyOnUnmount: false, // Preserve data
    onSubmit: (v) => v,
})(({ mode, handleSubmit: handleReduxFormSubmit, ...rest }) => {
    const { state } = useLocation();
    const RXDispatch = useDispatch();

    const { email } = state ? state : "";
    const [accountIsActive, setAccountActive] = useState(true);
    const hasError = useSelector((state) =>
    hasFormErrorSelector(state, FORM_NAME)
  );

    useEffect(() => {
        RXDispatch(formInitialize(FORM_NAME, {
            isEmployee: true,
            accountIsActive: true
        }));
    }, [mode]);

    useEffect(() => {
        if (email && mode === "update") {
            RXDispatch(getUser({ handleSubmit: handleGetUser, email }));
        }
    }, [email]);

    const handleGetUser = (payload) => {
        setAccountActive(payload.accountIsActive)
        RXDispatch(formInitialize(FORM_NAME, payload));
    }

    const handleCreate = () => {
        if (hasError) {
            handleReduxFormSubmit();
            return;
        }

        if (mode === "create")
            RXDispatch(createUser({ handleSubmit }));
        else if (mode === "update") {
            RXDispatch(updateUser({ handleSubmit }))
        }
    }
    const navigate = useNavigate();

    const handleSubmit = (payload) => {
        if (mode === "create")
            RXDispatch(formInitialize(FORM_NAME, {
                isEmployee: true
            }));

        setTimeout(() => {
            navigate("/user/all-users");
        }, 1000)
    }

    return (
        <ContentContainer
            title={mode + ' User'}
            sx={{ textTransform: 'capitalize' }}
            AdornmentComponent={<Fragment />}
        >
            <GridContainer>
                <GridItem md={4}>
                    <Input
                        name='email'
                        placeholder='email'
                        label='E-Mail'
                        required={true}
                        validate={[required, emailValidator]}
                        disabled={mode === "update" ? true : false}
                        autoComplete="do-not-autofill"
                    />
                </GridItem>
                {(mode === "create" || !accountIsActive) && (
                    <GridItem md={4} sx={{ position: 'relative' }}>
                        <InputPassword
                            name='password'
                            placeholder='Password'
                            label='Password'
                            required={true}
                            validate={[required, minLength8, maxLength20]}
                            autoComplete="new-password"
                        />
                    </GridItem>
                )}
                <GridItem md={4}>
                    <AutocompleteFetch
                        name='roles'
                        placeholder='Role'
                        label='Role'
                        domain={DOMAIN_NAME}
                        entity={ROLES}
                        multiple
                        required={true}
                        validate={[required, isEmpty]}
                    />
                </GridItem>
                <GridItem md={12}>
                    <InputRadio
                        name='isEmployee'
                        label='Is employee?'
                        options={IS_EMPLOYEE_OPTIONS}
                        required={true}
                        validate={required}
                    />
                </GridItem>
                <GridItem md={12}>
                    <InputRadio
                        name='accountIsActive'
                        label='Account Is Active?'
                        options={IS_ACCOUNT_ACTIVE_OPTION}
                    />
                </GridItem>

            </GridContainer>

            <ButtonWrapper>
                <StyledButton onClick={handleCreate}>
                    {mode === "create" ? "CREATE" : "UPDATE"}
                </StyledButton>
            </ButtonWrapper>
        </ContentContainer>
    );
});

User.propTypes = propTypes;
User.defaultProps = defaultProps;

export { User as default, User };

