import React, { useState } from 'react';
import {
    Avatar,
    Box,
    CardActions,
    CardContent,
    CardHeader,
    Checkbox,
    Divider,
    List,
    ListItem,
    ListItemText,
    TextField,
    TextareaAutosize,
    Typography,
    styled,
} from '@mui/material';
import { Button } from '@libComponents';
import LogoutIcon from '@mui/icons-material/Logout';
import DialogWrapper from '@libComponents/Dialog';
import { useDispatch } from 'react-redux';
import { resignEmployee } from 'employee/redux.actions';
import { normalizeIso8601DateOnly } from '@libs/dateTime';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';



const StyledCardAction = styled(CardActions)(({ theme: { spacing } }) => ({
    display: "flex",
    justifyContent: "space-between",
    paddingTop: spacing(3),
    paddingBottom: 0
}));

const StyledList = styled(ListItem)(
    ({ theme: { spacing } }) => ({
        padding: spacing(1, 0),
    })
);

export default function Resignation({ row = {} }) {
    const [resignationModalOpen, setResignationModalOpen] =
        useState(false);

    const [resignDate, setResignDate] = useState(new Date());
    const [isTerminated, setIsTerminated] = useState(false);
    const [description, setDescription] = useState('');

    const TABLE_DATA = {
        branchName: 'Branch',
        designationName: 'Designation',
        resignationDate: 'Last Working Day',
        isTerminated: 'Is Terminated',
        description: 'Description',
    };

    const RXDispatch = useDispatch();

    const { email, firstname, lastname } = row;

    const handleResignationModalOpen = () => {
        setResignationModalOpen(true);
    };

    const handleResignation = () => {
        RXDispatch(
            resignEmployee({
                handleSubmit: resignCallback,
                payload: { email: email, date: normalizeIso8601DateOnly(resignDate), description, isTerminated }
            })
        );
    };

    const resignCallback = () => {
        setResignationModalOpen(false);
    };

    const handleResignationModalClose = () => {
        setResignationModalOpen(false);
    };

    return (
        <Box>
            <Button
                variant="outlined"
                sx={{ cursor: 'pointer',justifyContent: "flex-start", pl:3 }}
                type="button"
                onClick={handleResignationModalOpen}
                size="small"
                color="primary"
                startIcon={<LogoutIcon color="primary" />}
            >
                Resign
            </Button>

            <DialogWrapper
                open={resignationModalOpen}
                onClose={handleResignationModalClose}
                sx={{ textAlign: 'center' }}
            >
                <Typography variant="h6" sx={{ mb: 1 }}>
                    Resignation: Employee Details
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <React.Fragment>
                    <CardHeader
                        sx={{ textAlign: "left", pt: 0, pb: 0 }}
                        avatar={
                            <Avatar
                                aria-label="recipe"
                                sx={{
                                    bgcolor: (theme) =>
                                        theme.palette.primary.main,
                                }}
                            >
                                {firstname[0]}
                            </Avatar>
                        }
                        title={firstname + ' ' + lastname}
                        subheader={email}
                    />
                    <CardContent>
                        <List>
                            {Object.entries(TABLE_DATA).map(
                                ([key, value], index) => {
                                    switch (key) {
                                        case "resignationDate":
                                            return (
                                                <ListItem disablePadding key={index}>
                                                    <ListItemText
                                                        sx={{ textTransform: 'capitalize' }}
                                                        primary={
                                                            <Typography variant="subtitle2">
                                                                {value}
                                                            </Typography>
                                                        }
                                                    />
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            value={resignDate}
                                                            onChange={(newValue) => {
                                                                setResignDate(newValue)
                                                            }}

                                                            renderInput={(params) => <TextField {...params} sx={{ maxWidth: 160, p: 1 }} />}
                                                        />
                                                    </LocalizationProvider>
                                                </ListItem>
                                            )
                                            case "isTerminated":
                                            return (
                                                <ListItem disablePadding key={index}>
                                                    <ListItemText
                                                        sx={{ textTransform: 'capitalize', pt: 1, pb: 2 }}
                                                        primary={
                                                            <Typography variant="subtitle2">
                                                                {value}
                                                            </Typography>
                                                        }
                                                    />
                                                    {/* mui checkbox */}
                                                    <Checkbox 
                                                        checked={isTerminated}
                                                        defaultChecked={isTerminated}
                                                        onChange={(e) => setIsTerminated(e.target.checked)}
                                                    />

                                                </ListItem>
                                            )
                                        case "description":
                                            return (
                                                <ListItem disablePadding key={index}>
                                                    <ListItemText
                                                        sx={{ textTransform: 'capitalize' }}
                                                        primary={
                                                            <Typography variant="subtitle2">
                                                                {value}
                                                            </Typography>
                                                        }
                                                    />
                                                    <TextareaAutosize 
                                                        value={description}
                                                        onChange={(e) => setDescription(e.target.value)}
                                                        rowsMin={2}
                                                        style={{ width: '100%', maxWidth: 300, minHeight: 100 }}
                                                    />
                                                </ListItem>
                                            );
                                        default:
                                            return (
                                                <StyledList disablePadding key={index}>
                                                    <ListItemText
                                                        sx={{ textTransform: 'capitalize' }}
                                                        primary={
                                                            <Typography variant="subtitle2">
                                                                {value}
                                                            </Typography>
                                                        }
                                                    />
                                                    <ListItemText
                                                        primary={
                                                            <Typography variant="body2">
                                                                {row[key]}
                                                            </Typography>
                                                        }
                                                        sx={{ textAlign: 'right' }}
                                                    />
                                                </StyledList>
                                            );
                                    }
                                }
                            )}
                        </List>
                    </CardContent>
                    <Divider />
                    <StyledCardAction>
                        <Button
                            onClick={handleResignationModalClose}
                            color="tertiary"
                        >
                            Cancel
                        </Button>
                        <Button onClick={handleResignation}>Confirm</Button>
                    </StyledCardAction>
                </React.Fragment>
            </DialogWrapper>
        </Box>
    );
}
