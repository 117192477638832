import {useState, Fragment} from 'react';
import { BranchCreateUpdate } from './BranchCreateUpdate';
import ViewBranch from './ViewBranch';
import { DeleteBranch } from './DeleteBranch';

export default function Branch() {
    const [viewType, setViewType] = useState('default');
    const [data, setData] = useState({ mode: 'create', row: {} });

    return (
        <Fragment>
            {viewType === 'default' && (
                <ViewBranch setViewType={setViewType} setData={setData} />
            )}
            {viewType === 'createBranch' && (
                <BranchCreateUpdate
                    mode={data.mode}
                    row={data.row}
                    setViewType={setViewType}
                />
            )}
            {viewType === 'deleteBranch' && (
                <DeleteBranch
                    row={data.row}
                    setViewType={setViewType}
                />
            )}
        </Fragment>
    );
}
