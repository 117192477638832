import { useCallback } from 'react';
import { useTheme } from '@mui/material';

import { GridContainer, GridItem } from '@formComponents';
import { normalizeIso8601Date } from '@libs/dateTime';

import {
    TimesheetsInputSearch,
    TimesheetsMonthPicker,
    TimeSheetsNavigator,
    SelectBranch
} from 'timeAndAttendance/components';
import useMonthlyTimesheets, {
    SET_EMAIL,
    SET_YEAR_MONTH,
    TIMESHEET_NEXT,
    TIMESHEET_PREV,
    SET_FILTERS
} from './useMonthlyTimesheets';

const Filters = () => {
    const { spacing } = useTheme();
    const [{ date, isNextDisabled, filters }, dispatch] =
        useMonthlyTimesheets();

    const handleSearch = useCallback((value) => {
        dispatch({
            type: SET_EMAIL,
            payload: value
        });
    }, []);

    const handleYearMonth = useCallback((value) => {
        try {
            if (value) {
                const normalizeValue = normalizeIso8601Date(value);
                if (!normalizeValue.includes('Invalid Date')) {
                    dispatch({
                        type: SET_YEAR_MONTH,
                        payload: normalizeValue
                    });
                }
            }
        } catch {}
    }, []);

    const handlePrevClick = useCallback(() => {
        dispatch({
            type: TIMESHEET_PREV
        });
    }, []);

    const handleNextClick = useCallback(() => {
        dispatch({
            type: TIMESHEET_NEXT
        });
    }, []);

    const handleFilterChange = useCallback((value) => {
        dispatch({
            type: SET_FILTERS,
            payload: {
                filterType: 'branch',
                value
            }
        });
    }, []);

    return (
        <GridContainer sx={{ paddingBottom: spacing(2) }}>
            <GridItem md={4}>
                <TimeSheetsNavigator
                    onPrevClick={handlePrevClick}
                    onNextClick={handleNextClick}
                    isNextDisabled={isNextDisabled}
                >
                    <TimesheetsMonthPicker
                        input={{
                            value: date,
                            onChange: handleYearMonth
                        }}
                    />
                </TimeSheetsNavigator>
            </GridItem>
            <GridItem md={2}>
                <SelectBranch
                    input={{
                        name: 'branch',
                        onChange: handleFilterChange,
                        value: filters.branch
                    }}
                />
            </GridItem>
            <GridItem md={3}>
                <TimesheetsInputSearch
                    name='monthly-view-employee-email'
                    handleSearch={handleSearch}
                />
            </GridItem>
        </GridContainer>
    );
};

export default Filters;
