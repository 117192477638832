export default [
    {
        key: 'officialEmail',
        label: 'Official E-Mail',
    },
    {
        key: 'personalEmail',
        label: 'Personal E-Mail',
    },
    {
        key: 'mobileNumber',
        label: 'Mobile Number',
    },
    {
        key: 'emergencyContact',
        label: 'Emergency Contact ID',
    },
    {
        key: 'permanentAddress',
        label: 'Permanent Address',
    },
    {
        key: 'presentAddress',
        label: 'Present Address',
    },
]