import TableContainer from '@libComponents/table/TableContainer';
import TableHeaderContainer from '@libComponents/table/TableHeaderContainer';
import { Box, styled, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { Button } from '@libComponents';

const StyledBox = styled(Box)(
    ({ theme: { spacing, palette, typography, shape } }) => ({
        width: "100%",
        maxHeight: "30vh",
        overflow: "auto",
        "& table": {
            tableLayout: "fixed",
        }
    }));

const StyledButton = styled(Button)(
    ({ theme: { spacing } }) => ({
        margin: spacing(1),
        width: "auto"
    }));

const Leaves = (({ data = [], onHandleDetails, ...props }) => {
    return (
        <StyledBox>
            <TableContainer sx={{ p: 2 }}>
                <TableHeaderContainer>
                    {data
                        .filter((item) => item?.totalDays !== -1)
                        .slice(0, 5)
                        .map((item) => (
                            <TableCell
                                data-testid={item?.category?.name
                                    .toLowerCase()
                                    .split(' ')
                                    .join('-')
                                    .concat('-fieldColllum')}
                                key={item?.category?.name}
                                size='small'
                                sx={{ border: 0 }}
                            >
                                {item?.category?.name}
                            </TableCell>
                        ))}
                    <TableCell size='small' sx={{ border: 0 }}>
                        All Leaves
                    </TableCell>
                </TableHeaderContainer>
                <TableBody>
                    <TableRow>
                        {data
                            .filter((item) => item?.totalDays !== -1)
                            .slice(0, 5)
                            .map((row, i) => (
                                <TableCell
                                    size='small'
                                    key={i}
                                    sx={{ border: 0 }}
                                >
                                    <Typography
                                        variant='h6'
                                        sx={{ fontWeight: 'bold' }}
                                    >
                                        {row.remainingDays +
                                            '/' +
                                            row.totalDays +
                                            ' Days'}
                                    </Typography>
                                </TableCell>
                            ))}
                        <TableCell size='small' sx={{ border: 0 }}>
                            <StyledButton
                                variant='contained'
                                color='primary'
                                onClick={() => onHandleDetails()}
                            >
                                Details
                            </StyledButton>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </TableContainer>
        </StyledBox>
    );
});

export default Leaves;