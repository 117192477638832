import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import {
    Avatar,
    Card,
    CardContent,
    CardHeader,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Typography
} from '@mui/material';


import { GridContainer, GridItem } from '@formComponents';

import React from 'react';
import styled from '@emotion/styled';

const propTypes = {
    TABLE_DATA: PropTypes.object.isRequired,
    handleClick: PropTypes.func.isRequired,
};

const StyledCard = styled(Card)(
    () => ({
        '& .MuiCardHeader-title':{
			fontWeight:"bold",
			fontSize:16
		}
    }));
	
function EmployeeCardView({ TABLE_DATA, rows, ActionComponent, handleClick }) {

    let visiableColumnsInContent = [
        'branchName',
        'designationName',
        'joiningDate',
        'employeeId'
    ];

    return (
        <GridContainer spacing={2}>
            {rows.map((row, i) => (
                <GridItem xs={12} md={6} lg={4} key={i} sx={{
                        opacity: row.resignationDate ? 0.5 : 1
                    }}>
                    <StyledCard variant='outlined'>
                        <React.Fragment>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label='recipe' sx={{bgcolor: (theme) => theme.palette.primary.main }} >
                                        {row.firstname[0]}
                                    </Avatar>
                                }
                                action={<ActionComponent row={row} handleClick={handleClick} />}
                                title={row.firstname + ' ' + row.lastname}
                                subheader={row.email}
                            />
                             <Divider />
                            <CardContent>
                                <List>
                                    {visiableColumnsInContent.map(
                                        (columnName, index) => {
                                            switch (columnName) {
                                                case 'joiningDate':
                                                    return(
                                                        <ListItem disablePadding key={index}>
                                                            <ListItemButton sx={{display:"flex", alignItems:"center", justifyContent:"space-between", gap:1}}>
                                                                <ListItemText primary={ TABLE_DATA[columnName] } />
                                                                <ListItemText primary={ dayjs(row[ columnName]).format('YYYY-MM-DD')} sx={{textAlign:"right"}}/>
                                                            </ListItemButton>
                                                        </ListItem>
                                                    );
                                                    case 'Resignation Date':
                                                    return null;
                                                default:
                                                    return (
                                                        <ListItem disablePadding key={index}>
                                                            <ListItemButton>
                                                                <ListItemText sx={{textTransform:"capitalize"}} primary={ 
																	<Typography variant='subtitle2'>
																		{TABLE_DATA[columnName] }
																	</Typography>
																	}/>
                                                                <ListItemText primary={ 
																	<Typography variant='body2'>
																		{row[columnName] }
																	</Typography>} sx={{textAlign:"right"}}/>
                                                            </ListItemButton>
                                                        </ListItem>
                                                    );
                                            }
                                        }
                                    )}
                                </List>
                            </CardContent>
                            <Divider />
                            
                          
                        </React.Fragment>
                    </StyledCard>
                </GridItem>
            ))}
        </GridContainer>
    );
}

EmployeeCardView.propTypes = propTypes;

export default EmployeeCardView;
