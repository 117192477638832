import {
    TimeRangeHeader,
    TimesheetDataCell,
    TimesheetDataRowWrapper
} from '../../../components';

const Header = () => {
    return (
        <TimesheetDataRowWrapper>
            <TimeRangeHeader sx={{ gridColumn: 'span 10' }} />
            <TimesheetDataCell
                sx={{ gridColumn: 'span 2', textAlign: 'center' }}
            >
                Total
            </TimesheetDataCell>
        </TimesheetDataRowWrapper>
    );
};

export default Header;
