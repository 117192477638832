import { calculateSecondsToHoursAndMinutes } from '@libs/dateTime';

import {
    WeekdayHours,
    TimesheetDataCell,
    TimesheetDataRowWrapper
} from '../../../components';

const Row = ({ entries, totalDuration }) => {
    return (
        <TimesheetDataRowWrapper>
            <WeekdayHours sx={{ gridColumn: 'span 10' }} entries={entries} />
            <TimesheetDataCell
                sx={{ gridColumn: 'span 2', textAlign: 'center', opacity: 0.8 }}
            >
                {calculateSecondsToHoursAndMinutes(totalDuration)}
            </TimesheetDataCell>
        </TimesheetDataRowWrapper>
    );
};

export default Row;
