import apiRequest from '@libs/apiRequest';
import { call, put, select } from 'redux-saga/effects';
import { setLoading, setSnack } from 'utilities/redux.actions';

export default function* (action) {
    const {
        meta: { handleSubmit = () => { } },
        payload: { url = "" }
    } = action;

    try {
        const resp = yield call(
            apiRequest, {
            url,
            method: "get",
            redirect: 'follow',
            responseType: 'blob'
        }
        );
        let _url = window.URL.createObjectURL(resp.data);
        return handleSubmit({
            type: "success",
            url: _url
        });

    } catch (error) {
        yield put(setLoading(false));
        yield put(setSnack({
            message: error?.response?.data?.message || error?.message,
            type: 'error',
        }));
        return handleSubmit(error);
    }
}
