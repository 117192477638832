import apiRequest from '@libs/apiRequest';
import { call, put } from 'redux-saga/effects';
import { setLoading, setSnack } from 'utilities/redux.actions';

export default function* (props) {
    const {
        meta: { handleSubmit = () => { } },
        payload: { email }
    } = props;

    let url = `employee/get/me`;
    if (email) {
        url = `employee/get?email=${email}`;
    }
    try {
        yield put(setLoading(true));

        const employee_data = yield call(
            apiRequest,
            {
                url,
                method: "get"
            }
        );
        yield put(setLoading(false));

        return handleSubmit(employee_data);
    } catch (error) {
        yield put(setLoading(false));
        yield put(setSnack({
            message: error?.response?.data?.message || error?.message,
            type: 'error',
        }));
        return handleSubmit(error);
    }
}
