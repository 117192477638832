import { Slide } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/system';
import PropTypes from 'prop-types';
import React from 'react'

const BootstrapDialog = styled(Dialog)(({ theme, expadding = 3 }) =>({
    '& .MuiPaper-root': {
        padding: theme.spacing(expadding),
        minWidth: 480,
        maxWidth: "80vw",
    },
}));

const LeftTransition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const DefaultTransition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogWrapper({ children ,position, direction, dialogPadding = 3, ...rest }) {
    let Transition = DefaultTransition;
    if(direction === "left"){
        Transition = LeftTransition
    }

    let dialogProps = {
        ...rest, 
        ...(direction ? { TransitionComponent: Transition } : null),
    }
    

    return (
        <BootstrapDialog {...dialogProps} expadding={dialogPadding}>
            {children}
        </BootstrapDialog>
    );
}

DialogWrapper.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    dialogPadding: PropTypes.number
};
