export const MENU_GROUPS = '@menuGroups';
export const MENUS = '@menus';
export const GET_ALL_PERMISSIONS = '@get-all-permissions';
export const GET_ALL_USERS = '@get-all-users';

export default {
    [MENU_GROUPS]: {
        url: 'company/get-menu-groups'
    },
    [MENUS]: {
        url: 'menu/get-menus',
        selector: () => false
    },
    [GET_ALL_PERMISSIONS]: {
        url: 'permission/get/all',
        selector: () => false
    },
    [GET_ALL_USERS]: {
        url: 'user/get/all',
        selector: () => false
    }
};
