// Material
import { Grid, styled } from '@mui/material';

const StyledGrid = styled(Grid)(({ theme: { spacing } }) => ({
    width: '98%',
    margin: 'auto',
    paddingTop: spacing(2),
    paddingBottom: spacing(6),
}));

const GridContainer = ({ children, ...props }) => (
    <StyledGrid container {...props}>
        {children}
    </StyledGrid>
);

export { GridContainer as default, GridContainer };
