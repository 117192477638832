import { Button } from '@libComponents';
import { Box, styled } from '@mui/material';

const StyledBox = styled(Box)(
    () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center"

    }));

const StyledButton = styled(Button)(
    ({ theme: { spacing } }) => ({
        margin: spacing(1),
        width: "auto"
    }));


const AdornmentLeave = (({ ...props }) => {
    return (
        <StyledBox>
            <StyledButton
                data-testid="apply-for-leave"
                variant="contained"
                color="primary"
                onClick={props.handleClick}
            >
                Apply For Leave
            </StyledButton>
        </StyledBox>
    )
});

export { AdornmentLeave as default, AdornmentLeave };

