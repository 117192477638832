import {
    SET_AUTH_DATA,
    SET_AUTH_FORM_LOADING,
    LOGOUT,
} from './redux.actionTypes';

const initialState = {
    email: null,
    loading: false,
};

export default (state = initialState, { type, payload, ...action }) => {
    switch (type) {
        case SET_AUTH_DATA: {
            return { ...state, ...payload };
        }
        case SET_AUTH_FORM_LOADING: {
            return { ...state, loading: payload };
        }
        case LOGOUT: {
            return {
                ...initialState,
            };
        }
        default: {
            return state;
        }
    }
};
