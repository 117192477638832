import { Box, styled } from '@mui/material'
import Overview from "./Overview";
import Attendance from "./Attendance";
import ApprovedLeave from "./ApprovedLeave";
import EventsCalender from './EventsCalender';

const StyledBox = styled(Box)(
    ({ theme: { spacing, palette, typography, shape } }) => ({
        display: 'flex',
        gap: spacing(4),
    })
);

function Dashboard() {
    return (
        <Box pt={4} pb={4}>
            <Overview />
            <Attendance />
            <StyledBox >
                <EventsCalender />
                <ApprovedLeave />
            </StyledBox>
        </Box>
    );
}

export default Dashboard;