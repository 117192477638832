import { CREATE_AND_UPDATE_EMPLOYEE_CATEGORY, DELETE_EMPLOYEE_CATEGORY, UPDATE_EMPLOYEE_CATEGORY } from './redux.actionTypes';

export const createEmployeeCategory = ({ handleSubmit, mode, payload }) => ({
    type: CREATE_AND_UPDATE_EMPLOYEE_CATEGORY,
    meta: { handleSubmit },
    mode,
    payload,
});

export const createAndUpdateEmployeeCategory = ({ handleSubmit, mode, payload }) => ({
    type: CREATE_AND_UPDATE_EMPLOYEE_CATEGORY,
    meta: { handleSubmit },
    mode,
    payload,
});

export const deleteEmployeeCategory = ({ handleSubmit, payload }) => ({
    type: DELETE_EMPLOYEE_CATEGORY,
    meta: { handleSubmit },
    payload,
});

export const updateEmployeeCategory = ({ handleSubmit, mode, ...payload }) => ({
    type: UPDATE_EMPLOYEE_CATEGORY,
    meta: { handleSubmit },
    mode,
    payload,
});