import { apiRequest } from '@libs/apiRequest';
import { call, put } from 'redux-saga/effects';
import { setLoading, setSnack } from 'utilities/redux.actions';

function normalizePermissions(permissions, initialCheckedList) {
    let norPermissions = {}
    permissions.forEach(permission => {
        let categoryName = permission?.name.split(".")[0];
        let categoryValue = permission?.name.split(".")[1];
        let value = false;
        if (initialCheckedList.find(elem => elem.name === permission.name)) {
            value = true;
        }
        if (norPermissions[categoryName]) {
            norPermissions[categoryName][categoryValue] = value;
        }
        else {
            norPermissions[categoryName] = { [categoryValue]: value };
        }
    });
    return norPermissions;
}


export default function* (action) {
    const {
        meta: { handleSubmit = () => { } },
        payload,
        ...rest
    } = action;
    yield put(setLoading(true));
    let initialCheckedList = [];

    try {
        const { data: { data } } = yield call(
            apiRequest,
            {
                url: `permission/get/all`,
                method: 'get'
            }
        );

        if (payload.name) {
            const {data} = yield call(
                apiRequest,
                {
                    url: `role/get/permissions?name=${payload.name}`,
                    method: 'get'
                }
            );
            initialCheckedList = data;
        }

        let normalizeData = normalizePermissions(data, initialCheckedList);

        yield put(setLoading(false));
        return handleSubmit(normalizeData);
    } catch (error) {
        yield put(setLoading(false));
        yield put(setSnack({
            message: error?.response?.data?.message || error?.message,
            type: 'error',
            duration: 5000
        }));
    }
}
