import { GridContainer, GridItem } from '@formComponents';

import useWeeklyTimesheets, {
    TIMESHEET_NEXT,
    TIMESHEET_PREV,
    useWeeklyTimesheetsDispatch
} from './useWeeklyTimesheets';
import { useCallback } from 'react';
import { TimeSheetsNavigator } from 'timeAndAttendance/components';
import { Typography } from '@mui/material';
import { formatDateToDDMMM } from '@libs/dateTime';

const Filters = () => {

    const [{ startOfWeek, endOfWeek, isNextDisabled }, dispatch] =
        useWeeklyTimesheets();

    const handlePrevClick = useCallback(() => {
        dispatch({
            type: TIMESHEET_PREV
        });
    }, []);

    const handleNextClick = useCallback(() => {
        dispatch({
            type: TIMESHEET_NEXT
        });
    }, []);

    return (
        <GridContainer sx={{ paddingBottom: 0 }}>
            <GridItem md={4}>
                <TimeSheetsNavigator
                    onPrevClick={handlePrevClick}
                    onNextClick={handleNextClick}
                    isNextDisabled={isNextDisabled}
                >
                    <Typography>
                        {formatDateToDDMMM(startOfWeek)} -{' '}
                        {formatDateToDDMMM(endOfWeek)}
                    </Typography>
                </TimeSheetsNavigator>
            </GridItem>
        </GridContainer>
    );
};

export default Filters;
