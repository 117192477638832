import { normalizeIso8601DateOnly } from "@libs/dateTime";
import { getCurrentMonthDate } from "@libs/dateTime";

const DOMAIN_NAME = 'dashboard';
const DASHBOARD_ROUTE = 1;
const ROWS_PER_PAGE = 10;

const FORM_NAME = "dashboard"

const DASHBOARD_ATTENDANCE_INITIAL_API_PARAMS = {
    date: normalizeIso8601DateOnly(),
    pageSize: 5,
    pageNumber: 1,
    filters: {
        branch: null,
        status: null
    }
};

const DASHBOARD_OVERVIEW_ATTENDANCE_INITIAL_API_PARAMS = {
    date: normalizeIso8601DateOnly(),
    pageSize: -1,
    pageNumber: 1,
    filters: {
        branch: null,
        status: null
    }
};

const DASHBOARD_OVERVIEW_LEAVE_INITIAL_API_PARAMS = {
    date: normalizeIso8601DateOnly(),
    pageSize: -1,
    pageNumber: 1,
    'is-sick-leave': false,
    filters: {
        branch: null
    }
};

const DASHBOARD_OVERVIEW_INITIAL_API_PARAMS = {
    date: normalizeIso8601DateOnly(),
    branch: null
};

const DASHBOARD_APPROVED_LEAVE_INITIAL_API_PARAMS = {
    limit: 4,
    branch: null
};

const DASHBOARD_OVERVIEW_EVENTS_CALENDER_INITIAL_API_PARAMS = {
    start: getCurrentMonthDate().startOfMonth,
    end: getCurrentMonthDate().endOfMonth
};

export { DOMAIN_NAME, DASHBOARD_ROUTE, FORM_NAME, ROWS_PER_PAGE, DASHBOARD_ATTENDANCE_INITIAL_API_PARAMS, DASHBOARD_OVERVIEW_INITIAL_API_PARAMS, DASHBOARD_APPROVED_LEAVE_INITIAL_API_PARAMS, DASHBOARD_OVERVIEW_ATTENDANCE_INITIAL_API_PARAMS, DASHBOARD_OVERVIEW_LEAVE_INITIAL_API_PARAMS, DASHBOARD_OVERVIEW_EVENTS_CALENDER_INITIAL_API_PARAMS };

