import { normalizeIso8601Date } from '@libs/dateTime';
export { DOMAIN_NAME } from '../common';

const TIMESHEET_ROUTE = 8;
const ROWS_PER_PAGE = 7;
const CREATE_ATTENDANCE_ROUTE = 28;

const ADMIN_TIMESHEET_INTITAL_API_PARAMS = {
    date: normalizeIso8601Date(),
    pageSize: 10,
    filters: {
        branch: null,
        status: null
    }
};

export {
    TIMESHEET_ROUTE,
    ROWS_PER_PAGE,
    CREATE_ATTENDANCE_ROUTE,
    ADMIN_TIMESHEET_INTITAL_API_PARAMS
};
