import { CREATE_HOLIDAY, DELETE_HOLIDAY, GET_HOLIDAY, UPDATE_HOLIDAY } from './redux.actionTypes';

export const createHoliday = ({ handleSubmit, payload }) => ({
    type: CREATE_HOLIDAY,
    meta: { handleSubmit },
    payload,
});

export const deleteHoliday = ({ handleSubmit, payload }) => ({
    type: DELETE_HOLIDAY,
    meta: { handleSubmit },
    payload,
});

export const updateHoliday = ({ handleSubmit, payload }) => ({
    type: UPDATE_HOLIDAY,
    meta: { handleSubmit },
    payload,
});

export const getHoliday = ({ handleSubmit, ...payload }) => ({
    type: GET_HOLIDAY,
    meta: { handleSubmit },
    payload,
});