import PropTypes from 'prop-types';

import { styled, StepLabel as MaterialStepLabel } from '@mui/material';

const propTypes = {
    children: PropTypes.node,
    disabled: PropTypes.bool.isRequired,
    style: PropTypes.objectOf(PropTypes.string),
};

const StyledMaterialStepLabel = styled(MaterialStepLabel)(
    ({ theme: { spacing, palette, typography, transitions } }) => ({
        color: palette.background.dark,
        flexDirection: 'column',
        zIndex: 2,
        '& .MuiStepLabel-label': {
            color: 'var(--color)',
            position: 'absolute',
            whiteSpace: 'nowrap',
            fontSize: typography.subtitle1.fontSize,
            fontWeight: typography.fontWeightSemi,
            paddingTop: spacing(),
            right: '50%',
            transform: 'translateX(50%)',
            transition: transitions.create(['color'], {
                easing: transitions.easing.easeOut,
            }),
            transitionDuration: 'var(--duration)',

            '&.Mui-active': {
                color: 'var(--color)',
                fontWeight: typography.fontWeightHeavy,
            },
        },
        '& .MuiStepLabel-labelContainer': {
            position: 'relative',
        },
        '& .MuiStepLabel-iconContainer': {
            paddingRight: 0,
        },
    })
);

const StepLabel = ({ classes: c, children, disabled, style, ...restProps }) => {
    const styleVariables = {
        ...style,
        ...(disabled ? { '--color': 'unset' } : {}),
    };

    return (
        <StyledMaterialStepLabel
            disabled={disabled}
            style={styleVariables}
            {...restProps}
        >
            {children}
        </StyledMaterialStepLabel>
    );
};

StepLabel.propTypes = propTypes;

export { StepLabel as default };
