import { takeLatest } from 'redux-saga/effects';

// Workers
import { CREATE_CALENDER, DELETE_CALENDER, UPDATE_CALENDER } from '../redux.actionTypes';
import createCalenderWorker from './createCalenderWorker';
import updateCalenderWorker from './updateCalenderWorker';
import deleteCalenderWorker from './deleteCalenderWorker';


// Watchers
export default function* () {
    yield takeLatest(CREATE_CALENDER, createCalenderWorker);
    yield takeLatest(UPDATE_CALENDER, updateCalenderWorker);
    yield takeLatest(DELETE_CALENDER, deleteCalenderWorker);
}