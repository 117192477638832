import { takeLatest } from 'redux-saga/effects';
import { CREATE_USER, DELETE_USER, GET_USER, UPDATE_USER } from 'user/redux.actionTypes';
import createUserWorker from './userSagas/createUserWorker';
import deleteUserWorker from './userSagas/deleteUserWorker';
import getUserWorker from './userSagas/getUserWorker';
import updateUserWorker from './userSagas/updateUserWorker';


export default function* () {
    yield takeLatest(CREATE_USER, createUserWorker);
    yield takeLatest(DELETE_USER, deleteUserWorker);
    yield takeLatest(GET_USER, getUserWorker);
    yield takeLatest(UPDATE_USER, updateUserWorker);
}