import { useMemo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    fetchDatasets,
    gridDatasetSelector,
    createFetchGridDataset
} from '@libs/datasets';
import { makeSideEffectCallback } from '@libs/makeContext';

import { MonthlyTimesheetsProvider } from './useMonthlyTimesheets';
import { MY_MONTHLY_ATTENDANCE } from 'timeAndAttendance/redux.datasets';
import { DOMAIN_NAME } from 'timeAndAttendance/common';
import Filters from './Filters';
import Content from './Content';

const TimesheetsMonthlyView = () => {
    const data = useSelector((state) =>
        gridDatasetSelector(state, DOMAIN_NAME, MY_MONTHLY_ATTENDANCE)
    );
    const dispatch = useDispatch();
    const fetchMonthlyAttendance = useCallback(
        () => dispatch(fetchDatasets(MY_MONTHLY_ATTENDANCE)),
        [dispatch]
    );
    const fetchGridMonthlyDataset = useCallback(
        (payload) =>
            dispatch(
                createFetchGridDataset(
                    DOMAIN_NAME,
                    MY_MONTHLY_ATTENDANCE
                )(payload)
            ),
        []
    );

    const initialize = useCallback(
        (state) => ({
            ...state,
            data
        }),
        [data]
    );

    const middlewareProps = useMemo(
        () => ({
            fetchData: makeSideEffectCallback(fetchGridMonthlyDataset)
        }),
        []
    );

    useEffect(() => {
        fetchMonthlyAttendance();
    }, []);

    return (
        <MonthlyTimesheetsProvider
            initialize={initialize}
            middlewareProps={middlewareProps}
        >
            <Filters />
            <Content />
        </MonthlyTimesheetsProvider>
    );
};

export default TimesheetsMonthlyView;
