import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { styled } from '@mui/system';
import { Box } from '@mui/material';

import { datasetLoadingSelector } from '@libs/datasets';

import { TimesheetsContainer } from '../components';
import { DOMAIN_NAME } from './common';
import {
    DAILY_ALL_ATTENDANCE,
    WEEKLY_ALL_ATTENDANCE,
    MONTHLY_ALL_ATTENDANCE
} from '../redux.datasets';
import TimesheetsDailyView from './TimesheetsDailyView';
import TimesheetsWeeklyView from './TimesheetsWeeklyView';
import TimesheetsMonthlyView from './TimesheetsMonthlyView';

const StyledTimesheetContainer = styled(Box)(({ theme: { spacing } }) => ({
    width: `100%`,
    position: 'relative',
    minHeight: spacing(20)
}));

const TimesheetContent = ({ currentView }) => {
    return (
        <StyledTimesheetContainer>
            {currentView === 'daily' && <TimesheetsDailyView />}
            {currentView === 'weekly' && <TimesheetsWeeklyView />}
            {currentView === 'monthly' && <TimesheetsMonthlyView />}
        </StyledTimesheetContainer>
    );
};

TimesheetContent.propTypes = {
    currentView: PropTypes.oneOf(['daily', 'weekly', 'monthly']).isRequired
};

const useAttendanceLoading = (type) => {
    return useSelector((state) =>
        datasetLoadingSelector(state, DOMAIN_NAME, type)
    );
};

const _Timesheet = () => {
    const isDailyAttendanceLoading = useAttendanceLoading(DAILY_ALL_ATTENDANCE);
    const isWeeklyAttendanceLoading = useAttendanceLoading(
        WEEKLY_ALL_ATTENDANCE
    );
    const isMonthlyAttendanceLoading = useAttendanceLoading(
        MONTHLY_ALL_ATTENDANCE
    );

    const isAnyAttendanceLoading =
        isDailyAttendanceLoading ||
        isWeeklyAttendanceLoading ||
        isMonthlyAttendanceLoading;

    return (
        <TimesheetsContainer
            ContentComponent={TimesheetContent}
            title='Timesheet'
            loading={isAnyAttendanceLoading}
        />
    );
};

export default _Timesheet;
