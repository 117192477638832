import { EMPLOYEE_CREATE_LEAVE, EMPLOYEE_ALL_LEAVE, GET_EMPLOYEE_REMAINING_LEAVES, EMPLOYEE_CANCEL_LEAVE, EMPLOYEE_UPDATE_DOCUMENT } from 'leaveAndHolidays/redux.actionTypes';
import { takeLatest } from 'redux-saga/effects';
import allLeaveWorker from './LeaveManagementSagas/allLeaveWorker';
import createLeaveWorker from './LeaveManagementSagas/createLeaveWorker';
import getRemainingLeavesWorker from './LeaveManagementSagas/getRemainingLeavesWorker';
import employeeCancelLeaveWorker from './LeaveManagementSagas/employeeCancelLeaveWorker';
import updateDocumentWorker from './LeaveManagementSagas/updateDocumentWorker';

export default function* () {
    yield takeLatest(EMPLOYEE_ALL_LEAVE, allLeaveWorker);
    yield takeLatest(EMPLOYEE_CREATE_LEAVE, createLeaveWorker);
    yield takeLatest(GET_EMPLOYEE_REMAINING_LEAVES, getRemainingLeavesWorker);
    yield takeLatest(EMPLOYEE_CANCEL_LEAVE, employeeCancelLeaveWorker);
    yield takeLatest(EMPLOYEE_UPDATE_DOCUMENT, updateDocumentWorker);
}