import { CREATE_ROLE, DELETE_ROLE, GET_PERMISSION, UPDATE_ROLE } from './redux.actionTypes';

export const createRole = ({ handleComplete, ...payload }) => ({
    type: CREATE_ROLE,
    meta: { handleComplete },
    payload,
});

export const updateRole = ({ handleComplete, ...payload }) => ({
    type: UPDATE_ROLE,
    meta: { handleComplete },
    payload,
});

export const getPermission = ({ handleSubmit, ...payload }) => ({
    type: GET_PERMISSION,
    meta: { handleSubmit },
    payload,
});

export const deleteRole = ({ handleSubmit, payload }) => ({
    type: DELETE_ROLE,
    meta: { handleSubmit },
    payload,
});
