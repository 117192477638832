import React, { useEffect } from 'react';

import ContentContainer from '@libComponents/ContentContainer';

import TableCellContainer from '@libComponents/table/TableCellContainer';
import TableContainer from '@libComponents/table/TableContainer';
import TableHeaderContainer from '@libComponents/table/TableHeaderContainer';
import { datasetLoadingSelector, entitySelector, createFetchPaginatedDataset } from '@libs/datasets';
import { Box, List, ListItem, TableBody, TableRow, Typography, styled } from '@mui/material';

import { Button, Pagination } from '@libComponents';
import DialogWrapper from '@libComponents/Dialog';

import { connect, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { DOMAIN_NAME, ROWS_PER_PAGE } from "../common";
import { APPLIED_LEAVES } from '../redux.datasets';
import { theme } from 'app/Theme';
import dayjs from 'dayjs';
import { handleLeave } from 'leaveManagement/redux.actions';

import createCachedSelector from 're-reselect';

import RejectReasonForm from './RejectReasonForm';
import SelectLeaveStatus from './LeaveStatus';
import { useSearchParams } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import BasicMenu from 'common/components/tableHandlar/rowhandlar';
import FilterInput from '@libComponents/FilterInput';

const propTypes = {
};

const defaultProps = {
    entity: APPLIED_LEAVES,
    domain: DOMAIN_NAME,
    loading: true,
};

const StyledBox = styled(Box)(
    () => ({
        width: '100%',
        overflow: 'auto',
        '& table': {
            tableLayout: 'fixed'
        }
    })
);

const StyledListItem = styled(ListItem)(
    ({ theme: { palette } }) => ({
        borderBottom: "1px solid",
        borderColor: palette.divider
    })
);

const DEFAULT_TABLE_DATA = {
    'employee': {
        label: 'Employee Name',
        isVisible: true,
        disabledChange: true,
        type: "nameObject"
    },
    'employeeEmail': {
        label: 'Employee Email',
        isVisible: false,
        disabledChange: false,
        type: "string"
    },
    'employeeId': {
        label: 'Employee ID',
        isVisible: false,
        disabledChange: false,
        type: "string"
    },
    'categoryName': {
        label: 'Leave Type',
        isVisible: true,
        disabledChange: true,
        type: "string",
    },
    'startDate': {
        label: "Start Date",
        isVisible: true,
        disabledChange: false,
        type: "date"
    },
    'endDate': {
        label: 'End Date',
        isVisible: false,
        disabledChange: false,
        type: "date"
    },
    'totalNumberOfDays': {
        label: 'Number Of Days',
        isVisible: true,
        disabledChange: false,
        type: "string"
    },
    'status': {
        label: 'Status',
        isVisible: true,
        disabledChange: false,
        type: "badge"
    },
    isEmergency: {
        label: 'Is Emergency',
        isVisible: false,
        disabledChange: false,
        type: "boolean"
    },
    updatedAt: {
        label: 'Updated Date',
        isVisible: false,
        disabledChange: false,
        type: "date"
    },
    updatedBy: {
        label: 'Updated By',
        isVisible: false,
        disabledChange: false,
        type: "string"
    },
    createdAt: {
        label: 'Created Date',
        isVisible: false,
        disabledChange: false,
        type: "date"
    },
    "reason": {
        label: 'Reason',
        isVisible: false,
        disabledChange: false,
        type: "string"
    },
};

const totalRowsSelector = createCachedSelector(entitySelector,
    (resp) => resp.total ? parseInt(resp.total) : 0
)((state, domain, entity) => `@data-${domain}-${entity}`);

const dataSelector = createSelector(
    (state) => entitySelector(state, DOMAIN_NAME, APPLIED_LEAVES),
    (resp) => ((resp && resp.data) ? resp.data : [])
);

const mapState = (state, { domain, entity }) => ({
    data: dataSelector(state, domain, entity),
    loading: datasetLoadingSelector(state, domain, entity),
    totalRows: totalRowsSelector(state, domain, entity)
});

const mapDispatch = (dispatch, { entity }) => ({
    fetchDataset: ({ offset = "", limit = ROWS_PER_PAGE, shouldAppend = false, filter }) => dispatch(
        createFetchPaginatedDataset({ entity: entity, domain: DOMAIN_NAME })({
            limit,
            offset,
            shouldAppend,
            "sort-descending": true,
            filterKey: filter
        })
    ),
});

const ALL_STATUS = ['approved', 'rejected', 'pending'];

const AppliedLeaves = connect(
    mapState,
    mapDispatch
)(({ fetchDataset, data, totalRows = 0 }) => {
    const [searchParams] = useSearchParams();
    useEffect(() => {
        fetchDataset({
            offset: "",
            filter: { status: searchParams.get("status") && ALL_STATUS.includes(searchParams.get("status")) ? searchParams.get("status") : "" }
        });
    }, []);

    const RXDispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [openReject, setOpenReject] = React.useState(false);
    const [rejectPayload, setRejectPayload] = React.useState(null);
    const [payload, setSelectedPayload] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [filterValue, setFilterValue] = React.useState("");
    const [tableData, setTableData] = React.useState(DEFAULT_TABLE_DATA);
    const [pageSize, setPageSize] = React.useState(ROWS_PER_PAGE);

    // is visible then only take that object 
    const TABLE_DATA = Object.entries(tableData).reduce((acc, [key, value]) => {
        if (value.isVisible) {
            acc[key] = value;
        }
        return acc;
    }, {});

    const handleClick = (row, action) => {
        if (action === "reject") {
            setOpenReject(true);
            setRejectPayload(row);
        } else {
            RXDispatch(handleLeave({ handleSubmit: handleLeaveRequest, payload: { row, action } }));
        }
    }
    const handleLeaveRequest = (payload) => {

    }

    const handleChangePage = (event, newPage) => {
        fetchDataset({
            offset: (newPage - 1) * pageSize,
            limit: pageSize,
            filter: { status: filterValue }
        })
        setCurrentPage(newPage);
    };

    const handleRowsPerPageChange = (pageSize) => {
        setPageSize(pageSize);
        setCurrentPage(1);
        fetchDataset({
            offset: 0 * pageSize,
            limit: pageSize,
            filter: { status: filterValue }
        });
    }

    const viewLeaveDetails = (row) => {
        setOpen(true);
        setSelectedPayload(row);
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleRejectClose = () => {
        setOpenReject(false);
    }

    const handleReject = () => {
        handleClick(payload, "reject");
        handleClose();
    }

    const handleApprove = () => {
        handleClick(payload, "approve");
        handleClose();
    }

    const handleFilterChange = (value) => {
        fetchDataset({
            offset: "",
            filter: { status: value }
        });
        setFilterValue(value);
        setCurrentPage(1);
    }

    const handleSearch = (value) => {
        fetchDataset({
            offset: "",
            limit: pageSize,
            filter: { searchTerm: value }
        });
        setFilterValue(value);
        setCurrentPage(1);
    }

    if (!data) return null;
    return (
        <ContentContainer title="All Leaves" headerBg={theme.palette.background.mainHeader}
            AdornmentComponent={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <FilterInput
                        name='employee-email'
                        handleSearch={handleSearch}
                    />
                    <SelectLeaveStatus
                        sx={{ backgroundColor: "#ffffff", maxWidth: 300, mt: 1 }}
                        input={{
                            name: 'status',
                            onChange: handleFilterChange,

                        }}
                    />
                    <BasicMenu tableData={tableData} setTableData={setTableData} />
                </Box>
            }
        >
            <StyledBox>
                <TableContainer >
                    <TableHeaderContainer>
                        {Object.entries(TABLE_DATA).map(([key, value], i) => (
                            <TableCellContainer
                                sx={{ pl: i === 0 ? 4 : 2, fontWeight: 'bold' }}
                                typography="caption" key={key}
                            >
                                {value.label}
                            </TableCellContainer>
                        ))}
                        <TableCellContainer
                            align='right'
                            sx={{ pr: 16.5, fontWeight: 'bold' }}
                        >
                            Action
                        </TableCellContainer>
                    </TableHeaderContainer>
                    <TableBody>
                        {data.map((row, i) => (
                            <TableRow key={i}>
                                {Object.entries(TABLE_DATA).map(([key, value], index) => {
                                    switch (value.type) {
                                        case 'file':
                                            return null;
                                        case 'date':
                                            return (
                                                <TableCellContainer key={key}
                                                    sx={{ pl: index === 0 ? 4 : 2 }}
                                                >
                                                    {dayjs(row[key]).format("YYYY-MM-DD")}
                                                </TableCellContainer>
                                            )
                                        case 'boolean':
                                            return (
                                                <TableCellContainer key={key}
                                                    sx={{ pl: index === 0 ? 4 : 2 }}
                                                >
                                                    {row[key] ? <CheckIcon sx={{ color: theme.palette.success.main, fontWeight: "bold" }} /> : ""}
                                                </TableCellContainer>
                                            )
                                        case 'nameObject':
                                            return (
                                                <TableCellContainer key={key}
                                                    sx={{ pl: index === 0 ? 4 : 2 }}
                                                >
                                                    {row[key].firstname ? row[key].firstname + " " : ""}{row[key].middlename ? row[key].middlename + " " : ""}{row[key].lastname}
                                                </TableCellContainer>
                                            )
                                        case "badge":
                                            return (
                                                <TableCellContainer key={key} sx={{ color: row[key] === "pending" ? theme.palette.quaternary.main : row[key] === "approved" ? theme.palette.success.main : theme.palette.fail.main }}>
                                                    {row[key]}
                                                </TableCellContainer>
                                            )
                                        default:
                                            return (
                                                <TableCellContainer
                                                    key={index}
                                                    sx={{ pl: index === 0 ? 4 : 2 }}
                                                >
                                                    {row[key]}
                                                </TableCellContainer>
                                            );

                                    }
                                })}
                                <TableCellContainer
                                    align='right'
                                    sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}
                                >
                                    <Button
                                        variant='outlined'
                                        sx={{ mr: 2, cursor: "pointer" }}
                                        type='button'
                                        onClick={() => { viewLeaveDetails(row) }}
                                        color='secondary'
                                    >
                                        Details
                                    </Button>
                                    <Button
                                        variant='outlined'
                                        sx={{ mr: 2, cursor: "pointer" }}
                                        type='button'
                                        onClick={() => { handleClick(row, "approve") }}
                                        color='success'
                                        disabled={row.status === "approved"}
                                    >
                                        Approve
                                    </Button>
                                    <Button
                                        variant='outlined'
                                        sx={{ mr: 2, cursor: "pointer" }}
                                        type='button'
                                        onClick={() => { handleClick(row, "reject") }}
                                        color='primary'
                                        disabled={row.status === "rejected"}
                                    >
                                        Reject
                                    </Button>
                                </TableCellContainer>
                            </TableRow>
                        ))}
                    </TableBody>
                </TableContainer>
                <Box sx={{ display: "flex", justifyContent: "center", ml: 2 }}>
                    <Pagination
                        count={Math.ceil(totalRows / pageSize)}
                        onChange={handleChangePage}
                        page={currentPage}
                        sx={{ display: 'flex', justifyContent: 'center' }}
                        isRowsPerPageVarient={true}
                        rowsPerPageProps={{
                            name: 'appliedLeaves',
                            onChange: handleRowsPerPageChange,
                            value: pageSize
                        }}
                    />
                </Box>
            </StyledBox>

            <DialogWrapper open={open} onClose={handleClose} sx={{ textAlign: "center" }}>
                <Typography variant='h6' sx={{ mb: 2 }}>
                    Leave Details
                </Typography>
                <Box>
                    <List dense={false} sx={{ mb: 3 }}>
                        {
                            Object.entries(DEFAULT_TABLE_DATA).map(([key, value], index) => {
                                let type = value.type;
                                switch (type) {
                                    case 'object':
                                        return (
                                            <StyledListItem key={index}>
                                                <Box sx={{ flex: 1 }}>{value.label} </Box>
                                                <Box sx={{ flex: 2 }}>{payload[key] && payload[key][value.key]}</Box>
                                            </StyledListItem>
                                        )
                                    case 'array':
                                        return (
                                            <StyledListItem key={index}>
                                                <Box sx={{ flex: 1 }}>{value.label} </Box>
                                                <Box sx={{ flex: 2 }}>{payload[key] && payload[key].map(item => item?.firstname + " " + item?.middlename + " " + item?.lastname).join(", ")}</Box>
                                            </StyledListItem>
                                        );
                                    case 'file':
                                        return null;
                                    case 'date':
                                        return (
                                            <StyledListItem key={index} >
                                                <Box sx={{ flex: 1 }}>{value.label} </Box>
                                                <Box sx={{ flex: 2 }}>{payload[key] ? dayjs(payload[key]).format("YYYY-MM-DD") : ""}</Box>
                                            </StyledListItem>
                                        )
                                    case 'boolean':
                                        return (
                                            <StyledListItem key={index}>
                                                <Box sx={{ flex: 1 }}>{value.label} </Box>
                                                <Box sx={{ flex: 2 }}>{payload[key] && payload[key] ? "Yes" : "No"}</Box>
                                            </StyledListItem>
                                        )
                                    case 'nameObject':
                                        return (
                                            <StyledListItem key={index}>
                                                <Box sx={{ flex: 1 }}>Name</Box>
                                                <Box sx={{ flex: 2 }}>{payload?.employee?.firstname ? payload?.employee?.firstname + " " : ""}{payload?.employee?.middlename ? payload?.employee?.middlename + " " : ""}{payload?.employee?.lastname}</Box>
                                            </StyledListItem>
                                        )
                                    default:
                                        return (
                                            <StyledListItem key={index}>
                                                <Box sx={{ flex: 1 }}>{value.label} </Box>
                                                <Box sx={{ flex: 2 }}>{payload[key]}</Box>
                                            </StyledListItem>
                                        );
                                }
                            })
                        }
                    </List>
                    {<Button sx={{ mr: 2 }} onClick={handleReject} color="primary">Reject</Button>}
                    {<Button onClick={handleApprove} color="success">Approve</Button>}
                </Box>
            </DialogWrapper>
            <DialogWrapper open={openReject} onClose={handleRejectClose} sx={{ textAlign: "center" }}>
                <Box sx={{ "minWidth": "600px", minHeight: "200px" }}>
                    <RejectReasonForm rejectPayload={rejectPayload} onReject={handleRejectClose} />
                </Box>
            </DialogWrapper>
        </ContentContainer>
    );
});

AppliedLeaves.propTypes = propTypes;
AppliedLeaves.defaultProps = defaultProps;

export default AppliedLeaves;
