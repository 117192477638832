import AutocompleteBase from '@formComponents/Autocomplete';
import React from 'react';

export const renderAutoComplete = ({
    input,
    label,
    type,
    options,
    placeholder,
    meta: { touched, error },
}) => {
    input.onBlur = function (event) {
        //  do nothing
    };
    return (
        <React.Fragment>
            <AutocompleteBase
                {...input}
                options={options}
                label={label}
                placeholder={placeholder}
            />

            {touched && error && <span>{error}</span>}
        </React.Fragment>
    );
};
