import { GridContainer, GridItem } from '@formComponents';
import { styled } from '@mui/material';
import useApprovedLeave, { SET_BRANCH } from '../useApprovedLeaveProvider';
import { useCallback } from 'react';
import { SelectBranch } from 'timeAndAttendance/components';

const StyledGridItem = styled(GridItem)(
    ({ theme: { spacing, palette, typography, shape } }) => ({
        paddingLeft: spacing(1),
        paddingRight: spacing(1)
    })
);

function FilterBranch() {

    const [{ branch }, dispatch] = useApprovedLeave();


    const handleFilterChange = useCallback((value) => {
        dispatch({
            type: SET_BRANCH,
            payload: value
        });
    }, []);


    return (
        <GridContainer sx={{ padding: 0, pt: 1, flex: 1, display: "flex", justifyContent: "flex-end" }}>
            <StyledGridItem md={4} lg={3}>
                <SelectBranch
                    input={{
                        name: 'branch',
                        onChange: handleFilterChange,
                        value: branch
                    }}
                    sx={{ padding: 0 }}
                />
            </StyledGridItem>
        </GridContainer>
    )
}

export default FilterBranch
