import AutocompleteBase from '@formComponents/Autocomplete';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { DOMAIN_NAME } from '../common';
import { DISTRICTS } from '../redux.datasets';
import { districtsSelector } from '../redux.selectors';

// Datasets
import { datasetLoadingSelector, fetchDatasets } from '@libs/datasets';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
};

const defaultProps = {
    name: 'district',
    label: 'District',
    placeholder: 'Select district',
    domain: DOMAIN_NAME,
    entity: DISTRICTS,
    disabled: false,
    required: false,
    loading: false,
};

const mapState = (state, { domain, entity }) => ({
    data: districtsSelector(state),
    loading: datasetLoadingSelector(state, domain, entity),
});

const mapDispatch = (dispatch, { entity }) => ({
    fetchDataset: () => dispatch(fetchDatasets(entity)),
});

const SelectDistrict = connect(
    mapState,
    mapDispatch
)(({ fetchDataset, data, ...props }) => {
    useEffect(() => {
        fetchDataset();
    }, []);

    return (
        <AutocompleteBase
            {...props}
            options={data ? data : []}
        />
    );
});

SelectDistrict.propTypes = propTypes;
SelectDistrict.defaultProps = defaultProps;

export default SelectDistrict;