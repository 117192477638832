import FormArray from '@formComponents/FormArray';
import React from 'react';
import renderChildrenOptions from './ChildrenFields';

const ChildInformation = ({ ...restProps }) => {
    return (
        <React.Fragment>
            <FormArray name="children" label="Add Children" renderSubFields={renderChildrenOptions}/>
        </React.Fragment>
    );
};

export default ChildInformation;
