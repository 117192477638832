import { useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { useTheme, InputAdornment } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { styled } from '@mui/system';

import { useDebounce } from '@libHooks';
import InputBase from '@baseComponents/InputBase';

const propTypes = {
    name: PropTypes.string.isRequired,
    handleSearch: PropTypes.func.isRequired,
    placeholder: PropTypes.string
};

const defaultProps = {
    placeholder: 'Search'
};

const StyledSearchIcon = styled(SearchIcon)(({ theme }) => ({
    color: theme.palette.icon.main,
    fontSize: theme.spacing(3),
    transition: 'color 0.3s ease',

    '&:hover': {
        color: theme.palette.icon.dark,
        cursor: 'pointer'
    }
}));

const TimesheetsInputSearch = ({ name, handleSearch, placeholder }) => {
    const [value, setValue] = useState('');
    const { spacing } = useTheme();
    const debounceValue = useDebounce(value, 1000);
    const isMounted = useRef(false);
    const prevValue = useRef('');

    const handleChange = useCallback((e) => {
        setValue(e.target.value);
    }, []);

    const handleKeyPress = useCallback(
        ({ key }) => {
            if (key === 'Enter' && value !== prevValue.current) {
                prevValue.current = value;
                handleSearch(value);
            }
        },
        [value, handleSearch]
    );

    const handleIconClick = useCallback(
        (e) => {
            if (value !== prevValue.current) {
                prevValue.current = value;
                handleSearch(value);
            }
        },
        [value, handleSearch]
    );

    useEffect(() => {
        if (isMounted.current && debounceValue !== prevValue.current) {
            prevValue.current = debounceValue;
            handleSearch(debounceValue);
        } else {
            isMounted.current = true;
        }
    }, [debounceValue, handleSearch]);

    return (
        <InputBase
            name={name}
            input={{ value, onChange: handleChange }}
            placeholder={placeholder}
            styles={{ control: { height: spacing(4.5) } }}
            onKeyPress={handleKeyPress}
            endAdornment={
                <InputAdornment position='end'>
                    <StyledSearchIcon onClick={handleIconClick} />
                </InputAdornment>
            }
        />
    );
};

TimesheetsInputSearch.prototypes = propTypes;
TimesheetsInputSearch.defaultProps = defaultProps;

export { TimesheetsInputSearch as default, TimesheetsInputSearch };
