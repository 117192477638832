import {
    GET_ATTENDANCE,
    GET_STATUS,
    GET_WEEKLY_STATUS,
    SUBMIT_STATUS,
    CREATE_ATTENDANCE
} from './redux.actionTypes';

export const submitStatus = ({ handleSubmit, payload }) => ({
    type: SUBMIT_STATUS,
    meta: { handleSubmit },
    payload
});

export const getInitialStatus = ({ handleSubmit, payload }) => ({
    type: GET_STATUS,
    meta: { handleSubmit },
    payload
});

export const getUserAttendanceData = ({ handleSubmit, payload }) => ({
    type: GET_ATTENDANCE,
    meta: { handleSubmit },
    payload
});

export const getWeeklyStatus = ({ handleSubmit, payload }) => ({
    type: GET_WEEKLY_STATUS,
    meta: { handleSubmit },
    payload
});

export const createAttendance = ({ handleSubmit, payload }) => ({
    type: CREATE_ATTENDANCE,
    meta: { handleSubmit },
    payload
});
