import { takeLatest } from 'redux-saga/effects';

// Workers
import { CREATE_BRANCH, UPDATE_BRANCH, DELETE_BRANCH } from '../redux.actionTypes';
import createBranchWorker from './createBranchWorker';
import updateBranchWorker from './updateBranchWorker';
import deleteBranchWorker from './deleteBranchWorker';

// Watchers
export default function* () {
    yield takeLatest(CREATE_BRANCH, createBranchWorker);
    yield takeLatest(UPDATE_BRANCH, updateBranchWorker);
    yield takeLatest(DELETE_BRANCH, deleteBranchWorker);
}