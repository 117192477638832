// import { getFormValues } from 'redux-form';

import { apiRequest } from "@libs/apiRequest";
import { call, put } from "redux-saga/effects";
import { LOADING_ATTENDANCE } from "timeAndAttendance/common";
import { setComponentLoading, setSnack } from "utilities/redux.actions";

export default function* (action) {
    const {
        meta: { handleSubmit = () => { } },
        payload: { latitude, longitude }
    } = action;
    try {
        yield put(setComponentLoading({
            entity: LOADING_ATTENDANCE,
            loading: true
        }));

        const { data } = yield call(
            apiRequest,
            {
                url: `attendance/submit?geoLocation=${latitude}, ${longitude}`,
                method: 'GET'
            }
        );

        const currentStatus = yield call(
            apiRequest,
            {
                url: `attendance/status`,
                method: 'GET'
            }
        );


        yield put(setSnack({
            message: `Employee ${data.status} successfully`,
            type: 'success',
        }));

        yield put(setComponentLoading({
            entity: LOADING_ATTENDANCE,
            loading: false
        }));

        return handleSubmit(currentStatus.data);

    } catch (error) {
        yield put(setComponentLoading({
            entity: LOADING_ATTENDANCE,
            loading: false
        }));
        yield put(setSnack({
            message: error?.response?.data?.message || error?.message,
            type: 'error',
        }));
        return handleSubmit(error);
    }
}

