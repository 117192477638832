import { useCallback, useEffect, useMemo } from "react";
import { DOMAIN_NAME } from "dashboard/common";
import { Box, Button, styled } from '@mui/material';
import { DASHBOARD_APPROVED_LEAVE } from "dashboard/redux.datasets";
import { useDispatch, useSelector } from "react-redux";
import { createFetchGridDataset, datasetLoadingSelector, fetchDatasets, gridDatasetSelector } from "@libs/datasets";
import { makeSideEffectCallback } from "@libs/makeContext";
import { ContentCircularLoader, ContentContainer } from "@libComponents";
import { theme } from "app/Theme";
import Filter from "./Filter";
import { ApprovedLeaveProvider } from "./useApprovedLeaveProvider";
import DataTable from "./DataTable";
import { useNavigate } from "react-router";
import { useNavigationState } from "@libComponents/NavigationContainer/useNavigation";

const StyledBox = styled(Box)(({ theme: { palette } }) => ({
    width: '100%',
    height: 'auto',
    padding: '5px',
    display: 'flex',
    justifyContent: 'center',
    borderTop: `1px solid ${palette.border.light}`
}));

const ApprovedLeave = (({ ...props }) => {

    let { flatHashes } = useNavigationState();
    const isViewAllWillShow = flatHashes["/leave-management/applied-leaves"] ? true : false;

    const leaveData = useSelector((state) =>
        gridDatasetSelector(state, DOMAIN_NAME, DASHBOARD_APPROVED_LEAVE)
    );

    let isLoading = useSelector((state) =>
        datasetLoadingSelector(state, DOMAIN_NAME, DASHBOARD_APPROVED_LEAVE)
    );

    const dispatch = useDispatch();
    const fetchEmployeeLeaveSummary = useCallback(
        () => dispatch(fetchDatasets(DASHBOARD_APPROVED_LEAVE)),
        [dispatch]
    );
    const fetchGridFilteredData = useCallback(
        (payload) =>
            dispatch(
                createFetchGridDataset(
                    DOMAIN_NAME,
                    DASHBOARD_APPROVED_LEAVE
                )(payload)
            ),
        [dispatch]
    );

    const initialize = useCallback(
        (state = {}) => ({
            ...state,
            leaveData
        }),
        [leaveData]
    );

    const middlewareProps = useMemo(
        () => ({
            fetchData: makeSideEffectCallback(fetchGridFilteredData)
        }),
        [fetchGridFilteredData]
    );

    useEffect(() => {
        fetchEmployeeLeaveSummary();
    }, []);

    const navigate = useNavigate();

    const navigateToAppliedLeave = ()=>{
        navigate('/leave-management/applied-leaves?status=approved');
    }

    return (
        <Box sx={{ position: "relative", "width": "-webkit-fill-available" }}>
            {isLoading && <ContentCircularLoader />}
            <ApprovedLeaveProvider
                initialize={initialize}
                middlewareProps={middlewareProps}>
                <ContentContainer
                    title="Approved Leave"
                    sx={{ "width": "100%" }}
                    headerBg={theme.palette.background.white}
                    AdornmentComponent={<Filter />}>
                    <DataTable />
                    {isViewAllWillShow &&
                        <StyledBox >
                            <Button onClick={navigateToAppliedLeave}> View all </Button>
                        </StyledBox>
                    }
                </ContentContainer>
            </ApprovedLeaveProvider>
        </Box>

    )
});

export default ApprovedLeave
