
import Label from '@baseComponents/Label';
import { withField } from '@libs/reduxForm';
import { FormControl, styled, TextField } from '@mui/material';

import FieldProgressBar from '@baseComponents/FieldProgressBar';
import HelperText from '@baseComponents/HelperText';
import { DatePicker, TimePicker, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import React, { useState } from 'react';
// import { districts } from './allDistrict';

const StyledInput = styled(TextField)(
    ({ theme: { spacing, palette, typography, shape } }) => ({

        width: '100%',
        height: '35px',
        border: 0,
        "& .MuiInputBase-root": {
            height: "100%",
            borderColor: palette.border.main,
            borderRadius: shape.borderRadius,
            fontSize: typography.subtitle2.fontSize,
            border: 0,
            '& .MuiOutlinedInput-notchedOutline': {
                border: '1px solid',
                borderColor: palette.border.main,
                borderRadius: shape.borderRadius,
            },
            '&:hover': {
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: palette.border.dark,
                }
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: palette.grey[500],
                borderWidth: "1px",
                '&:hover': {
                    borderColor: palette.grey[700],
                },
            },
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                borderColor: `${palette.error.light}`,
                '&:hover': {
                    borderColor: `${palette.error.dark}`,
                },
            },
            '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                display: 'flex',
                alignItems: 'center',
                backgroundColor: palette.background.disabled,
                color: palette.secondary.main,

                '&:hover': {
                    borderColor: palette[300],
                },
            }
        }
    })
);

const StyledBox = styled('div')(
    ({ theme: { spacing, palette, typography } }) => ({
        '& .MuiAutocomplete-option': {
            color: palette.text.primary,
            fontSize: '12px',
        },
    })
);
const StyledFormControl = styled(FormControl)({
    width: '100%',
    ' label': {
        paddingTop: '10px',
    },
});

const CustomInput = React.forwardRef((props, ref) => {
    return (
        <StyledInput
            {...props}
            ref={ref}
            inputProps={{
                ...props.inputProps,
                placeholder: props.placeholder
            }}
        />
    );
});

const DatePickerBase = withField()(
    ({
        fieldName,
        input,
        disabled,
        required,
        label,
        loading,
        staticValue,
        meta: { form, touched, error = null, asyncValidating },
        placeholder,
        options,
        minDate,
        maxDate,
        views,
        mode = "datepicker",
        ...restProps
    }) => {
        const id = `${form}-${fieldName}`;
        const isDisabled = disabled || loading;
        const hasError = !isDisabled && touched && !!error;

        const inputBaseProps = {
            id,
            disabled: isDisabled,
            ...(staticValue ? { value: staticValue } : input),
            ...restProps,
        };

        const { value, onChange } = input;
        const [open, setOpen] = useState(false);
        const [openTimePicker, setTimePickerOpen] = useState(false);
        const [openDateTimePicker, setDateTimePickerOpen] = useState(false);

        return (

            <StyledFormControl>
                <Label
                    label={label}
                    disabled={isDisabled}
                    required={required}
                    error={hasError}
                    htmlFor={id}
                />
                <StyledBox>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        {mode === 'datepicker' && (
                            <DatePicker
                                views={views ? views : ['year', 'month', "day"]}
                                label=""
                                {...inputBaseProps}
                                open={open}
                                onClose={() => setOpen(false)}
                                minDate={minDate ? dayjs(minDate) : dayjs('2022-01-01')}
                                maxDate={maxDate ? dayjs(maxDate) : dayjs()}
                                value={value ? dayjs(value) : null}
                                defaultValue={null}
                                onChange={(newValue) => {
                                    newValue && onChange(newValue.format('YYYY-MM-DDT00:00:00.000[Z]'));
                                }}

                                renderInput={(params) => <StyledInput
                                    label={label}
                                    ref={params.InputProps.ref}
                                    inputProps={params.inputProps}
                                    {...params}
                                    helperText={null}
                                    onClick={() => { setOpen(true) }}
                                    onTouchEnd={() => {!isDisabled && setOpen(true) }}
                                />}
                                inputFormat="YYYY-MM-DD"
                            />
                        )}
                        {mode === 'timepicker' && (
                            <TimePicker
                                label=""
                                {...inputBaseProps}
                                value={value ? dayjs(value) : null}
                                onChange={(newValue) => {
                                    newValue && dayjs(newValue).isValid() && onChange(dayjs(newValue).toISOString());
                                }}
                                views={['hours', 'minutes']}
                                open={openTimePicker}
                                onClose={() => setTimePickerOpen(false)}
                                renderInput={(params) => <CustomInput
                                    label={label}
                                    ref={params.InputProps.ref}
                                    inputProps={params.inputProps}
                                    placeholder={placeholder}
                                    {...params}
                                    helperText={null}
                                    onClick={() => { setTimePickerOpen(true) }}
                                    onTouchEnd={() => {!isDisabled && setTimePickerOpen(true) }}
                                />}
                            />
                        )}
                        {mode === 'datetimepicker' && (
                            <DateTimePicker
                                label=""
                                {...inputBaseProps}

                                minDate={minDate ? dayjs(minDate) : dayjs('2022-01-01')}
                                maxDate={maxDate ? dayjs(maxDate) : dayjs()}
                                value={value ? dayjs(value) : null}
                                defaultValue={dayjs(value)}
                                open={openDateTimePicker}
                                onClose={() => setDateTimePickerOpen(false)}
                                renderInput={(params) => <StyledInput
                                    label={label}
                                    ref={params.InputProps.ref}
                                    inputProps={params.inputProps}
                                    {...params}
                                    helperText={null}
                                    onClick={() => { setDateTimePickerOpen(true) }}
                                    onTouchEnd={() => {!isDisabled && setDateTimePickerOpen(true) }}
                                />}
                                inputFormat="YYYY-MM-DD HH:mm"
                            />
                        )}
                    </LocalizationProvider>
                </StyledBox>
                {(loading || asyncValidating) && (
                    <FieldProgressBar asyncValidating={asyncValidating} />
                )}
                {hasError && <HelperText msg={error} required={required} />}
            </StyledFormControl>
        );
    }
);

export default DatePickerBase;


