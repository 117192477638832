import { GET_FILE_URL, GET_USER_DATA } from "./redux.actionTypes";

export const getFileURL = ({ handleSubmit, payload }) => ({
    type: GET_FILE_URL,
    meta: { handleSubmit },
    payload,
});

export const getUserData = ({ handleSubmit, payload }) => ({
    type: GET_USER_DATA,
    meta: { handleSubmit },
});