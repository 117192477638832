import { useState, useCallback } from 'react';

import { capitalizeFirstLetter } from '@libs/string';

const useTimesheetViewSwitcher = () => {
    const [viewType, setViewType] = useState('daily');
    const selectedTimesheetView = `${capitalizeFirstLetter(
        viewType
    )} Timesheet`;

    const handleTimesheetViewChange = useCallback((value) => {
        setViewType(value);
    }, []);

    return { viewType, handleTimesheetViewChange, selectedTimesheetView };
};

export { useTimesheetViewSwitcher };
