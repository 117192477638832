import { Suspense, lazy, Fragment } from 'react';
import { useSelector } from 'react-redux';

import { FullLoader } from '@libComponents';

import { isAuthenticatedSelector } from './redux.selectors';

const PublicContainer = lazy(() => import('./PublicContainer'));
const PrivateContainer = lazy(() => import('../navigation'));

export default () => {
    const isAuthenticated = useSelector(isAuthenticatedSelector);
    return (
        <Suspense fallback={<FullLoader />}>
            {isAuthenticated ? <PrivateContainer /> : <PublicContainer />}
        </Suspense>
    );
};
