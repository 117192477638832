const matchCookie = (key) =>
    document.cookie.match(new RegExp(`(^| )${key}=([^;]+)`));

const setCookie = (key, value, maxage = 86400 ) => {
    if (key && value) {
        document.cookie = `${key}=${value}; max-age=${maxage}; path=/;`;
    }
};

const getCookie = (key) => {
    const cookieKey = matchCookie(key);

    if (cookieKey) {
        return cookieKey[2].trim();
    }
    return null;
};

const eraseCookieByKey = (key) =>
    matchCookie(key)
        ? (document.cookie = `${key}=;expires=${new Date(0).toUTCString()};path=/`)
        : false;

const eraseAllCookies = () => {
    document.cookie.split(';').forEach((cookie) => {
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + '=;expires=' + new Date(0).toUTCString()+";path=/";
    });
};

export { setCookie, getCookie, eraseCookieByKey, eraseAllCookies };

