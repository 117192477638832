import {
    getFormAsyncErrors,
    getFormSubmitErrors,
    getFormSyncErrors
} from 'redux-form';
import { createSelector } from 'reselect';

import { entitySelector } from '@libs/datasets';

import createCachedSelector from 're-reselect';
import { getFormValues } from 'redux-form';
import { DOMAIN_NAME } from './common';
import { DISTRICTS, UPAZILLAS } from './redux.datasets';
const commonSelector = (state) => state[DOMAIN_NAME];

const formSubmitErrorsSelector = createSelector(
    (state, formName) => getFormSubmitErrors(formName)(state),
    (errors) => errors
);

const formSyncErrorsSelector = createSelector(
    (state, formName) => getFormSyncErrors(formName)(state),
    (errors) => errors
);

const formAsyncErrorsSelector = createSelector(
    (state, formName) => getFormAsyncErrors(formName)(state),
    (errors) => errors
);

const formErrorsSelector = createSelector(
    (state, formName) => formSubmitErrorsSelector(state, formName),
    (state, formName) => formSyncErrorsSelector(state, formName),
    (state, formName) => formAsyncErrorsSelector(state, formName),
    (submitErrors, syncErrors, asyncErrors) => ({
        ...submitErrors,
        ...syncErrors,
        ...asyncErrors,
    })
);

const hasFormErrorSelector = createSelector(
    (state, formName) => formErrorsSelector(state, formName),
    (errors) => Object.values(errors).some((error) => !!error)
);

const divisionsSelector = createSelector(
    (state) => [
        { id: 1, name: 'Dhaka', code: 'DAK' },
        { id: 2, name: 'Chittagong', code: 'CHI' },
        { id: 3, name: 'Rajshahi', code: 'RAJ' },
        { id: 4, name: 'Khulna', code: 'KHU' },
        { id: 5, name: 'Barisal', code: 'BAR' },
        { id: 6, name: 'Sylhet', code: 'SYL' },
        { id: 7, name: 'Rangpur', code: 'RAN' },
        { id: 8, name: 'Mymensingh', code: 'MYM' },
    ],
    (divisions) => divisions.map(({ name, id }) => ({ label: name, value: id }))
);

const districtsSelector = createSelector(
    (state) => entitySelector(state, DOMAIN_NAME, DISTRICTS),
    (districts) => {
        if (districts && districts.data && districts.data.length) {
            return districts.data.map(({ name, id }) => ({ label: name, value: id }));
        }
    }
);

const upazillaDataSelector = createCachedSelector(
    state => entitySelector(state, DOMAIN_NAME, DISTRICTS),
    state => entitySelector(state, DOMAIN_NAME, UPAZILLAS),
    (state, formName, fieldName) => (getFormValues(formName)(state)),
    (state, formName, fieldName) => {
        return fieldName
    },
    (districts, upazillas, values, fieldName) => {
        const districtName = values[fieldName];
        let dsIDArr = [];

        if (districts && districts.data && districts.data.length) {
            dsIDArr = districts.data.filter((item) => (item.name === districtName));
        }
        let districtID;
        if (dsIDArr && dsIDArr.length > 0 && dsIDArr[0]) {
            districtID = dsIDArr[0].id;
        }
        if (upazillas && upazillas.data && upazillas.data.length && districtID !== undefined) {
            return upazillas.data.filter(({ district_id }) => districtID === district_id).map(({ name, id }) => ({ label: name, value: id }));
        }
    },)
    ((state, formName, fieldName) => `data-upzillia-${formName}-${fieldName}`);


const getAllEmployeeSelector = createCachedSelector(
    entitySelector,
    (resp) => resp ? (resp.map(({ id, email, ...datum }) => ({
        label: email,
        value: id,
    }))) : []
)((state, domain, entity) => `@data-${domain}-${entity}`);

export {
    formAsyncErrorsSelector,
    formSubmitErrorsSelector,
    formSyncErrorsSelector,
    formErrorsSelector,
    hasFormErrorSelector,
    districtsSelector,
    divisionsSelector,
    upazillaDataSelector,
    commonSelector,
    getAllEmployeeSelector
};

