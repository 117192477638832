import ContentContainer from '@libComponents/ContentContainer';
import PropTypes from 'prop-types';
import { Fragment, useState } from 'react';
import { useLocation } from 'react-router';
import { FORM_NAME, IS_PAID_LEAVE, PRE_APPROVED } from '../common';
// Libs
import { GridContainer, GridItem, Input } from '@formComponents';
import AutocompleteFetch from '@formComponents/AutocompleteFetch';
import InputRadio from '@formComponents/InputRadio';
import { Button } from '@libComponents';
import { minValue, required, isEmpty } from '@libs/validationRules';
import { styled } from '@mui/system';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

import {
    initialize as formInitialize,
    destroy as destroyCategoryForm,
    reduxForm
} from 'redux-form';


import { DOMAIN_NAME as COMMON_DOMAIN } from 'common/common';
import { EMPLOYEE_CATEGORY, ROLES } from 'common/redux.datasets';
import { createUpdateCategory, getCategory } from 'leaveCategory/redux.actions';
import { hasFormErrorSelector } from 'common/redux.selectors';

const propTypes = {
    mode: PropTypes.oneOf(['create', 'update']),
};
const defaultProps = {
    mode: 'create',
};

const ButtonWrapper = styled('div')({
    position: 'absolute',
    bottom: 0,
    right: '50%',
    transform: 'translate(50%, 50%)',
    zIndex: 4,
});

const StyledButton = styled(Button)(({ theme: { spacing, typography } }) => ({
    height: spacing(4.75),
    width: spacing(25),
    fontSize: typography.subtitle1.fontSize,
    marginLeft: spacing(0.75),
    marginRight: spacing(0.75),
}));


const LeaveCategoryCreateUpdate = reduxForm({
    form: FORM_NAME, // Form name
    destroyOnUnmount: false, // Preserve data
    onSubmit: (v) => v,
})(({ mode, handleSubmit: handleReduxFormSubmit, ...rest }) => {
    const { state } = useLocation();
    const RXDispatch = useDispatch();
    const { name } = state ? state : "";

    const [preApproved, setPreApproved] = useState(false);


    const INITIAL_VALUES = {
        isPaidLeave: true,
        preApproved: false,
        minimumApprovalsRequired: "1"
    };

    useEffect(() => {
        if (mode === 'create') {
            RXDispatch(formInitialize(FORM_NAME, INITIAL_VALUES));
        }
        return () => {
            return RXDispatch(destroyCategoryForm(FORM_NAME));
        };
    }, [mode]);


    useEffect(() => {
        if (name && mode === "update") {
            RXDispatch(getCategory({ handleSubmit: handleLeaveCategory, name }));
        }
    }, [name]);

    const handleLeaveCategory = (payload) => {
        setPreApproved(payload.preApproved);
        RXDispatch(formInitialize(FORM_NAME, payload));
    }
    const hasError = useSelector((state) =>
        hasFormErrorSelector(state, FORM_NAME)
    );

    const handleCreate = () => {
        if (hasError) {
            handleReduxFormSubmit();
            return;
        }
        RXDispatch(createUpdateCategory({ handleSubmit, payload: { mode } }));
    }
    const navigate = useNavigate();

    const handleSubmit = (payload) => {
        if(payload.data){
            setTimeout(() => {
                navigate("/leave-category/all-leave-category");
            }, 1000)
        }
    }

    const handleChange = (e) => {
        e.target && setPreApproved(JSON.parse(e.target.value));
    }

    return (
        <ContentContainer
            title={mode + ' Leave Category'}
            sx={{ textTransform: 'capitalize' }}
            AdornmentComponent={<Fragment />}
        >
            <GridContainer>
                <GridItem md={6}>
                    <Input
                        name='name'
                        placeholder='Name'
                        label='Name'
                        required={true}
                        validate={[required]}
                        disabled={mode === "update" ? true : false}
                        autoComplete="do-not-autofill"
                    />
                </GridItem>
                <GridItem md={6}>
                    <Input
                        name='totalPerBusinessYear'
                        placeholder='Total Per Business Year'
                        label='Total Per Business Year'
                        required={true}
                        validate={[required]}
                        autoComplete="do-not-autofill"
                        type="number"
                    />
                </GridItem>
                <GridItem md={6}>
                    <AutocompleteFetch
                        name='approvers'
                        placeholder='Approvers'
                        label='Approvers'
                        domain={COMMON_DOMAIN}
                        entity={ROLES}
                        multiple
                        required={true}
                        validate={[required, isEmpty]}
                    />
                </GridItem>

                <GridItem md={6}>
                    <AutocompleteFetch
                        name='eligibility'
                        placeholder='Eligibility'
                        label='Eligibility'
                        domain={COMMON_DOMAIN}
                        entity={EMPLOYEE_CATEGORY}
                        multiple
                    />
                </GridItem>

                <GridItem md={6}>
                    <InputRadio
                        name='preApproved'
                        label='Pre Approved?'
                        options={PRE_APPROVED}
                        onChange={handleChange}
                    />
                </GridItem>
                <GridItem md={6}>
                    <InputRadio
                        name='isPaidLeave'
                        label='Is Paid Leave?'
                        options={IS_PAID_LEAVE}
                        // defaultValue={true}
                    />
                </GridItem>

                {!preApproved &&
                    <GridItem md={6}>
                        <Input
                            name='minimumApprovalsRequired'
                            placeholder='Minimum Approvals Required'
                            label='Minimum Approvals Required'
                            validate={[minValue(1)]}
                            autoComplete="do-not-autofill"
                            type="number"
                        />
                    </GridItem>
                }

            </GridContainer>

            <ButtonWrapper>
                <StyledButton onClick={handleCreate}>
                    {mode === "create" ? "CREATE" : "UPDATE"}
                </StyledButton>
            </ButtonWrapper>
        </ContentContainer>
    );
});

LeaveCategoryCreateUpdate.propTypes = propTypes;
LeaveCategoryCreateUpdate.defaultProps = defaultProps;

export { LeaveCategoryCreateUpdate as default, LeaveCategoryCreateUpdate };

