import { reduxForm } from 'redux-form';

import { Paper, Typography, styled } from '@mui/material';

import { FORM_NAME } from '../common';
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const StyledPaper = styled(Paper)(({ theme: { palette, shadows, spacing } }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    borderRadius: 0,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: palette.background.dark,
    boxShadow: shadows[0],
    paddingBottom: spacing(2)
}));

const SuccessMessage = styled(Typography)(
    ({ theme: { spacing, typography, palette } }) => ({
        marginTop: spacing(5.5),
        fontWeight: typography.fontWeightBold,
        fontSize: '3.7vw',
        color: palette.common.black,
    })
);

const Complete = reduxForm({ form: FORM_NAME, destroyOnUnmount: false })(() => {
    const navigate = useNavigate();
    useEffect(()=>{
        setTimeout(() => {
            navigate("/employee/all-employee");
        }, 2000)
    },[])

    return (
        <StyledPaper>
            <SuccessMessage variant='h2'>Successfully added!</SuccessMessage>
            <Typography variant='subtitle1'>Redirecting to Employee...</Typography>
            <CircularProgress color="success" size={20}/>
        </StyledPaper>
    );
});

export default Complete;
