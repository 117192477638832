import { useMemo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    fetchDatasets,
    gridDatasetSelector,
    createFetchGridDataset
} from '@libs/datasets';
import { makeSideEffectCallback } from '@libs/makeContext';

import { MY_DAILY_ATTENDANCE } from 'timeAndAttendance/redux.datasets';
import { DOMAIN_NAME } from 'timeAndAttendance/common';
import { DailyTimesheetsProvider } from './useDailyTimesheets';
import Filters from './Filters';
import DataTable from './DataTable';

const TimesheetsDailyView = () => {
    const dailyTimesheetData = useSelector((state) =>
        gridDatasetSelector(state, DOMAIN_NAME, MY_DAILY_ATTENDANCE)
    );
    const dispatch = useDispatch();
    const fetchAttendance = useCallback(
        () => dispatch(fetchDatasets(MY_DAILY_ATTENDANCE)),
        [dispatch]
    );
    const fetchGridDailyDataset = useCallback(
        (payload) =>
            dispatch(
                createFetchGridDataset(
                    DOMAIN_NAME,
                    MY_DAILY_ATTENDANCE
                )(payload)
            ),
        [dispatch]
    );

    const initialize = useCallback(
        (state) => ({
            ...state,
            dailyTimesheetData
        }),
        [dailyTimesheetData]
    );

    const middlewareProps = useMemo(
        () => ({
            fetchData: makeSideEffectCallback(fetchGridDailyDataset)
        }),
        [fetchGridDailyDataset]
    );

    useEffect(() => {
        fetchAttendance();
    }, []);

    return (
        <DailyTimesheetsProvider
            initialize={initialize}
            middlewareProps={middlewareProps}
        >
            <Filters />
            <DataTable />
        </DailyTimesheetsProvider>
    );
};

export default TimesheetsDailyView;
