import {  GET_INITIAL_SHIFT, UPDATE_EMPLOYEE_SHIFT } from './redux.actionTypes';


export const getInitialShift = ({ handleSubmit, payload }) => ({
    type: GET_INITIAL_SHIFT,
    meta: { handleSubmit },
    payload,
});

export const updateManageShifts = ({ handleSubmit, payload }) => ({
    type: UPDATE_EMPLOYEE_SHIFT,
    meta: { handleSubmit },
    payload,
});