import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { DOMAIN_NAME } from 'utilities/common';
import { createSelector } from 'reselect';
import { entitySelector } from '@libs/datasets';

const MUICircularProgress = ({ entity }) => {
    const loadingSelector = createSelector(
        (state) => entitySelector(state, DOMAIN_NAME, entity),
        (resp) => resp
    );

    const isLoading = useSelector((state) => loadingSelector(state));

    return (
        isLoading && (
            <Box sx={{ position: "absolute", top: "50%", left: "50%" }}>
                <CircularProgress />
            </Box>
        )
    );
};

export default MUICircularProgress;
