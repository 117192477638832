import { useEffect, useMemo } from 'react';

import useDimensions from '@libHooks/useDimensions';
import useNavigation, {
    useNavigationState,
    useNavigationDispatch,
} from '../useNavigation';

export const useDrawerStyle = () => {
    const {
        drawerWidth,
        appBarHeight,
        isDrawerVisible,
        isDrawerMinified,
        isDrawerFloating,
    } = useNavigationState();

    return useMemo(() => {
        const drawerSize = isDrawerVisible
            ? isDrawerMinified && !isDrawerFloating
                ? appBarHeight
                : drawerWidth
            : 0;

        return {
            '--drawerWidth': `${drawerSize}px`,
            '--logoHeight': `${appBarHeight}px`,
        };
    }, [
        drawerWidth,
        appBarHeight,
        isDrawerVisible,
        isDrawerMinified,
        isDrawerFloating,
    ]);
};

export const useLogoRef = () => {
    const dispatch = useNavigationDispatch();

    const [logoRef, { width, height }] = useDimensions({
        width: null,
        height: null,
    });

    useEffect(() => {
        dispatch({
            type: 'setHeaderDimensions',
            payload: { width, height },
        });
    }, [width, height, dispatch]);

    return logoRef;
};

export const useDrawerHover = () => {
    const [{ isDrawerVisible, isDrawerMinified }, dispatch] = useNavigation();

    const handleMouseEnter = useMemo(() => {
        if (isDrawerVisible && isDrawerMinified)
            return () =>
                dispatch({ type: 'setDrawerFloatation', payload: true });
        else return () => void 0;
    }, [isDrawerVisible, isDrawerMinified]);

    const handleMouseLeave = useMemo(() => {
        if (isDrawerVisible && isDrawerMinified)
            return () =>
                dispatch({ type: 'setDrawerFloatation', payload: false });
        else return () => void 0;
    }, [isDrawerVisible, isDrawerMinified]);

    return [handleMouseEnter, handleMouseLeave];
};
