import { Fragment } from 'react';
import { reduxForm } from 'redux-form';
import { styled } from '@mui/system';

import ContentContainer from '@libComponents/ContentContainer';
import { GridContainer, GridItem, Input } from '@formComponents';
import { Button } from '@libComponents';
import { required } from '@libs/validationRules';

import TextArea from '@formComponents/TextArea';
import { REJECT_REASON_FORM_NAME } from '../common';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { handleLeave } from 'leaveManagement/redux.actions';

const StyledButton = styled(Button)(({ theme: { spacing, typography } }) => ({
    height: spacing(4.75),
    width: spacing(25),
    fontSize: typography.subtitle1.fontSize,
    marginLeft: spacing(0.75),
    marginRight: spacing(0.75),
}));

const ButtonWrapper = styled('div')({
    position: 'absolute',
    bottom: 0,
    right: '50%',
    transform: 'translate(50%, 50%)',
    zIndex: 4,
});

const RejectReasonForm = reduxForm({
    form: REJECT_REASON_FORM_NAME,
    destroyOnUnmount: false,
    onSubmit: (v) => v,
})(({ mode, handleSubmit: handleReduxFormSubmit, rejectPayload, onReject, ...rest }) => {
    const RXDispatch = useDispatch();
    const handleReject = () => {
        onReject();
        RXDispatch(handleLeave({ payload: { row: rejectPayload, action: "reject" } }));
    };

    return (
        <Box>
            <GridContainer
                sx={{ margin: "unset", padding: "unset", width: "100%", }}
            >
                <GridItem md={12}
                    sx={{ margin: "unset", padding: "unset", textAlign: "left", mt: 2, mb: 4 }}>
                    <TextArea
                        name='comment'
                        label='Write a reason to reject this leave'
                        rows='2'
                        required={true}
                        validate={[required]}
                    />
                </GridItem>
            </GridContainer>
            <StyledButton onClick={handleReject}>
                Reject
            </StyledButton>
        </Box>
    );
});

export { RejectReasonForm as default, RejectReasonForm };

