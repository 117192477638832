import { useCallback, useEffect, useMemo, useState } from 'react';

import { useExpandedRoutes, useIsMinified } from '../../hooks';
import { useNavigationDispatch, useNavigationState } from '../../useNavigation';

export const useExpanded = (route) => {
    const { accordion } = useNavigationState();

    return useMemo(() => accordion.includes(route), [accordion, route]);
};

export const useTransitionExpand = (expanded, { transitions }) => {
    const [transitionExpand, setTransitionExpand] = useState(expanded);

    useEffect(() => {
        let transitionTimeout = void 0;
        if (expanded) {
            setTransitionExpand(expanded);
        } else {
            transitionTimeout = setTimeout(
                () => setTransitionExpand(expanded),
                transitions.duration.complex
            );
        }
        return () => clearTimeout(transitionTimeout);
    }, [expanded]);
    return transitionExpand;
};

export const useHandleAccordion = ({ children, route }) => {
    const dispatch = useNavigationDispatch();
    const expandedRoutes = useExpandedRoutes(route);

    return useCallback(() => {
        if (!!children.length) {
            dispatch({ type: 'setAccordion', payload: expandedRoutes });
        }
    }, [children.length, dispatch, expandedRoutes]);
};

export const useAccordionStyle = ({
    depth,
    children,
    route,
    theme: { palette, spacing, typography },
}) => {
    const { active } = useNavigationState();
    const expandedRoutes = useExpandedRoutes(route);
    const isMinified = useIsMinified();

    const depthColors = useMemo(
        () => [
            palette.secondary.main,
            palette.secondary.dark,
            palette.common.black,
        ],
        []
    );

    return useMemo(() => {
        const accordionPadding = !depth ? 0 : spacing(6);

        const isOpenedRoute = !expandedRoutes.includes(route);

        const isMinifiedHeader = isMinified && !depth && isOpenedRoute;

        const headerColor =
            isMinifiedHeader || active === route
                ? palette.primary.main
                : depthColors[depth];

        const minifyJustify = isMinified ? 'center' : 'unset';

        const activeFontWeight = isOpenedRoute
            ? typography.fontWeightHeavy
            : typography.fontWeightLight;

        return {
            '--accordionColor': depthColors[depth],
            '--headerColor': headerColor,
            '--accordionPadding': `${accordionPadding}`,
            '--minifyJustify': minifyJustify,
            '--activeFontWeight': activeFontWeight,
        };
    }, [depth, children.length, active, isMinified, expandedRoutes, route]);
};

export const useShouldDisable = (children) => {
    return useMemo(() => {
        const checkModule = (children) => {
            return !!children.find(({ Component, children }) => {
                if (!Component) {
                    return checkModule(children);
                } else {
                    return Component;
                }
            });
        };
        return !checkModule(children);
    }, [children]);
};
