import { DASHBOARD_APPROVED_LEAVE_INITIAL_API_PARAMS, DASHBOARD_ATTENDANCE_INITIAL_API_PARAMS, DASHBOARD_OVERVIEW_ATTENDANCE_INITIAL_API_PARAMS, DASHBOARD_OVERVIEW_INITIAL_API_PARAMS, DASHBOARD_OVERVIEW_LEAVE_INITIAL_API_PARAMS, DASHBOARD_OVERVIEW_EVENTS_CALENDER_INITIAL_API_PARAMS } from "./common";

export const DASHBOARD_OVERVIEW = 'dashboard@employeeInformation';
export const DASHBOARD_ATTENDANCE = 'dashboard@attendance';
export const DASHBOARD_OVERVIEW_ATTENDANCE = 'dashboard@overview-attendance';
export const DASHBOARD_APPROVED_LEAVE = 'dashboard@approvedLeave';
export const DASHBOARD_OVERVIEW_LEAVE = 'dashboard@leave';
export const DASHBOARD_EVENTS_CALENDER = 'dashboard@eventCalender';

export default {
    [DASHBOARD_OVERVIEW]: {
        url: 'widget/employee-info',
        selector: () => false,
        params: DASHBOARD_OVERVIEW_INITIAL_API_PARAMS,
        dataGrid: true
    },
    [DASHBOARD_ATTENDANCE]: {
        url: 'attendance/get-all-daily-attendances',
        params: DASHBOARD_ATTENDANCE_INITIAL_API_PARAMS,
        selector: () => false,
        dataGrid: true
    },
    [DASHBOARD_OVERVIEW_ATTENDANCE]: {
        url: 'attendance/get-all-daily-attendances',
        params: DASHBOARD_OVERVIEW_ATTENDANCE_INITIAL_API_PARAMS,
        selector: () => false,
        dataGrid: true,
    },
    [DASHBOARD_OVERVIEW_LEAVE]: {
        url: 'widget/approved-sick-or-other-leaves',
        params: DASHBOARD_OVERVIEW_LEAVE_INITIAL_API_PARAMS,
        selector: () => false,
        dataGrid: true,
    },
    [DASHBOARD_APPROVED_LEAVE]: {
        url: '/widget/approved-leaves-preview',
        params: DASHBOARD_APPROVED_LEAVE_INITIAL_API_PARAMS,
        selector: () => false,
        dataGrid: true
    },
    [DASHBOARD_EVENTS_CALENDER]: {
        url: 'widget/events',
        params: DASHBOARD_OVERVIEW_EVENTS_CALENDER_INITIAL_API_PARAMS,
        selector: () => false,
        dataGrid: true,
    },
};
