import { Fragment } from 'react';
import { reduxForm } from 'redux-form';

// Libs
import { GridContainer, GridItem, Input } from '@formComponents';
import ContentContainer from '@libComponents/ContentContainer';
import { email, required } from '@libs/validationRules';

import { Box, Checkbox, Divider, FormControlLabel, Typography } from '@mui/material';
import SelectDistrict from 'common/components/SelectDistrict';
import SelectThana from 'common/components/SelectThana';
import { FORM_NAME, LABELS } from '../../common';
import { useDispatch } from 'react-redux';
import {
    destroy as destroyEmployeeForm, getFormValues,
    initialize as formInitialize
} from 'redux-form';


const Contact = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
})(({mode,...restProps}) => {
    const RXDispatch = useDispatch();
    
    const handleChange =(event)=>{
        if(event.target.checked){
            let payload = restProps._reduxForm.getValues();
            payload.presentThana =  payload.thana;
            payload.presentMobileNo =  payload.permanentMobileNo;
            payload.presentAddress =  payload.permanentAddress;
            payload.presentDistrict =  payload.district;
            payload.presentCountry =  payload.country;
            RXDispatch(formInitialize(FORM_NAME, payload));
        }
    }
    return (
        <ContentContainer
            title={LABELS.contact}
            AdornmentComponent={<Fragment />}
        >
            <GridContainer>
                <GridItem md={12} sx={{ mt: 2 }}>
                    <Typography> Permanent Address</Typography>
                    <Divider light />
                </GridItem>

                <GridItem md={3}>
                    <Input
                        name='permanentAddress'
                        placeholder='Address'
                        label='Address'
                        required={true}
                        validate={required}
                    />
                </GridItem>
                <GridItem md={3}>
                    <Input
                        name='district'
                        placeholder='City'
                        label='City'
                    />
                </GridItem>
                <GridItem md={3}>
                    <Input
                        name='thana'
                        placeholder='Postcode'
                        label='Postcode'
                    />
                </GridItem>
                <GridItem md={3}>
                    <Input
                        name='country'
                        placeholder='Country'
                        label='Country'
                    />
                </GridItem>
                <GridItem md={3}>
                    <Input
                        name='permanentMobileNo'
                        placeholder='Mobile no.'
                        label='Mobile No'
                    />
                </GridItem>
                <GridItem md={12} sx={{ mt: 3 }}>
                    <Box sx={{display:"flex", alignItems:"center", gap:4 }}>
                        <Typography> Present Address</Typography>
                        <FormControlLabel required control={<Checkbox onChange={handleChange}/>} label="Same as Above" />
                    </Box>
                    <Divider light />
                </GridItem>
                <GridItem md={3}>
                    <Input
                        name='presentAddress'
                        placeholder='Address'
                        label='Address'
                        required={true}
                        validate={required}
                    />
                </GridItem>
                <GridItem md={3}>
                    <Input
                        name='presentDistrict'
                        placeholder='City'
                        label='City'
                    />
                </GridItem>
                <GridItem md={3}>
                    <Input
                        name='presentThana'
                        placeholder='Postcode'
                        label='Postcode'
                    />
                </GridItem>
                <GridItem md={3}>
                    <Input
                        name='presentCountry'
                        placeholder='Country'
                        label='Country'
                    />
                </GridItem>
                <GridItem md={3}>
                    <Input
                        name='presentMobileNo'
                        placeholder='Mobile no.'
                        label='Mobile No'
                    />
                </GridItem>

                <GridItem md={12} sx={{ mt: 3 }}>
                    <Typography> Personal Contact</Typography>
                    <Divider light />
                </GridItem>

                <GridItem md={3}>
                    <Input
                        name='personalEmail'
                        placeholder='email'
                        label='Personal E-Mail'
                        required={true}
                        validate={email}
                    />
                </GridItem>
                <GridItem md={3}>
                    <Input
                        name='officialEmail'
                        placeholder='email'
                        label='Official E-Mail'
                        validate={email}
                        disabled = {mode === 'update' ? true : false}
                    />
                </GridItem>

                <GridItem md={3}>
                    <Input
                        name='personalMobileNo'
                        placeholder='Mobile no.'
                        label='Mobile No'
                        required={true}
                        validate={required}
                    />
                </GridItem>

                <GridItem md={12} sx={{ mt: 3 }}>
                    <Typography> Emergency Contact</Typography>
                    <Divider light />
                </GridItem>

                <GridItem md={3}>
                    <Input
                        name='emergencyName'
                        placeholder='name'
                        label='Name'
                    />
                </GridItem>
                <GridItem md={3}>
                    <Input
                        name='emergencyMobileNo'
                        placeholder='Mobile no.'
                        label='Mobile No'
                    />
                </GridItem>

                <GridItem md={3}>
                    <Input
                        name='emergencyAddress'
                        placeholder='Address'
                        label='Address'
                    />
                </GridItem>

                <GridItem md={3}>
                    <Input
                        name='emergencyRelationWithEmployee'
                        placeholder='Relation with Employee'
                        label='Relation with Employee'
                    />
                </GridItem>
            </GridContainer>
        </ContentContainer>
    );
});

export default Contact;
