export default [
    {
        key: 'passport',
        label: 'Passport Number',
    },
    {
        key: 'tin',
        label: 'Tax Identification Number',
    },
    {
        key: 'nationality',
        label: 'Nationality',
    },
    {
        key: 'nidNumber',
        label: 'NID Number',
    },
    {
        key: 'drivingLicenseNumber',
        label: 'Driving License Number',
    },
    {
        key: 'photograph',
        label: 'Photograph',
    },
    {
        key: 'etin',
        label: 'E-TIN',
    },
    {
        key: 'signature',
        label: 'Signature',
    },
    {
        key: 'nid',
        label: 'NID',
    },
]