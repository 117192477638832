import DashboardIcon from '@assets/NavigationIcons/dashboard.svg';
import ColoredDashboardIcon from '@assets/NavigationIcons/dashboardColored.svg';
import EmployeeIcon from '@assets/NavigationIcons/employee.svg';
import LeaveAndHolidays from '@assets/NavigationIcons/leaveAndHolidays.svg';
import TimeAndAttendanceIcon from '@assets/NavigationIcons/timeAndAttendance.svg';

export default {
    // Dashboard
    1: { icon: DashboardIcon, coloredIcon: ColoredDashboardIcon },
    2: { icon: EmployeeIcon, coloredIcon: EmployeeIcon },
    3: { icon: EmployeeIcon, coloredIcon: EmployeeIcon },
    // Leave and holidays
    4: { icon: LeaveAndHolidays, coloredIcon: LeaveAndHolidays },
    6: { icon: TimeAndAttendanceIcon, coloredIcon: TimeAndAttendanceIcon },
    11: { icon: LeaveAndHolidays, coloredIcon: LeaveAndHolidays },
    17: { icon: LeaveAndHolidays, coloredIcon: LeaveAndHolidays },
    20: { icon: LeaveAndHolidays, coloredIcon: LeaveAndHolidays },
    24: { icon: LeaveAndHolidays, coloredIcon: LeaveAndHolidays }
};
