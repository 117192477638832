import { styled } from '@mui/system';
import { Box } from '@mui/material';

import Header from './Header';
import Row from './Row';
import { useWeeklyTimesheetsState } from '../useWeeklyTimesheets';

const StyledDataTableContainer = styled(Box)(({ theme: { spacing } }) => ({
    width: `calc(100% - ${spacing(4)})`,
    margin: spacing(2)
}));

const TimesheetsDailyDataTable = () => {
    const { weeklyAttendanceData: { entries, totalDuration } } = useWeeklyTimesheetsState();
    return (
        <StyledDataTableContainer>
            <Header />
            <Row  entries={entries} totalDuration={totalDuration} />
        </StyledDataTableContainer>
    );
};

export default TimesheetsDailyDataTable;
