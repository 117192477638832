import { useCallback } from 'react';

import Typography from '@mui/material/Typography';

import { GridContainer, GridItem } from '@formComponents';
import { formatDateToDDMMM } from '@libs/dateTime';

import {
    TimesheetsInputSearch,
    TimeSheetsNavigator,
    SelectBranch
} from 'timeAndAttendance/components';
import useWeeklyTimesheets, {
    SET_EMAIL,
    TIMESHEET_NEXT,
    TIMESHEET_PREV,
    SET_FILTERS
} from './useWeeklyTimesheets';

const Filters = () => {
    const [{ startOfWeek, endOfWeek, isNextDisabled, filters }, dispatch] =
        useWeeklyTimesheets();

    const handleSearch = useCallback((value) => {
        dispatch({
            type: SET_EMAIL,
            payload: value
        });
    }, []);

    const handlePrevClick = useCallback(() => {
        dispatch({
            type: TIMESHEET_PREV
        });
    }, []);

    const handleNextClick = useCallback(() => {
        dispatch({
            type: TIMESHEET_NEXT
        });
    }, []);

    const handleFilterChange = useCallback((value) => {
        dispatch({
            type: SET_FILTERS,
            payload: {
                filterType: 'branch',
                value
            }
        });
    }, []);

    return (
        <GridContainer sx={{ paddingBottom: 0 }}>
            <GridItem md={4}>
                <TimeSheetsNavigator
                    onPrevClick={handlePrevClick}
                    onNextClick={handleNextClick}
                    isNextDisabled={isNextDisabled}
                >
                    <Typography>
                        {formatDateToDDMMM(startOfWeek)} -{' '}
                        {formatDateToDDMMM(endOfWeek)}
                    </Typography>
                </TimeSheetsNavigator>
            </GridItem>
            <GridItem md={2}>
                <SelectBranch
                    input={{
                        name: 'branch',
                        onChange: handleFilterChange,
                        value: filters.branch
                    }}
                />
            </GridItem>
            <GridItem md={3}>
                <TimesheetsInputSearch
                    name='weekly-view-employee-email'
                    handleSearch={handleSearch}
                />
            </GridItem>
        </GridContainer>
    );
};

export default Filters;
