import { useCallback } from 'react';

import { styled, IconButton, alpha } from '@mui/material';
import ChevronIcon from '@mui/icons-material/ChevronLeftRounded';

import useNavigation from '../useNavigation';

const StyledIconButton = styled(IconButton)(({ theme: { palette } }) => ({
    width: '100%',
    height: 'var(--buttonHeight)',
    borderRadius: 0,
    borderTopStyle: 'solid',
    borderTopWidth: 1,
    borderTopColor: alpha(palette.background.dark, 0.1),
    backgroundColor: palette.secondary.main,
    justifyContent: 'end',
}));

const StyledChevronIcon = styled(ChevronIcon)(({ theme }) => ({
    color: theme.palette.common.white,
    transform: 'rotate(var(--iconRotation))',
}));

export default () => {
    const [{ isDrawerMinified, footerHeight }, dispatch] = useNavigation();

    const handleMinifyToggle = useCallback(
        () =>
            dispatch({
                type: 'setDrawerMinification',
                payload: !isDrawerMinified,
            }),
        [dispatch, isDrawerMinified]
    );

    const style = {
        '--iconRotation': isDrawerMinified ? '0deg' : '180deg',
        '--buttonHeight': `${footerHeight}px`,
    };

    return (
        <StyledIconButton
            disableRipple
            disableTouchRipple
            onClick={handleMinifyToggle}
            style={style}
        >
            <StyledChevronIcon fontSize='large' style={style} />
        </StyledIconButton>
    );
};
