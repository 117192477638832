import { DELETE_EMPLOYEE, EMPLOYEE_PUBLISH, GET_EMPLOYEE, GET_EMPLOYEE_FIELD_VALUE, RESIGN_EMPLOYEE } from './redux.actionTypes';

export const employeePublish = ({ handleComplete, ...payload }) => ({
    type: EMPLOYEE_PUBLISH,
    meta: { handleComplete },
    payload,
});

export const getEmployee = ({ handleSubmit, ...payload }) => ({
    type: GET_EMPLOYEE,
    meta: { handleSubmit },
    payload,
});

export const deleteEmployee = ({ handleSubmit, payload }) => ({
    type: DELETE_EMPLOYEE,
    meta: { handleSubmit },
    payload,
});

export const resignEmployee = ({ handleSubmit, payload }) => ({
    type: RESIGN_EMPLOYEE,
    meta: { handleSubmit },
    payload,
});

export const getEmployeeFieldValue = ({ handleSubmit, payload }) => ({
    type: GET_EMPLOYEE_FIELD_VALUE,
    meta: { handleSubmit },
    payload,
});