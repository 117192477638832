import { styled } from '@mui/system';

import TimesheetDataCell from './TimesheetDataCell';

const StyledTimeRangeHeaderContainer = styled('div')({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative'
});

const StyledTimesheetDataCell = styled(TimesheetDataCell)({
    flex: 1
});

const TimeRangeHeader = (props) => {
    return (
        <StyledTimeRangeHeaderContainer {...props}>
            <StyledTimesheetDataCell>12:00 am</StyledTimesheetDataCell>
            <StyledTimesheetDataCell>06:00 am</StyledTimesheetDataCell>
            <StyledTimesheetDataCell>12:00 pm</StyledTimesheetDataCell>
            <StyledTimesheetDataCell>06:00 pm</StyledTimesheetDataCell>
            <StyledTimesheetDataCell>12:00 am</StyledTimesheetDataCell>
            <StyledTimesheetDataCell>06:00 am</StyledTimesheetDataCell>
        </StyledTimeRangeHeaderContainer>
    );
};

export { TimeRangeHeader as default, TimeRangeHeader };
