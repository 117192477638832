import apiRequest from '@libs/apiRequest';
import { getFormValues } from 'redux-form';
import { call, put, select } from 'redux-saga/effects';
import { FORM_NAME } from "leaveAndHolidays/common";
import { setLoading, setSnack } from 'utilities/redux.actions';
import dayjs from 'dayjs';

export default function* (action) {
    const {
        meta: { handleSubmit = () => { } },
        ...rest
    } = action;

    const { endDate, startDate, totalNumberOfDays, reason, isEmergency, employeesToNotify, category, requiredDocuments } = yield select(
        getFormValues(FORM_NAME)
    );

    let allSelector = {
        endDate: dayjs(endDate).format('YYYY-MM-DD'),
        startDate: dayjs(startDate).format('YYYY-MM-DD'),
        totalNumberOfDays,
        reason,
        employeesToNotify: employeesToNotify ? employeesToNotify.map((email) => {
            return { user: { email: email.name } }
        }) : {},
        category: {
            name: category
        },
        isEmergency
    }

    let documentSelector ={
        requiredDocuments
    }
    
    let formData = new FormData();
    FormData_append_object(formData, allSelector);

    let documentformData = new FormData();
    FormData_append_object(documentformData, documentSelector);

    yield put(setLoading(true));

    try {
        const initialData = yield call(
            apiRequest,
            {
                url: "leave/apply",
                method: 'post',
                data: formData,
            }
        );
        if(requiredDocuments){
            const { data } = yield call(
                apiRequest,
                {
                    url: `leave/attachdocuments?id=${initialData.data.id}`,
                    method: 'post',
                    data: documentformData,
                }
            );
            handleSubmit(data);
        }else{
            handleSubmit(initialData.data);
        }

        yield put(setLoading(false));
        yield put(setSnack({
            message: "Leave created successfully",
            type: 'success',
        }));
        return;
    } catch (error) {
        yield put(setLoading(false));
        yield put(setSnack({
            message: error?.response?.data?.message || error?.message,
            type: 'error',
        }));
    }
}


function FormData_append_object(fd, obj, key) {
    var i, k;
    for (i in obj) {
        k = key ? key + '[' + i + ']' : i;
        if (!obj[i])
            continue;
        if (typeof obj[i] == 'object') {
            if (obj[i].type === "file") {
                fd.append(k, obj[i].file);
            } else {
                FormData_append_object(fd, obj[i], k);
            }
        }
        else
            fd.append(k, obj[i]);
    }
}