const DOMAIN_NAME = 'employee';

const GENDER_OPTIONS = [
    { label: 'Female' },
    { label: 'Male' },
    { label: 'Transgender' },
    { label: 'Non-binary/non-conforming' },
];
const BLOODGROUP_OPTIONS = [
    { label: 'O+' },
    { label: 'O-' },
    { label: 'A+' },
    { label: 'A-' },
    { label: 'B+' },
    { label: 'B-' },
    { label: 'AB+' },
    { label: 'AB-' },
];

const MARITAL_STATUS = [
    { label: 'Single' },
    { label: 'Married' },
    { label: 'Widowed' },
    { label: 'Divorced' },
    { label: 'Separated' },
];

const LABEL_OF_EDUCATIONS = [
    { label: 'Graduate' },
    { label: 'Matriculation' },
    { label: 'Below Matriculation' }
];

const OCCUPATIONS_DATASET = [
    { label: 'Business' },
    { label: 'House Wife' },
    { label: 'Doctor' },
    { label: 'Public Service' },
    { label: 'Private Service' }
];

const RELATIVE_OPTIONS = [
    { label: "Yes", value: true },
    { label: "No", value: false }
];

const MARRIED_OPTIONS = [
    { label: "Yes", value: true },
    { label: "No", value: false }
];

const INITIALS = [
    { label: 'Mr.' },
    { label: 'Ms.' },
    { label: 'Mrs.' }
];

const NOMINATION_PURPOSE_OPTIONS = [
    { label: 'PF' },
    { label: 'GF' }
];

const ROWS_PER_PAGE = 12;
const ROWS_PER_PAGE_OPTIONS = [
    { label: '6', value: 6 },
    { label: '12', value: 12 },
    { label: '24', value: 24 },
    { label: '48', value: 48 }
];
export { ROWS_PER_PAGE, DOMAIN_NAME, MARRIED_OPTIONS, RELATIVE_OPTIONS, OCCUPATIONS_DATASET, LABEL_OF_EDUCATIONS, GENDER_OPTIONS, BLOODGROUP_OPTIONS, MARITAL_STATUS, INITIALS, NOMINATION_PURPOSE_OPTIONS, ROWS_PER_PAGE_OPTIONS };

