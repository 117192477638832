import { styled, useTheme, Typography, Collapse } from '@mui/material';

import RouteAccordion from './Route';
import { useIsMinified } from '../../hooks';

const Wrapper = styled('div')(({ theme }) => ({
    paddingTop: theme.spacing(1),
}));

const StyledTypography = styled(Typography)(
    ({ theme: { palette, spacing } }) => ({
        color: palette.secondary.light,
        textTransform: 'uppercase',
        paddingLeft: spacing(1.5),
        marginBottom: 0,
    })
);

const StyledCollase = styled(Collapse)(({ theme }) => ({
    marginTop: theme.spacing(2),
}));

export default ({ groupName, routes }) => {
    const { transitions } = useTheme();
    const isMinified = useIsMinified();

    return (
        <Wrapper>
            {groupName && (
                <StyledCollase
                    in={!isMinified}
                    timeout={transitions.duration.complex}
                >
                    <StyledTypography variant='caption'>
                        {groupName}
                    </StyledTypography>
                </StyledCollase>
            )}
            <Collapse
                in={!!routes.length}
                timeout={transitions.duration.complex * 2}
            >
                {routes.map((route) => (
                    <RouteAccordion {...route} key={route.route} />
                ))}
            </Collapse>
        </Wrapper>
    );
};
