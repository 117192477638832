import { Fragment } from 'react';
import { reduxForm } from 'redux-form';

// Libs
import { GridContainer, GridItem, Input } from '@formComponents';
import ContentContainer from '@libComponents/ContentContainer';

import DatePickerBase from '@formComponents/DatePicker';
import InputFile from '@formComponents/InputFile';
import { required } from '@libs/validationRules';
import { Divider, Typography } from '@mui/material';
import { FORM_NAME, LABELS } from '../../common';
import dayjs from 'dayjs';

const General = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false
})(() => {
    return (
        <ContentContainer
            title={LABELS.general}
            AdornmentComponent={<Fragment />}
        >
            <GridContainer>
                <GridItem md={12} sx={{ mt: 2 }}>
                    <Typography> Passport Information</Typography>
                    <Divider light />
                </GridItem>

                <GridItem md={3}>
                    <Input
                        name='passportNumber'
                        placeholder='Passport Number'
                        label='Passport Number'
                    />
                </GridItem>
                <GridItem md={3}>
                    <Input
                        name='passportIssuePlace'
                        placeholder='Issue Place'
                        label='Issue Place'
                    />
                </GridItem>
                <GridItem md={3}>
                    <DatePickerBase
                        name='passportIssueDate'
                        placeholder='Issue Date'
                        label='Issue Date'
                        minDate='2010-01-01'
                    />
                </GridItem>
                <GridItem md={3}>
                    <DatePickerBase
                        name='passportExpiryDate'
                        placeholder='Expiry Date'
                        label='Expiry Date'
                        minDate='2010-01-01'
                        maxDate={dayjs().add(10, 'year')}

                    />
                </GridItem>

                <GridItem md={12} sx={{ mt: 3 }}>
                    <Typography>Others</Typography>
                    <Divider light />
                </GridItem>
                <GridItem md={3}>
                    <Input
                        name='tin'
                        placeholder='Tax Identification Number'
                        label='Tax Identification Number'
                        required={true}
                        validate={required}
                    />
                </GridItem>
                <GridItem md={3}>
                    <Input
                        name='nationality'
                        placeholder='Nationality'
                        label='Nationality'
                        required={true}
                        validate={required}
                    />
                </GridItem>
                <GridItem md={3}>
                    <Input
                        name='nidNumber'
                        placeholder='NID'
                        label='NID Number'
                        required={true}
                        validate={required}
                    />
                </GridItem>
                <GridItem md={3}>
                    <Input
                        name='drivingLicenseNumber'
                        placeholder='Driving License Number'
                        label='Driving License Number'
                    />
                </GridItem>
                <GridItem md={12} sx={{ mt: 3 }}>
                    <Typography>Attachments</Typography>
                    <Divider light />
                </GridItem>
                <GridItem md={3}>
                    <InputFile name='photograph' label='Photograph' 
                        required={true}
                        validate={required}
                    />
                </GridItem>
                <GridItem md={3}>
                    <InputFile name='signature' label='Signature' />
                </GridItem>
                <GridItem md={3}>
                    <InputFile
                        name='nid'
                        label='NID'
                    />
                </GridItem>
                <GridItem md={3}>
                    <InputFile name='etin' label='E-TIN' />
                </GridItem>
            </GridContainer>
        </ContentContainer>
    );
});

export default General;
