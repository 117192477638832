import apiRequest from '@libs/apiRequest';
import { getFormValues } from 'redux-form';
import { call, put, select } from 'redux-saga/effects';
import { UPDATE_DOCUMENT_FORM_NAME } from "leaveAndHolidays/common";
import { setLoading, setSnack } from 'utilities/redux.actions';
import { EMPLOYEE_UPDATE_LEAVE_STATUS } from 'leaveAndHolidays/redux.actionTypes';
import { EMPLOYEE_APPLIED_LEAVES } from 'leaveAndHolidays/redux.datasets';


export default function* (action) {
    const {
        meta: { handleSubmit = () => { } },
        payload: { id },
    } = action;

    const { requiredDocuments } = yield select(
        getFormValues(UPDATE_DOCUMENT_FORM_NAME)
    );

    let documentSelector = {
        requiredDocuments
    }

    let documentformData = new FormData();
    FormData_append_object(documentformData, documentSelector);

    console.log(id);

    yield put(setLoading(true));

    try {

        const { data } = yield call(
            apiRequest,
            {
                url: `leave/attachdocuments?id=${id}`,
                method: 'post',
                data: documentformData,
            }
        );
        handleSubmit(data);

        yield put({
            type: EMPLOYEE_UPDATE_LEAVE_STATUS,
            payload: {
                entity: EMPLOYEE_APPLIED_LEAVES,
                value: data
            },
        })

        yield put(setLoading(false));
        yield put(setSnack({
            message: "Document Updated Successfully",
            type: 'success',
        }));
        return;
    } catch (error) {
        yield put(setLoading(false));
        yield put(setSnack({
            message: error?.response?.data?.message || error?.message,
            type: 'error',
        }));
    }
}


function FormData_append_object(fd, obj, key) {
    var i, k;
    for (i in obj) {
        k = key ? key + '[' + i + ']' : i;
        if (!obj[i])
            continue;
        if (typeof obj[i] == 'object') {
            if (obj[i].type === "file") {
                fd.append(k, obj[i].file);
            } else {
                FormData_append_object(fd, obj[i], k);
            }
        }
        else
            fd.append(k, obj[i]);
    }
}