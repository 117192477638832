import { useCallback, useEffect, useMemo } from "react";
import { DOMAIN_NAME } from "dashboard/common";
import { DASHBOARD_EVENTS_CALENDER } from "dashboard/redux.datasets";
import { useDispatch, useSelector } from "react-redux";
import { createFetchGridDataset, datasetLoadingSelector, fetchDatasets, gridDatasetSelector } from "@libs/datasets";
import { makeSideEffectCallback } from "@libs/makeContext";
import { ContentCircularLoader } from "@libComponents";
import { EventsCalenderProvider } from "./useEventsCalenderProvider";
import DataTable from "./DataTable";
import { Box } from "@mui/material";


const EventsCalender = (({ ...props }) => {

    const eventData = useSelector((state) =>
        gridDatasetSelector(state, DOMAIN_NAME, DASHBOARD_EVENTS_CALENDER)
    );

    let isLoading = useSelector((state) =>
        datasetLoadingSelector(state, DOMAIN_NAME, DASHBOARD_EVENTS_CALENDER)
    );

    const dispatch = useDispatch();
    const fetchEventsCalenderSummary = useCallback(
        () => dispatch(fetchDatasets(DASHBOARD_EVENTS_CALENDER)),
        [dispatch]
    );
    const fetchGridFilteredData = useCallback(
        (payload) =>
            dispatch(
                createFetchGridDataset(
                    DOMAIN_NAME,
                    DASHBOARD_EVENTS_CALENDER
                )(payload)
            ),
        [dispatch]
    );

    const initialize = useCallback(
        (state = {}) => ({
            ...state,
            eventData
        }),
        [eventData]
    );

    const middlewareProps = useMemo(
        () => ({
            fetchData: makeSideEffectCallback(fetchGridFilteredData)
        }),
        [fetchGridFilteredData]
    );

    useEffect(() => {
        fetchEventsCalenderSummary();
    }, []);

    return (
        <Box sx={{ position: "relative", height: "fit-content" }}>
            {isLoading && <ContentCircularLoader />}
            <EventsCalenderProvider
                initialize={initialize}
                middlewareProps={middlewareProps}>
                <DataTable />
            </EventsCalenderProvider>
        </Box>

    )
});

export default EventsCalender
