import { GridContainer, GridItem } from '@formComponents';
import { Button } from '@libComponents';
import { Typography } from '@mui/material';
import { Field } from 'redux-form';
import { renderField } from './renderFields/renderInputField';

const renderAcademicQualification = (member, index, fields) => (
    <GridContainer key={index} sx={{ p: 1, pl: 0,pr:0, m: 0 , width:"100%"}}>
        <GridItem
            md={12}
            sx={{
                pl: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
        >
            <Typography sx={{ textTransform: 'capitalize', width: 'auto' }}>
                Academic {fields.name} {index + 1}
            </Typography>
            <Button
                variant='outlined'
                sx={{ textTransform: 'capitalize', width: 'auto' }}
                type='button'
                onClick={() => fields.remove(index)}
                color="error"
            >
                Remove {fields.name} {index + 1}
            </Button>

        </GridItem>

        <GridItem md={3} sx={{ pl: 0 }}>
            <Field
                name={`${member}.qualification`}
                type='text'
                component={renderField}
                label='Qualification'
                placeholder='Qualification'
            />
        </GridItem>
        <GridItem md={3} sx={{ pl: 0 }}>
            <Field
                name={`${member}.department`}
                type='text'
                component={renderField}
                label='Discipline/Department'
                placeholder='Discipline/Department'
            />
        </GridItem>

        <GridItem md={3} sx={{ pl: 0 }}>
            <Field
                name={`${member}.name`}
                type='text'
                component={renderField}
                label='Institute Name'
                placeholder='Institute Name'
            />
        </GridItem>
        <GridItem md={3} sx={{ pl: 0 }}>
            <Field
                name={`${member}.board`}
                type='text'
                component={renderField}
                label='Institute/Board'
                placeholder='Institute/Board'
            />
        </GridItem>
        <GridItem md={3} sx={{ pl: 0 }}>
            <Field
                name={`${member}.passingYear`}
                type='text'
                component={renderField}
                label='Passing Year'
                placeholder='Passing Year'
            />
        </GridItem>
        <GridItem md={3} sx={{ pl: 0 }}>
            <Field
                name={`${member}.result`}
                type='text'
                component={renderField}
                label='Result (Marks/CGPA)'
                placeholder='Result (Marks/CGPA)'
            />
        </GridItem>
    </GridContainer>
);

export default renderAcademicQualification;
