import {
    RESET_SNACK,
    SET_SNACK,
    RESET_LOADING,
    SET_LOADING,
    SET_LOADING_PROGRESS,
    SET_COMPONENT_LOADING,
} from './redux.actionTypes';

export const resetLoading = () => ({
    type: RESET_LOADING,
});

/*
    @loading    bool
 */
export const setLoading = (loading) => ({
    type: SET_LOADING,
    payload: loading,
});

/*
    @loading    number      [1-100]
 */
export const setLoadingProgress = (loading) => ({
    type: SET_LOADING_PROGRESS,
    payload: loading,
});
export const resetSnack = () => ({
    type: RESET_SNACK,
});
export const setSnack = ({
    message,
    type = 'info',
    duration = 4000,
    action: { label, handle } = {},
}) => ({
    type: SET_SNACK,
    payload: { message, type, duration, action: { label, handle } },
});

export const setComponentLoading = (payload) => ({
    type: SET_COMPONENT_LOADING,
    payload,
});