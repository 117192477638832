import { BRANCH } from 'common/redux.datasets';
import { commonSelector } from 'common/redux.selectors';
import { createSelector } from 'reselect';

const getAllBranch = createSelector(
    commonSelector,
    data => {
        if (data && data[BRANCH] && data[BRANCH].data) {
            return data[BRANCH].data.map(row => {
                return {
                    ...row,
                    address: row.address.join(" "),
                    contacts: row.contacts.join(","),
                    emails: row.emails.join(",")
                }
            })
        }
    }
);

export { getAllBranch };
