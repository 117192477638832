import { takeLatest } from 'redux-saga/effects';

// Workers
import { GET_EMPLOYEE_DATA } from '../../redux.actionTypes';
import getUserEmployeeWorker from './getUserEmployeeWorker';


// Watchers
export default function* () {
    yield takeLatest(GET_EMPLOYEE_DATA, getUserEmployeeWorker);
}