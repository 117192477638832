import { fork } from 'redux-saga/effects';

// Sagas
import authSaga from 'auth/redux.sagas';
import navigationSaga from 'navigation/redux.sagas';
// import dashboard from 'dashboard/redux.sagas';
import employee from 'employee/redux.sagas';
import roles from 'roles/redux.sagas';
import user from 'user/redux.sagas';
import timeAndAttendance from 'timeAndAttendance/redux.sagas';
import LeaveCategory from 'leaveCategory/redux.sagas';
import LeaveManagement from 'leaveManagement/redux.sagas';
import common from 'common/redux.sagas';
import employeeLeaveManagement from 'leaveAndHolidays/redux.sagas';
import employeeLeaveManagementForUser from 'common/components/leaveDetails/redux.sagas';
import holiday from 'holidays/redux.sagas';

// Datasets
import { composeDatasetsSaga } from '@libs/datasets';
import datasets from './datasets';
import employeeSagas from 'profile/redux.sagas';

export default function* () {
    yield fork(authSaga);
    yield fork(navigationSaga);
    // yield fork(dashboard);
    yield fork(employee);
    yield fork(user);
    yield fork(roles);
    yield fork(timeAndAttendance);
    yield fork(common);
    yield fork(LeaveCategory);
    yield fork(LeaveManagement);
    yield fork(composeDatasetsSaga(datasets));
    yield fork(employeeLeaveManagement);
    yield fork(employeeLeaveManagementForUser);
    yield fork(holiday);
    yield fork(employeeSagas);
}
