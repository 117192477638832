import { Pagination, styled } from '@mui/material';

const StyledPagination = styled(Pagination)(
    ({ theme: { spacing, palette, typography, shape } }) => ({
        padding: spacing(1)
    })
);

const PaginationContainer = ({ className, ...props }) => (
    <StyledPagination className={className} {...props}>
        {props.children}
    </StyledPagination>
);

export { PaginationContainer as default, PaginationContainer };
