import { styled } from '@mui/system';

const StyledDataRowWrapper = styled('div')(
    ({ theme: { spacing, palette }, isOdd }) => ({
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        alignItems: 'center',
        padding: spacing(1.5),
        borderBottom: '1px solid',
        borderBottomColor: palette.border.main,
        backgroundColor: isOdd ? palette.grey[100] : 'transparent'
    })
);

const TimesheetDataRowWrapper = ({ children, ...rest }) => {
    return <StyledDataRowWrapper {...rest}>{children}</StyledDataRowWrapper>;
};

export { TimesheetDataRowWrapper as default, TimesheetDataRowWrapper };
