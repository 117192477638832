import makeContext from '@libs/makeContext';
import { DASHBOARD_APPROVED_LEAVE_INITIAL_API_PARAMS } from '../../common';


import { SET_BRANCH } from '../../context.actionTypes';

const initialState = {
    ...DASHBOARD_APPROVED_LEAVE_INITIAL_API_PARAMS
};

const reducer = (state, { type, payload }) => {
    switch (type) {
        case SET_BRANCH: {
            return {
                ...state,
                branch: payload
            };
        }
        default:
            return state;
    }
};

const middleware = (state, action, { fetchData }) => {
    switch (action.type) {
        case SET_BRANCH: {
            const { limit } = state;
            const { payload } = action;

            fetchData({
                limit,
                branch: payload
            });

            return action;
        }
        default:
            return action;
    }
};

const [
    ApprovedLeaveProvider,
    useApprovedLeaveState,
    useApprovedLeaveDispatch,
    useApprovedLeave
] = makeContext(reducer, initialState, {
    name: 'ApprovedLeave',
    middleware
});

export {
    useApprovedLeave as default,
    ApprovedLeaveProvider,
    useApprovedLeaveState,
    useApprovedLeaveDispatch,
    SET_BRANCH
};
