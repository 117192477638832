import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';

import {
    styled,
    AppBar as MaterialAppBar,
    Toolbar,
    IconButton,
    Fade,
    alpha,
    useTheme,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import { createShadow } from '@libs/materialUI';
import { renderedComponentPropType } from '@libs/propTypes';
import { useAppBarStyle } from './hooks';
import { usePath } from '../hooks';
import useNavigation from '../useNavigation';
import EndAdornment from './EndAdornment';

const propTypes = {
    adornments: PropTypes.arrayOf(renderedComponentPropType),
};

const defaultProps = {
    adornments: [],
};

const StyledAppBar = styled(MaterialAppBar)(
    ({ theme: { palette, transitions } }) => ({
        width: '100%',
        height: `calc(var(--appBarHeight))`,
        paddingLeft: 'var(--appBarPadding)',
        backgroundColor: palette.common.white,
        borderLeftStyle: 'solid',
        borderLeftWidth: 1,
        borderLeftColor: palette.background.light,
        boxShadow: createShadow({
            vOffset: 2,
            blur: 4,
            spread: -2,
            color: alpha(palette.common.black, 0.2),
        }),
        transition: transitions.create('padding', {
            easing: transitions.easing.sharp,
            duration: transitions.duration.shorter,
        }),
    })
);
const StyledToolbarBar = styled(Toolbar)(({ theme: { palette, spacing } }) => ({
    width: '100%',
    height: 'var(--appBarHeight)',
    backgroundColor: palette.common.white,
    paddingLeft: spacing(2),
    paddingRight: 0,
    boxShadow: createShadow({
        hOffset: spacing(10),
        blur: spacing(10),
        spread: spacing(-10),
        color: alpha(palette.common.black, 0.1),
        inset: true,
    }),
}));

const BarContent = styled('div')({
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
});

const AppBar = ({ adornments }) => {
    const { transitions } = useTheme();
    const location = useLocation();
    usePath(location);

    const [{ isDrawerVisible, isDrawerMinified, isDrawerFloating }, dispatch] =
        useNavigation();

    const handleMenu = useCallback(
        () =>
            dispatch({
                type: 'setDrawerVisibility',
                payload: !isDrawerVisible,
            }),
        [dispatch, isDrawerVisible]
    );

    return (
        <StyledAppBar position='fixed' style={useAppBarStyle()}>
            <StyledToolbarBar>
                <Fade
                    in={!isDrawerMinified || !isDrawerFloating}
                    timeout={transitions.duration.complex}
                >
                    <IconButton onClick={handleMenu}>
                        <MenuIcon />
                    </IconButton>
                </Fade>
                <BarContent>
                    <div>Favorites</div>
                    <EndAdornment adornments={adornments} />
                </BarContent>
            </StyledToolbarBar>
        </StyledAppBar>
    );
};

AppBar.propTypes = propTypes;
AppBar.defaultProps = defaultProps;

export default AppBar;
