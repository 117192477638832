import makeContext from '@libs/makeContext';

const initialState = {
    canSave: false,
    canPublish: false,
    mode: void 0,
    reset: () => {},
    handleSave: () => {},
    handlePublish: () => {}
};

const employeeReducer = (state) => state;

const [EmployeeProvider, useEmployeeState, useEmployeeDispatch, useEmployee] =
    makeContext(employeeReducer, initialState, { name: 'Employee' });

export {
    useEmployee as default,
    EmployeeProvider,
    useEmployeeState,
    useEmployeeDispatch
};
