import { useMemo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    fetchDatasets,
    gridDatasetSelector,
    createFetchGridDataset
} from '@libs/datasets';
import { makeSideEffectCallback } from '@libs/makeContext';

import { MY_WEEKLY_ATTENDANCE } from 'timeAndAttendance/redux.datasets';
import { DOMAIN_NAME } from 'timeAndAttendance/common';
import { WeeklyTimesheetsProvider } from './useWeeklyTimesheets';
import Filters from './Filters';
import DataTable from './DataTable';

const TimesheetsWeeklyView = () => {
    const weeklyAttendanceData = useSelector((state) =>
        gridDatasetSelector(state, DOMAIN_NAME, MY_WEEKLY_ATTENDANCE)
    );

    const dispatch = useDispatch();
    const fetchWeeklyAttendance = useCallback(
        () => dispatch(fetchDatasets(MY_WEEKLY_ATTENDANCE)),
        [dispatch]
    );
    const fetchGridWeeklyAllDataset = useCallback(
        (payload) =>
            dispatch(
                createFetchGridDataset(
                    DOMAIN_NAME,
                    MY_WEEKLY_ATTENDANCE
                )(payload)
            ),
        []
    );

    const initialize = useCallback(
        (state) => ({
            ...state,
            weeklyAttendanceData
        }),
        [weeklyAttendanceData]
    );

    const middlewareProps = useMemo(
        () => ({
            fetchData: makeSideEffectCallback(fetchGridWeeklyAllDataset)
        }),
        []
    );

    useEffect(() => {
        fetchWeeklyAttendance();
    }, []);

    return (
        <WeeklyTimesheetsProvider
            initialize={initialize}
            middlewareProps={middlewareProps}
        >
            <Filters />
            <DataTable />
        </WeeklyTimesheetsProvider>
    );
};

export default TimesheetsWeeklyView;
