import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import dayjs from 'dayjs';
import { Box, TableBody, TableRow, styled } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import CancelIcon from '@mui/icons-material/Cancel';

import ContentContainer from '@libComponents/ContentContainer';
import TableCellContainer from '@libComponents/table/TableCellContainer';
import TableContainer from '@libComponents/table/TableContainer';
import TableHeaderContainer from '@libComponents/table/TableHeaderContainer';
import DialogWrapper from '@libComponents/Dialog';
import { Button, Pagination } from '@libComponents';
import { datasetLoadingSelector, entitySelector, createFetchPaginatedDataset } from '@libs/datasets';
import { theme } from 'app/Theme';
import { EMPLOYEE_APPLIED_LEAVES } from '../redux.datasets';
import { DOMAIN_NAME, ROWS_PER_PAGE } from "../common";
import ActionList from 'leaveAndHolidays/components/ActionList';
import CancelLeaveForm from './CancelLeaveForm';
import LeaveDetails from './LeaveDetails';
import { TABLE_DATA } from './common';

const propTypes = {};

const defaultProps = {
    entity: EMPLOYEE_APPLIED_LEAVES,
    domain: DOMAIN_NAME,
    loading: true,
};

const StyledBox = styled(Box)(
    () => ({
        width: '100%',
        overflow: 'auto',
        '& table': {
            tableLayout: 'fixed'
        }
    })
);

const StyledRejectLabel = styled("span")(({ theme: { spacing, palette, shape } }) => ({
    background: palette.rejected.light,
    color: palette.rejected.main,
    padding: spacing(.5) + " " + spacing(2),
    borderRadius: shape.borderRadius,
}));

const StyledApproveLabel = styled("span")(({ theme: { spacing, palette, shape } }) => ({
    background: palette.approved.light,
    color: palette.approved.main,
    padding: spacing(.5) + " " + spacing(2),
    borderRadius: shape.borderRadius,
}));

const StyledCancelButton = styled("span")(({ theme: { spacing, palette, shape } }) => ({
    background: palette.cancelled.light,
    color: palette.cancelled.main,
    padding: spacing(.5) + " " + spacing(2),
    borderRadius: shape.borderRadius,
}));

const StyledPendingButton = styled("span")(({ theme: { spacing, palette, shape } }) => ({
    background: palette.pending.light,
    color: palette.pending.main,
    padding: spacing(.5) + " " + spacing(2),
    borderRadius: shape.borderRadius,
}));

const StyledMenuBUTTON = styled(Button)(() => ({
    cursor: 'pointer',
    justifyContent: "flex-start",
    width: "auto"
}));

const totalRowsSelector = createCachedSelector(entitySelector,
    (resp) => resp.total ? parseInt(resp.total) : 0
)((domain, entity) => `@data-${domain}-${entity}`);


const dataSelector = createSelector(
    (state) => entitySelector(state, DOMAIN_NAME, EMPLOYEE_APPLIED_LEAVES),
    (resp) => ((resp && resp.data) ? resp.data : [])
);

const mapState = (state, { domain, entity }) => ({
    data: dataSelector(state, domain, entity),
    loading: datasetLoadingSelector(state, domain, entity),
    totalRows: totalRowsSelector(state, domain, entity)
});

const mapDispatch = (dispatch, { entity }) => ({
    fetchDataset: ({ offset = "", limit = ROWS_PER_PAGE, shouldAppend = false }) => dispatch(
        createFetchPaginatedDataset({ entity: entity, domain: DOMAIN_NAME })({
            limit,
            offset,
            shouldAppend,
            "sort-descending": true,
        })
    ),
});

const AppliedLeaves = connect(
    mapState,
    mapDispatch
)(({ fetchDataset, data, totalRows = 0, ...props }) => {
    useEffect(() => {
        fetchDataset({ offset: "" });
    }, []);

    const [openReject, setOpenReject] = React.useState(false);
    const [rejectPayload, setRejectPayload] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [payload, setSelectedPayload] = React.useState({});
    const [pageSize, setPageSize] = React.useState(ROWS_PER_PAGE);
    const [currentPage, setCurrentPage] = React.useState(1);

    const handleChangePage = (event, newPage) => {
        fetchDataset({
            offset: (newPage - 1) * pageSize,
            limit: pageSize
        })
        setCurrentPage(newPage);
    };

    const handleRowsPerPageChange = (pageSize) => {
        setPageSize(pageSize);
        setCurrentPage(1);
        fetchDataset({
            offset: 0 * pageSize,
            limit: pageSize
        });
    }

    const viewLeaveDetails = (row) => {
        setOpen(true);
        setSelectedPayload(row);
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleRejectOpen = (row) => {
        setRejectPayload(row);
        setOpenReject(true);
    }

    const handleRejectClose = () => {
        setOpenReject(false);
        setRejectPayload(null);
    };

    if (!data) return null;

    return (
        <ContentContainer title="All Leaves" headerBg={theme.palette.background.mainHeader} >
            <StyledBox>
                <TableContainer >
                    <TableHeaderContainer>
                        {Object.values(TABLE_DATA).map((th, i) => (
                            <TableCellContainer
                                sx={{ pl: i === 0 ? 4 : 2, fontWeight: 'bold' }}
                                typography="caption" key={th}
                            >
                                {th}
                            </TableCellContainer>
                        ))}
                        <TableCellContainer
                            align='right'
                            sx={{ fontWeight: 'bold', textAlign: "right", pr: 4 }}
                        >
                            Action
                        </TableCellContainer>
                    </TableHeaderContainer>
                    <TableBody>
                        {data.map((row, i) => (
                            <TableRow key={i}>
                                {Object.keys(TABLE_DATA).map((key, index) => {
                                    switch (key) {
                                        case 'startDate':
                                            return (
                                                <TableCellContainer key={key}>
                                                    {dayjs(row[key]).format("YYYY-MM-DD")}
                                                </TableCellContainer>
                                            )
                                        case 'endDate':
                                            return (
                                                <TableCellContainer key={key}>
                                                    {dayjs(row[key]).format("YYYY-MM-DD")}
                                                </TableCellContainer>
                                            )
                                        case 'status':
                                            return (
                                                <TableCellContainer key={key}>{
                                                    (row[key].toLowerCase() === "approved") ? <StyledApproveLabel>{row[key].toUpperCase()}</StyledApproveLabel> : row[key].toLowerCase() === "rejected" ? <StyledRejectLabel>{row[key].toUpperCase()}</StyledRejectLabel> : row[key].toLowerCase() === "cancelled" ? <StyledCancelButton>{row[key].toUpperCase()}</StyledCancelButton> : <StyledPendingButton>{row[key].toUpperCase()}</StyledPendingButton>
                                                }
                                                </TableCellContainer>
                                            )
                                        default:
                                            return (
                                                <TableCellContainer
                                                    key={index}
                                                    sx={{ pl: index === 0 ? 4 : 2 }}
                                                >
                                                    {row[key]}
                                                </TableCellContainer>
                                            );
                                    }
                                })}
                                <TableCellContainer align='right'>
                                    <ActionList row={row}>
                                        <StyledMenuBUTTON
                                            variant='outlined'
                                            type='button'
                                            onClick={() => { viewLeaveDetails(row) }}
                                            size='small'
                                            color='primary'
                                            startIcon={<InfoIcon color='primary' />}
                                        >
                                            Details
                                        </StyledMenuBUTTON>
                                        <StyledMenuBUTTON
                                            variant='outlined'
                                            type='button'
                                            onClick={() => { handleRejectOpen(row) }}
                                            size='small'
                                            color='primary'
                                            startIcon={<CancelIcon color='primary' />}
                                            disabled={row.status !== "pending"}
                                        >
                                            Cancel Leave
                                        </StyledMenuBUTTON>
                                    </ActionList>
                                </TableCellContainer>
                            </TableRow>
                        ))}
                    </TableBody>
                </TableContainer>

                <Box sx={{ display: "flex", justifyContent: "center", ml: 2 }}>
                    <Pagination
                        count={Math.ceil(totalRows / pageSize)}
                        onChange={handleChangePage}
                        page={currentPage}
                        sx={{ display: 'flex', justifyContent: 'center' }}
                        isRowsPerPageVarient={true}
                        rowsPerPageProps={{
                            name: 'appliedLeaves',
                            onChange: handleRowsPerPageChange,
                            value: pageSize
                        }}
                    />
                </Box>
            </StyledBox>
            <DialogWrapper open={open} onClose={handleClose} sx={{ textAlign: "center" }}>
                <LeaveDetails payload={payload} handleClose={handleClose} />
            </DialogWrapper>
            <DialogWrapper open={openReject} onClose={handleRejectClose} sx={{ textAlign: "center" }}>
                <Box sx={{ "minWidth": "600px", minHeight: "200px" }}>
                    <CancelLeaveForm rejectPayload={rejectPayload} onReject={handleRejectClose} />
                </Box>
            </DialogWrapper>
        </ContentContainer>
    );
});

AppliedLeaves.propTypes = propTypes;
AppliedLeaves.defaultProps = defaultProps;

export default AppliedLeaves;
