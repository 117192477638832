import PropTypes from 'prop-types';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { styled, useTheme } from '@mui/system';
import { Box, IconButton } from '@mui/material';

const propTypes = {
    onPrevClick: PropTypes.func.isRequired,
    onNextClick: PropTypes.func.isRequired,
    isNextDisbled: PropTypes.bool,
    children: PropTypes.node.isRequired
};

const iconStyles = ({ theme, isDisabled }) => ({
    color: isDisabled ? theme.palette.icon.disabled : theme.palette.icon.main,
    fontSize: theme.spacing(3)
});

const StyledBox = styled(Box)({
    display: 'flex',
    alignItems: 'center' // Align center to vertically center the icons within IconButton
});

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    margin: theme.spacing(0, 0.5)
}));

const TimeSheetsNavigator = ({
    onPrevClick,
    onNextClick,
    isNextDisabled,
    children
}) => {
    const theme = useTheme(); // Fetch the theme

    return (
        <StyledBox>
            <StyledIconButton onClick={onPrevClick}>
                <ArrowBackIosNewIcon style={iconStyles({ theme })} />
            </StyledIconButton>
            {children}
            <StyledIconButton onClick={onNextClick} disabled={isNextDisabled}>
                <ArrowForwardIosIcon
                    style={iconStyles({ theme, isDisabled: isNextDisabled })}
                />
            </StyledIconButton>
        </StyledBox>
    );
};

TimeSheetsNavigator.propTypes = propTypes;

export { TimeSheetsNavigator as default, TimeSheetsNavigator };
