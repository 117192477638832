import { TableHead, TableRow } from '@mui/material';

// const styles = ({ palette, spacing }) => ({
//     root: {
//         "& th":{
//             color: palette.secondary.light,
//         }
//     },
// });

const TableHeadContainer = (
    ({  className, ...props }) => (
        <TableHead className={ className} {...props}>
            <TableRow>{props.children}</TableRow>
        </TableHead>
    )
);

export { TableHeadContainer as default, TableHeadContainer };

