import { Fragment } from 'react';
import { reduxForm } from 'redux-form';

// Libs
import { GridContainer, GridItem, Input } from '@formComponents';
import AutocompleteBase from '@formComponents/Autocomplete';
import DatePickerBase from '@formComponents/DatePicker';
import InputRadio from '@formComponents/InputRadio';
import ContentContainer from '@libComponents/ContentContainer';
import { Divider, Typography } from '@mui/material';
import ChildInformation from 'common/components/ChildInformation';
import RelativeEmployeeInformation from 'common/components/RelativeEmployeeInformation';
import { LABEL_OF_EDUCATIONS, MARRIED_OPTIONS, OCCUPATIONS_DATASET } from 'employee/common';
import { FORM_NAME, LABELS } from '../../common';
import dayjs from 'dayjs';

const Family = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
})(() => {
    return (
        <ContentContainer
            title={LABELS.family}
            AdornmentComponent={<Fragment />}
        >
            <GridContainer>
                <GridItem md={12} sx={{ mt: 2 }}>
                    <Typography sx={{ fontWeight: 600 }}> Spouse Information </Typography>
                    <Divider light />
                </GridItem>
                <GridItem md={12} >
                    <InputRadio
                        name='isMarried'
                        label='Is Married'
                        options={MARRIED_OPTIONS}
                    />
                </GridItem>
                <GridItem md={3}>
                    <Input
                        name='spouseName'
                        placeholder='Name'
                        label='Name'
                    />
                </GridItem>
                <GridItem md={3}>
                    <DatePickerBase
                        name='marriageDate'
                        placeholder='Marriage Date'
                        label='Marriage Date'
                        minDate="1900-01-01"
                        maxDate={dayjs()}  
                    />
                </GridItem>
                <GridItem md={3}>
                    <AutocompleteBase
                        name='spouseLevelOfEducation'
                        placeholder='Select Level of Education'
                        label='Level of Education'
                        options={LABEL_OF_EDUCATIONS}
                    />
                </GridItem>
                <GridItem md={3}>
                    <AutocompleteBase
                        name='spouseOccupation'
                        placeholder='Select Occupation'
                        label='Occupation'
                        options={OCCUPATIONS_DATASET}
                    />
                </GridItem>

                <GridItem md={12} sx={{ mt: 3, mb: 1 }}>
                    <Typography> Children Information</Typography>
                    <Divider light />
                </GridItem>

                <GridItem md={12}>
                    <ChildInformation />
                </GridItem>

                <GridItem md={12} sx={{ mt: 3, mb: 1 }}>
                    <Typography> Relatives’ Information</Typography>
                    <Divider light />
                </GridItem>

                <GridItem md={12}>
                    <RelativeEmployeeInformation formName={FORM_NAME} />
                </GridItem>

            </GridContainer>
        </ContentContainer>
    );
});

export default Family;
