export const USERS = 'user@alluser';
export const ROLES = 'user@allroles';

export default {
    [USERS]: {
        url: 'user/get/all',
    },
    [ROLES]: {
        url: 'role/get/all',
    },
};
