import { initializeDatasets } from '@libs/datasets';

import datasets from './redux.datasets';
import { UPDATE_LEAVE_STATUS } from './redux.actionTypes';

const initialState = {
    ...initializeDatasets(datasets),
};

export default (state = initialState, { type, payload, ...action }) => {
    switch (type) {
        case UPDATE_LEAVE_STATUS: {
            const { entity, value, key = "id" } = payload;
            const { data } = state[entity];
            let allEntries = [...data];
            const index = allEntries.findIndex((item) => (item[key] === value[key]));

            if (index === -1) return state;

            allEntries[index].status = value.status;
            return { ...state, [entity]: { ...state[entity], data: allEntries } };
        }
        default: {
            return state;
        }
    }
};
