import { DOMAIN_NAME } from "dashboard/common";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DASHBOARD_OVERVIEW_ATTENDANCE, DASHBOARD_OVERVIEW, DASHBOARD_OVERVIEW_LEAVE } from "dashboard/redux.datasets"
import { makeSideEffectCallback } from "@libs/makeContext";
import { createFetchGridDataset, datasetLoadingSelector, fetchDatasets, gridDatasetSelector } from "@libs/datasets";
import { OverviewProvider } from "./useOverviewProvider";
import DataTable from "./DataTable";
import Filter from "./Filter";
import { theme } from "app/Theme";
import { ContentCircularLoader, ContentContainer } from "@libComponents";
import { Box } from "@mui/material";
import ViewModalData from "./ViewModalData";

const propTypes = {};
const defaultProps = {
    domain: DOMAIN_NAME,
    loading: true,
};

const Overview = (({ ...props }) => {

    const overviewSummary = useSelector((state) =>
        gridDatasetSelector(state, DOMAIN_NAME, DASHBOARD_OVERVIEW)
    );

    const attendanceList = useSelector((state) =>
        gridDatasetSelector(state, DOMAIN_NAME, DASHBOARD_OVERVIEW_ATTENDANCE)
    );

    const leaveList = useSelector((state) =>
        gridDatasetSelector(state, DOMAIN_NAME, DASHBOARD_OVERVIEW_LEAVE)
    );

    let isLoading = useSelector((state) =>
        datasetLoadingSelector(state, DOMAIN_NAME, DASHBOARD_OVERVIEW)
    );

    const dispatch = useDispatch();
    const fetchEmployeeOverviewSummary = useCallback(
        () => dispatch(fetchDatasets(DASHBOARD_OVERVIEW)),
        [dispatch]
    );
    const fetchGridFilteredData = useCallback(
        (payload) =>
            dispatch(
                createFetchGridDataset(
                    DOMAIN_NAME,
                    DASHBOARD_OVERVIEW
                )(payload)
            ),
        [dispatch]
    );

    const fetchGridFilterAttendance = useCallback(
        (payload) =>
            dispatch(
                createFetchGridDataset(
                    DOMAIN_NAME,
                    DASHBOARD_OVERVIEW_ATTENDANCE
                )(payload)
            ),
        [dispatch]
    );

    const fetchGridFilterLeave = useCallback(
        (payload) =>
            dispatch(
                createFetchGridDataset(
                    DOMAIN_NAME,
                    DASHBOARD_OVERVIEW_LEAVE
                )(payload)
            ),
        [dispatch]
    );

    const initialize = useCallback(
        (state = {}) => ({
            ...state,
            overviewSummary,
            attendanceList,
            leaveList
        }),
        [overviewSummary, attendanceList, leaveList]
    );

    const middlewareProps = useMemo(
        () => ({
            fetchData: makeSideEffectCallback(fetchGridFilteredData),
            fetchAttendanceData: makeSideEffectCallback(fetchGridFilterAttendance),
            fetchLeaveData: makeSideEffectCallback(fetchGridFilterLeave),
        }),
        [fetchGridFilteredData]
    );

    useEffect(() => {
        fetchEmployeeOverviewSummary();
    }, []);


    return (
        <Box sx={{ position: "relative" }}>
            {isLoading && <ContentCircularLoader />}
            <OverviewProvider
                initialize={initialize}
                middlewareProps={middlewareProps}
            >
                <ContentContainer title="Overview" headerBg={theme.palette.background.white}
                    AdornmentComponent={
                        <Filter />
                    }
                >
                    <DataTable />
                </ContentContainer>
                <ViewModalData />
            </OverviewProvider>
        </Box>
    )
});

Overview.prototype = propTypes;
Overview.defaultProps = defaultProps;

export default Overview;
