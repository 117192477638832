import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';

import { FullLoader } from '@libComponents';

import store, { persistor } from './store';

const ReduxGate = ({ children }) => (
    <Provider store={store}>
        <PersistGate loading={<FullLoader />} persistor={persistor}>
            {children}
        </PersistGate>
    </Provider>
);

export { ReduxGate as default, store };
