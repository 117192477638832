import { Fragment, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// Material
import {
    Button,
    Grow,
    IconButton,
    Snackbar as MaterialSnackBar,
    SnackbarContent,
    alpha,
    useTheme,
    styled,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/CloseRounded';

// Local
import { snackSelector } from '../redux.selectors';
import { resetSnack } from '../redux.actions';
import { useSnackbarStyle } from './hooks';

const propTypes = {
    message: PropTypes.string,
    type: PropTypes.oneOf(['info', 'success', 'warning', 'error']),
    duration: PropTypes.number,
    action: PropTypes.object,
};

const StyledSnackbarContent = styled(SnackbarContent)(({ theme }) => ({
    backgroundColor: 'var(--backgroundColor)',
    color: alpha(theme.palette.secondary.main, 0.7),
}));
const StyledButton = styled(Button)(({ theme }) => ({
    color: theme.palette.error.main,
    textTransform: 'none',
}));
const StyledIconButton = styled(IconButton)(({ theme: { spacing } }) => ({
    padding: spacing(0.5),
    marginLeft: spacing(0.5),
}));

const Snackbar = () => {
    const theme = useTheme();
    const { message, type, duration, action } = useSelector(snackSelector);
    const dispatch = useDispatch();
    const { label = '', handle = () => {} } = action || {};

    const style = useSnackbarStyle({ type, theme });
    const handleClose = useCallback(() => dispatch(resetSnack()), [resetSnack]);
    const actionHandle = useCallback(() => {
        if (handle !== null) {
            if (typeof handle === 'object') dispatch(handle);
            else handle();
            handleClose();
        }
    }, [handle, dispatch]);

    useEffect(() => {
        let snackTimeout = null;
        if (!!message) {
            snackTimeout = setTimeout(handleClose, duration);
        }
        return () => clearTimeout(snackTimeout);
    }, [message, handleClose]);

    return (
        !!message && (
            <MaterialSnackBar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={!!message}
                key={message}
                TransitionComponent={(props) => <Grow {...props} />}
            >
                <StyledSnackbarContent
                    style={style}
                    message={message}
                    action={
                        label ? (
                            <Fragment>
                                <StyledButton
                                    variant='text'
                                    onClick={actionHandle}
                                >
                                    {label}
                                </StyledButton>
                                <StyledIconButton
                                    aria-label='close'
                                    color='inherit'
                                    onClick={handleClose}
                                >
                                    <CloseIcon />
                                </StyledIconButton>
                            </Fragment>
                        ) : (
                            <StyledIconButton
                                aria-label='close'
                                color='inherit'
                                onClick={handleClose}
                            >
                                <CloseIcon />
                            </StyledIconButton>
                        )
                    }
                />
            </MaterialSnackBar>
        )
    );
};

Snackbar.propTypes = propTypes;

export default Snackbar;
