const FORM_NAME = 'manageEmployee';

const SHIFTS = [
    { label: 'Morning', value: 'Morning' },
    { label: 'Evening', value: 'Evening' },
    { label: 'Night', value: 'Night' }
];

function formatedShiftData(shifts) {
    let newShifts = [];
    shifts.forEach(({ name }) => {
        newShifts.push({ label: name, value: name },)
    })
    return newShifts
}

export { SHIFTS, FORM_NAME, formatedShiftData }