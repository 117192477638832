import makeContext from '@libs/makeContext';
import { normalizeIso8601Date } from '@libs/dateTime';

import { SET_DATE } from '../../context.actionTypes';

const initialState = {
    entries: [],
    date: normalizeIso8601Date()
};

const reducer = (state, { type, payload }) => {
    switch (type) {
        case SET_DATE: {
            return {
                ...state,
                date: payload
            };
        }
        default:
            return state;
    }
};

const middleware = (state, action, { fetchData }) => {
    switch (action.type) {
        case SET_DATE: {
            const { payload } = action;
            fetchData({
                from: payload,
                to: payload
            });

            return action;
        }
        default:
            return action;
    }
};

const [
    DailyTimesheetsProvider,
    useDailyTimesheetsState,
    useDailyTimesheetsDispatch,
    useDailyTimesheets
] = makeContext(reducer, initialState, {
    name: 'DailyTimesheets',
    middleware
});

export {
    useDailyTimesheets as default,
    DailyTimesheetsProvider,
    useDailyTimesheetsState,
    useDailyTimesheetsDispatch,
    SET_DATE
};
