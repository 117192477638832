import { takeLatest } from 'redux-saga/effects';
import { CREATE_UPDATE_CATEGORY, DELETE_CATEGORY, GET_CATEGORY, UPDATE_CATEGORY } from 'leaveCategory/redux.actionTypes';
import deleteCategoryWorker from './categorySagas/deleteCategoryWorker';
import createUpdateCategoryWorker from './categorySagas/createUpdateCategoryWorker';
import getCategoryWorker from './categorySagas/getCategoryWorker';


export default function* () {
    yield takeLatest(CREATE_UPDATE_CATEGORY, createUpdateCategoryWorker);
    yield takeLatest(DELETE_CATEGORY, deleteCategoryWorker);
    yield takeLatest(GET_CATEGORY, getCategoryWorker);
    // yield takeLatest(UPDATE_CATEGORY, updateUserWorker);
}