import PropTypes from 'prop-types';

import { styled } from '@mui/material';

import { renderedComponentPropType } from '@libs/propTypes';

const propTypes = {
    adornments: PropTypes.arrayOf(renderedComponentPropType),
};

const defaultProps = {
    adornments: [],
};

const Container = styled('div')(({ theme: { palette, spacing } }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: palette.common.white,
    marginRight: spacing(2),
}));

const EndAdornment = ({ adornments }) => {
    return (
        <Container>
            {adornments.map((Adornment, index) => (
                <div key={`appBar-adornment${index}`}>
                    <Adornment />
                </div>
            ))}
        </Container>
    );
};

EndAdornment.propTypes = propTypes;
EndAdornment.defaultProps = defaultProps;

export default EndAdornment;
