const DOMAIN_NAME = 'employeeLeaveManagement';
const CREATE_EMPLOYEE_LEAVE_ROUTE_ID = 25;
const APPLIED_EMPLOYEE_LEAVE_ROUTE_ID = 5;
const ROWS_PER_PAGE = 5;
const FORM_NAME = "employeeLeaveManagement";
const REJECT_REASON_FORM_NAME = "rejectReason";
const UPDATE_DOCUMENT_FORM_NAME = "updateDocument";
const IS_EMERGENCY = [
    { label: "Yes", value: true },
    { label: "No", value: false }
];
export { DOMAIN_NAME, ROWS_PER_PAGE, CREATE_EMPLOYEE_LEAVE_ROUTE_ID, FORM_NAME, APPLIED_EMPLOYEE_LEAVE_ROUTE_ID, IS_EMERGENCY, REJECT_REASON_FORM_NAME, UPDATE_DOCUMENT_FORM_NAME };
