import PropTypes from 'prop-types';

import { styled } from '@mui/system';
import { Box, Pagination as MuiPagination } from '@mui/material';
import RowsPerPage from './RowsPerPage';

const propTypes = {
    count: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    isRowsPerPageVarient: PropTypes.bool,
    rowsPerPageProps: PropTypes.object,
    rowsPerPageOptions: PropTypes.array
};

const defaultProps = {
    variant: 'outlined',
    shape: 'rounded'
};

const StyledPagination = styled(MuiPagination)(({ theme: { spacing } }) => ({
    margin: spacing(1)
}));

const StyledBox = styled(Box)(({ theme: { spacing } }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: spacing(0,1)
}));

const Pagination = ({ rowsPerPageProps, isRowsPerPageVarient, rowsPerPageOptions, ...props }) => {
    if (isRowsPerPageVarient)
        return (
            <StyledBox>
                {rowsPerPageOptions && (
                    <RowsPerPage input={{ ...rowsPerPageProps }} data={[...rowsPerPageOptions]}/>
                )}
                {!rowsPerPageOptions && (
                    <RowsPerPage input={{ ...rowsPerPageProps }}/>
                )}
                <StyledPagination {...props} />
            </StyledBox>
        );
    return <StyledPagination {...props} />;
};

Pagination.propTypes = propTypes;
Pagination.defaultProps = defaultProps;

export { Pagination as default, Pagination };
