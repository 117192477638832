const DOMAIN_NAME = 'leaveCategory';
const LEAVE_CATEGORY_ROUTE_ID = 19;
const CREATE_LEAVE_CATEGORY_ROUTE_ID = 18;
const ROWS_PER_PAGE = 10;
const FORM_NAME = "leaveCategory";

const PRE_APPROVED = [
    { label: "Yes", value: true },
    { label: "No", value: false }
];
const IS_PAID_LEAVE = [
    { label: "Yes", value: true },
    { label: "No", value: false }
]

export { DOMAIN_NAME, LEAVE_CATEGORY_ROUTE_ID, ROWS_PER_PAGE, CREATE_LEAVE_CATEGORY_ROUTE_ID, FORM_NAME, PRE_APPROVED ,IS_PAID_LEAVE};
