import { takeLatest } from 'redux-saga/effects';

// Workers
import { GET_USER_DATA } from 'common/redux.actionTypes';
import getUserDataWorker from './getUserDataWorker';


// Watchers
export default function* () {
    yield takeLatest(GET_USER_DATA, getUserDataWorker);
}