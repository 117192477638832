import {useState, Fragment} from 'react';
import { CalenderCreateUpdate } from './CalenderCreateUpdate';
import ViewCalender from './ViewCalender';
import { DeleteCalender } from './DeleteCalender';

export default function Calender() {
    const [viewType, setViewType] = useState('default');
    const [data, setData] = useState({ mode: 'create', row: {} });

    return (
        <Fragment>
            {viewType === 'default' && (
                <ViewCalender setViewType={setViewType} setData={setData} />
            )}
            {viewType === 'createCalender' && (
                <CalenderCreateUpdate
                    mode={data.mode}
                    row={data.row}
                    setViewType={setViewType}
                />
            )}
            {viewType === 'deleteCalender' && (
                <DeleteCalender
                    row={data.row}
                    setViewType={setViewType}
                />
            )}
        </Fragment>
    );
}
