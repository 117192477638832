import ContentContainer from '@libComponents/ContentContainer';
import { Box, styled } from '@mui/material';
import { theme } from 'app/Theme';
import Attendance from './Attendance';
import UserInout from './UserInout';
import WeeklyActivities from './weeklyActivities';
import WeeklyTimes from './weeklyTimes';

const WeeklyContainerBox = styled(Box)(({ theme: { spacing } }) => ({
    display: 'grid',
    gap: 24,
    gridTemplateColumns: 'repeat(2, 1fr)',
    width: '100%',
    padding: spacing(2),

    '@media screen and (max-width: 768px)': {
        gridTemplateColumns: 'repeat(1, 1fr)',
        padding: spacing(0.5)
    }
}));

const UserTimesheetDashboard = ({ ...props }) => {
    return (
        <Box pt={4} pb={4}>
            <ContentContainer
                title='Time & Attendance'
                headerBg={theme.palette.background.mainHeader}
            >
                <Box sx={{ width: '100%', p: 2 }}>
                    <ContentContainer
                        title='Attendance'
                        AdornmentComponent={<UserInout />}
                    >
                        <Attendance />
                    </ContentContainer>
                </Box>

                <WeeklyContainerBox>
                    <ContentContainer title='Weekly activites'>
                        <WeeklyActivities />
                    </ContentContainer>

                    <ContentContainer title='Weekly Times'>
                        <WeeklyTimes />
                    </ContentContainer>
                </WeeklyContainerBox>
            </ContentContainer>
        </Box>
    );
};

export default UserTimesheetDashboard;
