import { useCallback, useEffect, useMemo, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import useDimensions from '@libHooks/useDimensions';
import useNavigation, { useNavigationState } from '../useNavigation';

export const useContentStyle = () => {
    const { drawerWidth, appBarHeight, isDrawerVisible, isDrawerMinified } =
        useNavigationState();

    return useMemo(() => {
        const contentPaddingLeft = isDrawerVisible
            ? isDrawerMinified
                ? appBarHeight
                : drawerWidth
            : 0;

        return {
            '--contentPaddingLeft': `${contentPaddingLeft}px`,
            '--contentPaddingTop': `${appBarHeight}px`,
        };
    }, [drawerWidth, appBarHeight, isDrawerVisible, isDrawerMinified]);
};

export const useFooterRef = () => {
    const [{ footerHeight }, dispatch] = useNavigation();

    const [footerRef, { height }] = useDimensions({
        height: footerHeight,
    });

    useEffect(() => {
        dispatch({
            type: 'setFooterDimensions',
            payload: height,
        });
    }, [height, dispatch]);

    return footerRef;
};

export const useRenderAll = (NotFoundPage) => {
    const { flatHashes } = useNavigationState();
    const checkRoutes = useCallback(
        (pathname) => {
            // First index is always empty
            const paths = pathname.split('/').slice(1);

            const path = `/${paths.join('/')}`;

            if (flatHashes.hasOwnProperty(path)) {
                return path;
            } else {
                if (paths.length === 1) return null;
                else {
                    // Take 1 off
                    const newPath = `/${paths.slice(1).join('/')}`;
                    return checkRoutes(newPath);
                }
            }
        },
        [flatHashes]
    );
    const { pathname } = useLocation();
    const path = checkRoutes(pathname);
    const timeSheetDashboardPath = '/time-and-attendance/dashboard';
    if (path) return <Navigate to={path} replace />;
    else if(!Object.keys(flatHashes).length) return null;
    // as for now there will be no dashboard route for user thus we redirect them to timesheet dashboard
    else if(pathname === '/' && checkRoutes(timeSheetDashboardPath)) return <Navigate to={timeSheetDashboardPath} replace />;
    else return <NotFoundPage />;
};

export const useTransitionLoading = (key, duration) => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let timeout = null;
        setLoading(true);
        timeout = setTimeout(() => setLoading(false), duration);

        return () => clearTimeout(timeout);
    }, [key]);

    return loading;
};
