import { styled } from '@mui/system';
import { Avatar } from '@mui/material';

const StyledAvatar = styled(Avatar)(({ theme }) => ({
    width: theme.spacing(4.5),
    height: theme.spacing(4.5),
    backgroundColor: theme.palette[800],
    color: theme.palette.primary.contrastText
}));

const StyledImageAvatar = styled(Avatar)(({ theme }) => ({
    width: theme.spacing(4.5),
    height: theme.spacing(4.5)
}));

const UserAvatar = ({ name, avatarUrl }) => {
    const getInitials = () => {
        if (!name) return '';

        const words = name.split(' ');

        if (words.length === 1) {
            return words[0].charAt(0).toUpperCase();
        } else {
            const firstInitial = words[0].charAt(0).toUpperCase();
            const lastInitial = words[words.length - 1].charAt(0).toUpperCase();
            return `${firstInitial}${lastInitial}`;
        }
    };

    return avatarUrl ? (
        <StyledImageAvatar src={avatarUrl} alt={`${name} Avatar`} />
    ) : (
        <StyledAvatar>{getInitials()}</StyledAvatar>
    );
};

export default UserAvatar;
