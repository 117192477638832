import { styled, TableCell } from '@mui/material';


const StyledTableCell = styled(TableCell)(
    ({ theme: { spacing, palette, typography, shape } }) => ({
        fontSize: typography.body2.fontSize,        
    })
);

const getNodeText = node => {
  if (['string', 'number'].includes(typeof node)) return node.toString().toLowerCase().replace(" ", "-")
  if (node instanceof Array) return node.map(getNodeText).join('')
  if (typeof node === 'object' && node) return getNodeText(node.props.children)
}

const TableCellContainer = ({ className, ...props }) => (   
    <StyledTableCell  data-testid = {getNodeText(props.children)} {...props}>{props.children}</StyledTableCell>
);

export { TableCellContainer as default, TableCellContainer };

