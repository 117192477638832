import calculateDuration from 'timeAndAttendance/components/calculateDuration';
import {
    TimeRangeBar,
    TimesheetDataCell,
    TimesheetDataRowWrapper,
    TimesheetsUser
} from '../../../components';

const Row = ({ date, email, name, isOdd, ...rest }) => {
    return (
        <TimesheetDataRowWrapper isOdd={isOdd}>
            <TimesheetsUser sx={{ gridColumn: 'span 3' }} name={name ? name: email } />
            <TimeRangeBar sx={{ gridColumn: 'span 8' }} {...rest} />

            <TimesheetDataCell
                sx={{ gridColumn: 'span 1', textAlign: 'center', opacity: 0.8 }}
            >
                {calculateDuration({...rest})}
            </TimesheetDataCell>
        </TimesheetDataRowWrapper>
    );
};

export default Row;
