import { apiRequest } from '@libs/apiRequest';
import { call, put } from 'redux-saga/effects';
import { setLoading } from 'utilities/redux.actions';

export default function* (action) {
    const {
        meta: { handleSubmit = () => { } },
        payload,
        ...rest
    } = action;
    yield put(setLoading(true));
    try {
        const { data: { name, preApproved, totalPerBusinessYear, minimumApprovalsRequired, isPaidLeave, eligibility, approvers } } = yield call(
            apiRequest,
            {
                url: `leave-category/get?name=${payload.name}`,
                method: 'get'
            }
        );

        yield put(setLoading(false));
        return handleSubmit({ name, preApproved, totalPerBusinessYear, minimumApprovalsRequired, isPaidLeave, eligibility, approvers });
    } catch (error) {
        yield put(setLoading(false));
        return handleSubmit(error);
    }
}
