const DOMAIN_NAME = 'user';
const USER_ROUTE_ID = 10;
const CREATE_USER_ROUTE_ID = 9;
const ROWS_PER_PAGE = 10;
const FORM_NAME = "create-upadate-user"

const IS_EMPLOYEE_OPTIONS = [
    { label: "Yes", value: true },
    { label: "No", value: false }
];

const IS_ACCOUNT_ACTIVE_OPTION = [
    { label: "Yes", value: true },
    { label: "No", value: false }
];

export { IS_EMPLOYEE_OPTIONS, DOMAIN_NAME, USER_ROUTE_ID, ROWS_PER_PAGE, CREATE_USER_ROUTE_ID, FORM_NAME, IS_ACCOUNT_ACTIVE_OPTION };

