import { DEPARTMENT } from 'common/redux.datasets';
import { commonSelector } from 'common/redux.selectors';
import { createSelector } from 'reselect';


const getAllDepartment = createSelector(
    commonSelector,
    data => {
        return data && data[DEPARTMENT] && data[DEPARTMENT].data.map((item) => {
            return {
                name: item.name,
                description: item.description,
                branch_name: item.branch?.name
            }
        })
    }
);

export { getAllDepartment };

