import { apiRequest } from "@libs/apiRequest";
import dayjs from "dayjs";
import { call, put } from "redux-saga/effects";
import { ALL_ATTENDANCE, LOADING_ATTENDANCE } from "timeAndAttendance/common";
import { setComponentLoading, setSnack } from "utilities/redux.actions";

export default function* (action) {
    const {
        meta: { handleSubmit = () => { } },
        payload
    } = action;

    try {
        yield put(setComponentLoading({
            entity: LOADING_ATTENDANCE,
            loading: true
        }));
        let startDate = payload.startDate ? payload.startDate : dayjs().subtract(1, "year").format("YYYY-MM-DD");
        let endDate = payload.endDate ? payload.endDate : dayjs().add(1, "day").format("YYYY-MM-DD");

        let pageSize = payload.pageSize ? payload.pageSize : 10;
        let pageNumber = payload.pageNumber ? payload.pageNumber : 1;

        let entity = ALL_ATTENDANCE;

        const { data } = yield call(
            apiRequest,
            {
                url: `attendance/my-attendance?from=${startDate}&to=${endDate}&pageSize=${pageSize}&pageNumber=${pageNumber}&sort-descending=true`,
                method: 'GET',
            }
        );

        yield put({
            type: "updateAttendance",
            payload: {
                entity: entity,
                value: data
            },
        });

        yield put(setComponentLoading({
            entity: LOADING_ATTENDANCE,
            loading: false
        }));

        return handleSubmit(data);

    } catch (error) {
        yield put(setComponentLoading({
            entity: LOADING_ATTENDANCE,
            loading: false
        }));
        yield put(setSnack({
            message: error?.response?.data?.message || error?.message,
            type: 'error',
        }));
        return handleSubmit(error);
    }
}

