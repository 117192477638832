import { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';

import { useTheme } from '@mui/material';

const useOneMonthTimesheet = (entries, yearMonth) => {
    const [timesheet, setTimesheet] = useState([]);

    useEffect(() => {
        const monthDate = dayjs(yearMonth);
        const startOfMonthDate = monthDate.startOf('month');
        const endOfMonthDate = monthDate.endOf('month');
        const allDays = [];
        let currentDate = startOfMonthDate;

        while (
            currentDate.isBefore(endOfMonthDate, 'day') ||
            currentDate.isSame(endOfMonthDate, 'day')
        ) {
            allDays.push(currentDate);
            currentDate = currentDate.add(1, 'day');
        }

        // Create the timesheet with formatted dates and durations
        const updatedTimesheet = allDays.map((day) => {
            const formattedDate = day.format('dddd DD MMM YYYY');

            const entry = entries.find(
                (e) => e?.date?.slice(0, 10) === day.format('YYYY-MM-DD')
            );
            return entry
                ? {
                      ...entry,
                      date: formattedDate,
                      duration: entry.duration
                  }
                : { date: formattedDate, duration: 0, presentType: 'absent' };
        });

        setTimesheet(updatedTimesheet);
    }, [entries, yearMonth]);

    return timesheet;
};

const usePresentTypeColor = (presentType, isBgColor = false) => {
    const { palette } = useTheme();

    const presentTypeWiseColor = useMemo(
        () => ({
            in: palette.success.main,
            arrivedLate: palette.error.main,
            leave: palette.warning.dark,
            holiday: palette.warning.light
        }),
        []
    );

    return (
        presentTypeWiseColor[presentType] ||
        (isBgColor ? palette.grey[300] : palette.grey[500])
    );
};

export { useOneMonthTimesheet, usePresentTypeColor };
