import { takeLatest, select } from 'redux-saga/effects';

import { history } from 'app';
import { routeSelector } from './redux.selectors';
import { REDIRECT_TO_ROUTE } from './redux.actionTypes';

// Workers
function* redirectToRouteWorker({ payload: { routeId } }) {
    const route = yield select(routeSelector, routeId);
    history.push(route);
}

// Watchers
export default function* () {
    yield takeLatest(REDIRECT_TO_ROUTE, redirectToRouteWorker);
}
