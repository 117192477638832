import { Fragment } from 'react';

import { styled, Stepper } from '@mui/material';

import stripPX from '@libs/stripPX';

import Step from './Step';
import Progress from './Progress';
import { useStepperState } from '../StepContext';
import { useStepPadding } from './hooks';

const Container = styled('div')({
    position: 'relative',
});

const StyledStepper = styled(Stepper)(({ theme: { spacing } }) => ({
    justifyContent: 'space-between',
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
    paddingLeft: `calc(var(--padding) - ${stripPX(spacing(3)) / 4}px)`,
    paddingRight: `calc(var(--padding) - ${stripPX(spacing(3)) / 4}px)`,
    marginBottom: spacing(4),
}));

const StepperBar = () => {
    const { activeStep, contents } = useStepperState();

    return (
        <Container>
            <StyledStepper
                activeStep={activeStep}
                connector={<Fragment />}
                style={useStepPadding()}
            >
                {contents.map((content, index) => (
                    <Step key={`stepper-${index}`} index={index} {...content} />
                ))}
            </StyledStepper>
            <Progress />
        </Container>
    );
};

export { StepperBar as default };
