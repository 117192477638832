const DOMAIN_NAME = 'timeAndAttendance';
const WEEKLY_ACTIVITIES = 'weeklyActivities';
const ALL_ATTENDANCE = 'allAttendance';
const ALL_ATTENDANCE_RANGE = 'allAttendanceRange';
const LOADING_ATTENDANCE = "utilities@loadingAttendanceSelector";
const LOADING_WEEKLY_ACTIVITIES = "utilities@loadingWeeklyActivities";

const USER_TIMESHEET_DASHBOARD = 7;
const ROWS_PER_PAGE = 5;
const FORM_NAME = "role";
const CREATE_ATTENDANCE_FORM = 'create-attendance'

const EMPLOYEE_STATUS = [
    { label: "In", value: 'in' },
    { label: "Out", value: 'out' }
];

const IS_JOINED_REMOTELY = [
    { label: "Yes", value: true },
    { label: "No", value: false }
];

export { DOMAIN_NAME, USER_TIMESHEET_DASHBOARD, ROWS_PER_PAGE, FORM_NAME, WEEKLY_ACTIVITIES, ALL_ATTENDANCE, ALL_ATTENDANCE_RANGE, LOADING_ATTENDANCE, LOADING_WEEKLY_ACTIVITIES, EMPLOYEE_STATUS, IS_JOINED_REMOTELY, CREATE_ATTENDANCE_FORM };

