import { takeLatest } from 'redux-saga/effects';

import { CREATE_HOLIDAY, DELETE_HOLIDAY, GET_HOLIDAY, UPDATE_HOLIDAY } from "holidays/redux.actionTypes";
import createHolidayWorker from "./holidaySagas/createHolidayWorker";
import deleteHolidayWorker from './holidaySagas/deleteHolidayWorker';
import getHolidayWorker from './holidaySagas/getHolidayWorker';
import updateHolidayWorker from './holidaySagas/updateHolidayWorker';


export default function* () {
    yield takeLatest(CREATE_HOLIDAY, createHolidayWorker);
    yield takeLatest(DELETE_HOLIDAY, deleteHolidayWorker);
    yield takeLatest(GET_HOLIDAY, getHolidayWorker);
    yield takeLatest(UPDATE_HOLIDAY, updateHolidayWorker);
}