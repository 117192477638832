import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';

import Stepper from '@libComponents/Stepper';
//import { Stepper } from '@formComponents/Stepper';
import { useLocation } from 'react-router';
import { FORM_NAME } from './common';
import EmployeeContainer from './EmployeeContainer';
import { useContent } from './hooks';
import { theme } from 'app/Theme';
import { ContentContainer } from '@libComponents/ContentContainer';

const propTypes = {
    mode: PropTypes.oneOf(['new', 'update']),
};
const defaultProps = {
    mode: 'new',
};

/**
 * ReduxForm stepper rules
 * * Connect each step with reduxForm() to the same form name.
 * * Specify the destroyOnUnmount: false flag to preserve form data across form component unmounts.
 */

const Employee = reduxForm({
    form: FORM_NAME, // Form name
    destroyOnUnmount: false, // Preserve data
    onSubmit: (v) => v,
})(({ mode, handleSubmit: handleReduxFormSubmit }) => {
    const { state } = useLocation();
    return (
        <ContentContainer title='Employee Information' headerBg={theme.palette.background.mainHeader}>
            <Stepper
                content={useContent(mode)}
                ContainerComponent={EmployeeContainer}
                ContainerProps={{ mode, handleReduxFormSubmit, ...state }}
            />
        </ContentContainer>
    );
});

Employee.propTypes = propTypes;
Employee.defaultProps = defaultProps;

export { Employee as default, Employee };
