import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { persistReducer } from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import createCompressor from 'redux-persist-transform-compress';

import reducers from './reducer';

const compressor = createCompressor();
const persistConfig = {
    key: 'root',
    storage: storage,
    transforms: [
        encryptTransform({
            secretKey: 'my-super-secret-key', // Todo: Change to server token (doesn't exist yet)
        }),
        compressor,
    ],
    whitelist: ['auth', 'utilities', 'navigation'],
    stateReconciler: autoMergeLevel2,
};

export default persistReducer(persistConfig, reducers);
