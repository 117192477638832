import ContentContainer from '@libComponents/ContentContainer';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { FORM_NAME } from './common';
// Libs
import { GridContainer, GridItem, Input } from '@formComponents';
import { Button } from '@libComponents';
import { styled } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';

import {
    initialize as formInitialize,
    reduxForm,
    destroy as destroyForm,
} from 'redux-form';
import { createCalender, updateCalender } from './redux.actions';
import { required } from '@libs/validationRules';
import { CancelOutlined } from '@mui/icons-material';
import { hasFormErrorSelector } from 'common/redux.selectors';

const propTypes = {
    mode: PropTypes.oneOf(['create', 'update']),
};
const defaultProps = {
    mode: 'create',
};


const ButtonWrapper = styled('div')({
    position: 'absolute',
    bottom: 0,
    right: '50%',
    transform: 'translate(50%, 50%)',
    zIndex: 4,
});

const StyledButton = styled(Button)(({ theme: { spacing, typography } }) => ({
    height: spacing(4.75),
    width: spacing(25),
    fontSize: typography.subtitle1.fontSize,
    marginLeft: spacing(0.75),
    marginRight: spacing(0.75),
}));


const CalenderCreateUpdate = reduxForm({
    form: FORM_NAME, // Form name
    destroyOnUnmount: false, // Preserve data
    onSubmit: (v) => v,
})(({ mode, row, setViewType, handleSubmit: handleReduxFormSubmit, ...rest }) => {
    const RXDispatch = useDispatch();

    useEffect(() => {
        RXDispatch(formInitialize(FORM_NAME, row));
        return () => {
            return RXDispatch(destroyForm(FORM_NAME));
        };
    }, [mode]);

    const handleSubmit = (data) => {
        setViewType("default");
    }
    const hasError = useSelector((state) =>
        hasFormErrorSelector(state, FORM_NAME)
    );

    const handleCreate = () => {
        if (hasError) {
            handleReduxFormSubmit();
            return;
        }
        if (mode === "create")
            RXDispatch(createCalender({ handleSubmit }));
        else if (mode === "update") {
            RXDispatch(updateCalender({ handleSubmit }));
        }
    }

    return (
        <ContentContainer
            key={`${mode}-calender`}
            title={`${mode} Calender`}
            sx={{ textTransform: 'capitalize', p: "0 !important", mb: 2 }}
            AdornmentComponent={
                <CancelOutlined onClick={() => {
                    setViewType("default");
                }}
                    sx={{ cursor: "pointer", width: 32, height: 32, mr: 1 }}
                />}
        >
            <GridContainer>
                <GridItem md={12} >
                    <Input
                        name='name'
                        placeholder='Name'
                        label='Name'
                        required={true}
                        validate={required}
                    />
                </GridItem>
                <GridItem md={12} >
                    <Input
                        name='description'
                        placeholder='Description'
                        label='Description'
                    />
                </GridItem>
            </GridContainer>

            <ButtonWrapper>
                <StyledButton onClick={handleCreate}>
                    {mode === "create" ? "CREATE" : "UPDATE"}
                </StyledButton>
            </ButtonWrapper>
        </ContentContainer>
    );
});

CalenderCreateUpdate.propTypes = propTypes;
CalenderCreateUpdate.defaultProps = defaultProps;

export { CalenderCreateUpdate as default, CalenderCreateUpdate };

