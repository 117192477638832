import React from 'react';
import InputBase from '@mui/material/InputBase';

import Label from '@baseComponents/Label';
import { withField } from '@libs/reduxForm';
import { Autocomplete, FormControl, styled } from '@mui/material';

import FieldProgressBar from '@baseComponents/FieldProgressBar';
import HelperText from '@baseComponents/HelperText';
import { ArrowDropDown, Settings } from '@mui/icons-material';
// import { districts } from './allDistrict';
import DialogWrapper from '@libComponents/Dialog';

const StyledInput = styled(InputBase)(
    ({ theme: { spacing, palette, typography, shape } }) => ({
        width: '100%',
        height: '35px',
        border: '1px solid',
        borderColor: palette.border.main,
        borderRadius: shape.borderRadius,
        paddingLeft: spacing(2),
        // paddingRight: spacing(2),
        fontSize: typography.subtitle2.fontSize,
        '&:hover': {
            borderColor: palette.border.dark,
        },
        '&.Mui-focused': {
            borderColor: palette.grey[500],
            '&:hover': {
                borderColor: palette.grey[700],
            },
        },
        '&.Mui-error': {
            borderColor: `${palette.error.light}`,
            '&:hover': {
                borderColor: `${palette.error.dark}`,
            },
        },
        '&.Mui-disabled': {
            display: 'flex',
            alignItems: 'center',
            backgroundColor: palette.background.disabled,
            color: palette.secondary.main,

            '&:hover': {
                borderColor: palette[300],
            },
        },
    })
);

const StyledBox = styled('div')(
    ({ theme: { spacing, palette, typography } }) => ({
        '& .MuiAutocomplete-option': {
            color: palette.text.primary,
            fontSize: '12px',
        },
    })
);

const StyledFormControl = styled(FormControl)(
    ({ theme: { spacing, palette, typography } }) => ({
        width: '100%',
        paddingBottom: spacing(1.25),
        ' label': {
            paddingTop: spacing(1.25),
            textTransform: 'initial',
        },
    })
);


const AutocompleteBase = withField()(
    ({
        fieldName,
        input,
        disabled,
        required,
        label,
        loading,
        staticValue,
        meta: { form, touched, error = null, asyncValidating },
        placeholder,
        options,
        ContainerComponent,
        ...restProps
    }) => {
        const id = `${form}-${fieldName}`;
        const isDisabled = disabled || loading;
        const hasError = !isDisabled && touched && !!error;
        const { onChange } = input;

        const inputBaseProps = {
            id,
            disabled: isDisabled,
            ...(staticValue ? { value: staticValue } : input),
            ...restProps,
        };

        const [open, setOpen] = React.useState(false);

        const handleDialogOpen = () => {
            setOpen(true);
        };
    
        const handleDialogClose = () => {
            setOpen(false);
        };

        return (
            <StyledFormControl>
                <Label
                    label={label}
                    disabled={isDisabled}
                    required={required}
                    error={hasError}
                    htmlFor={id}
                />
                <StyledBox>
                    <Autocomplete
                        freeSolo
                        clearOnBlur
                        disablePortal
                        options={options}
                        {...inputBaseProps}
                        onChange={(event, newValue) => {
                            onChange(newValue? newValue.label :"");
                        }}
                        onBlur={(e) => {}}
                        renderInput={(params) => (
                            <StyledInput
                                ref={params.InputProps.ref}
                                inputProps={params.inputProps}
                                placeholder={placeholder}
                                disabled = {isDisabled}
                                error={hasError}
                                endAdornment={
                                <> 
                                    <ArrowDropDown /> 
                                    {ContainerComponent && <Settings sx={{height:16, width:16, cursor:"pointer", marginRight: "10px"}} onClick={handleDialogOpen}/>}
                                </>
                                }
                            />
                        )}
                    />
                </StyledBox>
                {(loading || asyncValidating) && (
                    <FieldProgressBar asyncValidating={asyncValidating} />
                )}
                {hasError && <HelperText msg={error} required={required} />}

                {ContainerComponent && 
                <DialogWrapper open={open} onClose={handleDialogClose} direction="left">
                    <ContainerComponent/>
                </DialogWrapper>}
            </StyledFormControl>
        );
    }
);

export default AutocompleteBase;
