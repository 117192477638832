// Material
import CssBaseline from '@mui/material/CssBaseline';

// Libs
import Utilities from 'utilities';

// Local
import ErrorBoundary from './ErrorBoundary';
import Theme from './Theme';
import Router, { history } from './Router';
import ReduxGate from './ReduxGate';
import Auth from '../auth';

const App = () => (
    <ErrorBoundary>
        <Theme>
            <Router>
                <ReduxGate>
                    <CssBaseline />
                    <Utilities />
                    <Auth />
                </ReduxGate>
            </Router>
        </Theme>
    </ErrorBoundary>
);

export { App as default, history };
