import { Typography, Box } from '@mui/material';
import { styled } from '@mui/system';

const StyledBox = styled(Box)(({ theme }) => ({
    margin: `${theme.spacing(2)} ${theme.spacing(1)}`,
    textAlign: 'center'
}));

const TimesheetsNoDataFound = (props) => (
    <StyledBox {...props}>
        <Typography variant='body1' color='textSecondary'>
            No data found
        </Typography>
    </StyledBox>
);

export { TimesheetsNoDataFound as default, TimesheetsNoDataFound };
