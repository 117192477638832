import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TableRowsIcon from '@mui/icons-material/TableRows';
import { theme } from 'app/Theme';
import { CheckBox } from '@mui/icons-material';
import { Box, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { useState } from 'react';

export default function BasicMenu({ tableData = {}, setTableData }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [checked, setChecked] = useState(false);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (event) => {
        setTableData((prev) => {
            return {
                ...prev,
                [event.target.value]: {
                    ...prev[event.target.value],
                    isVisible: event.target.checked,                    
                }
            }
        });        
    };

    return (
        <div>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <TableRowsIcon sx={{ color: theme.palette.secondary.main, marginRight: 2, cursor: "pointer", rotate: "90deg" }} />
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}   
                             
            >
                <FormGroup sx={{pl:2}}>
                {
                    Object.entries(tableData).map(([key, value]) => {                    
                        return (
                            // <Box key={key} sx={{ display: "flex", alignItems: "center", gap: 2, paddingLeft: 2 }}>
                            //     <Checkbox id={key} onChange={handleChange} />
                            //     <MenuItem htmlFor={key} sx={{paddingLeft: "unset"}}>{value.label}</MenuItem>
                            // </Box>
                            <FormControlLabel key={key} control={<Checkbox disabled={value.disabledChange} checked={value.isVisible} value={key} name={value.label} onChange={handleChange} />} label={value.label} />
                            
                        )
                    })
                }
                </FormGroup>
            </Menu>
        </div>
    );
}