import ContentContainer from '@libComponents/ContentContainer';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { EMPLOYEE_STATUS, IS_JOINED_REMOTELY, CREATE_ATTENDANCE_FORM as FORM_NAME, } from '../common';
// Libs
import { GridContainer, GridItem } from '@formComponents';
import { Button } from '@libComponents';
import { required } from '@libs/validationRules';
import { styled } from '@mui/system';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    initialize as formInitialize,
    reduxForm
} from 'redux-form';
import { getAllEmployeeSelector, hasFormErrorSelector } from 'common/redux.selectors';
import { DOMAIN_NAME } from 'common/common';
import { BRANCH, GET_ALL_EMPLOYEE_NAME_EMAIL, SHIFT } from 'common/redux.datasets';
import Shift from 'common/components/shift';
import Branch from 'common/components/branch';
import AutocompleteFetch from '@formComponents/AutocompleteFetch';
import InputRadio from '@formComponents/InputRadio';
import DatePickerBase from '@formComponents/DatePicker';
import dayjs from 'dayjs';
import { createAttendance } from 'timeAndAttendance/redux.actions';

import { DOMAIN_NAME as COMMON_DOMAIN } from 'common/common';


const propTypes = {
    mode: PropTypes.oneOf(['create', 'update']),
};
const defaultProps = {
    mode: 'create',
};

/**
 * ReduxForm stepper rules
 * * Connect each step with reduxForm() to the same form name.
 * * Specify the destroyOnUnmount: false flag to preserve form data across form component unmounts.
 */
const ButtonWrapper = styled('div')({
    position: 'absolute',
    bottom: 0,
    right: '50%',
    transform: 'translate(50%, 50%)',
    zIndex: 4,
});

const StyledButton = styled(Button)(({ theme: { spacing, typography } }) => ({
    height: spacing(4.75),
    width: spacing(25),
    fontSize: typography.subtitle1.fontSize,
    marginLeft: spacing(0.75),
    marginRight: spacing(0.75),
}));


const CreateAttendance = reduxForm({
    form: FORM_NAME, // Form name
    destroyOnUnmount: false, // Preserve data
    onSubmit: (v) => v,
})(({ mode, handleSubmit: handleReduxFormSubmit, ...rest }) => {

    const RXDispatch = useDispatch();

    const hasError = useSelector((state) =>
        hasFormErrorSelector(state, FORM_NAME)
    );

    useEffect(() => {
        RXDispatch(formInitialize(FORM_NAME, {
            status: 'in',
            joinedRemotely: "false",
        }));
        return () => {
            RXDispatch(formInitialize(FORM_NAME, {}));
        }
    }, []);

    const handleCreate = () => {
        if (hasError) {
            handleReduxFormSubmit();
            return;
        }

        if (mode === "create")
            RXDispatch(createAttendance({ handleSubmit }));
    }

    const handleSubmit = ({ error }) => {
        if (!error) {
            RXDispatch(formInitialize(FORM_NAME, {
                status: 'in',
                joinedRemotely: "false",
            }));
        }
    }

    return (
        <ContentContainer
            title={mode + ' Attendance'}
            sx={{ textTransform: 'capitalize' }}
            AdornmentComponent={<Fragment />}
        >
            <GridContainer>
                <GridItem md={5}>
                    <AutocompleteFetch
                        name='employeeEmail'
                        placeholder='Employee Email'
                        label='Employee Email'
                        domain={COMMON_DOMAIN}
                        entity={GET_ALL_EMPLOYEE_NAME_EMAIL}
                        customselector={getAllEmployeeSelector}
                        required={true}
                        validate={required}
                    />
                </GridItem>

                <GridItem md={5}>
                    <AutocompleteFetch
                        name='branch'
                        placeholder='Branch'
                        label='Branch'
                        required={true}
                        validate={required}
                        entity={BRANCH}
                        domain={DOMAIN_NAME}
                        ContainerComponent={Branch}
                    />
                </GridItem>
                <GridItem md={5}>
                    <AutocompleteFetch
                        name='shiftType'
                        placeholder='Shift'
                        label='Shift'
                        domain={DOMAIN_NAME}
                        entity={SHIFT}
                        required={true}
                        validate={required}
                        ContainerComponent={Shift}
                    />
                </GridItem>

                <GridItem md={3}>
                    <DatePickerBase
                        name='createdAtDate'
                        placeholder='Entry Date'
                        label='Entry Date'
                        maxDate={dayjs()}
                        minDate={dayjs().subtract(1, 'year')}
                        required={true}
                        validate={required}
                    />
                </GridItem>
                <GridItem md={2}>
                    <DatePickerBase
                        name='createdAtTime'
                        placeholder='Entry Time'
                        label='Entry Time'
                        required={true}
                        validate={required}
                        mode="timepicker"
                    />
                </GridItem>
                <GridItem md={12}>
                    <InputRadio
                        name='status'
                        label='Status'
                        options={EMPLOYEE_STATUS}
                        required={true}
                        validate={required}
                    />
                </GridItem>
                <GridItem md={12}>
                    <InputRadio
                        name='joinedRemotely'
                        label='Joined Remotely?'
                        options={IS_JOINED_REMOTELY}
                        required={true}
                        validate={required}
                    />
                </GridItem>
            </GridContainer>

            <ButtonWrapper>
                <StyledButton onClick={handleCreate}>
                    {mode === "create" ? "CREATE" : "UPDATE"}
                </StyledButton>
            </ButtonWrapper>
        </ContentContainer>
    );
});

CreateAttendance.propTypes = propTypes;
CreateAttendance.defaultProps = defaultProps;

export { CreateAttendance as default, CreateAttendance };

