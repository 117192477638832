import { initializeDatasets } from '@libs/datasets';

import datasets from './redux.datasets';

const initialState = {
    ...initializeDatasets(datasets),
};

export default (state = initialState, { type, payload, ...action }) => {
    switch (type) {

        case "deleteHoliday": {
            const { entity, value } = payload;
            //initial data;
            const { data } = state[entity];
            let holidays = [...data];
            holidays = holidays.filter((item) => (item.id !== value.id));
            return { ...state, [entity]: { ...state[entity], data: holidays } };
        }
        default: {
            return state;
        }
    }
};
