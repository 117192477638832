import { apiRequest } from '@libs/apiRequest';
import { call, put } from 'redux-saga/effects';
import { setLoading, setSnack } from 'utilities/redux.actions';

export default function* (action) {
    const {
        meta: { handleSubmit = () => { } },
        payload,
        ...rest
    } = action;
    yield put(setLoading(true));
    try {
        const { data: { email, isEmployee, accountIsActive, ...rest } } = yield call(
            apiRequest,
            {
                url: `user/get/email?email=${payload.email}`,
                method: 'get'
            }
        );
        const rolesData = yield call(
            apiRequest,
            {
                url: `user/get/roles?email=${payload.email}`,
                method: 'get'
            }
        );

        yield put(setLoading(false));

        return handleSubmit({ isEmployee, email, roles: rolesData.data, accountIsActive });
    } catch (error) {
        yield put(setLoading(false));
        yield put(setSnack({
            message: error?.response?.data?.message || error?.message,
            type: 'error',
        }));

        return handleSubmit(error);
    }
}
