import dayjs from "dayjs";
import { theme } from "app/Theme"
import Typography from '@mui/material/Typography';
import { Avatar, Card, Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getFileURL } from "common/redux.actions";
function HeaderCard({ dataInfo, dataIdentificationInfo }) {

    const RXDispatch = useDispatch();
    const [profileUrl, setProfileUrl] = useState(null);

    useEffect(() => {
        const profileUrl = dataIdentificationInfo.find((info) => info.key === 'photograph')?.link
        if (profileUrl) {
            RXDispatch(getFileURL({
                handleSubmit, payload: { url: profileUrl }
            }));
        }
    }, []);

    const handleSubmit = (resp) => {
        if (resp.type === "success") {
            setProfileUrl(resp.url);
        }
    };
    return (
        <Card sx={{ mb: 4 }}>
            <Grid container spacing={1} sx={{ justifyContent: 'space-between', p: 4 }}>
                <Grid item xs={4}>
                    <Avatar src={profileUrl} sx={{ bgcolor: theme.palette.primary.main, width: 124, height: 124, fontSize: 72 }}>{dataInfo ? dataInfo.find((info) => info.key === 'firstname')?.value?.at(0) : ""}</Avatar>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h6" sx={{ fontWeight: "Bold" }}>{dataInfo.find((info) => info.key === 'initial')?.value} {dataInfo.find((info) => info.key === 'firstname')?.value} {dataInfo.find((info) => info.key === 'middlename')?.value} {dataInfo.find((info) => info.key === 'lastname')?.value}</Typography>
                    <Typography variant="subtitle1" sx={{ fontWeight: "Bold" }}>{dataInfo.find((info) => info.key === 'designation')?.value}</Typography>
                    <Typography variant="subtitle1" sx={{ fontWeight: "Bold" }}>{dataInfo.find((info) => info.key === 'employeeId')?.value}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="subtitle1" sx={{ fontWeight: "Bold" }}>Joining Date: {dayjs(dataInfo.find((info) => info.key === 'joiningDate')?.value).format("YYYY-MM-DD")}</Typography>
                    <Typography variant="subtitle1" sx={{ fontWeight: "Bold" }}>Department: {dataInfo.find((info) => info.key === 'department')?.value}</Typography>
                    <Typography variant="subtitle1" sx={{ fontWeight: "Bold" }}>Designation: {dataInfo.find((info) => info.key === 'designation')?.value}</Typography>
                </Grid>
            </Grid>
        </Card>
    )
}

export default HeaderCard
