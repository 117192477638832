import calculateDuration from 'timeAndAttendance/components/calculateDuration';
import {
    TimeRangeBar,
    TimesheetDataCell,
    TimesheetDataRowWrapper
} from '../../../components';


const Row = ({ date, ...rest }) => {

    return (
        <TimesheetDataRowWrapper>
            <TimeRangeBar sx={{ gridColumn: 'span 10' }} {...rest} />

            <TimesheetDataCell
                sx={{ gridColumn: 'span 2', textAlign: 'center', opacity: 0.8 }}
            >
                {calculateDuration({...rest})}
            </TimesheetDataCell>
        </TimesheetDataRowWrapper>
    );
};

export default Row;
