import { normalizeDate } from "@libs/dateTime"
import { Box, Typography, styled } from "@mui/material";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const StyledParent = styled(Box)(
    ({ theme: { spacing, palette, typography, shape } }) => ({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: spacing(1, 3, 1, 2),
        gap: spacing(2),
    }));

const StyledInfoParent = styled(Box)(
    ({ theme: { spacing, palette, typography, shape } }) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: spacing(.25),
    }));

const StyledText = styled(Typography)(
    ({ theme: { spacing, palette, typography, shape } }) => ({
        fontWeight: typography.fontWeightBold,
    })
);

const StyledLabel = styled(Typography)(
    ({ theme: { spacing, palette, typography, shape } }) => ({
        margin: 0,
        width: spacing(21.5),
        background: palette.background.secondary,
        textAlign: 'center',
        padding: spacing(.7, 0),
        borderRadius: shape.borderRadius,
    })
);

const StyledBox = styled(Box)(
    ({ theme: { spacing, palette, typography, shape } }) => ({
        fontFamily: typography.fontFamily,
        fontSize: '14px',
        lineHeight: '20px',
        textAlign: 'left',
        display: 'flex',
        gap: spacing(.75),
        alignItems: 'center'
    }));

function ListItem({ item }) {
    return (
        <StyledParent >
            <StyledInfoParent>
                <StyledText variant='p'>{item?.employee?.firstname + " " + (item?.employee?.middlename === null ? "" : item?.employee?.middlename) + " " + item?.employee?.lastname}</StyledText>
                <StyledBox >
                    <CalendarTodayIcon fontSize="14px" />
                    <span>{normalizeDate(item?.startDate)}</span>
                    <span> to </span>
                    <span>{normalizeDate(item?.endDate)}</span>
                    <span> . </span>
                    <span>{item?.totalNumberOfDays} Days</span>
                </StyledBox>
            </StyledInfoParent>
            <StyledLabel variant='body2'>{item?.categoryName}</StyledLabel>
        </StyledParent>
    )
}

export default ListItem
