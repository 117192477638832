import apiRequest from '@libs/apiRequest';
import { REJECT_REASON_FORM_NAME } from 'leaveManagement/common';
import { APPLIED_LEAVES } from 'leaveManagement/redux.datasets';
import { call, put, select } from 'redux-saga/effects';
import { setLoading, setSnack } from 'utilities/redux.actions';
import { getFormValues } from 'redux-form';
import { UPDATE_LEAVE_STATUS } from 'leaveManagement/redux.actionTypes';

export default function* (props) {
    const {
        meta: { handleSubmit = () => { } },
        payload: { action, row: { id, employeeEmail } }
    } = props;

    const allSelector = yield select(
        getFormValues(REJECT_REASON_FORM_NAME)
    );
    const payloadData = JSON.stringify(allSelector)

    const url = `leave/${action}?id=${id}&employeeEmail=${employeeEmail}`;
    let params = {
        url,
        method: "post",
    }
    if (action === 'reject') {
        params = {
            ...params,
            data: payloadData,
        }
    }
    try {
        const { data } = yield call(
            apiRequest,
            params
        );
        yield put(setLoading(false));
        yield put(setSnack({
            message: `Leave ${action} successfully`,
            type: 'success',
        }));
        yield put({
            type: UPDATE_LEAVE_STATUS,
            payload: {
                entity: APPLIED_LEAVES,
                value: data
            },
        })

        return handleSubmit(data);
    } catch (error) {
        yield put(setLoading(false));
        yield put(setSnack({
            message: error?.response?.data?.message || error?.message,
            type: 'error',
        }));
        return handleSubmit(error);
    }
}
