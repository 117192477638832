import { GridContainer, GridItem } from '@formComponents';
import { Button } from '@libComponents';
import { Typography } from '@mui/material';
import { GENDER_OPTIONS } from 'employee/common';
import { Field } from 'redux-form';
import { renderAutoComplete } from './renderFields/renderAutocomplete';
import { renderField } from './renderFields/renderInputField';

const renderChildrenOptions = (member, index, fields) => (
    <GridContainer key={index} sx={{ p: 1, pl: 0, pr: 0, m: 0, mt:1, mb:1, width: '100%' }}>
        <GridItem md={12} sx={{ pl: 0 }}>
            <Typography sx={{textTransform:"capitalize"}}>
                {fields.name} {index + 1}
            </Typography>
        </GridItem>

        <GridItem md={3} sx={{ pl: 0 }}>
            <Field
                name={`${member}.firstname`}
                type='text'
                component={renderField}
                label='Name'
            />
        </GridItem>
        <GridItem md={3}>
            <Field
                name={`${member}.gender`}
                type='dropdown'
                component={renderAutoComplete}
                label='Gender'
                placeholder='Select Gender'
                options={GENDER_OPTIONS}
            />
        </GridItem>

        <GridItem md={3} sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <Button
                variant='outlined'
                sx={{
                    textTransform: 'capitalize',
                    width: 'auto',
                    height: '35px',
                }}
                type='button'
                onClick={() => fields.remove(index)}
                color='error'
            >
                Remove {fields.name} {index + 1}
            </Button>
        </GridItem>
    </GridContainer>
);

export default renderChildrenOptions;
