import { getFormValues } from 'redux-form';
import apiRequest from '@libs/apiRequest';
import { FORM_NAME } from 'employee/ManageEmployee/common';
import { call, put, select } from 'redux-saga/effects';
import { setLoading, setSnack } from 'utilities/redux.actions';


const makeShiftPayload = ({ employeeEmail, ...restSelectors }) => {
    let shifts = [];
    let shiftmapper = {};

    Object.entries(restSelectors).forEach(([key, value], i) => {

        if (key.includes("shifts")) {
            let day = parseInt(key.replace("shifts", ""));
            if (shiftmapper[value]) {
                shiftmapper[value].push(day);
            } else {
                shiftmapper[value] = [day]
            }
        }
    });

    Object.entries(shiftmapper).forEach(([shiftName, days], i) => {
        shifts.push({
            shiftName, days
        })
    });

    return ({
        employeeEmail,
        shifts
    });
}

export default function* (action) {
    const {
        meta: { handleSubmit = () => { } },
    } = action;

    yield put(setLoading(true));
    const allSelector = yield select(
        getFormValues(FORM_NAME)
    );
    let shiftPayload = JSON.stringify(makeShiftPayload(allSelector)) ;

    try {
        const { data } = yield call(
            apiRequest,
            {
                url: "employee/manage",
                method: 'post',
                data: shiftPayload,
            }
        );
        yield put(setSnack({
            message: "User Shift Updated",
            type: 'success',
        }));
        
        yield put(setLoading(false));
        handleSubmit(data);
    } catch (error) {
        yield put(setLoading(false));
        yield put(setSnack({
            message: error?.response?.data?.message || error?.message,
            type: 'error',
        }));
    }
}

