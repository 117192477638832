import TableCellContainer from '@libComponents/table/TableCellContainer';
import TableContainer from '@libComponents/table/TableContainer';
import TableHeaderContainer from '@libComponents/table/TableHeaderContainer';
import { Box, TableBody, TableRow, Typography, styled } from '@mui/material';

import { convertToHoursAndMinuteFromSecond, formatTime } from "@libs/dateTime";
import { useAttendanceState } from "../useAttendanceProvider";
import { theme } from 'app/Theme';


const TABLE_DATA = {
    'name': 'Name',
    'designation': 'Designation',
    'branch': 'Branch',
    'inTime': "In Time",
    'outTime': 'Out Time',
    'duration': 'Total Hours',
    'status': 'Status'
};

const LEAVE_STATUS = {
    'in': {
        label: 'Present',
        color: 'db_overview_2'
    },
    'absent': {
        label: 'Absent',
        color: 'db_overview_3'
    },
    'arrivedLate': {
        label: 'Late',
        color: 'db_overview_4'
    },
}

const StyledLabel = styled(Typography)(
    ({ theme: { spacing, palette, typography, shape } }) => ({
        margin: 0,
        background: palette.background.secondary,
        maxWidth: spacing(12),
        textAlign: 'center',
        padding: spacing(.5, 2),
        borderRadius: shape.borderRadius,
    })
);

const StyledBox = styled(Box)(
    ({ theme: { palette, typography, shape } }) => ({
        width: '100%',
        overflow: 'auto',
        minHeight:300,
        '& table': {
            tableLayout: 'fixed'
        }
    })
);

const DataTable = (({ ...props }) => {
    const { data: attendanceData } = useAttendanceState();


    return (
        <StyledBox>
            <TableContainer >
                <TableHeaderContainer>
                    {Object.values(TABLE_DATA).map((th, i) => (
                        <TableCellContainer
                            sx={{ pl: 2, fontWeight: 'bold' }}
                            typography="caption" key={th}
                        >
                            {th}
                        </TableCellContainer>
                    ))}
                </TableHeaderContainer>
                <TableBody>
                    {attendanceData?.map((row, i) => (
                        <TableRow key={i}>
                            {Object.keys(TABLE_DATA).map((key, index) => {
                                switch (key) {
                                    case 'inTime':
                                        return (
                                            <TableCellContainer key={key}>
                                                {row[key] && formatTime(row[key])}
                                            </TableCellContainer>
                                        )
                                    case 'outTime':
                                        return (
                                            <TableCellContainer key={key}>
                                                {row[key] && formatTime(row[key])}
                                            </TableCellContainer>
                                        )
                                    case 'duration':
                                        return (
                                            <TableCellContainer key={key}>
                                                {row[key] && convertToHoursAndMinuteFromSecond(row[key])}
                                            </TableCellContainer>
                                        )
                                    case 'status':
                                        return (
                                            <TableCellContainer key={key}>
                                                {<StyledLabel
                                                    variant='body2'
                                                    sx={{ background: LEAVE_STATUS[row[key]] ? theme.palette[LEAVE_STATUS[row[key]].color].light : theme.palette.background.secondary, color: LEAVE_STATUS[row[key]] ? theme.palette[LEAVE_STATUS[row[key]].color].dark : theme.palette.text.main }}
                                                >{LEAVE_STATUS[row[key]]?.label || row[key]}</StyledLabel>}
                                            </TableCellContainer>
                                        )
                                    default:
                                        return (
                                            <TableCellContainer key={key}>
                                                {row[key]}
                                            </TableCellContainer>
                                        )
                                }
                            })}
                        </TableRow>
                    ))}
                </TableBody>
            </TableContainer>
        </StyledBox>
    )
});

export default DataTable
