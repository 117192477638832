import { Box, styled } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Bar, BarChart, ResponsiveContainer, Tooltip, XAxis
} from "recharts";
import { getWeeklyStatus } from '../redux.actions';
import { weeklyActivitiesSelector } from '../redux.selectors';
import MUICircularProgress from 'utilities/MaterialUILoader';
import { LOADING_WEEKLY_ACTIVITIES } from '../common';
import { usePresentTypeColor } from 'timeAndAttendance/hooks';

const StyledBox = styled(Box)(
    ({ theme: { } }) => ({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
    }));

const TooltipBox = styled(Box)(
    ({ theme: { palette, spacing } }) => ({
        background: palette.background.default,
        border: "2px solid",
        borderRadius: "5px",
        textAlign: "center",
        padding: spacing(0.5)
    }));

const StyledTooltipBox = styled(Box)(
    ({ theme: { palette, spacing } }) => ({
        fontWeight: "bold",
        textTransform: "capitalize"
    }));

const titleMapper = {
    "holiday": "Holiday",
    "in": "In",
    "arrivedLate": "Late",
}

const CustomTooltip = ({ active, payload }) => {
    const presentType = payload && payload[0] ? payload[0]?.payload?.presentType : "in";
    let color =  usePresentTypeColor(presentType);
    let title = titleMapper[presentType] ? titleMapper[presentType] : presentType;

    if (active && payload && payload.length) {
        return (
            <TooltipBox className="custom-tooltip">
                <Box className="label">{payload && payload[0]&& payload[0].payload.timeStrampHour}</Box>
                <StyledTooltipBox className="label" color={color}>{title}</StyledTooltipBox>
            </TooltipBox>
        );
    }

    return null;
};

const WeeklyTimes = (({ ...props }) => {
    const RXDispatch = useDispatch();
    useEffect(() => {
        RXDispatch(
            getWeeklyStatus({
                payload: {}
            })
        );
    }, []);

    const weeklyActivitiesData = useSelector((state) => weeklyActivitiesSelector(state));
    const { rows } = weeklyActivitiesData ? weeklyActivitiesData : [];

    return (
        <StyledBox >
            <ResponsiveContainer width="100%" height={200}>
                <BarChart
                    width="auto"
                    data={rows}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5
                    }}
                >
                    <Tooltip
                        content={<CustomTooltip />}
                        cursor={{ fill: "transparent" }}
                    />
                    <XAxis dataKey="timeStrampHour" xAxisId={0} axisLine={false} tickLine={false} />
                    <XAxis dataKey="name" xAxisId={1} axisLine={false} tickLine={false} />
                    <Bar dataKey="hour" fill="#546178" />

                </BarChart>
            </ResponsiveContainer>
            <MUICircularProgress entity={LOADING_WEEKLY_ACTIVITIES} />
        </StyledBox>
    );
});

export default WeeklyTimes;