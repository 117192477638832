import FieldProgressBar from '@baseComponents/FieldProgressBar';
import HelperText from '@baseComponents/HelperText';
import LabelBase from '@baseComponents/Label';
import { withField } from '@libs/reduxForm';
import { FormControl, FormLabel, Link, styled } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import { getFileURL } from 'common/redux.actions';
import { useDispatch } from 'react-redux';
import AttachmentIcon from '@mui/icons-material/Attachment';

const StyledInput = styled(InputBase)(
    ({ theme: { spacing, palette, typography, shape } }) => ({
        width: '100%',
        height: '35px',
        border: '1px solid',
        borderColor: palette.border.main,
        borderRadius: shape.borderRadius,
        paddingLeft: spacing(2),
        fontSize: typography.subtitle2.fontSize,
        visibility: 'hidden',
        opacity: 0,
        position: 'absolute',
        zIndex: -1,
        top: 0,
        left: 0
    })
);

const StyledBox = styled('div')(
    ({ theme: { palette, shape } }) => ({
        border: '1px solid',
        borderColor: palette.border.main,
        borderRadius: shape.borderRadius,
        position: 'relative',
        '& .MuiAutocomplete-option': {
            color: palette.text.primary,
            fontSize: '12px'
        },

        '&:hover': {
            borderColor: palette.border.dark
        },
        '&.Mui-focused': {
            borderColor: palette.grey[500],
            '&:hover': {
                borderColor: palette.grey[700]
            }
        }
    })
);
const StyledLabel = styled(FormLabel)(
    ({ theme: { spacing, palette, typography, shape } }) => ({
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontSize: typography.subtitle2.fontSize,
        color: palette.text.main,
        height: "35px",
        paddingLeft: spacing(2),
        cursor: "pointer"
    })
);

const StyledFormControl = styled(FormControl)({
    width: '100%'
});

const handleFileLabel = (value) => {
    if (value && typeof value === 'string') return value.split('__').pop();
    else if (value && value.fileName) return value.fileName;
    return 'Select File...';
};

const InputFile = withField()(
    ({
        fieldName,
        input,
        disabled,
        required,
        label,
        loading,
        meta: { form, touched, error = null, asyncValidating },
        placeholder
    }) => {
        const id = `${form}-${fieldName}`;
        const isDisabled = disabled || loading;
        const hasError = !isDisabled && touched && !!error;
        const { onChange } = input;

        const handleChange = (event) => {
            const file = event.target.files[0];
            const url = URL.createObjectURL(file);
            onChange({ url, fileName: file.name, file, type: 'file' });
        };
        const RXDispatch = useDispatch();

        const handleClick = async (e) => {
            e.preventDefault();
            RXDispatch(getFileURL({
                handleSubmit, payload: { url: e.target.href }
            }));
        };

        const handleSubmit = (resp) => {
            if (resp.type === "success") {
                window.open(resp.url, "_blank");
            }
        };

        return (
            <StyledFormControl>
                {label && (
                    <LabelBase
                        label={label}
                        disabled={isDisabled}
                        required={required}
                        error={hasError}
                    />
                )}
                <StyledBox>
                    <StyledLabel htmlFor={id}>
                        {handleFileLabel(input.value)}
                        <AttachmentIcon color='tertiary' fontSize='small' sx={{ transform: "rotate(90deg)" }} />
                    </StyledLabel>
                    <StyledInput
                        id={id}
                        name={input.name}
                        onChange={handleChange}
                        placeholder={placeholder}
                        error={hasError}
                        type='file'
                    />
                </StyledBox>
                {input.value && input.value.url && (
                    <Link target='_blank' href={input.value.url}>
                        Preview
                    </Link>
                )}
                {input.value && typeof input.value === 'string' && (
                    <Link target='_blank' href={input.value} onClick={handleClick}>
                        Preview
                    </Link>
                )}
                {(loading || asyncValidating) && (
                    <FieldProgressBar asyncValidating={asyncValidating} />
                )}
                {hasError && <HelperText msg={error} required={required} />}
            </StyledFormControl>
        );
    }
);

export default InputFile;
