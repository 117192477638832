import { CREATE_UPDATE_CATEGORY, DELETE_CATEGORY, GET_CATEGORY } from './redux.actionTypes';

export const createUpdateCategory = ({ handleSubmit, payload }) => ({
    type: CREATE_UPDATE_CATEGORY,
    meta: { handleSubmit },
    payload,
});

export const deleteCategory = ({ handleSubmit, payload }) => ({
    type: DELETE_CATEGORY,
    meta: { handleSubmit },
    payload,
});

export const getCategory = ({ handleSubmit, ...payload }) => ({
    type: GET_CATEGORY,
    meta: { handleSubmit },
    payload,
});