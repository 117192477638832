import PropTypes from 'prop-types';

// Material
import { styled, Box, CircularProgress } from '@mui/material';

const propTypes = {
    size: PropTypes.number,
};

const defaultProps = {
    size: 160,
};

const Wrapper = styled(Box)(({ theme: { palette } }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: palette.background.main,
    color: palette.primary.main,
}));

const FullLoader = ({ size }) => (
    <Wrapper>
        <CircularProgress size={size} color='inherit' />
    </Wrapper>
);

FullLoader.propTypes = propTypes;
FullLoader.defaultProps = defaultProps;

export { FullLoader as default, FullLoader };
