import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import { Box, styled } from '@mui/material';

const StyledVisibilityBox = styled(Box)(
    ({ theme: { spacing, palette, typography, shape } }) => ({
        transition: 'all .3s',
        position: 'absolute',
        top: '38px',
        right: '25px',
        cursor: 'pointer',
        color: '#ababab',
        '&:hover': {
            color: '#7c7c7c'
        }
    })
);

function PasswordVisibility(props) {
    const { showPassword, setShowPassword } = props;
    return (
        <div>
            {showPassword ? (
                <StyledVisibilityBox>
                    <VisibilityOffOutlined
                        onClick={() => setShowPassword(false)}
                        sx={{ fontSize: '18px' }}
                    />
                </StyledVisibilityBox>
            ) : (
                <StyledVisibilityBox>
                    <VisibilityOutlined
                        onClick={() => setShowPassword(true)}
                        sx={{ fontSize: '18px' }}
                    />
                </StyledVisibilityBox>
            )}
        </div>
    );
}

export default PasswordVisibility;
