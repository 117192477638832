import { CREATE_LEAVE, GET_REMAINING_LEAVES, HANDLE_LEAVE } from 'leaveManagement/redux.actionTypes';
import { takeLatest } from 'redux-saga/effects';
import handleLeaveWorker from './LeaveManagementSagas/handleLeaveWorker';
import createLeaveWorker from './LeaveManagementSagas/createLeaveWorker';
import getRemainingLeavesWorker from './LeaveManagementSagas/getRemainingLeavesWorker';

export default function* () {
    yield takeLatest(HANDLE_LEAVE, handleLeaveWorker);
    yield takeLatest(CREATE_LEAVE, createLeaveWorker);
    yield takeLatest(GET_REMAINING_LEAVES, getRemainingLeavesWorker);
}