import PropTypes from 'prop-types';

import { Box, Typography } from '@mui/material';

import { calculateSecondsToHoursAndMinutes, formatTime } from '@libs/dateTime';

const propTypes = {
    date: PropTypes.string.isRequired,
    presentType: PropTypes.string,
    duration: PropTypes.number
};

const PresentTypeMap = {
    in: 'Present',
    arrivedLate: 'Late',
    leave: 'Leave',
    holiday: 'Holiday',
    absent: 'Absent'
};

const TooltipContent = ({ date, duration, presentType, inTime, outTime }) => {
    const formatPresentType = (type) => PresentTypeMap[type] || 'Unknown';
    const formatDuration = (duration) =>
        `${calculateSecondsToHoursAndMinutes(duration)} h`;

    return (
        <Box>
            <Typography variant='body2'>{date}</Typography>
            <Typography variant='body2'>
                Present Type: {formatPresentType(presentType)}
            </Typography>
            {(inTime || outTime) && (
                <Typography variant='body2'>
                    In Time: {formatTime(inTime)} , Out Time:{' '}
                    {formatTime(outTime)}
                </Typography>
            )}

            <Typography variant='body2'>
                Total: {formatDuration(duration)}
            </Typography>
        </Box>
    );
};

TooltipContent.propTypes = propTypes;

export default TooltipContent;
