import { useLayoutEffect, useMemo } from 'react';
import useNavigation, { useNavigationState } from './useNavigation';
import { useExpanded } from './Drawer/Routes/hooks';

const flattenRoutes = (routes, parents = []) =>
    routes
        .map(({ children = [], name, route, Component }) => {
            const composedParents = [...parents, { route, name }];
            const childRoutes = children.map(({ name, route }) => ({
                route,
                name,
            }));
            return [
                { name, route, Component, parents, childRoutes },
                ...flattenRoutes(children, composedParents),
            ];
        })
        .flatMap((x) => x);

export const useFlattenedRoutes = (menus) => {
    const routes = useMemo(() => {
        const ungroupedRoutes = menus.flatMap(({ routes }) => routes);

        return flattenRoutes(ungroupedRoutes);
    }, [menus]);

    const hashes = useMemo(
        () =>
            routes.reduce(
                (acm, { route, ...rest }) => ({ ...acm, [route]: rest }),
                {}
            ),
        [routes]
    );

    return [routes, hashes];
};

export const useExpandedRoutes = (path) => {
    const { flatHashes } = useNavigationState();
    const expanded = useExpanded(path);

    return useMemo(() => {
        const { parents = [] } = flatHashes[path] || {};
        const parentRoutes = parents.map(({ route }) => route);
        const currentRoute = expanded ? [] : [path];
        return [...parentRoutes, ...currentRoute];
    }, [path, flatHashes, expanded]);
};

export const usePath = ({ pathname }) => {
    const [{ accordion }, dispatch] = useNavigation();
    const expandedRoutes = useExpandedRoutes(pathname);

    useLayoutEffect(() => {
        if (JSON.stringify(accordion) !== JSON.stringify(expandedRoutes)) {
            dispatch({ type: 'setAccordion', payload: expandedRoutes });
        }
        dispatch({ type: 'setActive', payload: pathname });
    }, [pathname]);
};

export const useIsMinified = () => {
    const { isDrawerMinified, isDrawerFloating } = useNavigationState();

    return useMemo(() => {
        return isDrawerMinified && !isDrawerFloating;
    }, [isDrawerMinified, isDrawerFloating]);
};
