import {useState, Fragment} from 'react';
import { ShiftCreateUpdate } from './ShiftCreateUpdate';
import ViewShift from './ViewShift';
import { DeleteShift } from './DeleteShift';

export default function Shift() {
    const [viewType, setViewType] = useState('default');
    const [data, setData] = useState({ mode: 'create', row: {} });

    return (
        <Fragment>
            {viewType === 'default' && (
                <ViewShift setViewType={setViewType} setData={setData} />
            )}
            {viewType === 'createShift' && (
                <ShiftCreateUpdate
                    mode={data.mode}
                    row={data.row}
                    setViewType={setViewType}
                />
            )}
            {viewType === 'deleteShift' && (
                <DeleteShift
                    row={data.row}
                    setViewType={setViewType}
                />
            )}
        </Fragment>
    );
}
