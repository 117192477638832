import apiRequest from '@libs/apiRequest';
import { call, put } from 'redux-saga/effects';
import { ROLES } from 'roles/redux.datasets';
import { setLoading, setSnack } from 'utilities/redux.actions';

export default function* (action) {
    const {
        meta: { handleSubmit = () => { } },
        payload,
        ...rest
    } = action;

    yield put(setLoading(true));

    try {
        const { data } = yield call(
            apiRequest,
            {
                url: `role/delete?name=${payload.name}`,
                method: 'get'
            }
        );
        yield put(setLoading(false));
        yield put(setSnack({
            message: "Role deleted successfully",
            type: 'success',
        }));

        yield put({
            type: "deleteRole",
            payload: {
                entity: ROLES,
                value: data
            },
        })
        return handleSubmit(data);
    } catch (error) {
        yield put(setLoading(false));
        yield put(setSnack({
            message: error?.response?.data?.message || error?.message,
            type: 'error',
        }));
        return handleSubmit(error);
    }
}
