import makeContext from '@libs/makeContext';
import { getCurrentStartAndEndOfWeek, modifyWeek } from '@libs/dateTime';

import {
    SET_EMAIL,
    SET_PAGE_NUMBER,
    SET_WEEK,
    TIMESHEET_NEXT,
    TIMESHEET_PREV,
    SET_FILTERS,
    SET_PAGE_SIZE
} from '../../context.actionTypes';
import { ADMIN_TIMESHEET_INTITAL_API_PARAMS } from '../common';

const initialState = {
    currentPage: 1,
    data: [],
    totalPages: 0,
    email: '',
    isNextDisabled: true,
    pageSize: 10,
    filters: ADMIN_TIMESHEET_INTITAL_API_PARAMS.filters,
    ...getCurrentStartAndEndOfWeek()
};

const reducer = (state, { type, payload }) => {
    switch (type) {
        case SET_EMAIL: {
            return {
                ...state,
                email: payload
            };
        }
        case SET_WEEK: {
            const currentWeek = getCurrentStartAndEndOfWeek();
            return {
                ...state,
                ...payload,
                // Set isNextDisabled based on whether the selected week is the same as the current week
                isNextDisabled: currentWeek.startOfWeek === payload.startOfWeek
            };
        }
        case SET_FILTERS: {
            return {
                ...state,
                filters: payload
            };
        }
        case SET_PAGE_SIZE: {
            return {
                ...state,
                pageSize: payload
            };
        }
        default:
            return state;
    }
};

const middleware = (state, action, { fetchData }) => {
    switch (action.type) {
        case SET_PAGE_NUMBER: {
            const { email, startOfWeek, endOfWeek, filters, pageSize } = state;
            const { payload } = action;
            fetchData({
                email,
                startOfWeek,
                endOfWeek,
                filters,
                pageSize,
                pageNumber: payload
            });

            return action;
        }

        case SET_PAGE_SIZE: {
            const { email, startOfWeek, endOfWeek, filters } = state;
            const { payload } = action;
            fetchData({
                email,
                startOfWeek,
                endOfWeek,
                filters,
                pageNumber: 1,
                pageSize: payload
            });

            return action;
        }
        case SET_EMAIL: {
            const { startOfWeek, endOfWeek, filters, pageSize } = state;
            const { payload } = action;

            fetchData({
                email: payload,
                startOfWeek,
                endOfWeek,
                filters,
                pageSize,
                pageNumber: 1
            });

            return action;
        }
        case SET_WEEK: {
            const { payload } = action;
            // TODO: extract start and end of week from payload
            const startOfWeek = 'payload start of week';
            const endOfWeek = 'payload end of week';
            fetchData({
                ...state,
                startOfWeek,
                endOfWeek
            });

            return { ...action, payload: { startOfWeek, endOfWeek } };
        }
        case TIMESHEET_NEXT: {
            const { email, startOfWeek, endOfWeek, filters, pageSize } = state;

            const newWeek = modifyWeek({ startOfWeek, endOfWeek }, true);

            fetchData({
                email,
                filters,
                pageSize,
                ...newWeek,
                pageNumber: 1
            });

            return {
                type: SET_WEEK,
                payload: newWeek
            };
        }
        case TIMESHEET_PREV: {
            const { email, startOfWeek, endOfWeek, filters, pageSize } = state;

            const prevWeek = modifyWeek({ startOfWeek, endOfWeek }, false);

            fetchData({
                email,
                filters,
                pageSize,
                ...prevWeek,
                pageNumber: 1
            });

            return {
                type: SET_WEEK,
                payload: prevWeek
            };
        }
        case SET_FILTERS: {
            const { email, startOfWeek, endOfWeek, filters, pageSize } = state;
            const { filterType, value } = action.payload;

            const newFilters = {
                ...filters,
                [filterType]: value || null
            };

            fetchData({
                email,
                startOfWeek,
                endOfWeek,
                pageNumber: 1,
                pageSize,
                filters: newFilters
            });

            return { ...action, payload: { ...newFilters } };
        }
        default:
            return action;
    }
};

const [
    WeeklyTimesheetsProvider,
    useWeeklyTimesheetsState,
    useWeeklyTimesheetsDispatch,
    useWeeklyTimesheets
] = makeContext(reducer, initialState, {
    name: 'WeeklyTimesheets',
    middleware
});

export {
    useWeeklyTimesheets as default,
    WeeklyTimesheetsProvider,
    useWeeklyTimesheetsState,
    useWeeklyTimesheetsDispatch,
    SET_EMAIL,
    SET_PAGE_NUMBER,
    SET_WEEK,
    TIMESHEET_NEXT,
    TIMESHEET_PREV,
    SET_FILTERS,
    SET_PAGE_SIZE
};
