import apiRequest from '@libs/apiRequest';
import { call, put } from 'redux-saga/effects';
import { setLoading, setSnack } from 'utilities/redux.actions';

export default function* (props) {
    const {
        meta: { handleSubmit = () => { } },
        payload: { action, row: { id, employeeEmail } }
    } = props;

    const url = `leave/${action}?id=${id}&employeeEmail=${employeeEmail}`;
    try {
        const leave_data = yield call(
            apiRequest,
            {
                url,
                method: "get"
            }
        );
        yield put(setLoading(false));
        yield put(setSnack({
            message: `Leave ${action} successfully`,
            type: 'success',
        }));

        return handleSubmit(leave_data);
    } catch (error) {
        yield put(setLoading(false));
        yield put(setSnack({
            message: error?.response?.data?.message || error?.message,
            type: 'error',
        }));
        return handleSubmit(error);
    }
}
