import { getEmployeeData } from "profile/redux.actions";
import { getAcademicInformation, getContactInformation, getFamilyInformation, getGeneralInformation, getIdentificationInformation, getOtherInformation } from "profile/utils/getStructureData";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

export default function useUserData() {
    const dispatch = useDispatch();
    const [data, setData] = useState({});
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");
    const email = id;

    useEffect(() => {
        dispatch(getEmployeeData({ handleSubmit, payload: { email } }));
    }, [email]);

    const handleSubmit = (resp) => {
        setData({ ...resp.data });
    };

    if (!data.id) return {};

    return {
        dataGeneralInfo: getGeneralInformation(data),
        dataContactInfo: getContactInformation(data),
        dataFamilyInfo: getFamilyInformation(data),
        dataIdentificationInfo: getIdentificationInformation(data),
        dataAcademicInfo: getAcademicInformation(data),
        dataOtherInfo: getOtherInformation(data),
    };
}