import { CREATE_CALENDER, DELETE_CALENDER, UPDATE_CALENDER } from './redux.actionTypes';

export const createCalender = ({ handleSubmit, payload }) => ({
    type: CREATE_CALENDER,
    meta: { handleSubmit },
    payload,
});

export const deleteCalender = ({ handleSubmit, payload }) => ({
    type: DELETE_CALENDER,
    meta: { handleSubmit },
    payload,
});

export const updateCalender = ({ handleSubmit, ...payload }) => ({
    type: UPDATE_CALENDER,
    meta: { handleSubmit },
    payload,
});