// Material
import { styled, Grid } from '@mui/material';

const StyledGridItem = styled(Grid)(({ theme: { spacing } }) => ({
    paddingLeft: spacing(2),
    paddingRight: spacing(2),
}));

const GridItem = (props) => <StyledGridItem item {...props} />;

export { GridItem as default, GridItem };
