import { useSelector } from 'react-redux';
import { Box, styled, Typography } from '@mui/material';
import MUICircularProgress from 'utilities/MaterialUILoader';
import { weeklyActivitiesSelector } from '../redux.selectors';
import { LOADING_WEEKLY_ACTIVITIES } from '../common';

const StyledBox = styled(Box)(
    ({ theme: { spacing } }) => ({
        padding: spacing(5),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
        width: "100%"
    }));


const WeeklyActivities = (({ }) => {
    const hours = useSelector((state) => weeklyActivitiesSelector(state));
    const { totalHours = "" } = hours ? hours : {}
    return (
        <StyledBox>
            <Typography
                sx={{ mr: 2 }}
                variant={"body1"} display="inline">
                Total Time:
            </Typography>
            <Typography variant={"h3"} display="inline">
                {totalHours}
            </Typography>
            <MUICircularProgress entity={LOADING_WEEKLY_ACTIVITIES} />
        </StyledBox>
    );
});

export default WeeklyActivities;