export function parseJwt(token) {
    try {
        if (token) {
            const [, payload] = token.split('.');

            const base64Payload = payload.replace(/-/g, '+').replace(/_/g, '/');
            const { email, ...restPayload } = JSON.parse(
                decodeURIComponent(atob(base64Payload))
            );
            return { email };
        }
    } catch (err) {
        console.log(`JWT Parsing error: ${err}`);
    }
}

export function parseTokenExpireTime(token) {
    try {
        if (token) {
            const [, payload] = token.split('.');
            const base64Payload = payload.replace(/-/g, '+').replace(/_/g, '/');
            const { exp } = JSON.parse(decodeURIComponent(atob(base64Payload)));
            // convert unix_timestamp and get token expire time in second
            const tokenExpireTime = Math.floor(
                (new Date(exp * 1000).getTime() - new Date().getTime()) / 1000
            );
            return tokenExpireTime;
        }
    } catch (err) {
        console.log(`unix_timestamp error: ${err}`);
    }
}

export function parseTokenExpireFullTime(token) {
    try {
        if (token) {
            const [, payload] = token.split('.');
            const base64Payload = payload.replace(/-/g, '+').replace(/_/g, '/');
            const { exp } = JSON.parse(decodeURIComponent(atob(base64Payload)));
            return exp * 1000;
        }
    } catch (err) {
        console.log(`unix_timestamp error: ${err}`);
    }
}