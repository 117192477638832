import FormArray from '@formComponents/FormArray';
import React from 'react';
import renderNominationOptions from './NominationFields';

const NomineInformation = ({ ...restProps }) => {
    return (
        <React.Fragment>
            <FormArray name="nominees" label="Add Nominee" renderSubFields={renderNominationOptions}/>
        </React.Fragment>
    );
};

export default NomineInformation;
