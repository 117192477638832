import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useReducer
} from 'react';
import PropTypes from 'prop-types';

const componentType = PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.object
]);

const propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(componentType),
        componentType
    ]),
    initialize: PropTypes.func,
    initialAction: PropTypes.object,
    middlewareProps: PropTypes.object
};

const defaultProps = {
    initialize: (state) => state,
    initialAction: {},
    middlewareProps: {}
};

const makeContext = (
    initialReducer,
    initialState,
    { name = null, middleware = null }
) => {
    const StateContext = createContext({});
    const DispatchContext = createContext((action) => action);

    const useStateContext = () => {
        const state = useContext(StateContext);
        if (state === void 0) {
            const hookName = name ? 'useStateContext' : `use${name}State`;
            const providerName = name ? 'its Provider' : `a ${name}Provider`;
            throw new Error(`${hookName} must be used within ${providerName}`);
        }
        return state;
    };

    const useDispatchContext = () => {
        const dispatch = useContext(DispatchContext);
        if (dispatch === void 0) {
            const hookName = name ? 'useStateContext' : `use${name}Dispatch`;
            const providerName = name ? 'its Provider' : `a ${name}Provider`;
            throw new Error(`${hookName} must be used within ${providerName}`);
        }
        return dispatch;
    };

    const useFullContext = () => [useStateContext(), useDispatchContext()];

    const INITIALIZE = '@@initialize-reducer';

    const useInitializedReducer = (initialize) =>
        useCallback(
            (state, action) => {
                switch (action.type) {
                    case INITIALIZE: {
                        return initialize(state);
                    }
                    default:
                        return initialReducer(state, action);
                }
            },
            [initialize]
        );

    const useMiddleware = (reducer, middlewareProps) =>
        useCallback(
            (state, action) => {
                const middlewareAction = middleware
                    ? middleware(state, action, middlewareProps)
                    : action;
                return reducer(state, middlewareAction);
            },
            [reducer, middlewareProps]
        );

    const Provider = ({
        children,
        initialize,
        initialAction,
        middlewareProps
    }) => {
        const initializedReducer = useInitializedReducer(initialize);
        const reducer = useMiddleware(initializedReducer, middlewareProps);

        const [state, dispatch] = useReducer(reducer, initialState, initialize);

        useEffect(() => {
            dispatch({ type: INITIALIZE });
        }, [initialize]);

        useEffect(() => {
            dispatch(initialAction);
        }, [initialAction]);

        return (
            <StateContext.Provider value={state}>
                <DispatchContext.Provider value={dispatch}>
                    {children}
                </DispatchContext.Provider>
            </StateContext.Provider>
        );
    };
    Provider.propTypes = propTypes;
    Provider.defaultProps = defaultProps;

    return [Provider, useStateContext, useDispatchContext, useFullContext];
};

const makeSideEffectCallback =
    (callback) =>
    (...args) =>
        setTimeout(() => {
            callback.apply(null, args);
        }, 0);

export { makeContext as default, makeSideEffectCallback };
