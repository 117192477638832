import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TableCellContainer from '@libComponents/table/TableCellContainer';
import TableContainer from '@libComponents/table/TableContainer';
import TableHeaderContainer from '@libComponents/table/TableHeaderContainer';
import { Box, styled, TableBody, TableRow, Typography } from '@mui/material';

import MUICircularProgress from 'utilities/MaterialUILoader';
import { getUserAttendanceData } from '../redux.actions';
import { LOADING_ATTENDANCE, ROWS_PER_PAGE } from "../common";
import { getALLAttendance, getTotalRows } from '../redux.selectors';
import { Pagination } from '@libComponents';
import { theme } from 'app/Theme';


const StyledBox = styled(Box)(({ theme: { } }) => ({
    width: '100%',
    overflow: 'auto',
    '& table': {
        tableLayout: 'fixed',
        width: '100%',
        borderCollapse: 'collapse',
        lineHeight: '2.5rem',

        tr: {
            display: 'grid',
            gridTemplateColumns:
                'minmax(100px, 2fr) repeat(auto-fit, minmax(50px, 1fr))',
            padding: '0.5rem 0.5rem 0 1.5rem'
        },
        th: { textAlign: 'left', borderBottom: '1px solid #eee' },
        'th, td': { padding: '0.5rem' },

        '@media screen and (max-width: 768px)': {
            'td, tr': { display: 'block' },
            'thead': { position: 'absolute', bottom: '100vh', display: 'none' },
            'tr': {
                marginBottom: '1rem',
                border: '1px solid #eee'
            },
            'td:before': {
                content: 'attr(data-heading)',
                position: 'absolute',
                left: '0',
                width: '50%',
                fontWeight: 'bold'
            },
            td: { position: 'relative', paddingLeft: '50%' }
        }
    }
}));

const StyledLabel = styled(Typography)(
    ({ theme: { spacing, palette, typography, shape } }) => ({
        margin: 0,
        background: palette.background.secondary,
        maxWidth: spacing(12),
        textAlign: 'center',
        padding: spacing(.5, 2),
        borderRadius: shape.borderRadius,
    })
);

const Attendance = (({ ...props }) => {
    const RXDispatch = useDispatch();
    const tableRows = useSelector((state) => getALLAttendance(state));
    const totalRows = useSelector((state) => getTotalRows(state));
    const [pageSize, setPageSize] = React.useState(ROWS_PER_PAGE);
    const [currentPage, setCurrentPage] = React.useState(1);

    useEffect(() => {
        RXDispatch(
            getUserAttendanceData({
                payload: {
                    pageSize: ROWS_PER_PAGE,
                    pageNumber: 1,
                }
            })
        );
    }, []);


    const TABLE_DATA = {
        "date": "Day & Date",
        "inTime": "In Time",
        "outTime": "Out Time",
        "duration": "Duration",
        "status": "Status"
    }

    const LEAVE_STATUS = {
        'in': {
            label: 'Present',
            color: theme.palette.db_overview_2
        },
        'absent': {
            label: 'Absent',
            color: theme.palette.db_overview_3
        },
        'arrivedLate': {
            label: 'Late',
            color: theme.palette.db_overview_4
        },
    }

    const statusHandler = (status) => {
        return LEAVE_STATUS[status] ? LEAVE_STATUS[status] : { label: 'status', color: theme.palette.background }
    }

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
        RXDispatch(
            getUserAttendanceData({
                payload: {
                    pageSize: pageSize,
                    pageNumber: newPage,
                }
            })
        );
    };

    const handleRowsPerPageChange = (pageSize) => {
        setPageSize(pageSize);
        setCurrentPage(1);
        RXDispatch(
            getUserAttendanceData({
                payload: {
                    pageSize: pageSize,
                    pageNumber: 1,
                }
            })
        );
    }


    return (
        <StyledBox>
            <TableContainer >
                <TableHeaderContainer>
                    {Object.values(TABLE_DATA).map((th, i) => (
                        <TableCellContainer
                            sx={{ pl: i === 0 ? 4 : 2, fontWeight: 'bold' }}
                            typography="caption" key={th}
                        >
                            {th}
                        </TableCellContainer>
                    ))}
                </TableHeaderContainer>
                <TableBody>
                    {tableRows.map((row, i) => (
                        <TableRow key={i}>
                            {Object.keys(TABLE_DATA).map((key, index) => {
                                let columnData = row[key];
                                switch (key) {
                                    case "status":
                                        return (<TableCellContainer key={key} data-heading={key}>
                                            {<StyledLabel
                                                variant='body2'
                                                sx={{ background: statusHandler(columnData).color.light, color: statusHandler(columnData).color.dark }}
                                            >{statusHandler(columnData).label}</StyledLabel>}

                                        </TableCellContainer>);

                                    default:
                                        return (
                                            <TableCellContainer
                                                key={key}
                                                sx={{
                                                    pl: key === 'date' ? 4 : 2
                                                }}
                                                data-heading={key}
                                            >
                                                {columnData ? columnData : "---"}
                                            </TableCellContainer>
                                        );
                                }
                            })}
                        </TableRow>
                    ))}
                </TableBody>
            </TableContainer>
            <Box sx={{ display: "flex", justifyContent: "center", ml:2 }}>

                <Pagination
                    count={Math.ceil(totalRows / pageSize)}
                    page={currentPage}
                    onChange={handleChangePage}
                    sx={{ display: 'flex', justifyContent: 'center' }}
                    isRowsPerPageVarient={true}
                    rowsPerPageProps={{
                        name: 'attendance',
                        onChange: handleRowsPerPageChange,
                        value: pageSize
                    }}
                />
            </Box>
            <MUICircularProgress entity={LOADING_ATTENDANCE} />
        </StyledBox>
    );
});

export default Attendance;