import apiRequest from '@libs/apiRequest';
import { getFormValues } from 'redux-form';
import { call, put, select } from 'redux-saga/effects';


import { setLoading, setSnack } from 'utilities/redux.actions';

import { FORM_NAME } from '../../../Employee/common';
import { getAllCalender } from 'common/components/calender/redux.selectors';

function makeNomimeePayload(nominees) {
    if (!nominees || nominees.lenght < 1)
        return nominees;
    let updatedList = nominees.map(nominee => {
        const { firstname, email, address, relation, occupation, contact, id, ...rest } = nominee;
        nominee = { ...rest }
        nominee.information = {
            firstname,
            email,
            relation,
            occupation,
            contact,
            lastname: firstname ? " " : null,
            address: {
                address,
                thana: address ? " " : null,
                district: address ? " " : null,
                city: address ? " " : null,
                country: address ? " " : null,
                phoneNumber: address ? " " : null
            }
        }
        return nominee
    });
    return updatedList;
}


function makeInsurancePayload(insurances) {
    if (!insurances || insurances.lenght < 1)
        return insurances;

    return insurances.map(insurance => {
        const { firstname, occupation, relation, id, ...rest } = insurance;
        insurance = { ...rest }
        insurance.information = {
            firstname,
            occupation,
            relation,
            lastname: firstname ? " " : null,
        }
        return insurance;
    });
}

function makeArray(string) {
    if (typeof string === 'string')
        return string.split(",")
    return string
}

function makeWorkExperiencePayload(insurances) {
    if (!insurances || insurances.lenght < 1)
        return insurances;

    insurances.forEach(insurance => {
        insurance.skills = makeArray(insurance.skills);
        insurance.contributions = makeArray(insurance.contributions);
        insurance.achievements = makeArray(insurance.achievements);
    });
    return insurances;
}

function makePayload(obj) {
    const { initial,
        firstname,
        lastname,
        nameInBengali,
        middlename,
        gender,
        bloodGroup,
        dateOfBirth,
        placeOfBirth,
        religion,
        maritalStatus,
        fatherNameInEng,
        fatherNameInBn,
        fatherOccupation,
        motherOccupation,
        motherNameInBn,
        motherNameInEng,
        presentAddress,
        presentThana,
        presentDistrict,
        presentMobileNo,
        permanentAddress,
        permanentMobileNo,
        thana,
        district,
        country,
        presentCountry,
        personalMobileNo,
        personalEmail,
        officialEmail,
        emergencyName,
        emergencyMobileNo,
        emergencyAddress,
        emergencyRelationWithEmployee,
        marriageDate,
        spouseName,
        spouseLevelOfEducation,
        spouseOccupation,
        children,

        relatedEmployees,
        passportNumber,
        passportIssuePlace,
        passportIssueDate,
        passportExpiryDate,
        tin,
        nationality,
        nidNumber,
        drivingLicenseNumber,
        photograph,
        signature,
        nid,
        etin,
        qualifications,
        workExperiences,
        // otherQualification,
        // otherDepartment,
        // otherInstituteName,
        // otherInstitute,

        bankName,
        branch,
        acNo,
        nominees,

        references,

        insurances,

        shift,
        reportingManager,
        department,
        officeBranch,
        designation,
        employeeCategory,
        joiningDate,
        employeeId,
        isMarried,
        calenderId
    } = obj;

    let payload = {
        initial,
        firstname,
        middlename,
        lastname,
        nameInBengali,
        gender,
        religion,
        bloodGroup,
        placeOfBirth,
        dateOfBirth,
        maritalStatus,
        employeeId,
        user: { email: officialEmail },
        father: {
            firstname: fatherNameInEng,
            nameInBengali: fatherNameInBn,
            occupation: fatherOccupation,
        },
        mother: {
            firstname: motherNameInEng,
            nameInBengali: motherNameInBn,
            occupation: motherOccupation,
        },
        contact: {
            presentAddress: {
                address: presentAddress,
                thana: presentThana,
                district: presentDistrict,
                //city is required in backend it will be same as district for now and default 
                city: presentDistrict,
                country: presentCountry,
                phoneNumber: presentMobileNo,
            },
            permanentAddress: {
                address: permanentAddress,
                thana,
                district,
                //city is required in backend it will be same as district for now and default
                city: district,
                country: country,
                phoneNumber: permanentMobileNo,
            },
            personalEmail: personalEmail ? personalEmail : "",
            officialEmail,
            mobileNumber: personalMobileNo ? personalMobileNo : "",
            emergencyContact: {
                firstname: emergencyName,
                contact: emergencyMobileNo,
                address: {
                    address: emergencyAddress,
                    // set blank thana, district, city, country when user give any address 
                    thana: emergencyAddress ? " " : null,
                    district: emergencyAddress ? " " : null,
                    city: emergencyAddress ? " " : null,
                    country: emergencyAddress ? " " : null,
                    phoneNumber: emergencyAddress ? emergencyMobileNo : null
                },
                relation: emergencyRelationWithEmployee
            }
        },
        family: {
            spouse: {
                firstname: spouseName,
                occupation: spouseOccupation,
                education: {
                    institutions: [{
                        qualification: spouseLevelOfEducation,
                        // need to pass empty data if spouse label of education is entered
                        name: spouseLevelOfEducation ? " " : null,
                        department: spouseLevelOfEducation ? " " : null,
                        result: spouseLevelOfEducation ? " " : null,
                        board: spouseLevelOfEducation ? " " : null,
                    }]
                }
            },
            marriageDate,
            children,
            isMarried
        },
        identification: {
            passport: {
                number: passportNumber,
                issuePlace: passportIssuePlace,
                issueDate: passportIssueDate,
                expiryDate: passportExpiryDate,
            },
            tin,
            nationality,
            nidNumber,
            drivingLicenseNumber,

            photograph,
            etin,
            signature,
            nid
        },
        academic: {
            institutions: qualifications,
        },
        bank: {
            name: bankName,
            branch,
            accountNumber: acNo,
        },
        employeeCategory: {
            name: employeeCategory
        },
        joiningDate,
        shift: {
            name: shift
        },
        reportingManager,
        department: {
            name: department
        },
        branch: {
            name: officeBranch
        },
        relatedEmployees,
        designation: { name: designation },
        nominees: nominees ? makeNomimeePayload(nominees) : [],
        references: references ? references : [],
        insurances: insurances ? makeInsurancePayload(insurances) : [],
        workExperiences: workExperiences ? makeWorkExperiencePayload(workExperiences) : [],
        calenderId
    }

    return payload;
}

function FormData_append_object(fd, obj, key) {
    var i, k;
    for (i in obj) {
        k = key ? key + '[' + i + ']' : i;
        if (obj[i] === undefined || obj[i] === null)
            continue;
        if (typeof obj[i] == 'object') {
            if (obj[i].type === "file") {
                fd.append(k, obj[i].file);
            } else {
                FormData_append_object(fd, obj[i], k);
            }
        }
        else
            fd.append(k, obj[i]);
    }
}

function getCalenderId(calenders, name) {
    return calenders.filter(item => item.name === name)[0]?.id || name;
}

export default function* (action) {
    const {
        meta: { handleComplete = () => { } },
        payload
    } = action;

    yield put(setLoading(true));

    const selectors = yield select(
        getFormValues(FORM_NAME)
    );
    const allSelector = { ...selectors };

    const calenders = yield select(getAllCalender);
    const clid = getCalenderId(calenders, allSelector?.calenderId);
    allSelector.calenderId = clid;

    let formData = new FormData();
    FormData_append_object(formData, makePayload(allSelector));

    const { mode } = payload;
    let url = 'employee/create';
    let method = 'post';
    if (mode === 'update') {
        url = `employee/update?email=${allSelector.officialEmail}`;
        method = 'put';
    }

    try {
        const { data } = yield call(
            apiRequest,
            {
                url: url,
                method: method,
                data: formData,
            }
        );

        yield put(setLoading(false));
        return handleComplete(data);
    } catch (error) {
        yield put(setLoading(false));
        yield put(
            setSnack({
                message: error?.response?.data?.message || error?.message,
                type: 'error',
            })
        );
        handleComplete({ error: true });
    }

}





