import apiRequest from '@libs/apiRequest';
import { REJECT_REASON_FORM_NAME } from 'leaveManagement/common';
import { call, put, select } from 'redux-saga/effects';
import { setLoading, setSnack } from 'utilities/redux.actions';
import { getFormValues } from 'redux-form';
import { EMPLOYEE_APPLIED_LEAVES } from 'leaveAndHolidays/redux.datasets';
import { EMPLOYEE_UPDATE_LEAVE_STATUS } from 'leaveAndHolidays/redux.actionTypes';

export default function* (props) {
    const {
        meta: { handleSubmit = () => { } },
        payload: { row: { id } }
    } = props;

    const allSelector = yield select(
        getFormValues(REJECT_REASON_FORM_NAME)
    );

    const payloadData = JSON.stringify(allSelector)
    const url = `leave/cancel/my?id=${id}`;

    try {
        const { data } = yield call(
            apiRequest, {
            url,
            method: "post",
            data: payloadData
        });

        yield put(setLoading(false));

        yield put(setSnack({
            message: 'Leave cancel successfully',
            type: 'success',
        }));

        yield put({
            type: EMPLOYEE_UPDATE_LEAVE_STATUS,
            payload: {
                entity: EMPLOYEE_APPLIED_LEAVES,
                value: data
            },
        })

        return handleSubmit(data);

    } catch (error) {
        yield put(setLoading(false));

        yield put(setSnack({
            message: error?.response?.data?.message || error?.message,
            type: 'error',
        }));

        return handleSubmit(error);
    }
}
