import { GridContainer, GridItem } from '@formComponents';
import { Button } from '@libComponents';
import { Typography } from '@mui/material';
import { Field } from 'redux-form';
import { renderField } from './renderFields/renderInputField';


const renderEmployeeOptions = (member, index, fields) => (
    <GridContainer key={index} sx={{ p: 1, pl: 0, m: 0 }}>
        <GridItem md={12} sx={{ pl: 0 }}>
            <Typography>
                {fields.name} {index + 1}
            </Typography>
        </GridItem>

        <GridItem md={3} sx={{ pl: 0 }}>
            <Field
                name={`${member}.employeeId`}
                type='text'
                component={renderField}
                label='Employee ID'
            />
        </GridItem>
        <GridItem md={3}>
            <Field
                name={`${member}.relationship`}
                type='text'
                component={renderField}
                label='Relation with Employee'
            />
        </GridItem>


        <GridItem md={6} sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <Button
                variant='outlined'
                sx={{
                    textTransform: 'capitalize',
                    width: 'auto',
                    height: '35px',
                }}
                type='button'
                onClick={() => fields.remove(index)}
                color='error'
            >
                Remove {fields.name} {index + 1}
            </Button>
        </GridItem>
    </GridContainer>
);

export default renderEmployeeOptions;
