import { Fragment } from 'react';
import { Route, Routes } from 'react-router-dom';

import { Fade, styled, useTheme } from '@mui/material';

import {
    functionalComponentPropType, renderedComponentPropType
} from '@libs/propTypes';

import Employee from 'employee/Employee';

import CreateRole from 'roles/Roles';
import { User } from 'user/User';
import { useNavigationState } from '../useNavigation';
import {
    useContentStyle, useFooterRef, useRenderAll
} from './hooks';
import { LeaveCategoryCreateUpdate } from 'leaveCategory/LeaveCategory';
// import LeaveDetails from 'leaveManagement/LeaveManagement/LeaveDetails';
import LeaveDetails from 'common/components/leaveDetails';
import Holiday from 'holidays/holidays';
import ManageEmployee from 'employee/ManageEmployee';
import Profile from 'profile';


const propTypes = {
    FooterComponent: renderedComponentPropType,
    NotFoundPageComponent: functionalComponentPropType,
    EmptyRoutePage: functionalComponentPropType,
};

const defaultProps = {
    FooterComponent: Fragment,
    NotFoundPage: () => <Fragment />,
    EmptyRoutePage: () => <div />,
};

const Main = styled('main')(({ theme: { palette, spacing, transitions } }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflow: 'hidden',

    minHeight: '100vh',
    paddingLeft: 'var(--contentPaddingLeft)',
    paddingTop: `calc(var(--contentPaddingTop) + ${spacing(4)})`,

    backgroundColor: palette.background.main,

    transition: transitions.create('padding', {
        easing: transitions.easing.sharp,
        duration: transitions.duration.complex,
    }),
}));
const StyledContent = styled('div')(({ theme: { spacing } }) => ({
    paddingLeft: spacing(4),
    paddingRight: spacing(4),
    '@media screen and (max-width: 768px)': {
    paddingLeft: spacing(1),
    paddingRight: spacing(1),
    }
}));

const Content = ({ FooterComponent, NotFoundPage, EmptyRoutePage }) => {
    const { transitions } = useTheme();
    const { flatRoutes } = useNavigationState();

    return (
        <Main style={useContentStyle()}>
            <Fade
                in={true}
                timeout={{
                    enter: transitions.duration.enteringScreen,
                    exit: transitions.duration.leavingScreen,
                }}
            >
                <StyledContent>
                    <Routes>
                        {flatRoutes.map(
                            ({
                                Component = EmptyRoutePage,
                                route,
                                name,
                                parents,
                                childRoutes,
                            }) => (
                                <Route
                                    key={route}
                                    exact
                                    path={route}
                                    element={
                                        <Component
                                            name={name}
                                            parents={parents}
                                            childRoutes={childRoutes}
                                        />
                                    }
                                />
                            )
                        )}

                        <Route path='/employee-list/*' element={<Employee mode="update"/>} />
                        <Route path='/manage/*' element={<ManageEmployee mode="update"/>} />
                        <Route path='/user-list/update/*' element={<User mode="update"/>} />
                        <Route path='/holiday-list/update/*' element={<Holiday mode="update"/>} />
                        <Route path='/role-list/update/*' element={<CreateRole mode="update"/>} />
                        <Route path='/leave-category/update/*' element={<LeaveCategoryCreateUpdate mode="update"/>} />
                        <Route path='/leave-details/*' element={<LeaveDetails/>} />
                        <Route path='/my-profile/*' element={<Profile/>} />
                        <Route path='/profile/*' element={<Profile/>} />
                        <Route path='*' element={useRenderAll(NotFoundPage)} />
                    </Routes>
                </StyledContent>
            </Fade>
            <FooterComponent ref={useFooterRef()} />
        </Main>
    );
};

Content.propTypes = propTypes;
Content.defaultProps = defaultProps;

export default Content;
