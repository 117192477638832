import { CREATE_LEAVE, GET_REMAINING_LEAVES, HANDLE_LEAVE } from './redux.actionTypes';

export const handleLeave = ({ handleSubmit, payload }) => ({
    type: HANDLE_LEAVE,
    meta: { handleSubmit },
    payload,
});

export const createLeave = ({ handleSubmit, payload }) => ({
    type: CREATE_LEAVE,
    meta: { handleSubmit },
    payload,
});

export const getRemainingLeaves = ({ handleSubmit, payload }) => ({
    type: GET_REMAINING_LEAVES,
    meta: { handleSubmit },
    payload,
});