import makeContext from '@libs/makeContext';

import { SET_YEAR_MONTH } from '../../context.actionTypes';

const initialState = {
    currentPage: 1,
    entries: [],
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1
};

const reducer = (state, { type, payload }) => {
    switch (type) {
        case SET_YEAR_MONTH: {
            return {
                ...state,
                date: payload,
                year: new Date(payload).getFullYear(),
                month: new Date(payload).getMonth() + 1
            };
        }
        default:
            return state;
    }
};

const middleware = (state, action, { fetchData }) => {
    switch (action.type) {
        case SET_YEAR_MONTH: {
            const { payload } = action;

            fetchData({
                year: new Date(payload).getFullYear(),
                month: new Date(payload).getMonth() + 1
            });

            return action;
        }
        default:
            return action;
    }
};

const [
    MonthlyTimesheetsProvider,
    useMonthlyTimesheetsState,
    useMonthlyTimesheetsDispatch,
    useMonthlyTimesheets
] = makeContext(reducer, initialState, {
    name: 'MonthlyTimesheets',
    middleware
});

export {
    useMonthlyTimesheets as default,
    MonthlyTimesheetsProvider,
    useMonthlyTimesheetsState,
    useMonthlyTimesheetsDispatch,
    SET_YEAR_MONTH
};
