import { SelectBase } from '@baseComponents/Select';
import { Box, Typography } from '@mui/material';
import { theme } from 'app/Theme';

const rows = [
    { label: '5', value: 5 },
    { label: '10', value: 10 },
    { label: '20', value: 20 },
    { label: '50', value: 50 }
];

const defaultProps = {
    fieldName: 'rowsPerPage',
    meta: { form: 'pagination' },
    data: rows
};

const RowsPerPage = (props) => (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, pt: 1, pl: 1 }}>
        <Typography variant='body2' sx={{ whiteSpace: 'nowrap', pb: 1 }}>
            Rows Per Page
        </Typography>
        <SelectBase
            sx={{ maxWidth: 60, background: theme.palette.background.default }}
            {...props}
        />
    </Box>
);

RowsPerPage.defaultProps = defaultProps;

export { RowsPerPage as default, RowsPerPage };
