import PropTypes from 'prop-types';

// Material
import { useTheme } from '@mui/material';

// Local
import InputBase from '@baseComponents/Input';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    styles: PropTypes.object,
    staticValue: PropTypes.string,
};

const defaultProps = {
    styles: {},
};

const Input = ({
    styles: { control, input, ...restStyles },
    placeholder,
    ...restProps
}) => {
    const { spacing } = useTheme();
    const props = {
        placeholder: placeholder || restProps.label || '',
        styles: {
            control: { paddingBottom: spacing(1.25),  textTransform: 'initial', ...control },
            input: { height: spacing(4.375), ...input },
            ...restStyles,
        },
        ...restProps,
    };
    return <InputBase {...props} />;
};

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

export { Input as default, Input };
