import common from 'common/redux.datasets';
import employee from 'employee/redux.datasets';
import navigation from 'navigation/redux.datasets.js';
import roles from 'roles/redux.datasets.js';
import user from 'user/redux.datasets.js';
import timeAndAttendance from 'timeAndAttendance/redux.datasets.js';
import leaveCategory from 'leaveCategory/redux.datasets.js';
import leaveManagement from 'leaveManagement/redux.datasets.js';
import employeeLeaveManagement from 'leaveAndHolidays/redux.datasets.js';
import employeeLeaveManagementForUser from 'common/components/leaveDetails/redux.datasets.js';
import holiday from 'holidays/redux.datasets.js';
import dashboard from 'dashboard/redux.datasets.js';

export default {
    navigation,
    timeAndAttendance,
    employee,
    common,
    user,
    roles,
    leaveCategory,
    leaveManagement,
    employeeLeaveManagement,
    employeeLeaveManagementForUser,
    holiday,
    dashboard,
};
