import { styled, Drawer as MaterialDrawer, IconButton } from '@mui/material';

import {
    renderedComponentPropType,
    functionalComponentPropType,
} from '@libs/propTypes';
import useNavigation from '../useNavigation';
import ToggleButton from './ToggleButton';
import Routes from './Routes';
import { useLogoRef, useDrawerStyle, useDrawerHover } from './hooks';
import { useCallback } from 'react';
import CloseIcon from '@mui/icons-material/Close';

const propTypes = {
    FullLogoComponent: renderedComponentPropType.isRequired,
    CompactLogoComponent: functionalComponentPropType.isRequired,
};

const StyledDrawerPaper = styled(MaterialDrawer)(
    ({ theme: { transitions } }) => ({
        '& .MuiDrawer-paper': {
            width: 'var(--drawerWidth)',
            overflow: 'hidden',
            transition: transitions.create('width', {
                easing: transitions.easing.sharp,
                duration: transitions.duration.shorter,
            }),
        },
    })
);
const Container = styled('div')(({ theme }) => ({
    display: 'flex',
    height: 'calc(100% - var(--logoHeight))',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.secondary.main,
}));
const LogoWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 'var(--logoHeight)',
});

const Drawer = ({ FullLogoComponent, CompactLogoComponent }) => {
    const [{ isDrawerVisible, isDrawerMinified, isDrawerFloating, routes },dispatch] =  useNavigation();
    const logoRef = useLogoRef();
    const [handleMouseEnter, handleMouseLeave] = useDrawerHover();

    const handleMenu = useCallback(
        () =>
            dispatch({
                type: 'setDrawerVisibility',
                payload: !isDrawerVisible,
            }),
        [dispatch, isDrawerVisible]
    );

    return (
        <StyledDrawerPaper
            variant='permanent'
            style={useDrawerStyle()}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <LogoWrapper>
                {isDrawerMinified && !isDrawerFloating ? (
                    <CompactLogoComponent />
                ) : (
                    <FullLogoComponent ref={logoRef} />
                )}
            </LogoWrapper>
            <Container>
                <IconButton sx={{position: "absolute", right: 0, color:"#ffffff"}} onClick={handleMenu}>
                    <CloseIcon />
                </IconButton>
                {routes.map((menuGroup, index) => {
                    if (!menuGroup.groupName) return null;
                    return (
                        <Routes key={`menu-group-${index}`} {...menuGroup} />
                    )
                })}
                <ToggleButton />
            </Container>
        </StyledDrawerPaper>
    );
};

Drawer.propTypes = propTypes;

export default Drawer;
