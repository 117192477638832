import { select, put, takeLatest, call } from 'redux-saga/effects';
import { formValueSelector } from 'redux-form';

// Libs
import { setSnack } from 'utilities/redux.actions';
import { AUTH_TOKEN_KEY, AUTH_TOKEN_TIME, FORGET_PASSWORD_FORM_NAME, REFRESH_TOKEN_KEY, RESET_PASSWORD_FORM_NAME } from './common';
import { setCookie, eraseAllCookies } from '@libs/cookies';

// Local
import { FORGET_PASSWORD, LOGIN, LOGOUT, REQUEST_LOGIN, RESET_PASSWORD } from './redux.actionTypes';
import { setAuthFormLoading, setAuthData } from './redux.actions';
import { LOGIN_FORM_NAME } from './common';
import { parseJwt, parseTokenExpireFullTime, parseTokenExpireTime } from '@libs/parseJWT';
import apiRequest from '@libs/apiRequest';

// Workers
function* requestLoginWorker(action) {
    const {
        meta: { handleSubmit = () => { } },
        ...rest
    } = action;

    const { email, password } = yield select(
        formValueSelector(LOGIN_FORM_NAME),
        'email',
        'password'
    );
    try {
        yield put(setAuthFormLoading(true));
        const { data: { message } = {} } = yield call(
            apiRequest,
            {
                url: 'auth/request-login',
                method: 'post',
                data: JSON.stringify({ email, password }),
            }
        );
        yield put(
            setSnack({
                message,
                type: 'success',
            })
        );
        yield put(setAuthFormLoading(false));
        return handleSubmit();
    } catch (error) {
        yield put(setAuthFormLoading(false));
        yield put(
            setSnack({
                message: error?.response?.data?.message || error?.message,
                type: 'error',
            })
        );
    }
}

function* loginWorker() {
    const { email, password, otp } = yield select(
        formValueSelector(LOGIN_FORM_NAME),
        'email',
        'password',
        'otp'
    );
    try {
        yield put(setAuthFormLoading(true));
        const { data: { authToken, refreshToken } = {} } = yield call(
            apiRequest,
            {
                url: 'auth/login',
                method: 'post',
                data: JSON.stringify({ email, password, otp }),
            }
        );
        const parsedAuthData = parseJwt(authToken);
        yield setCookie(AUTH_TOKEN_KEY, authToken, parseTokenExpireTime(authToken));
        yield setCookie(AUTH_TOKEN_TIME, JSON.stringify({ exp: parseTokenExpireFullTime(authToken)}));
        yield setCookie(REFRESH_TOKEN_KEY, refreshToken, parseTokenExpireTime(refreshToken));
        yield put(setAuthData(parsedAuthData));
        yield put(setAuthFormLoading(false));
    } catch (error) {
        yield put(setAuthFormLoading(false));
        yield put(
            setSnack({
                message: error?.response?.data?.message || error?.message,
                type: 'error',
            })
        );
    }
}
function* logoutWorker() {
    yield localStorage.removeItem('persist:root');
    eraseAllCookies();
}

function* forgetPasswordWorker(action){
    const {
        meta: { navigateToLogin = () => { } },
        ...rest
    } = action;

    const  email  = yield select(
        formValueSelector(FORGET_PASSWORD_FORM_NAME),
        'email'
    );
    try {
        yield put(setAuthFormLoading(true));
        const { data } = yield call(
            apiRequest,
            {
                url: `user/forgot-password?email=${email}`,
                method: 'get'
            }
        );
        yield put(setAuthFormLoading(false));
        if (data) {
            yield put(
                setSnack({
                    message: "A link was sent to your email",
                    type: 'success',
                })
            );
        }
        setTimeout(() => {
            return navigateToLogin();
        }, 1000);
    } catch (error) {
        yield put(setAuthFormLoading(false));
        yield put(
            setSnack({
                message: error?.response?.data?.message || error?.message,
                type: 'error',
            })
        );
    }
}

function* resetPasswordWorker(action) {
    const {
        meta: { navigateToLogin = () => { } },
        ...rest
    } = action;

    const { newPassword, confirmPassword } = yield select(
        formValueSelector(RESET_PASSWORD_FORM_NAME),
        "newPassword",
        "confirmPassword"
    );

    const token = window.location.pathname.split("/").pop();

    try {
        yield put(setAuthFormLoading(true));
        const { data } = yield call(
            apiRequest,
            {
                url: 'user/reset-password/',
                method: 'post',
                data: JSON.stringify({ newPassword, confirmPassword , token }),
            }
        );
        yield put(setAuthFormLoading(false));

        if(data){
            yield put(
                setSnack({
                    message: "Successfully updated",
                    type: 'success',
                })
            );
            return navigateToLogin();
        }else{
            yield put(
                setSnack({
                    message: "New and Confirm Password Mismatched",
                    type: 'error',
                })
            );
        }
    } catch (error) {
        yield put(setAuthFormLoading(false));
        yield put(
            setSnack({
                message: error?.response?.data?.message || error?.message,
                type: 'error',
            })
        );
    }
}
// Watchers
export default function* () {
    yield takeLatest(LOGIN, loginWorker);
    yield takeLatest(REQUEST_LOGIN, requestLoginWorker);
    yield takeLatest(LOGOUT, logoutWorker);
    yield takeLatest(FORGET_PASSWORD, forgetPasswordWorker);
    yield takeLatest(RESET_PASSWORD, resetPasswordWorker);
}
