import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { makeSideEffectCallback } from '@libs/makeContext';
import { renderedComponentPropType } from '@libs/propTypes';

import { StepperProvider } from './StepContext';
import StepperContainer from './StepperContainer';

const propTypes = {
    content: PropTypes.objectOf(renderedComponentPropType).isRequired,
    ContainerComponent: renderedComponentPropType,
    ContainerProps: PropTypes.object,
    initialState: PropTypes.object,
    storeState: PropTypes.func,
};

const defaultProps = {
    ContainerComponent: Fragment,
    ContainerProps: {},
    initialState: {},
    storeState: () => {},
};

const Stepper = ({
    content,
    ContainerComponent,
    ContainerProps,
    initialState,
    storeState,
}) => {
    const [action, dispatch] = useState({});

    useEffect(() => {
        dispatch({ type: 'initialize', payload: initialState });
    }, [content]);

    const contents = useMemo(
        () =>
            Object.entries(content).reduce(
                (acm, [label, content], idx) => [
                    ...acm,
                    {
                        label,
                        content,
                        touched: idx === 0,
                        valid: false,
                        locked: false,
                    },
                ],
                []
            ),
        [content]
    );

    const initialize = useCallback(
        (state) => ({
            ...state,
            contents,
        }),
        [contents]
    );

    const middlewareProps = useMemo(
        () => ({
            storeState: makeSideEffectCallback(storeState),
        }),
        [storeState]
    );

    return (
        <StepperProvider
            initialize={initialize}
            initialAction={action}
            middlewareProps={middlewareProps}
        >
            <StepperContainer {...{ ContainerComponent, ...ContainerProps }} />
        </StepperProvider>
    );
};

Stepper.propTypes = propTypes;
Stepper.defaultProps = defaultProps;

export default Stepper;
