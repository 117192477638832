import { styled, LinearProgress as MuiLinearProgress } from '@mui/material';

const ButtonProgressBar = styled(MuiLinearProgress)(
    ({ theme: { palette, spacing } }) => ({
        position: 'absolute',
        width: '100%',
        bottom: 0,
        borderBottomLeftRadius: spacing(0.5),
        borderBottomRightRadius: spacing(0.5),
        '& .MuiLinearProgress-colorPrimary': {
            backgroundColor: palette.common.transparent,
        },
        '& .MuiLinearProgress-barColorPrimary': {
            backgroundColor: 'var(--color)',
        },
    })
);

const LinearProgress = (props) => {
    return <ButtonProgressBar {...props} />;
};

export default LinearProgress;
