import AutocompleteBase from '@formComponents/Autocomplete';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { DOMAIN_NAME } from '../common';
import { UPAZILLAS } from '../redux.datasets';
import { upazillaDataSelector } from '../redux.selectors';

// Datasets
import { datasetLoadingSelector, fetchDatasets } from '@libs/datasets';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    districtfieldname: PropTypes.string.isRequired,
    formname: PropTypes.string.isRequired,
};

const defaultProps = {
    name: 'thana',
    label: 'Thana',
    placeholder: 'Select Thana',
    domain: DOMAIN_NAME,
    entity: UPAZILLAS,
    disabled: false,
    required: false,
    loading: false,
};

const mapState = (
    state,
    { domain, entity, districtfieldname : dfn, formname }
) => {
    return {
        data: upazillaDataSelector(state, formname, dfn),
        loading: datasetLoadingSelector(state, domain, entity),
    };
};

const mapDispatch = (dispatch, { entity }) => ({
    fetchDataset: () => dispatch(fetchDatasets(entity)),
});

const SelectThana = connect(
    mapState,
    mapDispatch
)(({ fetchDataset, data, ...props }) => {
    useEffect(() => {
        fetchDataset();
    }, []);

    return (
        <AutocompleteBase
            {...props}
            options={data ? data : []}
        />
    );
});

SelectThana.propTypes = propTypes;
SelectThana.defaultProps = defaultProps;

export default SelectThana;
