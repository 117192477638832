export default [
    {
        key: 'isMarried',
        label: 'Is Married',
    },
    {
        key: 'marriageDate',
        label: 'Marriage Date',
    },
    {
        key: 'spouse',
        label: 'Spouse Name',
    },
    {
        key: 'contact',
        label: 'Spouse Contact',
    },
    {
        key: 'occupation',
        label: 'Spouse Occupation',
    },
    {
        key: 'children',
        label: 'Children',
    },
]