import apiRequest from '@libs/apiRequest';
import { HOLIDAY } from 'holidays/redux.datasets';
import { call, put } from 'redux-saga/effects';
import { setLoading, setSnack } from 'utilities/redux.actions';

export default function* (action) {
    const {
        meta: { handleSubmit = () => { } },
        payload
    } = action;

    yield put(setLoading(true));

    try {
        const { data } = yield call(
            apiRequest,
            {
                url: `holiday/delete?id=${payload.id}`,
                method: 'get'
            }
        );
        yield put(setLoading(false));
        yield put(setSnack({
            message: "Holidays deleted successfully",
            type: 'success',
        }));

        yield put({
            type: "deleteHoliday",
            payload: {
                entity: HOLIDAY,
                value: data
            },
        })

        return handleSubmit(data);

    } catch (error) {
        yield put(setLoading(false));
        yield put(setSnack({
            message: error?.response?.data?.message || error?.message,
            type: 'error',
        }));


        return handleSubmit(error);
    }
}
