import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { styled } from '@mui/material';
import { useIsMinified } from '../../hooks';

const propTypes = {
    name: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired,
    icon: PropTypes.string,
    childRoutes: PropTypes.array,
    depth: PropTypes.number,
};

const defaultProps = {
    icon: void 0,
    depth: 0,
    childRoutes: [],
};

const Wrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
});
const Icon = styled('img')(({ theme: { spacing } }) => ({
    width: spacing(3),
    height: spacing(3),
    marginLeft: spacing(1.5),
    marginRight: spacing(1.5),
}));
const StyledLink = styled(Link)(({ theme: { palette, typography } }) => {
    return {
        color: palette.common.white,
        fontFamily: typography.fontFamily,
        fontWeight: 'var(--activeFontWeight)',
        paddingLeft: 'var(--accordionPadding)',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    };
});

const RouteHeader = ({ icon, name, route, childRoutes, depth }) => {
    const isMinified = useIsMinified();
    const hasChildren = !!childRoutes.length;
    return hasChildren ? (
        <Wrapper data-testid={`${name}-wrapper`}>
            {!depth && <Icon src={icon} alt={`${name}-Icon`} data-testid={`${name}-icon`} />}
            {!isMinified && (
                <StyledLink
                    data-testid={`${name}-link`}
                    onClick={(event) => event.stopPropagation()}
                    to={childRoutes[0].route}
                >
                    {name}
                </StyledLink>
            )}
        </Wrapper>
    ) : (
        <StyledLink
            data-testid={`${name}-link`}
            to={route}
            sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                justifyContent: 'var(--minifyJustify)',
            }}
        >
            {!depth && <Icon src={icon} alt={`${name}-Icon`} />}
            {!isMinified && name}
        </StyledLink>
    );
};

RouteHeader.propTypes = propTypes;
RouteHeader.defaultProps = defaultProps;

export default RouteHeader;
