import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';

const domainSelector = createCachedSelector(
    (state) => state,
    (state, domain) => domain,
    (state, domain) => state[domain]
)((state, domain) => `@domain-${domain}`);

export const entitySelector = createCachedSelector(
    domainSelector,
    (state, domain, entity) => entity,
    (domain, entity) => domain[entity]
)((state, domain, entity) => `@entity-${domain}-${entity}`);

export const valueSelector = createCachedSelector(
    entitySelector,
    (state, domain, entity, valueId) => valueId,
    (entity, valueId) =>
        entity.reduce((acm, { id, ...rest }) => {
            const [value = void 0] = Object.values(rest);
            return id == valueId ? value : acm;
        }, '')
)((state, domain, entity, valueId) => `@value-${domain}-${entity}-${valueId}`);

export const datasetLoadingSelector = createCachedSelector(
    domainSelector,
    (state, domain, entity) => entity,
    (domain, entity) => domain.loading[entity]
)((state, domain, entity) => `@loading-${domain}-${entity}`);

export const datasetSelector = createCachedSelector(entitySelector, (data) =>
    data.map(({ id, ...datum }) => ({
        label: Object.values(datum)[0],
        value: id
    }))
)((state, domain, entity) => `@data-${domain}-${entity}`);

export const gridDatasetSelector = createCachedSelector(
    entitySelector,
    (values) => values
)((state, domain, entity) => `@data-${domain}-${entity}`);

export default ({ domain, entity, selector = null, fields = null }) => {
    // If selector doesn't exist
    if (!selector) {
        // If is paginated
        if (!!fields) {
            return createSelector(
                (state) => entitySelector(state, domain, entity),
                (entity) => !!entity.data.length
            );
        } else {
            return createSelector(
                (state) => entitySelector(state, domain, entity),
                (entity) => !!entity.length
            );
        }
    } else return selector;
};
