export const DISTRICTS = 'common@districts';
export const UPAZILLAS = 'common@upazillas';
export const EMPLOYEE_CATEGORY = 'common@employeeCategoty';
export const SHIFT = 'common@shift';
export const DEPARTMENT = 'common@department';
export const BRANCH = 'common@branch';
export const DESIGNATION = 'common@designation';
export const ROLES = 'common@allroles';
export const GET_ALL_EMPLOYEE_NAME_EMAIL = 'common@allEmployeeNameAndEmail';
export const CALENDER = 'common@calender';


export default {
    [DISTRICTS]: {
        url: 'districts',
    },
    [UPAZILLAS]: {
        url: 'upazillas',
    },
    [EMPLOYEE_CATEGORY]: {
        url: 'employee-category/get/all',
    },
    [SHIFT]: {
        url: 'shift/get/all',
    },
    [DEPARTMENT]: {
        url: 'department/get/all',
    },
    [BRANCH]: {
        url: 'branch/get/all',
    },
    [DESIGNATION]: {
        url: 'designation/all',
    },
    [ROLES]: {
        url: 'role/get/all',
    },
    [GET_ALL_EMPLOYEE_NAME_EMAIL]: {
        url: 'employee/names-and-emails',
    },
    [CALENDER]: {
        url: 'calender/get/all',
    },
};
