import FormArray from '@formComponents/FormArray';
import React from 'react';
import renderWorkExperienceFields from './WorkExperienceFields';

const WorkExperiences = ({ ...restProps }) => {
    return (
        <React.Fragment>
            <FormArray name="workExperiences" label="Add Work Experiences" renderSubFields={renderWorkExperienceFields}/>
        </React.Fragment>
    );
};

export default WorkExperiences;
