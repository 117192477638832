import apiRequest from '@libs/apiRequest';
import { call, put } from 'redux-saga/effects';
import { setLoading, setSnack } from 'utilities/redux.actions';

export default function* (props) {
    const {
        meta: { handleSubmit = () => { } },
    } = props;

    const url = `user/get/me`;
    try {
        yield put(setLoading(true));
        const leave_data = yield call(
            apiRequest,
            {
                url,
                method: "get"
            }
        );
        yield put(setLoading(false));
        yield put(setSnack({
            // message: `User data get successfully`,
            type: 'success',
        }));

        return handleSubmit(leave_data);
    } catch (error) {
        yield put(setLoading(false));
        yield put(setSnack({
            message: error?.response?.data?.message || error?.message,
            type: 'error',
        }));
        return handleSubmit(error);
    }
}
