import { isMobileWidth } from '@libs/helpers';
import makeContext from '@libs/makeContext';

const initialState = {
    routes: [],
    flatRoutes: [],
    flatHashes: {},
    drawerWidth: 0,
    appBarHeight: 0,
    footerHeight: 0,
    isDrawerVisible: isMobileWidth(),
    isDrawerMinified: false,
    isDrawerFloating: false,
    accordion: [],
    active: '',
};

const navigationReducer = (state, { type, payload }) => {
    switch (type) {
        case 'setHeaderDimensions': {
            const { width, height } = payload;
            return { ...state, drawerWidth: width, appBarHeight: height };
        }
        case 'setFooterDimensions': {
            return { ...state, footerHeight: payload };
        }

        case 'setDrawerVisibility': {
            return { ...state, isDrawerVisible: payload };
        }

        case 'setDrawerMinification': {
            return { ...state, isDrawerMinified: payload };
        }

        case 'setDrawerFloatation': {
            return { ...state, isDrawerFloating: payload };
        }

        case 'setAccordion': {
            return { ...state, accordion: payload };
        }

        case 'setActive': {
            return { ...state, active: payload };
        }

        default:
            return state;
    }
};

const [
    NavigationProvider,
    useNavigationState,
    useNavigationDispatch,
    useNavigation,
] = makeContext(navigationReducer, initialState, { name: 'Navigation' });

export {
    useNavigation as default,
    NavigationProvider,
    useNavigationState,
    useNavigationDispatch,
};
