import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import InputBase from '@mui/material/InputBase';

import { Autocomplete, FormControl, styled } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';

import Label from '@baseComponents/Label';
import FieldProgressBar from '@baseComponents/FieldProgressBar';
import HelperText from '@baseComponents/HelperText';

const propTypes = {
    fieldName: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    label: PropTypes.string,
    loading: PropTypes.bool,
    meta: PropTypes.shape({
        form: PropTypes.string.isRequired,
        touched: PropTypes.bool,
        error: PropTypes.string,
        asyncValidating: PropTypes.bool
    }),
    placeholder: PropTypes.string,
    input: PropTypes.shape({
        onChange: PropTypes.func.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }),
    data: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number
                ]),
                label: PropTypes.string.isRequired
            })
        ),
        PropTypes.array.isRequired
    ])
};

const StyledInput = styled(InputBase)(
    ({ theme: { spacing, palette, typography, shape } }) => ({
        width: '100%',
        height: spacing(4.5),
        border: '1px solid',
        borderColor: palette.border.main,
        borderRadius: shape.borderRadius,
        fontSize: typography.subtitle2.fontSize,
        '& input':{
            cursor:"pointer",
            zIndex: 9,
            paddingRight: spacing(2),
            paddingLeft: spacing(2),
        },
        '&:hover': {
            borderColor: palette.border.dark
        },
        '&.Mui-focused': {
            borderColor: palette.grey[500],
            '&:hover': {
                borderColor: palette.grey[700]
            }
        },
        '&.Mui-error': {
            borderColor: `${palette.error.light}`,
            '&:hover': {
                borderColor: `${palette.error.dark}`
            }
        },
        '&.Mui-disabled': {
            display: 'flex',
            alignItems: 'center',
            backgroundColor: palette.background.disabled,
            color: palette.secondary.main,

            '&:hover': {
                borderColor: palette[300]
            }
        }
    })
);

const StyledBox = styled('div')(({ theme: { palette } }) => ({
    '& .MuiAutocomplete-option': {
        color: palette.text.primary,
        fontSize: '12px'
    }
}));

const StyledFormControl = styled(FormControl)(({ theme: { spacing } }) => ({
    width: '100%',
    paddingBottom: spacing(1.25),
    ' label': {
        paddingTop: spacing(1.25),
        textTransform: 'initial'
    }
}));

const SelectBase = ({
    fieldName,
    disabled,
    required,
    label,
    loading,
    meta: { form, touched, error = null, asyncValidating },
    placeholder,
    data,
    input: { value, onChange, ...input },
    ...restProps
}) => {
    const id = `${form}-${fieldName}`;
    const isDisabled = disabled || loading;
    const hasError = !isDisabled && touched && !!error;

    const inputBaseProps = {
        id,
        disabled: isDisabled,
        ...restProps,
        ...input
    };

    const handleOnChange = useCallback(
        (event, newValue) => {
            if (newValue) {
                onChange(newValue.value);
            }
        },
        [onChange]
    );

    const getOptionLabel = useCallback((option) => option.label || '', []);

    const selectedValue = useMemo(
        () => data.find((option) => option.value === value),
        [data, value]
    );

    return (
        <StyledFormControl>
            {label && (
                <Label
                    label={label}
                    disabled={isDisabled}
                    required={required}
                    error={hasError}
                    htmlFor={id}
                />
            )}
            <StyledBox>
                <Autocomplete
                    disablePortal
                    options={data}
                    sx={{padding:0}}
                    renderInput={(params) => (
                        <StyledInput
                            ref={params.InputProps.ref}
                            inputProps={params.inputProps}
                            placeholder={placeholder}
                            disabled={isDisabled}
                            error={hasError}
                            endAdornment={<ArrowDropDown sx={{position:"absolute", right:0}}/>}
                        />
                    )}
                    value={selectedValue}
                    getOptionLabel={getOptionLabel}
                    onChange={handleOnChange}
                    {...inputBaseProps}
                />
            </StyledBox>
            {(loading || asyncValidating) && (
                <FieldProgressBar asyncValidating={asyncValidating} />
            )}
            {hasError && <HelperText msg={error} required={required} />}
        </StyledFormControl>
    );
};

SelectBase.propTypes = propTypes;

export default SelectBase;
