import { Paper, styled } from '@mui/material';

import { renderedComponentPropType } from '@libs/propTypes';

import useStepper from './StepContext';
import StepperBar from './StepperBar';

const propTypes = {
    ContainerComponent: renderedComponentPropType.isRequired,
};

const StyledPaper = styled(Paper)(({ theme: { spacing, shadows } }) => ({
    width: '100%',
    position: 'relative',
    boxShadow: shadows[0],
    paddingLeft: spacing(2),
    paddingRight: spacing(2),
}));

const StepperContainer = ({ ContainerComponent, ...props }) => {
    const [state, dispatch] = useStepper();

    const { contents, activeStep } = state;
    const { content: Content } = contents[activeStep] || {};

    return (
        <ContainerComponent
            {...{ stepReducer: [() => state, dispatch], ...props }}
        >
            <StyledPaper>
                <StepperBar />
                {contents.length && <Content {...props} />}
            </StyledPaper>
        </ContainerComponent>
    );
};

StepperContainer.propTypes = propTypes;

export default StepperContainer;
