import { TableBody, TableRow } from '@mui/material';
import { useSelector } from 'react-redux';
import { getAllDepartment } from './redux.selectors';
import TableCellContainer from '@libComponents/table/TableCellContainer';
import TableContainer from '@libComponents/table/TableContainer';
import TableHeaderContainer from '@libComponents/table/TableHeaderContainer';
import { ContentContainer } from '@libComponents/ContentContainer';
import { Button } from '@libComponents';
import dayjs from 'dayjs';
import { DeleteOutline } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/EditOutlined';


export default function ViewDepartment({ setViewType, setData }) {
    const tableRows = useSelector((state) => getAllDepartment(state));

    const TABLE_DATA = {
        'name': 'Department Name',
        'description': 'Description',
        'branch_name': 'Branch Name',
    };

    const handleEdit = (row) => {
        setViewType('createDepartment');
        setData({ mode: 'update', row });
    }

    const handleDelete = (row) => {
        setViewType('deleteDepartment');
        setData({ row });
    }

    if (!tableRows) return null;
    return (
        <ContentContainer
            title='Department'
            sx={{ border: 0, p: "0 !important", mb: 0 }}
            AdornmentComponent={
                <Button sx={{ width: 'auto' }}
                    onClick={() => {
                        setViewType('createDepartment');
                        setData({ mode: 'create', row: {} });
                    }}
                >
                    Add department
                </Button>
            }
        >
            <TableContainer sx={{ p: "0 !important" }}>
                <TableHeaderContainer>
                    {Object.values(TABLE_DATA).map((th, i) => (
                        <TableCellContainer typography='caption' key={th}>
                            {th}
                        </TableCellContainer>
                    ))}
                    <TableCellContainer typography='caption' key="action" align="right">
                        Actions
                    </TableCellContainer>
                </TableHeaderContainer>
                <TableBody>
                    {tableRows.map((row, i) => (
                        <TableRow key={i}>
                            {Object.keys(TABLE_DATA).map((key, index) => {
                                let columnData = row[key];
                                switch (key) {
                                    case 'startTime':
                                        return (
                                            <TableCellContainer key={key}>
                                                {dayjs(columnData).format("HH:mm")}
                                            </TableCellContainer>
                                        )
                                    case 'endTime':
                                        return (
                                            <TableCellContainer key={key}>
                                                {dayjs(columnData).format("HH:mm")}
                                            </TableCellContainer>
                                        )
                                    default:
                                        return (
                                            <TableCellContainer key={key}>
                                                {columnData}
                                            </TableCellContainer>
                                        )
                                }
                            })}
                            <TableCellContainer
                                align='right'
                                sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}
                            >
                                <EditIcon
                                    onClick={() => { handleEdit(row) }}
                                    sx={{ cursor: 'pointer', opacity: 0.75 }}
                                />

                                <DeleteOutline
                                    sx={{ cursor: 'pointer', opacity: 0.5 }}
                                    onClick={() => { handleDelete(row) }}
                                />
                            </TableCellContainer>
                        </TableRow>
                    ))}
                </TableBody>
            </TableContainer>
        </ContentContainer>
    );
}
