import { Box, Typography, styled } from '@mui/material';
import { theme } from 'app/Theme';
import useOverview from '../useOverviewProvider';
import { SET_FILTERS_OVERVIEW_ATTENDANCE, SET_FILTERS_OVERVIEW_LEAVE } from 'dashboard/context.actionTypes';
import { useCallback } from 'react';

const SummaryBoxContainer = styled('div')(
    ({ theme: { spacing, palette, typography, shape } }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flex: 1,
        paddingBottom: spacing(2),
        cursor: "pointer"
    })
);

const StyledProgressWrapper = styled('div')(
    ({ theme: { spacing, palette, typography, shape } }) => ({
        height: spacing(.5),
        width: '100%',
        marginBottom: spacing(2)
    })
);

const StyledNumber = styled(Typography)(
    ({ theme: { spacing, palette, typography, shape } }) => ({
        paddingLeft: spacing(2),
        fontWeight: typography.fontWeightBold,
        marginBottom: spacing(0.5)
    })
);

const StyledDescription = styled(Typography)(
    ({ theme: { spacing, palette, typography, shape } }) => ({
        color: palette.quaternary.main,
        paddingLeft: spacing(2)
    })
);

function SummaryBox({ data: { present, total, color, description, status, leaveType } }) {
    const progress = (present / total) * 100;
    const [{ }, dispatch] = useOverview();

    const handleClick = useCallback(() => {
        if (status !== 'leave') {
            dispatch({
                type: SET_FILTERS_OVERVIEW_ATTENDANCE,
                payload: status
            });
        } else {
            dispatch({
                type: SET_FILTERS_OVERVIEW_LEAVE,
                payload: leaveType
            });
        }
    }, []);

    return (
        <SummaryBoxContainer sx={{ background: theme.palette[color].light }} onClick={handleClick}>
            <StyledProgressWrapper sx={{ background: theme.palette[color].main }}>
                <Box sx={{ height: "100%", background: theme.palette[color].dark, width: `${progress}%` }}></Box>
            </StyledProgressWrapper>
            <StyledNumber variant='h4' sx={{ color: theme.palette[color].dark }}>{present}</StyledNumber>
            <StyledDescription variant="subtitle2">{description}</StyledDescription>
        </SummaryBoxContainer>
    )
}

export default SummaryBox;
