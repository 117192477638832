import { darken, lighten } from '@mui/material';
import { grey } from '@mui/material/colors';

const PRIMARY = '#FA4B00';
const SECONDARY = '#212121';
const SUCCESS = '#4CAF50';
const ERROR = '#D32F2F';
const WARNING = '#FFA726';
const INFO = '#2196F3';
const TEXT = '#2B2B2B';
const DISABLED_TEXT = '#CECECE';
const ALT_TEXT = '#A2A2A2';
const BACKGROUND = '#F9FAFB';
const MAIN_HEADER_BG = 'rgb(244, 244, 244)';
const CHILD_HEADER_BG = '#F4F4F4';
const DISABLE_BG = '#F2F2F2';
const FIELD_BORDER = '#D2D2D2';
const TABLE_BORDER = '#E2E2E2';
const ICON = '#546178';
const SUCCESS_TEXT = '#FFFFFF';
const QUATERNARY = '#546178';

const palette = {
    primary: {
        light: lighten(PRIMARY, 0.4),
        main: PRIMARY,
        dark: darken(PRIMARY, 0.1),
        alert: '#d1eef8'
    },
    secondary: {
        light: lighten(SECONDARY, 0.6),
        main: SECONDARY,
        dark: darken(SECONDARY, 0.1)
    },
    tertiary: {
        light: lighten(ALT_TEXT, 0.6),
        main: ALT_TEXT,
        dark: darken(ALT_TEXT, 0.1)
    },
    quaternary: {
        light: lighten(QUATERNARY, 0.6),
        main: QUATERNARY,
        dark: darken(QUATERNARY, 0.1)
    },
    success: {
        light: lighten(SUCCESS, 0.4),
        main: SUCCESS,
        dark: darken(SUCCESS, 0.1),
        alert: '#beede1',

        // Legacy
        primary: '#5cd3b4',
        hover: lighten('#5cd3b4', 0.2)
    },
    warning: {
        light: lighten(WARNING, 0.4),
        main: WARNING,
        dark: darken(WARNING, 0.1)
    },
    error: {
        light: lighten(ERROR, 0.4),
        main: ERROR,
        dark: darken(ERROR, 0.1),
        alert: '#ffe2e1'
    },
    fail: {
        light: lighten(ERROR, 0.4),
        main: ERROR,
        dark: darken(ERROR, 0.1),
        alert: '#ffe2e1'
    },
    info: {
        light: lighten(INFO, 0.7),
        main: INFO,
        dark: darken(INFO, 0.1)
    },
    text: {
        main: TEXT,
        dark: darken(TEXT, 0.1),
        secondary: ALT_TEXT,
        disabled: DISABLED_TEXT,
        success: SUCCESS_TEXT
    },
    background: {
        mainHeader: MAIN_HEADER_BG,
        childHeader: CHILD_HEADER_BG,
        dark: darken(BACKGROUND, 0.1),
        default: BACKGROUND,
        disabled: DISABLE_BG,
        secondary: '#F0F3F9',
        white: "#FFFFFF"
    },
    border: {
        light: lighten(FIELD_BORDER, 0.7),
        main: FIELD_BORDER,
        dark: darken(FIELD_BORDER, 0.1),
        secondary: TABLE_BORDER
    },

    // Legacy
    common: {
        transparent: 'rgba(0, 0, 0, 0)'
    },
    icon: {
        light: lighten(ICON, 0.7),
        main: ICON,
        dark: '#394457',
        opacity: {
            regular: 0.5,
            hover: 1
        }
    },

    amend: {
        main: '#d1eef8',
        dark: lighten('#d1eef8', 0.2)
    },
    cancel: {
        main: grey[800]
    },

    db_overview_1: {
        main: 'rgba(250, 75, 0, 1)',
        dark: 'rgba(250, 75, 0, 1)',
        light: 'rgba(255, 237, 230, 1)'
    },
    db_overview_2: {
        main: 'rgba(201, 231, 203, 1)',
        dark: 'rgba(76, 175, 80, 1)',
        light: 'rgba(237, 247, 238, 1)'
    },
    db_overview_3: {
        main: 'rgba(229, 130, 130, 1)',
        dark: 'rgba(118, 50, 30, 1)',
        light: 'rgba(255, 219, 209, 1)'
    },
    db_overview_4: {
        main: 'rgba(255, 220, 168, 1)',
        dark: 'rgba(255, 167, 38, 1)',
        light: 'rgba(255, 246, 233, 1)'
    },
    db_overview_5: {
        main: 'rgba(216, 197, 141, 1)',
        dark: 'rgba(180, 155, 52, 1)',
        light: 'rgba(255, 250, 229, 1)'
    },
    db_overview_6: {
        main: 'rgba(122, 192, 248, 1)',
        dark: 'rgba(33, 150, 243, 1)',
        light: 'rgba(233, 245, 254, 1)'
    },
    db_overview_7: {
        main: 'rgba(136, 151, 174, 1)',
        dark: 'rgba(94, 113, 141, 1)',
        light: 'rgba(240, 243, 249, 1)'
    },
    approved: {
        main: 'rgba(76, 175, 80, 1)',
        dark: 'rgba(76, 175, 80, 1)',
        light: 'rgba(232, 245, 233, 1)'
    },
    pending: {
        main: 'rgba(255, 167, 38, 1)',
        dark: 'rgba(255, 167, 38, 1)',
        light: 'rgba(255, 243, 224, 1)'
    },
    rejected: {
        main: 'rgba(255, 87, 34, 1)',
        dark: 'rgba(255, 87, 34, 1)',
        light: 'rgba(255, 235, 238, 1)'
    },
    cancelled: {
        main: 'rgba(158, 158, 158, 1)',
        dark: 'rgba(158, 158, 158, 1)',
        light: 'rgba(224, 224, 224, 1)'
    },
};

export default palette;
