import { styled } from '@mui/system';

import TimesheetDataCell from './TimesheetDataCell';
import { WEEKDAYS } from './WeekdayHours';

const StyledTimeRangeHeaderContainer = styled('div')({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative'
});

const StyledTimesheetDataCell = styled(TimesheetDataCell)({
    flex: 1
});

const WeekdayHeader = (props) => {
    return (
        <StyledTimeRangeHeaderContainer {...props}>
            {WEEKDAYS.map(({ label, value }) => (
                <StyledTimesheetDataCell key={value}>
                    {label}
                </StyledTimesheetDataCell>
            ))}
        </StyledTimeRangeHeaderContainer>
    );
};

export { WeekdayHeader as default, WeekdayHeader };
