import { initializeDatasets } from '@libs/datasets';

import datasets from './redux.datasets';

const initialState = {
    ...initializeDatasets(datasets),
};

export default (state = initialState, { type, payload, ...action }) => {
    switch (type) {
        case "deleteEmployee": {
            const { entity, value } = payload;
            const { data } = state[entity];
            let employees = [...data];
            employees = employees.filter((item) => (item.userEmail !== value.userEmail));
            return { ...state, [entity]: { ...state[entity], data: employees } };
        }
        default: {
            return state;
        }
    }
};
