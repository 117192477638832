import PropTypes from 'prop-types';

// Material
import { FormControl } from '@mui/material';
import { styled } from '@mui/system';
import { DatePicker } from '@mui/x-date-pickers';

// Local
import withLocalizationProvider from '../withLocalizationProvider';
import Label from '../../Label';
import HelperText from '../../HelperText';
import DateTimePickerInput from '../DateTimePickerInput';
import { useState } from 'react';

const propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string
};

const defaultProps = {
    minDate: new Date('2022-01-01'),
    maxDate: new Date('2100-01-01'),
    inputFormat: 'YYYY-MM-DD',
    styles: {},
    input: { onChange: (f) => f },
    views: ['year', 'month', 'day']
};

const StyledFormControl = styled(FormControl)({
    width: '100%',
    height: '100%'
});

const DatePickerBase = withLocalizationProvider(
    ({
        input: { value, onChange, ...input } = {},
        meta: { form, touched, error } = {},
        fieldName,
        label,
        required,
        disabled: rootDisabled,
        loading,
        styles,
        ...props
    }) => {
        const id = `${form}-${fieldName}`;
        const disabled = rootDisabled || loading;
        const hasError = touched && error ? true : false;

        const datePickerProps = {
            value,
            disabled,
            onChange,
            ...input,
            ...props
        };

        const [open,setOpen] = useState(false);
        return (
            <StyledFormControl>
                {label && (
                    <Label
                        label={label}
                        disabled={disabled}
                        required={required}
                        error={hasError}
                        htmlFor={id}
                    />
                )}
                <DatePicker
                    {...datePickerProps}
                    open={open}
                    onClose={() => setOpen(false)}
                    renderInput={(params) => (
                        <DateTimePickerInput
                            {...params}
                            error={hasError}
                            disabled={disabled}
                            sx={styles.input}
                            onClick = {()=>{setOpen(true)}}
                        />
                    )}
                />
                {hasError && <HelperText msg={error} required={required} />}
            </StyledFormControl>
        );
    }
);

DatePickerBase.propTypes = propTypes;
DatePickerBase.defaultProps = defaultProps;

export default DatePickerBase;
