export const required = (value) => (value ? null : 'Required');
export const isEmpty = (value) => ((value && value.length) ? null : 'Required');

export const maxLength = (max) => (value) =>
    value && value.length > max ? `Must be ${max} characters or less` : null;

export const minLength = (min) => (value) =>
    value && value.length < min
        ? `Must be at least ${min} characters or more`
        : null;

export const number = (value) =>
    value && isNaN(Number(value)) ? 'Must be a number' : null;

export const textOnly = (value) =>
    value && !/[A-Za-z]$/i.test(value) ? 'Must be a text' : null;

export const minValue = (min) => (value) =>
    value && value < min ? `Must be at least ${min}` : null;

export const email = (value) =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
        ? 'Invalid email address'
        : null;
