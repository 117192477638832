import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/system';

import { calculateSecondsToHoursAndMinutes } from '@libs/dateTime';

import TimesheetDataCell from './TimesheetDataCell';
import { usePresentTypeColor } from '../hooks';

const proptypes = {
    entries: PropTypes.array
};

const defaultProps = {
    entries: []
};

const StyledTimeRangeHeaderContainer = styled('div')({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative'
});

const StyledTimesheetDataCell = styled(TimesheetDataCell)({
    flex: 1,
    opacity: 0.8
});

const WEEKDAYS = [
    { value: 'Monday', label: 'Mon' },
    { value: 'Tuesday', label: 'Tue' },
    { value: 'Wednesday', label: 'Wed' },
    { value: 'Thursday', label: 'Thu' },
    { value: 'Friday', label: 'Fri' },
    { value: 'Saturday', label: 'Sat' },
    { value: 'Sunday', label: 'Sun' }
];

const Day = ({ value, duration, presentType }) => {
    const presentTypeColor = usePresentTypeColor(presentType);
    return (
        <StyledTimesheetDataCell
            key={value}
            sx={{
                color: presentTypeColor
            }}
        >
            {calculateSecondsToHoursAndMinutes(duration)}
        </StyledTimesheetDataCell>
    );
};

const WeekdayHours = ({ entries, ...rest }) => {
    const flattenedDaysHours = useMemo(() => {
        return entries.reduce(
            (acm, { day, duration, presentType }) => ({
                ...acm,
                [day]: { duration, presentType }
            }),
            {}
        );
    }, [entries]);

    return (
        <StyledTimeRangeHeaderContainer {...rest}>
            {WEEKDAYS.map(({ value: dayName }) => (
                <Day
                    key={dayName}
                    value={dayName}
                    duration={flattenedDaysHours[dayName]?.duration}
                    presentType={flattenedDaysHours[dayName]?.presentType}
                />
            ))}
        </StyledTimeRangeHeaderContainer>
    );
};

WeekdayHours.propTypes = proptypes;
WeekdayHours.defaultProps = defaultProps;

export { WeekdayHours as default, WeekdayHours, WEEKDAYS };
