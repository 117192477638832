// Material
import { createTheme, ThemeProvider } from '@mui/material/styles';

// Local
import palette from './palette';
import overrides from './overrides';
import transitions from './transitions';
import typography from './typography';

export const theme = createTheme({
    palette,
    components: overrides,
    spacing: 8,
    transitions,
    typography,
});

export default ({ children }) => (
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
);
