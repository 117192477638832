export { DOMAIN_NAME } from '../common';
export { FORM_NAME, LABELS, INITIAL_VALUES, NEW_EMPLOYEE_ROUTE_ID, ALL_EMPLOYEE_ROUTE_ID };
const FORM_NAME = 'employee';

const LABELS = {
    general: 'General Information',
    contact: 'Contact Information',
    family: 'Family Information',
    identification: 'Identification',
    academic: 'Academic Information',
    others: 'Others',
    complete: 'Complete!',
};

const INITIAL_VALUES = {};

const NEW_EMPLOYEE_ROUTE_ID = 15;
const ALL_EMPLOYEE_ROUTE_ID = 16;


