import {
    getCurrentStartAndEndOfWeek,
    normalizeIso8601DateOnly
} from '@libs/dateTime';

import { ADMIN_TIMESHEET_INTITAL_API_PARAMS } from './Timesheet/common';

export const ATTENDANCE = 'timeAndAttendance@allAttendance';
export const DAILY_ALL_ATTENDANCE = '@dailyAllAttendance';
export const WEEKLY_ALL_ATTENDANCE = '@weeklyAllAttendance';
export const MONTHLY_ALL_ATTENDANCE = '@monthlyAllAttendance';
export const MY_DAILY_ATTENDANCE = '@myDailyAttendance';
export const MY_WEEKLY_ATTENDANCE = '@myWeeklyAttendance';
export const MY_MONTHLY_ATTENDANCE = '@myMonthlyAttendance';

const timeAndAttendanceDatasets = {
    [DAILY_ALL_ATTENDANCE]: {
        url: 'attendance/get-all-daily-attendances',
        params: ADMIN_TIMESHEET_INTITAL_API_PARAMS,
        selector: () => false,
        dataGrid: true
    },
    [WEEKLY_ALL_ATTENDANCE]: {
        url: 'attendance/get-all-weekly-attendances',
        params: {
            ...getCurrentStartAndEndOfWeek(),
            pageSize: 10
        },
        selector: () => false,
        dataGrid: true
    },
    [MONTHLY_ALL_ATTENDANCE]: {
        url: 'attendance/get-all-monthly-attendances',
        params: {
            pageSize: 10,
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1
        },
        selector: () => false,
        dataGrid: true
    },
    [MY_DAILY_ATTENDANCE]: {
        url: 'attendance/my-attendance',
        params: {
            from: normalizeIso8601DateOnly(),
            to: normalizeIso8601DateOnly()
        },
        selector: () => false,
        dataGrid: true
    },
    [MY_WEEKLY_ATTENDANCE]: {
        url: 'attendance/weekly-status',
        params: {
            ...getCurrentStartAndEndOfWeek()
        },
        selector: () => false,
        dataGrid: true
    },
    [MY_MONTHLY_ATTENDANCE]: {
        url: 'attendance/monthly-status',
        params: {
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1
        },
        selector: () => false,
        dataGrid: true
    }
};

export default timeAndAttendanceDatasets;
