import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
    LinearProgress,
    Fade,
    Typography,
    styled,
    useTheme,
    alpha,
} from '@mui/material';

import useTransitionToggle from '@libHooks/useTransitionToggle';

import { loadingProgressSelector, loadingSelector } from '../redux.selectors';

const Container = styled('div')(({ theme: { palette, zIndex } }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    backgroundColor: alpha(palette.secondary.dark, 0.8),
    zIndex: zIndex.tooltip,
}));

const LoadingText = styled(Typography)(
    ({ theme: { palette, typography, spacing } }) => ({
        marginLeft: spacing(1),
        color: palette.primary.main,
        fontWeight: typography.fontWeightLight,
    })
);

const ProgressWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
});

const ProgressContainer = styled(Typography)(
    ({ theme: { palette, spacing } }) => ({
        backgroundColor: palette.primary.light,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: palette.primary.main,
        borderRadius: spacing(0.625),
        padding: 1,
    })
);

const StyledProgressBar = styled(LinearProgress)(
    ({ theme: { spacing, palette } }) => ({
        width: spacing(33),
        height: spacing(0.625),
        borderRadius: 4,
        '& .MuiLinearProgress-colorPrimary': {
            backgroundColor: palette.background.dark,
        },
        '& MuiLinearProgress-barColorPrimary': {
            background: `repeating-linear-gradient(
                -60deg, 
                ${palette.primary.main}, 
                ${palette.primary.main} ${spacing(0.25)},
                transparent ${spacing(0.5)})`,
            borderRadius: spacing(0.625),
        },
    })
);

const Loader = () => {
    const { transitions } = useTheme();
    const loading = useSelector(loadingSelector);
    const loadingProgress = useSelector(loadingProgressSelector);
    const isLoading = useMemo(
        () => !!loading || !!loadingProgress,
        [loading, loadingProgress]
    );

    const shouldRender = useTransitionToggle(
        isLoading,
        transitions.duration.complex
    );

    const variant = useMemo(
        () => (!!loadingProgress ? 'determinate' : 'indeterminate'),
        [loadingProgress]
    );

    const progress = useMemo(() => {
        const normalizedProgress =
            loadingProgress >= 100 ? 100 : loadingProgress;
        return `${Math.round(normalizedProgress)}%`;
    }, [loadingProgress]);

    return (
        shouldRender && (
            <Fade in={isLoading} timeout={transitions.duration.complex}>
                <Container>
                    <ProgressWrapper>
                        <ProgressContainer>
                            <StyledProgressBar
                                variant={variant}
                                value={loadingProgress}
                            />
                        </ProgressContainer>
                        {!!loadingProgress && (
                            <LoadingText variant='caption'>
                                {progress}
                            </LoadingText>
                        )}
                    </ProgressWrapper>
                </Container>
            </Fade>
        )
    );
};

export default Loader;
