import PropTypes from 'prop-types';

import { styled } from '@mui/system';
import { Box, CircularProgress } from '@mui/material';

const propTypes = {
    size: PropTypes.number,
    color: PropTypes.string
};

const defaultProps = {
    size: 40,
    color: 'primary'
};

const StyledCircularProgressWrapper = styled(Box) (({ theme: { spacing, palette, typography, shape } }) => ({
    position: 'absolute',
    zIndex: 1,
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: `${palette.background.default}8C`,
}))

const ContentCircularLoader = (props) => {
    return (
        <StyledCircularProgressWrapper>
            <CircularProgress {...props} />
        </StyledCircularProgressWrapper>
    );
};

ContentCircularLoader.propTypes = propTypes;

ContentCircularLoader.defaultProps = defaultProps;

export { ContentCircularLoader as default, ContentCircularLoader };
