import { EMPLOYEE_CREATE_LEAVE, EMPLOYEE_ALL_LEAVE, GET_EMPLOYEE_REMAINING_LEAVES, EMPLOYEE_CANCEL_LEAVE, EMPLOYEE_UPDATE_DOCUMENT } from './redux.actionTypes';

export const allLeave = ({ handleSubmit, payload }) => ({
    type: EMPLOYEE_ALL_LEAVE,
    meta: { handleSubmit },
    payload,
});

export const createLeave = ({ handleSubmit, payload }) => ({
    type: EMPLOYEE_CREATE_LEAVE,
    meta: { handleSubmit },
    payload,
});

export const handleCancelLeave = ({ handleSubmit, payload }) => ({
    type: EMPLOYEE_CANCEL_LEAVE,
    meta: { handleSubmit },
    payload,
});

export const handleUploadDocument = ({ handleSubmit, payload }) => ({
    type: EMPLOYEE_UPDATE_DOCUMENT,
    meta: { handleSubmit },
    payload,
});

export const getEmplopyeeRemainingLeaves = ({ handleSubmit, payload }) => ({
    type: GET_EMPLOYEE_REMAINING_LEAVES,
    meta: { handleSubmit },
    payload,
});