import Karla700 from '@assets/fonts/Karla-Bold.ttf';
import Karla300 from '@assets/fonts/Karla-Light.ttf';
import Karla500 from '@assets/fonts/Karla-Medium.ttf';
import Karla400 from '@assets/fonts/Karla-Regular.ttf';

const karla300 = {
    fontFamily: 'Karla',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 300,
    src: `local('Karla'), url(${Karla300}) format('truetype')`,
};

const karla400 = {
    fontFamily: 'Karla',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 400,
    src: `local('Karla'), url(${Karla400}) format('truetype')`,
};

const karla500 = {
    fontFamily: 'Karla',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 500,
    src: `local('Karla'), url(${Karla500}) format('opentype')`,
};
const karla700 = {
    fontFamily: 'Karla',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 700,
    src: `local('Karla'), url(${Karla700}) format('truetype')`,
};

export default {
    MuiCssBaseline: {
        styleOverrides: {
            '@font-face': karla400,
            fallbacks: [
                {
                    '@font-face': karla300
                },
                {
                    '@font-face': karla500
                },
                {
                    '@font-face': karla700
                }
            ]
        },
    },
    MuiFormLabel: {
        styleOverrides: {
            asterisk: {
                color: '#db3131',
            },
        },
    },
    MuiInputBase: {
        styleOverrides: {
            input: {
                '&:-webkit-autofill': {
                    transitionDelay: '9999s',
                    transitionProperty: 'background-color, color',
                },
            },
        },
    },
    MuiFormHelperText: {
        styleOverrides: {
            root: {
                marginLeft: 0,
            },
        },
    },
    MuiButton: {
        styleOverrides: {
            root: {
                '&.Mui-disabled': {
                    opacity: 0.4
                },
            },
        },
    },
};
