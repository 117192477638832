import { useState, useCallback } from 'react';

import { capitalizeFirstLetter } from '@libs/string';

const useManageViewSwitcher = () => {
    const [viewType, setViewType] = useState('shifts');
    const selectedManageView = `Manage ${capitalizeFirstLetter(
        viewType
    )}`;

    const handleManageViewChange = useCallback((value) => {
        setViewType(value);
    }, []);

    return { viewType, handleManageViewChange, selectedManageView };
};

export { useManageViewSwitcher };
