import { GridContainer, GridItem } from '@formComponents';
import { withField } from '@libs/reduxForm';
import { ExpandMore } from '@mui/icons-material';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import React from 'react';

const propTypes = {};

const SelectAllCheckbox = withField()(
    ({
        fieldName,
        input,
        disabled,
        required,
        label,
        loading,
        staticValue,
        meta: { form, touched, error = null, asyncValidating },
        styles,
        checkboxOptions,
        key,
        ...restProps
    }) => {
        const [expanded, setExpanded] = React.useState(false);

        const handleChange = (panel) => (event, isExpanded) => {
            if (
                !(
                    event.target.tagName.toUpperCase() === 'INPUT' ||
                    (event.target.tagName.toUpperCase() === 'SPAN' &&
                        event.target.innerText === 'Select All')
                )
            )
                setExpanded(isExpanded ? panel : false);
        };

        const { onChange, name, value } = input;

        const handleSelectAllClick = (event) => {
            let newValue = { ...value };
            Object.entries(newValue).forEach(([key, value], i) => {
                newValue[key] = event.target.checked;
            });
            onChange(newValue);
        };

        const handleCheckboxChange = (event) => {
            let newValue = { ...value };
            newValue[event.target.name] = event.target.checked;
            onChange(newValue);
        };

        const checkIntermidate = () => {
            let newValue = { ...value };
            let valArr = Object.entries(newValue);
            let filteredTrueValue = valArr.filter(([key, value], i) => {
                return value;
            });
            return (
                valArr.length > filteredTrueValue.length &&
                filteredTrueValue.length > 0
            );
        };

        const isAllChecked = () => {
            let newValue = { ...value };
            let valArr = Object.entries(newValue);
            let filteredTrueValue = valArr.filter(([key, value], i) => {
                return value;
            });
            return valArr.length === filteredTrueValue.length;
        };

        return (
            <Accordion
                key={`panel-${key}`}
                expanded={expanded === `panel-${key}`}
                onChange={handleChange(`panel-${key}`)}
            >
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls={`panel-${key}-content`}
                    id={`panel-${key}-header`}
                >
                    <GridContainer
                        sx={{
                            justifyContent: 'space-between',
                            padding: '0px',
                            alignItems: 'center',
                        }}
                    >
                        <GridItem md={9}>
                            <Typography
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                {name}
                            </Typography>
                        </GridItem>

                        <GridItem md={3}>
                            <Typography
                                sx={{ color: 'text.secondary' }}
                                variant='subtitle2'
                            >
                                <FormGroup sx={{ alignItems: 'flex-end' }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={isAllChecked()}
                                                indeterminate={checkIntermidate()}
                                                onChange={handleSelectAllClick}
                                                name={name}
                                            />
                                        }
                                        label='Select All'
                                    />
                                </FormGroup>
                            </Typography>
                        </GridItem>
                    </GridContainer>
                </AccordionSummary>

                <AccordionDetails>
                    <Typography variant='subtitle2'>
                        <GridContainer>
                            {Object.entries(value).map(
                                ([label, value], labelIndex) => {
                                    return (
                                        <GridItem
                                            md={6}
                                            lg={4}
                                            sm={12}
                                            key={label}
                                        >
                                            <FormControlLabel
                                                key={`label-${labelIndex}`}
                                                control={
                                                    <Checkbox
                                                        checked={value}
                                                        onChange={
                                                            handleCheckboxChange
                                                        }
                                                        name={label}
                                                    />
                                                }
                                                label={label}
                                            />
                                        </GridItem>
                                    );
                                }
                            )}
                        </GridContainer>
                    </Typography>
                </AccordionDetails>
            </Accordion>
        );
    }
);

SelectAllCheckbox.propTypes = propTypes;

export default SelectAllCheckbox;
