import apiRequest from '@libs/apiRequest';
import dayjs from 'dayjs';
import { getFormValues } from 'redux-form';
import { call, put, select } from 'redux-saga/effects';
import { CREATE_ATTENDANCE_FORM } from 'timeAndAttendance/common';

import { setLoading, setSnack } from 'utilities/redux.actions';

export default function* (action) {
    const {
        meta: { handleSubmit = () => { } }
    } = action;

    const { employeeEmail, branch, shiftType, status, joinedRemotely, createdAtDate, createdAtTime } = yield select(
        getFormValues(CREATE_ATTENDANCE_FORM)
    );

    let allSelector = {
        employeeEmail,
        branch: {
            name: branch
        },
        shiftType: {
            name: shiftType
        },
        isIn: status === 'in' ? true : false,
        isOut: status === 'in' ? false : true,
        createdAt: dayjs(`${new Date(createdAtDate).toLocaleDateString()} ${new Date(createdAtTime).toLocaleTimeString()}`).toISOString(),
        isFromRemote: joinedRemotely === "true" ? true : false,
    }

    const payloadData = JSON.stringify(allSelector);
    try {
        const { data } = yield call(
            apiRequest,
            {
                url: "attendance/create",
                method: 'post',
                data: payloadData,
            }
        );;
        yield put(setLoading(false));
        yield put(setSnack({
            message: "Attendance created successfully",
            type: 'success',
        }));
        return handleSubmit(data);
    } catch (error) {
        yield put(setLoading(false));
        yield put(setSnack({
            message: error?.response?.data?.message || error?.message,
            type: 'error',
        }));
        return handleSubmit({ error });
    }
}
