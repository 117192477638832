export default (datasets) =>
    Object.entries(datasets).reduce(
        (acm, [entity, { fields = null, dataGrid = false }]) => {
            const initialDataset = !!fields
                ? { data: [], nextPage: false }
                : [];
            const loading = acm.loading || {};

            return {
                ...acm,
                [entity]: dataGrid
                    ? { data: [], currentPage: 1, totalPages: 0 }
                    : initialDataset,
                loading: { ...loading, [entity]: false }
            };
        },
        {}
    );
