import FormArray from '@formComponents/FormArray';
import React from 'react';
import renderAcademicQualification from './AcademicInformationFields';

const AcademicQualification = ({ ...restProps }) => {
    return (
        <React.Fragment>
            <FormArray name="qualifications" label="Add Qualification" renderSubFields={renderAcademicQualification}/>
        </React.Fragment>
    );
};

export default AcademicQualification;
