import apiRequest from '@libs/apiRequest';
import { EMPLOYEE_CATEGORY } from 'common/redux.datasets';
import { call, put } from 'redux-saga/effects';
import { setLoading, setSnack } from 'utilities/redux.actions';

export default function* (action) {
    const {
        meta: { handleSubmit = () => { } },
        payload,
        ...rest
    } = action;

    yield put(setLoading(true));

    try {
        const { data } = yield call(
            apiRequest,
            {
                url: `employee-category/?name=${payload.name}`,
                method: 'delete',
            }
        );
        yield put(setLoading(false));
        yield put(setSnack({
            message: "Employee category deleted successfully",
            type: 'success',
        }));
        yield put({
            type: "handleDelete",
            payload: {
                entity: EMPLOYEE_CATEGORY,
                value: data,
                key: "name"
            },
        });

        return handleSubmit(data);

    } catch (error) {
        yield put(setLoading(false));
        yield put(setSnack({
            message: error?.response?.data?.message || error?.message,
            type: 'error',
        }));

        return handleSubmit(error);
    }
}
