import { SelectBase } from '@baseComponents/Select';

const statusDatsets = [
    { value: null, label: 'All Status' },
    {
        value: 'arrivedLate',
        label: 'Arrived Late'
    },
    {
        value: 'in',
        label: 'Present'
    },
    {
        value: 'absent',
        label: 'Absent'
    }
];

const defaultProps = {
    fieldName: 'status',
    placeholder: 'Select Status',
    meta: { form: 'daily-view' },
    data: statusDatsets
};

const SelectPresentStatus = (props) => <SelectBase {...props} />;

SelectPresentStatus.defaultProps = defaultProps;

export default SelectPresentStatus;
