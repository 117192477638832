import DatePickerBase from '@formComponents/DatePicker';
import React from 'react';

export const renderDatepicker = ({
    input,
    label,
    type,
    meta: { touched, error },
    placeholder,
    ...restProps
}) => {
    return (
        <React.Fragment>
            <DatePickerBase
                {...input}
                placeholder={placeholder}
                label={label}
                {...restProps}
            />

            {touched && error && <span>{error}</span>}
        </React.Fragment>
    );
};