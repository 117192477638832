import { styled } from '@mui/system';
import { Typography } from '@mui/material';

const StyledTimesheetDataCell = styled(Typography)(
    ({ theme: { spacing, typography } }) => ({
        fontSize: typography.body2.fontSize,
        fontWeight: 'bold',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    })
);

const TimesheetDataCell = ({ children, ...props }) => {
    return (
        <StyledTimesheetDataCell {...props}>{children}</StyledTimesheetDataCell>
    );
};

export { TimesheetDataCell as default, TimesheetDataCell };
