import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    destroy as destroyForm,
    initialize as formInitialize,
    reduxForm,
} from 'redux-form';

import { styled } from '@mui/system';
import { Box } from '@mui/material';
import { Button } from '@libComponents';
import InputFile from '@formComponents/InputFile';
import { hasFormErrorSelector } from 'common/redux.selectors';
import { handleUploadDocument } from 'leaveAndHolidays/redux.actions';
import { UPDATE_DOCUMENT_FORM_NAME } from '../common';

const StyledButton = styled(Button)(({ theme: { spacing } }) => ({
    textWrap: "nowrap",
    marginLeft: spacing(1),
    width: "fit-contain"
}));


const UpdateDocumentForm = reduxForm({
    form: UPDATE_DOCUMENT_FORM_NAME,
    destroyOnUnmount: false,
    onSubmit: (v) => v,
})(({ handleSubmit: handleReduxFormSubmit, row: { requiredDocuments, id } }) => {

    const RXDispatch = useDispatch();
    useEffect(() => {
        RXDispatch(formInitialize(UPDATE_DOCUMENT_FORM_NAME, {
            // need to change the document[0] as there will be multiple documents
            requiredDocuments: requiredDocuments && requiredDocuments.length > 0 ? requiredDocuments[0] : ""
        }));
        return () => {
            return RXDispatch(destroyForm(UPDATE_DOCUMENT_FORM_NAME));
        };
    }, [requiredDocuments]);

    const hasError = useSelector((state) =>
        hasFormErrorSelector(state, UPDATE_DOCUMENT_FORM_NAME)
    );

    const handleReject = () => {
        if (hasError) {
            handleReduxFormSubmit();
            return;
        }
        RXDispatch(handleUploadDocument({ handleSubmit: onSubmit, payload: { id } }));
    };

    const onSubmit = (data) => {
        // console.log(data);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <InputFile
                name='requiredDocuments'
                sx={{ flex: 1, pt: 0 }}
            />
            <StyledButton onClick={handleReject} size="small" >
                Update
            </StyledButton>
        </Box>
    );
});

export { UpdateDocumentForm, UpdateDocumentForm as default };

