import { fork } from 'redux-saga/effects';

// Watchers
import shift from '../components/shift/redux.sagas';
import department from '../components/department/redux.sagas';
import branch from '../components/branch/redux.sagas';
import designation from '../components/designation/redux.sagas';
import employeeCategory from '../components/employeeCategory/redux.sagas';
import inputFileSagas from './inputFileSagas';
import userSagas from './userSagas';
import calender from '../components/calender/redux.sagas';
import leaveDetails from '../components/leaveDetails/redux.sagas';

export default function* () {
    yield fork(shift);
    yield fork(department);
    yield fork(branch);
    yield fork(designation);
    yield fork(employeeCategory);
    yield fork(inputFileSagas);
    yield fork(userSagas);
    yield fork(calender);
    yield fork(leaveDetails);
}

