import PropTypes from 'prop-types';
import { FORM_NAME } from './common';
// Libs
import { Button } from '@libComponents';
import { useDispatch } from 'react-redux';

import { reduxForm } from 'redux-form';
import { deleteEmployeeCategory } from './redux.actions';
import { Box, Typography } from '@mui/material';

const propTypes = {
    mode: PropTypes.oneOf(['create', 'update']),
};
const defaultProps = {
    mode: 'create',
};

/**
 * ReduxForm stepper rules
 * * Connect each step with reduxForm() to the same form name.
 * * Specify the destroyOnUnmount: false flag to preserve form data across form component unmounts.
 */

const DeleteEmployeeCategory = reduxForm({
    form: FORM_NAME, // Form name
    destroyOnUnmount: false, // Preserve data
    onSubmit: (v) => v,
})(({ row, setViewType, ...rest }) => {
    const RXDispatch = useDispatch();

    const handleClose = () => {
        setViewType("default");
    }

    const handleDelete = () => {
        RXDispatch(deleteEmployeeCategory({ handleSubmit: handleClose, payload: row }));
    }

    return (
        <>
            <Typography variant='h6' sx={{ mb: 4, mt: 4, textAlign: "center" }}>
                Do you want to delete the employee category?
            </Typography>

            <Box sx={{ mb: 2, display: "flex", alignItems: "center", justifyContent: "center", gap: 2 }}>
                <Button onClick={handleClose} color="tertiary">Cancel</Button>
                <Button onClick={handleDelete}>Delete</Button>
            </Box>
        </>
    );
});

DeleteEmployeeCategory.propTypes = propTypes;
DeleteEmployeeCategory.defaultProps = defaultProps;

export { DeleteEmployeeCategory as default, DeleteEmployeeCategory };

