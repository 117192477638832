import { Accordion, AccordionSummary, AccordionDetails, Grid, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from '@emotion/styled';
import { theme } from "app/Theme"


const StyledTitle = styled(Typography)(({ theme: { typography } }) => ({
  display: 'flex',
  ...typography.h6,
  fontWeight: typography.fontWeightBold,
  whiteSpace: 'nowrap',
}));

const AcademicAndWorkInfo = ({ expanded, onChange, panelId, title, dataInfo }) => {
  return (
    <Accordion expanded={expanded === panelId} onChange={onChange(panelId)} sx={{ m: 0 }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel5bh-content"
        id="panel5bh-header"
        sx={{ bgcolor: theme.palette.background.mainHeader, borderBottom: `1px solid ${theme.palette.border.secondary}`, margin: 0, "&>div": { margin: "0 !important" } }}
      >
        <StyledTitle>
          {title}
        </StyledTitle>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 4 }}>
        <Grid container spacing={1} sx={{ justifyContent: 'space-between' }}>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ fontWeight: "Bold", borderBottom: `1px solid ${theme.palette.border.light}`, marginBottom: 2, paddingBottom: .5 }}>Academic Information</Typography>
          </Grid>

          {dataInfo && dataInfo.find((info) => info.key === 'institutions')?.value && dataInfo.find((info) => info.key === 'institutions')?.value !== "N/A" && dataInfo.find((info) => info.key === 'institutions').value.map((info) => (
            <Grid item xs={6} key={info.key}>
              <Grid container>
                <Grid item xs={10} sx={{ marginBottom: 2.5 }}>
                  <Typography sx={{ fontWeight: "Bold", borderBottom: `1px solid ${theme.palette.border.light}`, marginBottom: 1, paddingBottom: .5 }}>{info?.label} </Typography>
                  {Object.keys(info.value).map((key) => (
                    <Grid container key={key}>
                      <Grid item xs={5}>
                        <Typography sx={{ fontWeight: "Bold" }}>{key}</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography>: {info.value[key]}</Typography>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid container spacing={1} sx={{ justifyContent: 'space-between' }}>
          <Grid item xs={12} sx={{ mt: 4 }}>
            <Typography variant="h5" sx={{ fontWeight: "Bold", borderBottom: `1px solid ${theme.palette.border.light}`, marginBottom: 2.5, paddingBottom: .5 }}>Work Experiences</Typography>
          </Grid>
          {dataInfo && dataInfo.find((info) => info.key === 'workExperiences')?.value && dataInfo.find((info) => info.key === 'workExperiences').value.map((info) => (
            <Grid item xs={6} key={info.key}>
              <Grid container>
                <Grid item xs={10} sx={{ marginBottom: 2 }}>
                  <Typography sx={{ fontWeight: "Bold", borderBottom: `1px solid ${theme.palette.border.light}`, marginBottom: 1, paddingBottom: .5 }}>{info?.label} </Typography>
                  {Object.keys(info.value).map((key) => (
                    <Grid container key={key}>
                      <Grid item xs={5}>
                        <Typography sx={{ fontWeight: "Bold" }}>{key}</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography>: {info.value[key]}</Typography>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default AcademicAndWorkInfo;