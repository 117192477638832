import { DatePickerBase } from '@baseComponents/DateTimePicker';
import { GridContainer, GridItem } from '@formComponents';
import { styled, useTheme } from '@mui/material';
import useOverview, { SET_DATE, SET_BRANCH } from '../useOverviewProvider';
import { isValidDate, normalizeIso8601Date } from '@libs/dateTime';
import { useCallback } from 'react';
import { SelectBranch } from 'timeAndAttendance/components';


const StyledGridItem = styled(GridItem)(
    ({ theme: { spacing } }) => ({
        paddingLeft: spacing(1),
        paddingRight: spacing(1)
    })
);

function FilterBranch() {
    const { spacing } = useTheme();

    const [{ date, branch }, dispatch] = useOverview();

    const handleDateChange = useCallback((value) => {
        if (isValidDate(value)) {
            const normalizeValue = normalizeIso8601Date(value);
            dispatch({
                type: SET_DATE,
                payload: normalizeValue
            });
        }
    }, []);

    const handleFilterChange = useCallback((value) => {
        dispatch({
            type: SET_BRANCH,
            payload: value
        });
    }, []);


    return (
        <GridContainer sx={{ padding: 0,  pt: 1, display: "flex", justifyContent: "flex-end", flex:1 }}>
            <StyledGridItem md={3} lg={1.5}>
                <SelectBranch
                    input={{
                        name: 'branch',
                        onChange: handleFilterChange,
                        value: branch
                    }}
                    sx={{ padding: 0 }}
                />
            </StyledGridItem>
            <StyledGridItem md={3} lg={2}>
                <DatePickerBase
                    input={{
                        value: date,
                        onChange: handleDateChange
                    }}
                    maxDate={new Date()}
                    name='daily-view-date-picker'
                    styles={{ input: { height: spacing(4.5) } }}
                    inputFormat='YYYY-MM-DD'
                />
            </StyledGridItem>
        </GridContainer>
    )
}

export default FilterBranch
