import makeContext from '@libs/makeContext';
import { DASHBOARD_ATTENDANCE_INITIAL_API_PARAMS } from '../../common';



import {
    SET_DATE_ATTENDANCE,
    SET_PAGE_NUMBER,
    SET_FILTERS_ATTENDANCE,
    SET_EMAIL,
    SET_ROWS_PER_PAGE_NUMBER
} from '../../context.actionTypes';
import { normalizeIso8601Date } from '@libs/dateTime';

const initialState = {
    currentPage: 0,
    data: [],
    totalPages: 0,
    pageSize: 5,
    isNextDisabled: true,
    email: "",
    ...DASHBOARD_ATTENDANCE_INITIAL_API_PARAMS
};

const reducer = (state, { type, payload }) => {
    switch (type) {
        case SET_DATE_ATTENDANCE: {
            return {
                ...state,
                date: payload,
            };
        }
        case SET_EMAIL: {
            return {
                ...state,
                email: payload
            };
        }
        case SET_FILTERS_ATTENDANCE: {
            return {
                ...state,
                filters: payload
            };
        }
        default:
            return state;
    }
};

const middleware = (state, action, { fetchData }) => {
    switch (action.type) {
        case SET_PAGE_NUMBER: {
            const { email, date, filters, pageSize } = state;
            const { payload } = action;
            fetchData({
                email,
                date,
                filters,
                pageSize,
                pageNumber: payload
            });

            return action;
        }

        case SET_ROWS_PER_PAGE_NUMBER: {
            const { email, date, filters } = state;
            const { payload } = action;
            fetchData({
                email,
                date,
                filters,
                pageSize: payload ,
                pageNumber: 1,
            });
            return action;
        }

        case SET_EMAIL: {
            const { date, filters, pageSize } = state;
            const { payload } = action;

            fetchData({
                email: payload,
                date,
                pageNumber: 1,
                filters,
                pageSize
            });

            return action;
        }
        case SET_DATE_ATTENDANCE: {
            const { filters, pageSize, email } = state;
            const { payload } = action;

            fetchData({
                email,
                filters,
                pageSize,
                date: payload,
            });

            return action;
        }


        case SET_FILTERS_ATTENDANCE: {
            const { date, filters, pageSize, email } = state;
            const { filterType, value } = action.payload;

            const newFilters = {
                ...filters,
                [filterType]: value || null
            };

            fetchData({
                email,
                date: normalizeIso8601Date(date),
                pageSize,
                filters: newFilters
            });

            return { ...action, payload: { ...newFilters } };
        }

        default:
            return action;
    }
};

const [
    AttendanceProvider,
    useAttendanceState,
    useAttendanceDispatch,
    useAttendance
] = makeContext(reducer, initialState, {
    name: 'Attendance',
    middleware
});

export {
    useAttendance as default,
    AttendanceProvider,
    useAttendanceState,
    useAttendanceDispatch,
    SET_PAGE_NUMBER,
    SET_DATE_ATTENDANCE,
    SET_FILTERS_ATTENDANCE,
    SET_EMAIL
};
