import makeContext from '@libs/makeContext';

import {
    SET_EMAIL,
    SET_PAGE_NUMBER,
    SET_YEAR_MONTH,
    TIMESHEET_NEXT,
    TIMESHEET_PREV,
    SET_FILTERS,
    SET_PAGE_SIZE
} from '../../context.actionTypes';
import { ADMIN_TIMESHEET_INTITAL_API_PARAMS } from '../common';

const initialState = {
    currentPage: 1,
    data: [],
    totalPages: 0,
    email: '',
    date: new Date(),
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    isNextDisabled: true,
    pageSize: 10,
    filters: ADMIN_TIMESHEET_INTITAL_API_PARAMS.filters
};

const reducer = (state, { type, payload }) => {
    switch (type) {
        case SET_EMAIL: {
            return {
                ...state,
                email: payload
            };
        }
        case SET_YEAR_MONTH: {
            const currentYearAndMonth = `${new Date().getFullYear()}-${
                new Date().getMonth() + 1
            }`;
            const newYear = new Date(payload).getFullYear();
            const newMonth = new Date(payload).getMonth() + 1;

            return {
                ...state,
                date: payload,
                year: new Date(payload).getFullYear(),
                month: new Date(payload).getMonth() + 1,
                // Set isNextDisabled based on whether the selected year and month is the same as the current year and month
                isNextDisabled: currentYearAndMonth === `${newYear}-${newMonth}`
            };
        }
        case SET_FILTERS: {
            return {
                ...state,
                filters: payload
            };
        }
        case SET_PAGE_SIZE: {
            return {
                ...state,
                pageSize: payload
            };
        }
        default:
            return state;
    }
};

const middleware = (state, action, { fetchData }) => {
    switch (action.type) {
        case SET_PAGE_NUMBER: {
            const { email, year, month, filters, pageSize } = state;
            const { payload } = action;
            fetchData({
                year,
                month,
                email,
                filters,
                pageSize,
                pageNumber: payload
            });

            return action;
        }

        case SET_PAGE_SIZE: {
            const {email, year, month, filters } = state;
            const { payload } = action;
            fetchData({
                year,
                month,
                filters,
                email,
                pageSize: payload,
                pageNumber: 1
            });
            return action;
        }

        case SET_EMAIL: {
            const { year, month, filters, pageSize } = state;
            const { payload } = action;

            fetchData({
                year,
                month,
                filters,
                pageSize,
                email: payload,
                pageNumber: 1
            });

            return action;
        }
        case SET_YEAR_MONTH: {
            const { email, filters, pageSize } = state;
            const { payload } = action;

            fetchData({
                email,
                filters,
                pageSize,
                year: new Date(payload).getFullYear(),
                month: new Date(payload).getMonth() + 1,
                pageNumber: 1
            });

            return action;
        }
        case TIMESHEET_NEXT: {
            const { year, month, email, filters, pageSize } = state;

            // Calculate the next month
            const nextMonth = (month % 12) + 1;
            const nextYear = month === 12 ? year + 1 : year;

            fetchData({
                year: nextYear,
                month: nextMonth,
                pageNumber: 1,
                pageSize,
                email,
                filters
            });

            // Update the date in the state
            return {
                type: SET_YEAR_MONTH,
                payload: `${nextYear}-${String(nextMonth).padStart(2, '0')}`
            };
        }
        case TIMESHEET_PREV: {
            const { year, month, email, filters, pageSize } = state;

            // Calculate the previous month
            const prevMonth = month === 1 ? 12 : month - 1;
            const prevYear = month === 1 ? year - 1 : year;

            fetchData({
                year: prevYear,
                month: prevMonth,
                pageNumber: 1,
                email,
                pageSize,
                filters
            });

            // Update the date in the state
            return {
                type: SET_YEAR_MONTH,
                payload: `${prevYear}-${String(prevMonth).padStart(2, '0')}`
            };
        }
        case SET_FILTERS: {
            const { year, month, email, filters, pageSize } = state;
            const { filterType, value } = action.payload;

            const newFilters = {
                ...filters,
                [filterType]: value || null
            };

            fetchData({
                email,
                year,
                month,
                pageSize,
                pageNumber: 1,
                filters: newFilters
            });

            return { ...action, payload: { ...newFilters } };
        }
        default:
            return action;
    }
};

const [
    MonthlyTimesheetsProvider,
    useMonthlyTimesheetsState,
    useMonthlyTimesheetsDispatch,
    useMonthlyTimesheets
] = makeContext(reducer, initialState, {
    name: 'MonthlyTimesheets',
    middleware
});

export {
    useMonthlyTimesheets as default,
    MonthlyTimesheetsProvider,
    useMonthlyTimesheetsState,
    useMonthlyTimesheetsDispatch,
    SET_EMAIL,
    SET_PAGE_NUMBER,
    SET_YEAR_MONTH,
    TIMESHEET_NEXT,
    TIMESHEET_PREV,
    SET_FILTERS,
    SET_PAGE_SIZE
};
