import AppliedLeaves from './AppliedLeaves';
import RemainingLeaves from './RemainingLeaves';

function index() {
    return (
        <>
            <RemainingLeaves />
            <AppliedLeaves />
        </>
    )
}

export default index
