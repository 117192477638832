import useOverview, { SET_MODAL_CLOSE } from '../useOverviewProvider';
import DialogWrapper from '@libComponents/Dialog';
import DataTable from './dataTable';

const ViewModalData = () => {
    const [{ openModal }, dispatch] = useOverview();

    const handleDialogClose = () => {
        dispatch({
            type: SET_MODAL_CLOSE
        });
    };
    return (
        <DialogWrapper open={openModal} onClose={handleDialogClose} dialogPadding={0} sx={{ textAlign: "center" }}>
            <DataTable onClose={handleDialogClose}/>
        </DialogWrapper>
    );
};

export default ViewModalData;
