import axios from 'axios';
import { call } from 'redux-saga/effects';

import { eraseCookieByKey, getCookie, setCookie } from '@libs/cookies';
import { AUTH_TOKEN_KEY, AUTH_TOKEN_TIME, REFRESH_TOKEN_KEY } from 'auth/common';
import { store } from 'app/ReduxGate';
import { logout } from 'auth/redux.actions';
// Local
import config from './config';
import { UNAUTHENTICATED_CODE } from './httpStatusCodes';
import { userEmailSelector } from 'auth/redux.selectors';
import { parseTokenExpireFullTime, parseTokenExpireTime } from '@libs/parseJWT';

const transformIntoFormData = (data) =>
    Object.entries(data).reduce((formData, [key, value]) => {
        if (Array.isArray(value)) {
            value.forEach((data) => formData.append(key, data));
        } else {
            formData.append(key, value);
        }
        return formData;
    }, new FormData());

const errorInterceptorHandler = () => {
    // Array to store callbacks for requests that are waiting for a new token
    let pendingRefreshRequests = [];
    let isRefreshing = false;

    // Function to handle token refresh flow
    const handleTokenRefresh = (originalConfig, err) => {
        // Don't retry the request if it has already been retried
        if (originalConfig._retry) {
            return;
        }
        originalConfig._retry = true;

        // Check if another refresh request is already in progress
        if (!isRefreshing) {
            isRefreshing = true;

            // Get the user's email and refresh token
            const email = userEmailSelector(store.getState());
            const refreshToken = getCookie(REFRESH_TOKEN_KEY);

            // Set the refresh token as the Authorization header
            apiClient.defaults.headers.common[
                'Authorization'
            ] = `Bearer ${refreshToken}`;

            // Send the refresh request
            apiClient
                .post('auth/refresh', { email })
                .then(({ data: { authToken, refreshToken } }) => {
                    // Update the auth and refresh tokens in cookies
                    setCookie(AUTH_TOKEN_KEY, authToken, parseTokenExpireTime(authToken));
                    setCookie(REFRESH_TOKEN_KEY, refreshToken, parseTokenExpireTime(refreshToken));
                    setCookie(AUTH_TOKEN_TIME, JSON.stringify({ exp: parseTokenExpireFullTime(authToken)}));

                    // Call all callbacks for requests that were waiting for the new token
                    pendingRefreshRequests.forEach((cb) => cb(authToken));
                })
                .catch(() => {
                    // When refresh token api also gets error then we logout the user
                    (async () => {
                        await store.dispatch(logout());
                    })();
                })
                .finally(() => {
                    pendingRefreshRequests = [];
                    isRefreshing = false;
                });
        }

        // Return a promise that resolves with the request using the new token
        // when the refresh request is completed
        return new Promise((resolve, reject) => {
            pendingRefreshRequests.push((token) => {
                if (token) {
                    originalConfig.headers.authorization = `Bearer ${token}`;
                    resolve(apiClient(originalConfig));
                } else {
                    reject(err);
                }
            });
        });
    };

    // Return the interceptor function
    return (err) => {
        const originalConfig = err.config;
        if (originalConfig.url !== 'auth/login') {
            // Check if the error is a 401 unauthenticated error
            if (err?.response?.status === UNAUTHENTICATED_CODE) {
                // Handle the token refresh flow
                handleTokenRefresh(originalConfig, err);
            }
        }

        // Reject the promise for other errors
        return Promise.reject(err);
    };
};

const apiClient = axios.create(config);

apiClient.interceptors.response.use((res) => res, errorInterceptorHandler());

function* apiRequest(action) {
    const { type, transformData = false, uploadToken, ...rest } = action;
    const refreshToken = getCookie(REFRESH_TOKEN_KEY);
    const AUTH_TOKEN_REMAINING = getCookie(AUTH_TOKEN_TIME);

    if (AUTH_TOKEN_REMAINING) {
        let remainingTime = JSON.parse(AUTH_TOKEN_REMAINING).exp - new Date().getTime();
        if (remainingTime < 0)
            eraseCookieByKey(AUTH_TOKEN_KEY)
    }

    const AUTH_TOKEN = getCookie(AUTH_TOKEN_KEY);

    if (AUTH_TOKEN) {
        apiClient.defaults.headers.common[
            'Authorization'
        ] = `Bearer ${AUTH_TOKEN}`;

    } else if(refreshToken) {
        async function updateAuthToken() {
            const email = userEmailSelector(store.getState());
            // Set the refresh token as the Authorization header
            apiClient.defaults.headers.common[
                'Authorization'
            ] = `Bearer ${refreshToken}`;

            // Send the refresh request
            let { data } = await apiClient.post('auth/refresh', { email });
     
            setCookie(AUTH_TOKEN_KEY, data.authToken, parseTokenExpireTime(data.authToken));
            setCookie(REFRESH_TOKEN_KEY, data.refreshToken, parseTokenExpireTime(data.refreshToken));
            setCookie(AUTH_TOKEN_TIME, JSON.stringify({ exp: parseTokenExpireFullTime(data.authToken)}));

            apiClient.defaults.headers.common[
                'Authorization'
            ] = `Bearer ${data.authToken}`;
        }
        yield updateAuthToken();
    }

    const loadedConfig = {
        ...rest,
        transformRequest: transformData ? transformIntoFormData : null,
    };

    return yield call(apiClient, loadedConfig);
}

export { apiRequest as default, apiRequest, apiClient };
