import { CALENDER } from 'common/redux.datasets';
import { commonSelector } from 'common/redux.selectors';
import { createSelector } from 'reselect';


const getAllCalender = createSelector(
    commonSelector,
    data => {
        return data && data[CALENDER] && data[CALENDER].data 
    }
);


export {  getAllCalender };
