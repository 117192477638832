import { useCallback } from 'react';

import { useTheme } from '@mui/material';

import { DatePickerBase } from '@baseComponents/DateTimePicker';
import { GridContainer, GridItem } from '@formComponents';
import { normalizeIso8601Date, isValidDate } from '@libs/dateTime';

import {
    TimesheetsInputSearch,
    SelectBranch
} from 'timeAndAttendance/components';
import useAttendance, {
    SET_DATE_ATTENDANCE,
    SET_EMAIL,
    SET_FILTERS_ATTENDANCE
} from '../useAttendanceProvider';
import SelectPresentStatus from 'timeAndAttendance/Timesheet/TimesheetsDailyView/Filters/SelectPresentStatus';

import styled from '@emotion/styled';

const StyledGridItem = styled(GridItem)(
    ({ theme: { spacing, palette, typography, shape } }) => ({
        paddingLeft: spacing(1),
        paddingRight: spacing(1)
    })
);

const Filters = () => {
    const [{ date, filters }, dispatch] = useAttendance();
    const { spacing } = useTheme();

    const handleDateChange = useCallback((value) => {
        if (isValidDate(value)) {
            const normalizeValue = normalizeIso8601Date(value);
            dispatch({
                type: SET_DATE_ATTENDANCE,
                payload: normalizeValue
            });
        }
    }, []);



    const handleSearch = useCallback((value) => {
        dispatch({
            type: SET_EMAIL,
            payload: value
        });
    }, []);

    const handleFilterChange = useCallback(
        (filterType) => (value) => {
            dispatch({
                type: SET_FILTERS_ATTENDANCE,
                payload: {
                    filterType,
                    value
                }
            });
        },
        []
    );

    return (
        <GridContainer sx={{ padding: 0, pt: 1, flex: 1, display: "flex", justifyContent: "flex-end" }}>
            <StyledGridItem lg={2} md={3}>
                <TimesheetsInputSearch
                    name='daily-view-employee-email'
                    handleSearch={handleSearch}
                />
            </StyledGridItem>

            <StyledGridItem md={3} lg={2}>
                <SelectBranch
                    input={{
                        name: 'branch',
                        onChange: handleFilterChange('branch'),
                        value: filters.branch
                    }}
                    sx={{ padding: 0 }}
                />
            </StyledGridItem>
            <StyledGridItem md={3} lg={2}>
                <SelectPresentStatus
                    input={{
                        name: 'status',
                        onChange: handleFilterChange('status'),
                        value: filters.status
                    }}
                />
            </StyledGridItem>
            <StyledGridItem md={3} lg={2}>
                <DatePickerBase
                    input={{
                        value: date,
                        onChange: handleDateChange
                    }}
                    maxDate={new Date()}
                    name='daily-view-date-picker'
                    styles={{ input: { height: spacing(4.5) } }}
                    inputFormat='YYYY-MM-DD'
                />
            </StyledGridItem>
        </GridContainer>
    );
};

export default Filters;
