import { ALL_ATTENDANCE_RANGE, DOMAIN_NAME, WEEKLY_ACTIVITIES } from './common';
import { createSelector } from 'reselect';
import { ALL_ATTENDANCE } from './common';
import dayjs from 'dayjs';
import { convertToHoursAndMinuteFromSecond } from '@libs/dateTime';

var duration = require('dayjs/plugin/duration')
dayjs.extend(duration)

const timeAndAttendanceSelector = (state) => state[DOMAIN_NAME];

const weeklyActivitiesSelector = createSelector(
    timeAndAttendanceSelector,
    data => data[WEEKLY_ACTIVITIES] ? data[WEEKLY_ACTIVITIES] : {}
);

const getALLAttendance = createSelector(
    timeAndAttendanceSelector,
    data => data && data[ALL_ATTENDANCE] && data[ALL_ATTENDANCE].data ? data[ALL_ATTENDANCE].data.map(({ date, inTime, outTime, duration, ...rest }) => ({
        date: dayjs(date).format("LL"),
        inTime: inTime ? dayjs(inTime).format("LT") : inTime,
        outTime: outTime ? dayjs(outTime).format("LT") : outTime,
        duration: duration && duration > 0 ? convertToHoursAndMinuteFromSecond(duration) : 0,
        ...rest
    })) : []
);

const getTotalRows = createSelector(
    timeAndAttendanceSelector,
    data => data && data[ALL_ATTENDANCE] && data[ALL_ATTENDANCE].totalCount ? data[ALL_ATTENDANCE].totalCount : 0,
);

const getRangesALLAttendance = createSelector(
    timeAndAttendanceSelector,
    data => data && data[ALL_ATTENDANCE_RANGE] && data[ALL_ATTENDANCE_RANGE].data ? data[ALL_ATTENDANCE_RANGE].data.map(({ date, duration, email, entries }) => ({
        date: dayjs(date).format("LL"), entries, duration: dayjs.duration(duration * 1000).format('HH:mm'), email
    })) : []
)

export { timeAndAttendanceSelector, weeklyActivitiesSelector, getALLAttendance, getRangesALLAttendance, getTotalRows };
