import { Fragment } from 'react';
import { reduxForm } from 'redux-form';

// Libs
import { GridContainer, GridItem, Input } from '@formComponents';
import ContentContainer from '@libComponents/ContentContainer';

import { Divider, Typography } from '@mui/material';
import InsuranceInformation from 'common/components/InsuranceInformation';
import NomineInformation from 'common/components/NomineInformation';
import ReferencesInformation from 'common/components/ReferencesInformation';
import { FORM_NAME, LABELS } from '../../common';

const Other = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
})(() => {
    return (
        <ContentContainer
            title={LABELS.others}
            AdornmentComponent={<Fragment />}
        >
            <GridContainer>
                <GridItem md={12} sx={{ mt: 2 }}>
                    <Typography> Bank Account Details</Typography>
                    <Divider light />
                </GridItem>

                <GridItem md={3}>
                    <Input
                        name='bankName'
                        placeholder='Bank name'
                        label='Bank name'
                    />
                </GridItem>
                <GridItem md={3}>
                    <Input
                        name='branch'
                        placeholder='Branch'
                        label='Branch'
                    />
                </GridItem>

                <GridItem md={3}>
                    <Input
                        name='acNo'
                        placeholder='A/C no'
                        label='A/C no'
                    />
                </GridItem>

                <GridItem md={12} sx={{mb: 1, mt: 3 }}>
                    <Typography> Nominee Information</Typography>
                    <Divider light />
                </GridItem>

                <GridItem md={12} >
                    <NomineInformation />
                </GridItem>

                <GridItem md={12} sx={{ mb: 1, mt: 3 }}>
                    <Typography>References</Typography>
                    <Divider light />
                </GridItem>
                <GridItem md={12}>
                    <ReferencesInformation />
                </GridItem>

                <GridItem md={12} sx={{  mb: 1, mt: 3 }}>
                    <Typography> Insurance Information</Typography>
                    <Divider light />
                </GridItem>

                <GridItem md={12}>
                    <InsuranceInformation />
                </GridItem>
                
            </GridContainer>
        </ContentContainer>
    );
});

export default Other;
