import FormArray from '@formComponents/FormArray';
import React from 'react';
import renderInsuranceOptions from './InsuranceFields';

const InsuranceInformation = ({ ...restProps }) => {
    return (
        <React.Fragment>
            <FormArray name="insurances" label="Add Insurance" renderSubFields={renderInsuranceOptions}/>
        </React.Fragment>
    );
};

export default InsuranceInformation;
