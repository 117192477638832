import {
    SET_DATASET_LOADING,
    FETCH_DATASETS,
    SET_DATASET,
    FETCH_PAGINATED_DATASET,
    SET_PAGINATED_DATASET,
    FETCH_GRID_DATASET
} from './libs.actionTypes';

const setDatasetLoading = ({ entity, domain, loading }) => ({
    type: domain + SET_DATASET_LOADING,
    meta: { entity },
    payload: loading
});

const setDataset = ({ entity, domain, dataset }) => ({
    type: domain + SET_DATASET,
    meta: { entity },
    payload: { dataset }
});

const fetchDatasets = (datasets) => ({
    type: FETCH_DATASETS,
    payload: Array.isArray(datasets) ? datasets : [datasets]
});

const createFetchPaginatedDataset =
    ({ domain, entity }) =>
    ({
        pageNumber = 1,
        pageSize = 10,
        filterKey = '',
        filterCategory = '',
        shouldAppend = false,
        searchTerm,
        ...rest
    }) => ({
        type: FETCH_PAGINATED_DATASET,
        meta: { domain, entity, shouldAppend },
        payload: {
            // pageNumber,
            // pageSize,
            filterKey,
            // filterCategory,
            'search-term': searchTerm,
            ...rest
        }
    });

const setPaginatedDataset = ({
    domain,
    entity,
    shouldAppend,
    dataset,
    nextPage,
    total
}) => ({
    type: domain + SET_PAGINATED_DATASET,
    meta: { entity, shouldAppend },
    payload: { dataset, nextPage, total }
});

const createFetchGridDataset = (domain, entity) => (payload) => ({
    type: FETCH_GRID_DATASET,
    meta: { domain, entity },
    payload
});

export {
    setDatasetLoading,
    fetchDatasets,
    createFetchPaginatedDataset,
    setDataset,
    setPaginatedDataset,
    createFetchGridDataset
};
