import { Box, styled } from '@mui/material';
import { useApprovedLeaveState } from '../useApprovedLeaveProvider';
import ListItem from './ListItem';



const StyledBox = styled(Box)(
    ({ theme: { spacing, palette, typography, shape } }) => ({
        width: '100%',
        overflow: 'auto',
        padding: spacing(1, 0),
        '& > div': {
            // #E9EFF6
            borderBottom: `1px solid ${palette.border.light}`
        },

        '& > div:last-child': {
            borderBottom: 'none'
        }
    })
);
let isArray = function (a) {
    return (!!a) && (a.constructor === Array);
};

const DataTable = () => {
    const { leaveData } = useApprovedLeaveState();

    return (
        <StyledBox>
            {isArray(leaveData) && leaveData.map((item, index) => {
                return <ListItem key={index} item={item} />
            })}

        </StyledBox>
    );
};

export default DataTable;
