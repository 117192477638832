import { useCallback } from 'react';

import { useTheme } from '@mui/material';

import { DatePickerBase } from '@baseComponents/DateTimePicker';
import { GridContainer, GridItem } from '@formComponents';
import { normalizeIso8601Date, isValidDate } from '@libs/dateTime';

import {
    TimesheetsInputSearch,
    TimeSheetsNavigator,
    SelectBranch
} from 'timeAndAttendance/components';
import useDailyTimesheets, {
    SET_DATE,
    SET_EMAIL,
    TIMESHEET_NEXT,
    TIMESHEET_PREV,
    SET_FILTERS
} from '../useDailyTimesheets';
import SelectPresentStatus from './SelectPresentStatus';

const Filters = () => {
    const [{ date, isNextDisabled, filters }, dispatch] = useDailyTimesheets();
    const { spacing } = useTheme();

    const handleDateChange = useCallback((value) => {
        if (isValidDate(value)) {
            const normalizeValue = normalizeIso8601Date(value);
            dispatch({
                type: SET_DATE,
                payload: normalizeValue
            });
        }
    }, []);

    const handleSearch = useCallback((value) => {
        dispatch({
            type: SET_EMAIL,
            payload: value
        });
    }, []);

    const handlePrevClick = useCallback(() => {
        dispatch({
            type: TIMESHEET_PREV
        });
    }, []);

    const handleNextClick = useCallback(() => {
        dispatch({
            type: TIMESHEET_NEXT
        });
    }, []);

    const handleFilterChange = useCallback(
        (filterType) => (value) => {
            dispatch({
                type: SET_FILTERS,
                payload: {
                    filterType,
                    value
                }
            });
        },
        []
    );

    return (
        <GridContainer sx={{ paddingBottom: 0 }}>
            <GridItem md={4}>
                <TimeSheetsNavigator
                    onPrevClick={handlePrevClick}
                    onNextClick={handleNextClick}
                    isNextDisabled={isNextDisabled}
                >
                    <DatePickerBase
                        input={{
                            value: date,
                            onChange: handleDateChange
                        }}
                        maxDate={new Date()}
                        name='daily-view-date-picker'
                        styles={{ input: { height: spacing(4.5) } }}
                        inputFormat='YYYY-MM-DD'
                    />
                </TimeSheetsNavigator>
            </GridItem>
            <GridItem md={2}>
                <SelectBranch
                    input={{
                        name: 'branch',
                        onChange: handleFilterChange('branch'),
                        value: filters.branch
                    }}
                />
            </GridItem>
            <GridItem md={2}>
                <SelectPresentStatus
                    input={{
                        name: 'status',
                        onChange: handleFilterChange('status'),
                        value: filters.status
                    }}
                />
            </GridItem>
            <GridItem md={3}>
                <TimesheetsInputSearch
                    name='daily-view-employee-email'
                    handleSearch={handleSearch}
                />
            </GridItem>
        </GridContainer>
    );
};

export default Filters;
