import { styled } from '@mui/system';

import { calculateSecondsToHoursAndMinutes } from '@libs/dateTime';

import {
    TimesheetDataCell,
    TimesheetDataRowWrapper,
    TimesheetsUser
} from '../../../../components';

import DayRange from './DayRange';

const StyledTimesheetDataRowWrapper = styled(TimesheetDataRowWrapper)(
    ({ theme: { spacing, palette } }) => ({
        width: `calc(100% - ${spacing(4)})`,
        borderTop: '1px solid',
        borderTopColor: palette.border.main,
        borderBottom: 'none',
        margin: `0px ${spacing(2)}`
    })
);

const UserTimesheet = ({
    name,
    email,
    avatarUrl,
    totalDuration,
    totalShiftDuration,
    isOdd,
    ...rest
}) => {
    return (
        <StyledTimesheetDataRowWrapper isOdd={isOdd}>
            <TimesheetsUser
                sx={{ gridColumn: 'span 3' }}
                name={email}
                avatarUrl={avatarUrl}
            />
            <DayRange sx={{ gridColumn: 'span 8' }} {...rest} />
            <TimesheetDataCell
                sx={{ gridColumn: 'span 1', textAlign: 'center' }}
            >
                {calculateSecondsToHoursAndMinutes(totalDuration)}/
                {calculateSecondsToHoursAndMinutes(totalShiftDuration)}
            </TimesheetDataCell>
        </StyledTimesheetDataRowWrapper>
    );
};

export default UserTimesheet;
