import makeContext from '@libs/makeContext';
import { normalizeIso8601Date } from '@libs/dateTime';

import { ADMIN_TIMESHEET_INTITAL_API_PARAMS } from '../common';

import {
    SET_DATE,
    SET_EMAIL,
    SET_PAGE_NUMBER,
    TIMESHEET_NEXT,
    TIMESHEET_PREV,
    SET_FILTERS,
    SET_PAGE_SIZE
} from '../../context.actionTypes';

const initialState = {
    currentPage: 1,
    data: [],
    totalPages: 0,
    email: '',
    isNextDisabled: true,
    ...ADMIN_TIMESHEET_INTITAL_API_PARAMS
};

const reducer = (state, { type, payload }) => {
    switch (type) {
        case SET_DATE: {
            return {
                ...state,
                date: payload,
                // Set isNextDisabled based on whether the selected date is the same as the current date
                isNextDisabled:
                    new Date().toISOString().split('T')[0] ===
                    new Date(payload).toISOString().split('T')[0]
            };
        }
        case SET_EMAIL: {
            return {
                ...state,
                email: payload
            };
        }
        case SET_FILTERS: {
            return {
                ...state,
                filters: payload
            };
        }
        case SET_PAGE_SIZE: {
            return {
                ...state,
                pageSize: payload
            };
        }
        default:
            return state;
    }
};

const middleware = (state, action, { fetchData }) => {
    switch (action.type) {
        case SET_PAGE_NUMBER: {
            const { email, date, filters, pageSize } = state;
            const { payload } = action;

            fetchData({
                email,
                date,
                filters,
                pageSize,
                pageNumber: payload
            });

            return action;
        }
        case SET_DATE: {
            const { email, filters, pageSize } = state;
            const { payload } = action;

            fetchData({
                email,
                filters,
                pageSize,
                date: payload,
                pageNumber: 1
            });
            return action;
        }

        case SET_PAGE_SIZE: {
            const { email, filters, date } = state;
            const { payload } = action;

            fetchData({
                email,
                filters,
                date,
                pageNumber: 1,
                pageSize: payload
            });

            return action;
        }
        case SET_EMAIL: {
            const { date, filters, pageSize } = state;
            const { payload } = action;

            fetchData({
                email: payload,
                date,
                pageSize,
                pageNumber: 1,
                filters
            });

            return action;
        }
        case TIMESHEET_NEXT: {
            const { email, date, filters, pageSize } = state;

            // Calculate the next day date
            const nextDate = new Date(date);
            nextDate.setDate(nextDate.getDate() + 1);

            fetchData({
                email,
                filters,
                pageSize,
                date: normalizeIso8601Date(nextDate),
                pageNumber: 1
            });

            // Update the date in the state
            return {
                type: SET_DATE,
                payload: normalizeIso8601Date(nextDate)
            };
        }
        case TIMESHEET_PREV: {
            const { email, date, filters, pageSize } = state;

            // Calculate the previous day date
            const prevDate = new Date(date);
            prevDate.setDate(prevDate.getDate() - 1);

            fetchData({
                email,
                filters,
                pageSize,
                date: normalizeIso8601Date(prevDate),
                pageNumber: 1
            });

            // Update the date in the state
            return {
                type: SET_DATE,
                payload: normalizeIso8601Date(prevDate)
            };
        }
        case SET_FILTERS: {
            const { email, date, filters, pageSize } = state;
            const { filterType, value } = action.payload;

            const newFilters = {
                ...filters,
                [filterType]: value || null
            };

            fetchData({
                email,
                date: normalizeIso8601Date(date),
                pageNumber: 1,
                pageSize,
                filters: newFilters
            });

            return { ...action, payload: { ...newFilters } };
        }
        default:
            return action;
    }
};

const [
    DailyTimesheetsProvider,
    useDailyTimesheetsState,
    useDailyTimesheetsDispatch,
    useDailyTimesheets
] = makeContext(reducer, initialState, {
    name: 'DailyTimesheets',
    middleware
});

export {
    useDailyTimesheets as default,
    DailyTimesheetsProvider,
    useDailyTimesheetsState,
    useDailyTimesheetsDispatch,
    SET_DATE,
    SET_EMAIL,
    SET_PAGE_NUMBER,
    TIMESHEET_NEXT,
    TIMESHEET_PREV,
    SET_FILTERS,
    SET_PAGE_SIZE
};
