import PropTypes from 'prop-types';

// Material
import { useTheme } from '@mui/material';

// Local
import InputBase from '@baseComponents/Input';
import React from 'react';
import PasswordVisibility from './PasswordVisibility';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    styles: PropTypes.object,
    staticValue: PropTypes.string,
};

const defaultProps = {
    styles: {}
};

const InputPassword = ({
    styles: { control, input, ...restStyles },
    placeholder,
    type,
    ...restProps
}) => {
    const [showPassword, setShowPassword] = React.useState(false);

    if(showPassword){
        type="text"
    } else {
        type="password"
    }

    const { spacing } = useTheme();
    const props = {
        placeholder: placeholder || restProps.label || '',
        type:type,
        styles: {
            control: { paddingBottom: spacing(1.25), ...control },
            input: { height: spacing(4.375), ...input },
            ...restStyles
        },
        ...restProps
    };
    return (
        <React.Fragment>
            <InputBase {...props} />
            <PasswordVisibility
                showPassword={showPassword}
                setShowPassword={setShowPassword}
            />
        </React.Fragment>
    );
};

InputPassword.propTypes = propTypes;
InputPassword.defaultProps = defaultProps;

export { InputPassword as default, InputPassword };
