import { styled, useTheme, LinearProgress } from '@mui/material';

import { useTransition, useProgress } from './hooks';

const StyledSLinearProgress = styled(LinearProgress)(
    ({ theme: { palette, spacing, transitions } }) => ({
        position: 'absolute',
        top: `calc(50% - ${spacing(0.25)})`, // 50% - (height - 4px)

        width: '100%',
        height: spacing(0.75),

        backgroundColor: palette.background.dark,
        zIndex: 3,

        '& .MuiLinearProgress-barColorPrimary': {
            backgroundColor: palette.success.main,
            backgroundImage: 'linear-gradient(90deg, var(--gradient))',

            transition: transitions.create(['transform'], {
                easing: transitions.easing.easeInOut,
                duration: transitions.duration.complex,
            }),

            '&::before': {
                display: 'block',
                content: '""',
                width: '100%',
                height: '100%',

                backgroundColor: palette.success.main,
                backgroundImage:
                    'linear-gradient(90deg, var(--phantomGradient))',

                opacity: 'var(--phantomOpacity)',

                transition: transitions.create(['opacity'], {
                    easing: transitions.easing.easeInOut,
                }),
                transitionDuration: 'var(--phantomDuration)',
            },
        },
    })
);

const Progress = () => {
    const theme = useTheme();

    return (
        <StyledSLinearProgress
            variant='determinate'
            value={useProgress()}
            style={useTransition(theme)}
        />
    );
};

export { Progress as default };
