import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import {
    initialize as formInitialize,
    reduxForm
} from 'redux-form';
import { styled } from '@mui/system';
import { Typography } from '@mui/material';

// Libs
import { GridContainer, GridItem, Input } from '@formComponents';
import { Button } from '@libComponents';
import { hasFormErrorSelector } from 'common/redux.selectors';
import { getWeekDays } from '@libs/dateTime';
import AutocompleteBase from '@formComponents/Autocomplete';

import { FORM_NAME, formatedShiftData } from './common';
import { getInitialShift, updateManageShifts } from 'employee/ManageEmployee/redux.actions';
import Shift from 'common/components/shift';
import { SHIFT } from 'common/redux.datasets';
import { fetchDatasets } from '@libs/datasets';
import { getAllShift } from 'common/components/shift/redux.selectors';


const propTypes = {
    mode: PropTypes.oneOf(['create', 'update']),
};
const defaultProps = {
    mode: 'create',
};

/**
 * ReduxForm stepper rules
 * * Connect each step with reduxForm() to the same form name.
 * * Specify the destroyOnUnmount: false flag to preserve form data across form component unmounts.
 */
const ButtonWrapper = styled('div')({
    position: 'absolute',
    bottom: 0,
    right: '50%',
    transform: 'translate(50%, 50%)',
    zIndex: 4,
});

const StyledButton = styled(Button)(({ theme: { spacing, typography } }) => ({
    height: spacing(4.75),
    width: spacing(25),
    fontSize: typography.subtitle1.fontSize,
    marginLeft: spacing(0.75),
    marginRight: spacing(0.75),
}));

const StyledContainer = styled(GridContainer)(({ theme: { spacing, typography } }) => ({
    alignItems: 'center',
    padding: 0
}));

const ManageShifts = reduxForm({
    form: FORM_NAME, // Form name
    destroyOnUnmount: false, // Preserve data
    onSubmit: (v) => v,
})(({ mode, handleSubmit: handleReduxFormSubmit, ...rest }) => {
    const { state } = useLocation();
    const RXDispatch = useDispatch();

    const { email } = state ? state : "";
    const hasError = useSelector((state) =>
        hasFormErrorSelector(state, FORM_NAME)
    );

    const shifts = useSelector((state) => getAllShift(state));


    useEffect(() => {
        if (email) {
            RXDispatch(fetchDatasets(SHIFT));
            RXDispatch(getInitialShift({ handleSubmit: handleGetManageShifts, payload: { employeeEmail: email } }));
            RXDispatch(formInitialize(FORM_NAME, { employeeEmail: email }));
        }
    }, [email, RXDispatch]);

    const handleGetManageShifts = ({ enhancedEmployeeShiftData }) => {
        RXDispatch(formInitialize(FORM_NAME, enhancedEmployeeShiftData));
    }

    const handleManage = () => {
        if (hasError) {
            handleReduxFormSubmit();
            return;
        }
        RXDispatch(updateManageShifts({ handleSubmit }))
    }


    const handleSubmit = (payload) => {
        // console.log(payload);
    }

    let weekdays = getWeekDays();

    return (
        <>
            <GridContainer sx={{ pb: 1 }}>
                <GridItem md={4}>
                    <Input
                        name='employeeEmail'
                        placeholder='Employee Email'
                        label='Employee E-Mail'
                        disabled={true}
                        autoComplete="do-not-autofill"
                    />
                </GridItem>
            </GridContainer>
            <GridContainer>
                {weekdays.map(({ label, value }, i) => (
                    <GridItem md={12} key={label}>
                        <StyledContainer>
                            <GridItem lg={3} sm={6} >
                                <Typography variant='h6'>{label}</Typography>
                            </GridItem>
                            <GridItem lg={3} sm={6}>
                                <AutocompleteBase
                                    name={`shifts${value}`}
                                    placeholder='Select Shift'
                                    label='Select Shift'
                                    options={formatedShiftData(shifts)}
                                    ContainerComponent={Shift}
                                />
                            </GridItem>
                        </StyledContainer>
                    </GridItem>
                ))}
            </GridContainer>


            <ButtonWrapper>
                <StyledButton onClick={handleManage}>
                    UPDATE
                </StyledButton>
            </ButtonWrapper>
        </>
    );
});

ManageShifts.propTypes = propTypes;
ManageShifts.defaultProps = defaultProps;

export { ManageShifts as default, ManageShifts };

