import { SHIFT } from 'common/redux.datasets';
import { commonSelector } from 'common/redux.selectors';
import { createSelector } from 'reselect';


const getAllShift = createSelector(
    commonSelector,
    data => {
        return data && data[SHIFT] && data[SHIFT].data ? data[SHIFT].data : []  
    }
);

export {  getAllShift };
