import { Fragment } from 'react';
import PropTypes from 'prop-types';

import {
    styled,
    alpha,
    useTheme,
    Accordion,
    AccordionSummary,
    Collapse,
} from '@mui/material';
import ExpandIcon from '@mui/icons-material/ExpandMore';

import { useIsMinified } from '../../hooks';
import {
    useExpanded,
    useAccordionStyle,
    useHandleAccordion,
    useTransitionExpand,
} from './hooks';
import RouteHeader from './RouteHeader';

const propTypes = {
    name: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired,
    icon: PropTypes.string,
    children: PropTypes.array,
    depth: PropTypes.number,
};

const defaultProps = {
    icon: void 0,
    depth: 0,
    children: [],
};

const StyledAccordion = styled(Accordion)(
    ({ theme: { spacing, shadows } }) => ({
        backgroundColor: 'var(--accordionColor)',
        boxShadow: shadows[0],
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: spacing(5.5),
        '&::before': {
            display: 'none',
        },
        '&.Mui-expanded': {
            marginTop: 0,
            marginBottom: 0,
        },
    })
);
const StyledAccordionSummary = styled(AccordionSummary)(
    ({ theme: { spacing, transitions, palette } }) => ({
        backgroundColor: 'var(--headerColor)',
        color: palette.common.white,
        width: '100%',
        height: '100%',
        display: 'flex',
        minHeight: 'unset',
        paddingLeft: 0,
        paddingRight: 0,
        transition: transitions.create('background', {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.shortest,
        }),
        '&.Mui-expanded': {
            minHeight: spacing(5.5),
        },
        '& .MuiAccordionSummary-content': {
            height: spacing(5.5),
            justifyContent: 'var(--minifyJustify)',
            marginTop: 0,
            marginBottom: 0,
            '& > :last-child': {
                paddingRight: 0,
            },

            '&.Mui-expanded': {
                marginTop: 0,
                marginBottom: 0,
            },
        },
        '& .MuiAccordionSummary-expandIconWrapper': {
            color: palette.common.white,
            marginRight: spacing(2),
        },
        '&:hover': {
            backgroundColor: alpha(palette.primary.main, 0.8),
        },
    })
);

const Route = ({ icon, name, route, children, depth }) => {
    const theme = useTheme();
    const isMinified = useIsMinified();
    const expanded = useExpanded(route);
    const transitionExpand = useTransitionExpand(expanded, theme);

    return (
        <StyledAccordion
            expanded={expanded}
            style={useAccordionStyle({ depth, children, route, theme })}
        >
            <StyledAccordionSummary
                onClick={useHandleAccordion({
                    children,
                    route,
                })}
                expandIcon={
                    children.length && !isMinified ? (
                        <ExpandIcon />
                    ) : (
                        <Fragment />
                    )
                }
            >
                <RouteHeader
                    icon={icon}
                    name={name}
                    childRoutes={children}
                    route={route}
                    depth={depth}
                />
            </StyledAccordionSummary>
            {transitionExpand && (
                <Collapse
                    in={!!children.length && !isMinified}
                    timeout={theme.transitions.duration.complex}
                >
                    {children.map((route, index) => (
                        <Route key={index} {...route} depth={depth + 1} />
                    ))}
                </Collapse>
            )}
        </StyledAccordion>
    );
};

Route.propTypes = propTypes;
Route.defaultProps = defaultProps;

export default Route;
