import { useLayoutEffect, useMemo, useState } from 'react';

import { useStepperState } from '../../StepContext';

const useValid = (index) => {
    const { contents } = useStepperState();
    return useMemo(() => {
        const nextContent = contents[index + 1];

        if (nextContent) {
            return !nextContent.touched || contents[index].valid;
        } else return true;
    }, [index, contents]);
};

const useColor = (index, { palette, transitions }) => {
    const { activeStep } = useStepperState();
    const validColor = palette.success.main;
    const invalidColor = palette.tertiary.light;

    const [color, setColor] = useState(validColor);
    const [duration, setDuration] = useState(0);

    const valid = useValid(index);

    useLayoutEffect(() => {
        setDuration(0);
        setColor(validColor);

        const transition =
            !valid &&
            setTimeout(() => {
                setColor(invalidColor);
                setDuration(transitions.duration.short);
            }, transitions.duration.complex);

        return () => clearTimeout(transition);
    }, [activeStep, valid]);

    return useMemo(
        () => ({
            '--color': color,
            '--duration': `${duration / 1000}s`,
        }),
        [color, duration]
    );
};

export { useColor };
