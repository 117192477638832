import React, { useEffect } from 'react';

// Libs
import ContentContainer from '@libComponents/ContentContainer';

import TableCellContainer from '@libComponents/table/TableCellContainer';
import TableContainer from '@libComponents/table/TableContainer';
import TableHeaderContainer from '@libComponents/table/TableHeaderContainer';
import { datasetLoadingSelector, entitySelector, createFetchPaginatedDataset } from '@libs/datasets';
import { Box, TableBody, TableRow, Tooltip, Typography, styled } from '@mui/material';

import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { DOMAIN_NAME, ROWS_PER_PAGE } from "user/common";
import { USERS } from 'user/redux.datasets';
import { theme } from 'app/Theme';
import { Button, Pagination } from '@libComponents';
import { useLocation, useNavigate } from 'react-router';
import createCachedSelector from 're-reselect';
import FilterInput from '@libComponents/FilterInput';

const propTypes = {
};

const defaultProps = {
    entity: USERS,
    domain: DOMAIN_NAME,
    loading: true,
};

const StyledBox = styled(Box)(
    ({ theme: { spacing, palette, typography, shape } }) => ({
        width: '100%',
        overflow: 'auto',
        '& table': {
            tableLayout: 'fixed'
        }
    })
);
const DisabledStyledBox = styled(Box)(
    ({ theme: { spacing, palette, typography, shape } }) => ({
        color: palette.text.disabled,
        backgroundColor: palette.background.disabled,
        padding: '6px',
        borderRadius: '5px',
        display: 'inline-block'
    })
);
const SuccessStyledBox = styled(Box)(
    ({ theme: { spacing, palette, typography, shape } }) => ({
        color: palette.text.success,
        backgroundColor: palette.success.main,
        padding: '6px',
        borderRadius: '5px',
        display: 'inline-block'
    })
);

const TABLE_DATA = {
    'email': 'Email',
    'accountIsActive': 'User Status'
};

const totalRowsSelector = createCachedSelector(entitySelector,
    (resp) => resp.total ? parseInt(resp.total) : 0
)((state, domain, entity) => `@data-${domain}-${entity}`);


const dataSelector = createSelector(
    (state) => entitySelector(state, DOMAIN_NAME, USERS),
    (resp) => ((resp && resp.data) ? (resp.data.map((obj) => Object.fromEntries(Object.keys(TABLE_DATA).map((key) => [key, obj[key]])))) : [])
);

const mapState = (state, { domain, entity }) => ({
    data: dataSelector(state, domain, entity),
    loading: datasetLoadingSelector(state, domain, entity),
    totalRows: totalRowsSelector(state, domain, entity)
});

const mapDispatch = (dispatch, { entity }) => ({
    fetchDataset: ({ offset = "", limit = ROWS_PER_PAGE, shouldAppend = false, searchTerm }) => dispatch(
        createFetchPaginatedDataset({ entity: entity, domain: DOMAIN_NAME })({
            limit,
            offset,
            shouldAppend,
            searchTerm
        })
    ),
});

const EmployeeLeaveStatus = connect(
    mapState,
    mapDispatch
)(({ fetchDataset, data, totalRows = 0 }) => {
    const [pageSize, setPageSize] = React.useState(ROWS_PER_PAGE);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [searchTerm, setSearchTerm] = React.useState("");

    const navigate = useNavigate();
    const location = useLocation();
    const query = new URLSearchParams(location.search);

    useEffect(() => {
        const value = query.get('searchTerm') || "";
        setSearchTerm(value)
        fetchDataset({
            offset: "",
            searchTerm: value,
        })
    }, [location.search]);

    const handleClick = (row) => {
        navigate(`/leave-details/${row?.email?.split("@")[0]}`, { state: { ...row, searchTerm } });
    }

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
        fetchDataset({
            offset: (newPage - 1) * pageSize,
            limit: pageSize,
            searchTerm: searchTerm
        })
    };

    const handleRowsPerPageChange = (pageSize) => {
        setPageSize(pageSize);
        setCurrentPage(1);
        fetchDataset({
            offset: 0 * pageSize,
            limit: pageSize,
            searchTerm: searchTerm
        });
    }

    const handleSearch = (value) => {
        setSearchTerm(value);
        fetchDataset({
            offset: "",
            searchTerm: value,
            limit: pageSize
        })
    }

    if (!data) return null;
    return (
        <ContentContainer title="User List" headerBg={theme.palette.background.mainHeader}
            AdornmentComponent={
                <Box sx={{ width: 300, background: theme.palette.common.white }}>
                    <FilterInput
                        name='employee-email'
                        handleSearch={handleSearch}
                        value={searchTerm}
                    />
                </Box>}>
            <StyledBox>
                <TableContainer >
                    <TableHeaderContainer>
                        {Object.values(TABLE_DATA).map((th, i) => (
                            <TableCellContainer
                                sx={{ pl: i === 0 ? 4 : 2, fontWeight: 'bold' }}
                                typography="caption" key={th}
                            >
                                {th}
                            </TableCellContainer>
                        ))}
                        <TableCellContainer
                            align='right'
                            sx={{ pr: 16.5, fontWeight: 'bold' }}
                        >
                            Action
                        </TableCellContainer>
                    </TableHeaderContainer>
                    <TableBody>
                        {data.map((row, i) => (
                            <TableRow key={i}>
                                {Object.keys(TABLE_DATA).map((key, index) => {
                                    switch (key) {
                                        case 'roles':
                                            return (
                                                <TableCellContainer key={index} sx={{ textTransform: 'capitalize' }} >
                                                    <Tooltip
                                                        title={
                                                            <React.Fragment>
                                                                <Typography
                                                                    component='div'
                                                                    variant='caption'
                                                                    color='inherit'
                                                                    sx={{ textTransform: 'capitalize' }}
                                                                >
                                                                    {row[key].map((data) => data.name).join(', ')}
                                                                </Typography>
                                                            </React.Fragment>}>
                                                        <Box>
                                                            {row[key].map((data) => data.name).join(', ')}
                                                        </Box>
                                                    </Tooltip>
                                                </TableCellContainer>
                                            );
                                        case 'accountIsActive':
                                            return (
                                                <TableCellContainer key={index}>
                                                    {row[key] ?
                                                        (<SuccessStyledBox>Active</SuccessStyledBox>) :
                                                        (<DisabledStyledBox>Deactivated</DisabledStyledBox>)}
                                                </TableCellContainer>
                                            );
                                        default:
                                            return (
                                                <TableCellContainer
                                                    key={index}
                                                    sx={{ pl: 4 }}
                                                >
                                                    {row[key]}
                                                </TableCellContainer>
                                            );
                                    }
                                })}
                                <TableCellContainer
                                    align='right'
                                    sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}
                                >
                                    <Button
                                        variant='outlined'
                                        sx={{ mr: 2, cursor: "pointer", width: "auto" }}
                                        type='button'
                                        color='primary'
                                        onClick={() => { handleClick(row) }}
                                    >
                                        View Leave Details
                                    </Button>


                                </TableCellContainer>
                            </TableRow>
                        ))}
                    </TableBody>
                </TableContainer>

                <Box sx={{ display: "flex", justifyContent: "center", ml: 2 }}>
                    <Pagination
                        count={Math.ceil(totalRows / pageSize)}
                        onChange={handleChangePage}
                        page={currentPage}
                        sx={{ display: 'flex', justifyContent: 'center' }}
                        isRowsPerPageVarient={true}
                        rowsPerPageProps={{
                            name: 'appliedLeaves',
                            onChange: handleRowsPerPageChange,
                            value: pageSize
                        }}
                    />
                </Box>

            </StyledBox>

        </ContentContainer>
    );
});

EmployeeLeaveStatus.propTypes = propTypes;
EmployeeLeaveStatus.defaultProps = defaultProps;

export default EmployeeLeaveStatus;
