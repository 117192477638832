import { CREATE_USER, DELETE_USER, GET_USER, UPDATE_USER } from './redux.actionTypes';

export const createUser = ({ handleSubmit, payload }) => ({
    type: CREATE_USER,
    meta: { handleSubmit },
    payload,
});

export const deleteUser = ({ handleSubmit, payload }) => ({
    type: DELETE_USER,
    meta: { handleSubmit },
    payload,
});

export const updateUser = ({ handleSubmit, ...payload }) => ({
    type: UPDATE_USER,
    meta: { handleSubmit },
    payload,
});

export const getUser = ({ handleSubmit, ...payload }) => ({
    type: GET_USER,
    meta: { handleSubmit },
    payload,
});