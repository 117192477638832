import { Field } from 'redux-form';

export const withField =
    (fieldProps = {}) =>
    (Component) =>
    (props) =>
        (
            <Field
                fieldName={props.name}
                component={Component}
                {...props}
                {...fieldProps}
            />
        );
