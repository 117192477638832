import { formValueSelector } from 'redux-form';
import { select } from 'redux-saga/effects';
import { FORM_NAME } from '../../../Employee/common';

export default function* (action) {
    const {
        meta: { handleSubmit = () => { } },
        payload,
        ...rest
    } = action;

    const value = yield select(
        formValueSelector(FORM_NAME),
        payload.fieldName
    );
    handleSubmit(value);
}





