import {useState, Fragment} from 'react';
import { EmployeeCategoryCreateUpdate } from './EmployeeCategoryCreateUpdate';
import ViewEmployeeCategory from './ViewEmployeeCategory';
import { DeleteEmployeeCategory } from './DeleteEmployeeCategory';

export default function EmployeeCategory() {
    const [viewType, setViewType] = useState('default');
    const [data, setData] = useState({ mode: 'create', row: {} });

    return (
        <Fragment>
            {viewType === 'default' && (
                <ViewEmployeeCategory setViewType={setViewType} setData={setData} />
            )}
            {viewType === 'createEmployeeCategory' && (
                <EmployeeCategoryCreateUpdate
                    mode={data.mode}
                    row={data.row}
                    setViewType={setViewType}
                />
            )}
            {viewType === 'deleteEmployeeCategory' && (
                <DeleteEmployeeCategory
                    row={data.row}
                    setViewType={setViewType}
                />
            )}
        </Fragment>
    );
}
