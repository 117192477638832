import { createSelector } from 'reselect';

export const authSelector = (state) => state.auth;
export const authLoadingSelector = createSelector(
    authSelector,
    ({ loading }) => loading
);
export const userEmailSelector = createSelector(
    authSelector,
    ({ email }) => email
);

export const domainSelector = createSelector(
    authSelector,
    ({ email }) => email.split("@")[1]
);

export const isAuthenticatedSelector = createSelector(
    userEmailSelector,
    (email) => !!email
);
