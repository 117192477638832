import FieldProgressBar from '@baseComponents/FieldProgressBar';
import HelperText from '@baseComponents/HelperText';
import Label from '@baseComponents/Label';
import { withField } from '@libs/reduxForm';
import { FormControl, styled ,TextareaAutosize} from '@mui/material';

const StyledInput = styled(TextareaAutosize )(
    ({ theme: { spacing, palette, typography, shape } }) => ({
        width: '100%',
        minHeight: '70px',
        border: '1px solid',
        borderColor: palette.border.main,
        borderRadius: shape.borderRadius,
        paddingLeft: spacing(2),
        maxWidth: 'calc(200% + 32px)',
        minWidth: '100%',
        padding: '10px 16px',
        fontSize: typography.subtitle2.fontSize,
        raws: '5',
        '&:hover': {
            borderColor: palette.border.dark,
        },
        '&:focus': {
            borderColor: palette.grey[700],
            outline: 'none',
        },
        '&.Mui-focused': {
            borderColor: palette.grey[500],
            '&:hover': {
                borderColor: palette.grey[700],
            },
        },
        '&.Mui-error': {
            borderColor: `${palette.error.light}`,
            '&:hover': {
                borderColor: `${palette.error.dark}`,
            },
        },
        '&[error="true"]':{
            borderColor: `${palette.error.light}`,
        },
        '&.Mui-disabled': {
            display: 'flex',
            alignItems: 'center',
            backgroundColor: palette.background.disabled,
            color: palette.secondary.main,

            '&:hover': {
                borderColor: palette[300],
            },
        },
    })
);

const StyledBox = styled('div')(
    ({ theme: { spacing, palette, typography } }) => ({
        '& .MuiAutocomplete-option': {
            color: palette.text.primary,
            fontSize: '12px',
        },
    })
);
const StyledFormControl = styled(FormControl)({
    width: '100%',
    ' label': {
        paddingTop: '10px',
    },
});

const TextArea = withField()(
    ({
        fieldName,
        input,
        disabled,
        required,
        label,
        loading,
        staticValue,
        meta: { form, touched, error = null, asyncValidating },
        placeholder,
        options,
        className: classNameProp,
        ...restProps
    }) => {
        const id = `${form}-${fieldName}`;
        const isDisabled = disabled || loading;
        const hasError = !isDisabled && touched && !!error;

        const inputBaseProps = {
            id,
            error: hasError.toString(),
            disabled: isDisabled,
            ...(staticValue ? { value: staticValue } : input),
            ...restProps,
        };

        return (
            <StyledFormControl className={classNameProp}>
                <Label
                    className={classNameProp}
                    label={label}
                    disabled={isDisabled}
                    required={required}
                    htmlFor={id}
                    error = {hasError}
                />
                <StyledBox>
                    <StyledInput
                        placeholder={placeholder}
                        {...inputBaseProps}
                    />
                </StyledBox>
                {(loading || asyncValidating) && (
                    <FieldProgressBar asyncValidating={asyncValidating} />
                )}
                {hasError && <HelperText msg={error} required={required} />}
            </StyledFormControl>
        );
    }
);

export default TextArea;
