export default [
    {
        key: 'bank',
        label: 'Bank Account Details 1',
    },
    {
        key: 'nominees',
        label: 'Nominee Information',
    },
    {
        key: 'references',
        label: 'References',
    },
    {
        key: 'insurances',
        label: 'Insurance Information',
    }
]