import { takeLatest } from 'redux-saga/effects';
import { CREATE_ROLE, DELETE_ROLE, GET_PERMISSION, UPDATE_ROLE } from 'roles/redux.actionTypes';
import createRoleWorker from './rolesSagas/createRoleWorker';
import deleteRoleWorker from './rolesSagas/deleteRoleWorker';
import getPermissionWorker from './rolesSagas/getPermissionWorker';
import updateRoleWorker from './rolesSagas/updateRoleWorker';


export default function* () {
    yield takeLatest(CREATE_ROLE, createRoleWorker);
    yield takeLatest(DELETE_ROLE, deleteRoleWorker);
    yield takeLatest(GET_PERMISSION, getPermissionWorker);
    yield takeLatest(UPDATE_ROLE, updateRoleWorker);
}