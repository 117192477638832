import { TimesheetsNoDataFound } from 'timeAndAttendance/components';
import { useMonthlyTimesheetsState } from './useMonthlyTimesheets';
import UserTimesheet from 'timeAndAttendance/Timesheet/TimesheetsMonthlyView/UsersTimesheet/UserTimesheet';

const UsersTimesheet = () => {
    const { data: user, year, month } = useMonthlyTimesheetsState();
    return user.email ? (
        <UserTimesheet {...user} yearMonth={`${year}-${month}`} />
    ) : (
        <TimesheetsNoDataFound />
    );
};

export default UsersTimesheet;
