import { Fragment } from 'react';
import { reduxForm } from 'redux-form';

// Libs
import { GridContainer, GridItem, Input } from '@formComponents';
import ContentContainer from '@libComponents/ContentContainer';

import { Divider, Typography } from '@mui/material';
import AcademicQualification from 'common/components/AcademicQualification';
import { FORM_NAME, LABELS } from '../../common';
import WorkExperiences from 'common/components/WorkExperience';

const Academic = reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
})(() => {
    return (
        <ContentContainer
            title={LABELS.academic}
            AdornmentComponent={<Fragment />}
        >
            <GridContainer>
                <GridItem md={12} sx={{ mb: 1, mt: 2 }}>
                    <Typography>Academic Informations</Typography>
                    <Divider light />
                </GridItem>

                <GridItem md={12}>
                    <AcademicQualification />
                </GridItem>
                <GridItem md={12} sx={{ mb: 1, mt: 2 }}>
                    <Typography>Work Experiences</Typography>
                    <Divider light />
                </GridItem>
                <GridItem md={12}>
                    <WorkExperiences />
                </GridItem>
            </GridContainer>
        </ContentContainer>
    );
});

export default Academic;
