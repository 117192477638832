import { styled } from '@mui/material';
import { useOverviewState } from '../useOverviewProvider';
import SummaryBox from './SummaryBox';

const StyledContainer = styled('div')(
    ({ theme: { spacing, palette, typography, shape } }) => ({
        display: 'flex',
        gap: spacing(1),
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '24px 20px'
    })
);

const info = {
    "totalEmployees": {
        description: 'Total Employee',
        color: "db_overview_1",
        status: 'all'
    },
    "totalPresent": {
        description: 'Present',
        color: "db_overview_2",
        status: "in"
    },
    "totalAbsent": {
        description: 'Absent',
        color: "db_overview_3",
        status: "absent"
    },
    "totalLate": {
        description: 'Late to join',
        color: "db_overview_4",
        status: "arrivedLate"
    },
    "sickLeave": {
        description: 'Sick Leave',
        color: "db_overview_5",
        status: "leave",
        leaveType: "sick"
    },
    "otherLeave": {
        description: 'Other Leave',
        color: "db_overview_6",
        status: "leave",
        leaveType: "other"
    },
    "totalRemote": {
        description: 'Joined Remotely',
        color: "db_overview_7",
        status: "remote",
    }
}

const DataTable = () => {
    const { overviewSummary } = useOverviewState();
    return (
        <StyledContainer>
            {
                Object.entries(info).map(([key, value]) => {
                    value = {
                        ...value,
                        present: overviewSummary[key] ? overviewSummary[key] : 0,
                        total: overviewSummary.totalEmployees ? overviewSummary.totalEmployees : 0
                    }

                    return (<SummaryBox key={key} data={value} />)
                })
            }
        </StyledContainer>
    );
};

export default DataTable;
