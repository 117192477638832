import { takeLatest } from 'redux-saga/effects';
import { CREATE_ATTENDANCE, GET_ATTENDANCE, GET_STATUS, GET_WEEKLY_STATUS, SUBMIT_STATUS } from 'timeAndAttendance/redux.actionTypes';

// Workers
import submitStatusWorker from './submitStatusWorker';
import getStatusWorker from './getStatusWorker';
import getAttendanceWorker from './getAttendanceWorker';
import getWeeklyStatusWorker from './getWeeklyStatusWorker';
import createAttendance from './createAttendance';


// Watchers
export default function* () {
    yield takeLatest(GET_STATUS, getStatusWorker);
    yield takeLatest(SUBMIT_STATUS, submitStatusWorker);
    yield takeLatest(GET_ATTENDANCE, getAttendanceWorker);
    yield takeLatest(GET_WEEKLY_STATUS, getWeeklyStatusWorker);
    yield takeLatest(CREATE_ATTENDANCE, createAttendance);
}