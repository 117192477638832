import { EMPLOYEE_CATEGORY } from 'common/redux.datasets';
import { commonSelector } from 'common/redux.selectors';
import { createSelector } from 'reselect';


const getAllEmployeeCategory = createSelector(
    commonSelector,
    data => {
        return data && data[EMPLOYEE_CATEGORY] && data[EMPLOYEE_CATEGORY].data.map((item) => {
            return {
                name: item.name,
                description: item.description,
            }
        })
    }
);

export { getAllEmployeeCategory };

