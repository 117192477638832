import {
    call,
    fork,
    put,
    take,
    takeEvery,
    select,
    takeLatest
} from 'redux-saga/effects';
import { channel } from 'redux-saga';

import { apiRequest } from '@libs/apiRequest';
import { setSnack, setLoading } from 'utilities/redux.actions';

import composeEntities from './composeEntities';
import {
    FETCH_DATASETS,
    FETCH_PAGINATED_DATASET,
    FETCH_GRID_DATASET
} from './libs.actionTypes';
import {
    setDatasetLoading,
    setDataset,
    setPaginatedDataset
} from './libs.actions';

const fetchPaginationDatasetFactory = (entities) =>
    function* ({
        meta: { domain, entity, shouldAppend },
        payload: { filterCategory = '', filterKey = '', ...paginationInfo }
    }) {
        const { url, normalizer, params, fields } = entities[entity];

        const isParamsFn = typeof params === 'function';

        // If normalizer takes more than one argument (more than just 'data')
        const isStoreNeeded =
            isParamsFn || (!!normalizer && normalizer.length > 1);

        const store = isStoreNeeded ? yield select() : void 0;

        const rootParams = isParamsFn ? params(store) : params;

        // Flips through fields to find the correct name
        const category = filterCategory
            ? Object.entries(fields).reduce((acm, [rawLabel, label]) => {
                  if (label === filterCategory) return rawLabel;
                  else return acm;
              }, '')
            : '';

        // Fixes naming conventions
        const normalizedPageInfo = {
            ...paginationInfo,
            filter: filterKey,
            category
        };
        yield put(setLoading(true));

        try {
            const response = yield call(apiRequest, {
                url,
                params: { ...rootParams, ...normalizedPageInfo }
            });

            const {
                data: dataset,
                nextPage,
                offset,
                total
            } = normalizer(response);
            yield put(
                setPaginatedDataset({
                    domain,
                    entity,
                    shouldAppend,
                    dataset,
                    nextPage,
                    offset,
                    total
                })
            );
            yield put(setLoading(false));
        } catch {
            yield put(setLoading(false));
            console.log(`Error while fetching ${entity} resources`);
        }
    };

function* fetchDatasetWorker(datasetsChannel) {
    while (true) {
        const {
            entity,
            url,
            domain,
            selector,
            normalizer,
            params = {},
            dataGrid = false
        } = yield take(datasetsChannel);
        const dataExists = yield select(selector);
        if (!dataExists) {
            const isParamsFn = typeof params === 'function';

            // If normalizer takes more than one argument (more than just 'data')
            const isStoreNeeded =
                isParamsFn || (!!normalizer && normalizer.length > 1);

            const store = isStoreNeeded ? yield select() : void 0;

            yield put(setDatasetLoading({ entity, domain, loading: true }));

            try {
                const response = yield call(apiRequest, {
                    url,
                    params: isParamsFn ? params(store) : params
                });
                const dataset = normalizer(response, store);
                yield put(setDataset({ entity, domain, dataset }));
                yield put(
                    setDatasetLoading({ entity, domain, loading: false })
                );
            } catch (error) {
                if (dataGrid) {
                    yield put(
                        setSnack({
                            message: error?.response?.data?.message,
                            type: 'error'
                        })
                    );
                    yield put(
                        setDatasetLoading({ entity, domain, loading: false })
                    );
                }
                console.log(`Error while fetching ${entity} resources`);
            }
        }
    }
}
// Channels
const fetchDatasetsChannelFactory = (entities) =>
    function* fetchDatasetsChannel({ payload: datasets }) {
        const datasetsChannel = yield call(channel);

        // create 6 worker 'threads'
        for (let i = 0; i < 6; i++) {
            yield fork(fetchDatasetWorker, datasetsChannel);
        }

        for (const dataset of datasets) {
            yield put(datasetsChannel, entities[dataset]);
        }
    };

const fetchGridDatasetChannelFactory = (entities) =>
    function* ({ meta: { domain, entity }, payload = {} }) {
        const { url, params, normalizer } = entities[entity];

        const isParamsFn = typeof params === 'function';

        // If normalizer takes more than one argument (more than just 'data')
        const isStoreNeeded = isParamsFn;

        const store = isStoreNeeded ? yield select() : void 0;

        const rootParams = isParamsFn ? params(store) : params;

        try {
            yield put(setDatasetLoading({ entity, domain, loading: true }));

            const response = yield call(apiRequest, {
                url,
                params: { ...rootParams, ...payload }
            });

            const dataset = normalizer(response, store);

            yield put(setDataset({ entity, domain, dataset }));
            yield put(setDatasetLoading({ entity, domain, loading: false }));
        } catch (error) {
            yield put(setDatasetLoading({ entity, domain, loading: false }));
            yield put(
                setSnack({
                    message: error?.response?.data?.message,
                    type: 'error'
                })
            );
            console.log(`Error while fetching ${entity} resources`);
        }
    };
const watchers = (datasets) => {
    const entities = composeEntities(datasets);

    const fetchDatasetsChannel = fetchDatasetsChannelFactory(entities);
    const fetchPaginationDatasetWorker =
        fetchPaginationDatasetFactory(entities);

    const fetchGridDatasetWorker = fetchGridDatasetChannelFactory(entities);

    return function* () {
        yield takeEvery(FETCH_DATASETS, fetchDatasetsChannel);
        yield takeEvery(FETCH_PAGINATED_DATASET, fetchPaginationDatasetWorker);
        yield takeLatest(FETCH_GRID_DATASET, fetchGridDatasetWorker);
    };
};

export default watchers;
