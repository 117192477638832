import apiRequest from '@libs/apiRequest';
import { LEAVE_CATEGORY } from 'leaveCategory/redux.datasets';
import { call, put } from 'redux-saga/effects';
import { setLoading, setSnack } from 'utilities/redux.actions';

export default function* (action) {
    const {
        meta: { handleSubmit = () => { } },
        payload
    } = action;

    yield put(setLoading(true));

    try {
        const { data } = yield call(
            apiRequest,
            {
                url: `leave-category?name=${payload.name}`,
                method: 'delete'
            }
        );
        yield put(setLoading(false));
        yield put(setSnack({
            message: "Category deleted successfully",
            type: 'success',
        }));

        yield put({
            type: "deleteCategory",
            payload: {
                entity: LEAVE_CATEGORY,
                value: data
            },
        });
        return handleSubmit(data);

    } catch (error) {
        yield put(setLoading(false));
        yield put(setSnack({
            message: error?.response?.data?.message || error?.message,
            type: 'error',
        }));
        return handleSubmit(error);
    }
}
