import { GridItem } from '@formComponents';
import FormArray from '@formComponents/FormArray';
import InputRadio from '@formComponents/InputRadio';
import { RELATIVE_OPTIONS } from 'employee/common';
import { getEmployeeFieldValue } from 'employee/redux.actions';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import renderEmployeeOptions from './RelatedEmployeeOptions';

const RelativeEmployeeInformation = ({ ...restProps }) => {
    const [hasRelative, setHasRelative] = React.useState(false);

    const handleChange = (event, value) => {
        setHasRelative(JSON.parse(value));
    };

    const RXDispatch = useDispatch();

    useEffect(() => {
        RXDispatch(
            getEmployeeFieldValue({
                handleSubmit: getFieldValue,
                payload: { fieldName: 'relatedEmployees' },
            })
        );
    }, []);

    const getFieldValue = (payload) => {
        if (payload && payload.length > 0) {
            setHasRelative(true);
        }
    };

    return (
        <React.Fragment>
            <GridItem md={12} sx={{ pl: 0 }}>
                <InputRadio
                    name='relativeInEchologyx'
                    label='Do you have any relative in EchoLogyx?'
                    options={RELATIVE_OPTIONS}
                    onChange={handleChange}
                    defaultValue = {hasRelative}
                />
            </GridItem>
            {hasRelative && (
                <FormArray
                    name='relatedEmployees'
                    label='Add Related Employees'
                    renderSubFields={renderEmployeeOptions}
                />
            )}
        </React.Fragment>
    );
};

export default RelativeEmployeeInformation;
