import React, { useEffect } from 'react';

// Libs
import ContentContainer from '@libComponents/ContentContainer';

import { datasetLoadingSelector, entitySelector, createFetchPaginatedDataset } from '@libs/datasets';

import { Button, Pagination } from '@libComponents';
import DialogWrapper from '@libComponents/Dialog';
import { TableCellContainer } from '@libComponents/table/TableCellContainer';
import TableContainer from '@libComponents/table/TableContainer';
import TableHeaderContainer from '@libComponents/table/TableHeaderContainer';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/EditOutlined';
import { Box, TableBody, TableRow, Typography, styled } from '@mui/material';
import createCachedSelector from 're-reselect';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { DOMAIN_NAME, ROWS_PER_PAGE } from "./common";
import { deleteRole } from './redux.actions';
import { ROLES } from './redux.datasets';

const propTypes = {
};

const defaultProps = {
    entity: ROLES,
    domain: DOMAIN_NAME,
    loading: true,
};

const totalRowsSelector = createCachedSelector(entitySelector,
    (resp) => resp.total ? parseInt(resp.total) : 0
)((state, domain, entity) => `@data-${domain}-${entity}`);

const dataSelector = createCachedSelector(entitySelector,
    (resp) => ((resp && resp.data) ? (resp.data.map(({ name, ...datum }) => ({
        name
    }))) : [])

)((state, domain, entity) => `@data-${domain}-${entity}`);

const mapState = (state, { domain, entity }) => ({
    data: dataSelector(state, domain, entity),
    loading: datasetLoadingSelector(state, domain, entity),
    totalRows: totalRowsSelector(state, domain, entity)
});

const mapDispatch = (dispatch, { entity }) => ({
    fetchDataset: ({ offset = "", limit = ROWS_PER_PAGE, shouldAppend = false }) => dispatch(
        createFetchPaginatedDataset({ entity: entity, domain: DOMAIN_NAME })({
            limit,
            offset,
            shouldAppend
        })
    ),
});

const StyledBox = styled(Box)(
    ({ theme: { spacing, palette, typography, shape } }) => ({
        width: "100%",
        overflow: "auto",
        "& table": {
            tableLayout: "fixed",
        }
    })
);


const RolesList = connect(
    mapState,
    mapDispatch
)(({ fetchDataset, data, totalRows = 0, ...props }) => {
    useEffect(() => {
        fetchDataset({ offset: "" });
    }, []);

    const RXDispatch = useDispatch();
    const navigate = useNavigate();

    let tableHeader = ["Role", "Action"];
    const [open, setOpen] = React.useState(false);
    const [payload, setSelectedPayload] = React.useState({});
    const [pageSize, setPageSize] = React.useState(ROWS_PER_PAGE);
    const [currentPage, setCurrentPage] = React.useState(1);

    const handleClose = (value) => {
        setOpen(false);
    };

    const onEditClick = (row) => {
        navigate(`/role-list/update/${row?.name}`, { state: row });
    }
    const onDeleteClick = (row) => {
        setOpen(true);
        setSelectedPayload(row);
    }

    const handleDelete = () => {
        RXDispatch(deleteRole({ handleSubmit: deleteCallBack, payload }));
    }

    const deleteCallBack = (resp) => {
        handleClose();
    }
    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
        fetchDataset({
            offset: (newPage - 1) * pageSize,
            limit: pageSize
        })
    };

    const handleRowsPerPageChange = (pageSize) => {
        setPageSize(pageSize);
        setCurrentPage(1);
        fetchDataset({
            offset: 0 * pageSize,
            limit: pageSize
        });
    }

    return (
        <ContentContainer title="Role List">
            <StyledBox>
                <TableContainer >
                    <TableHeaderContainer sx={{ boxShadow: "none" }}>
                        {tableHeader.map((th, i) => (
                            <TableCellContainer align={i !== 0 ? "right" : "left"}
                                sx={{
                                    textTransform: "capitalize", pl: (4), fontWeight: "bold",
                                    pr: i !== 0 ? "132px" : "0"
                                }}
                                typography="caption" key={th}>{th}</TableCellContainer>
                        ))}
                    </TableHeaderContainer>
                    <TableBody>
                        {data.map((row, i) => (
                            <TableRow key={i}>
                                {Object.entries(row).map(([key, tc], i) => {
                                    return (
                                        <TableCellContainer key={key} sx={{ pl: (4) }}>
                                            {tc}
                                        </TableCellContainer>
                                    )
                                })}
                                <TableCellContainer align="right">
                                    <Button
                                        variant='outlined'
                                        sx={{ mr: 2, cursor: "pointer" }}
                                        type='button'
                                        onClick={() => { onEditClick(row) }}
                                        color='primary'
                                        startIcon={<EditIcon color='primary' />}
                                    >
                                        Edit
                                    </Button>

                                    <DeleteOutline sx={{ cursor: 'pointer', opacity: 0.5, position: 'relative', top: 9 }} onClick={() => { onDeleteClick(row) }} />
                                </TableCellContainer>
                            </TableRow>
                        ))}
                    </TableBody>
                </TableContainer>

                <Box sx={{ display: "flex", justifyContent: "center", ml: 2 }}>
                    <Pagination
                        count={Math.ceil(totalRows / pageSize)}
                        onChange={handleChangePage}
                        page={currentPage}
                        sx={{ display: 'flex', justifyContent: 'center' }}
                        isRowsPerPageVarient={true}
                        rowsPerPageProps={{
                            name: 'attendance',
                            onChange: handleRowsPerPageChange,
                            value: pageSize
                        }}
                    />
                </Box>
            </StyledBox>

            <DialogWrapper open={open} onClose={handleClose} sx={{ textAlign: "center" }}>
                <Typography variant='h6' sx={{ mb: 4 }}>
                    Do you want to delete this role?
                </Typography>

                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 2 }}>
                    <Button onClick={handleClose} color="tertiary">Cancel</Button>
                    <Button onClick={handleDelete}>Delete</Button>
                </Box>
            </DialogWrapper>
        </ContentContainer>
    );
});

RolesList.propTypes = propTypes;
RolesList.defaultProps = defaultProps;

export default RolesList;
