import { FormHelperText, styled } from '@mui/material';
import PropTypes from 'prop-types';

const propTypes = {
    msg: PropTypes.string.isRequired,
    required: PropTypes.bool,
};

const defaultProps = {
    required: false,
};

const StyledFormHelperText = styled(FormHelperText)(
    ({ theme: { palette } }) => ({
        color: palette.error.main,
    })
);

const HelperText = ({ msg }) => {
    return <StyledFormHelperText>{msg}</StyledFormHelperText>;
};

HelperText.propTypes = propTypes;
HelperText.defaultProps = defaultProps;

export default HelperText;
