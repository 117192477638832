import makeContext from '@libs/makeContext';
import { DASHBOARD_OVERVIEW_INITIAL_API_PARAMS } from '../../common';


import {
    SET_DATE,
    SET_BRANCH,
    SET_MODAL_CLOSE,
    SET_FILTERS_OVERVIEW_ATTENDANCE,
    SET_FILTERS_OVERVIEW_LEAVE
} from '../../context.actionTypes';
import { normalizeIso8601Date } from '@libs/dateTime';

const initialState = {
    openModal: false,
    ...DASHBOARD_OVERVIEW_INITIAL_API_PARAMS
};

const reducer = (state, { type, payload }) => {
    switch (type) {
        case SET_DATE: {
            return {
                ...state,
                date: payload,
            };
        }
        case SET_BRANCH: {
            return {
                ...state,
                branch: payload
            };
        }
        case SET_FILTERS_OVERVIEW_ATTENDANCE: {
            return payload === 'remote' ? { ...state, joinedRemotely: true, openModal: true, status: "remote" } : { ...state, status: payload, openModal: true, joinedRemotely: false };
        }

        case SET_FILTERS_OVERVIEW_LEAVE: {
            return {
                ...state,
                status: payload,
                openModal: true
            };
        }
        case SET_MODAL_CLOSE: {
            return {
                ...state,
                openModal: false
            };
        }
        default:
            return state;
    }
};

const middleware = (state, action, { fetchData, fetchAttendanceData, fetchLeaveData }) => {
    switch (action.type) {

        case SET_DATE: {
            const { branch } = state;
            const { payload } = action;

            fetchData({
                branch,
                date: payload,
            });

            return action;
        }

        case SET_BRANCH: {
            const { date } = state;
            const { payload } = action;

            fetchData({
                date: normalizeIso8601Date(date),
                branch: payload
            });

            return action;
        }

        case SET_FILTERS_OVERVIEW_ATTENDANCE: {
            const { date, branch } = state;
            const { payload } = action;

            fetchAttendanceData({
                date: normalizeIso8601Date(date),
                filters: payload === 'remote' ? { branch: branch, joinedRemotely: true } : { branch: branch, status: payload === 'all' ? "" : payload }
            });

            return action;
        }

        case SET_FILTERS_OVERVIEW_LEAVE: {
            const { date, branch } = state;
            const { payload } = action;

            fetchLeaveData({
                date: normalizeIso8601Date(date),
                'is-sick-leave': payload === 'sick' ? true : false,
                filters: {
                    branch: branch,
                }
            });

            return action;
        }
        default:
            return action;
    }
};

const [
    OverviewProvider,
    useOverviewState,
    useOverviewDispatch,
    useOverview
] = makeContext(reducer, initialState, {
    name: 'Overview',
    middleware
});

export {
    useOverview as default,
    OverviewProvider,
    useOverviewState,
    useOverviewDispatch,
    SET_DATE,
    SET_BRANCH,
    SET_MODAL_CLOSE
};
