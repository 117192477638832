import { useEffect, useState } from 'react';

// Libs
import ContentContainer from '@libComponents/ContentContainer';
import { createFetchPaginatedDataset, datasetLoadingSelector, entitySelector } from '@libs/datasets';
import { Box, styled } from '@mui/material';

import createCachedSelector from 're-reselect';
import { connect } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { DOMAIN_NAME, ROWS_PER_PAGE, ROWS_PER_PAGE_OPTIONS } from "./common";
import { EMPLOYEES } from './redux.datasets';
import EmployeeListView from './components/employeeListView';
import EmployeeCardView from './components/employeeCardView';
import ChangeView from './components/ChangeView';
import { theme } from 'app/Theme';
import FilterInput from '@libComponents/FilterInput';
import ActionList from './components/ActionList';
import { Pagination } from '@libComponents';

const propTypes = {
};

const defaultProps = {
    entity: EMPLOYEES,
    domain: DOMAIN_NAME,
    loading: true,
};

const TABLE_DATA = {
    'email': 'Email',
    'firstname': 'Full Name',
    'lastname': 'ignore',
    'branchName': 'Branch',
    'designationName': 'Designation',
    'joiningDate': 'Joining Date',
    'employeeId': 'Employee Id',
    'resignationDate': 'Resignation Date'
};


const dataSelector = createCachedSelector(entitySelector,
    (resp) => ((resp && resp.data) ? (resp.data.map(
        (obj) => {        
            let data = (({ user, branch, designation, ...rest }) => ({ ...rest, email: user.email, branchName: branch.name, designationName: designation.name }))(obj);
            return Object.fromEntries(Object.keys(TABLE_DATA).map(
                (key) => [key, data[key]])
            )
        }
    )
    ) : [])
)((state, domain, entity) => `@data-${domain}-${entity}`);

const totalRowsSelector = createCachedSelector(entitySelector,
    (resp) => resp.total ? parseInt(resp.total) : 0
)((state, domain, entity) => `@data-${domain}-${entity}`);

const mapState = (state, { domain, entity }) => ({
    data: dataSelector(state, domain, entity),
    loading: datasetLoadingSelector(state, domain, entity),
    totalRows: totalRowsSelector(state, domain, entity)
});

const mapDispatch = (dispatch, { entity }) => ({
    fetchDataset: ({ offset = "", limit = ROWS_PER_PAGE, shouldAppend = false, searchTerm }) => dispatch(
        createFetchPaginatedDataset({ entity: entity, domain: DOMAIN_NAME })({
            limit,
            offset,
            shouldAppend,
            searchTerm
        })
    ),
});

const StyledBox = styled(Box)(
    ({ theme: { spacing, palette, typography, shape } }) => ({
        width: "100%",
        overflow: "auto",
        "& table": {
            tableLayout: "fixed",
        }
    }));

const EmployeeList = connect(
    mapState,
    mapDispatch
)(({ fetchDataset, data, totalRows = 0 }) => {
    useEffect(() => {
        fetchDataset({ offset: "" });
    }, []);

    const [pageSize, setPageSize] = useState(ROWS_PER_PAGE);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");

    const [view, setView] = useState("listView");
    const navigate = useNavigate();

    const handleClick = ({ action, row }) => {
        // dispatch action
        if (action === "edit") {
            navigate(`/employee-list/${row?.email?.split("@")[0]}`, { state: row });
        } else if (action === "manage") {
            navigate(`/manage/${row?.email}`, { state: row });
        }
    }

    const handleChangePage = (event, newPage) => {
        fetchDataset({
            offset: (newPage - 1) * pageSize,
            limit: pageSize,
            searchTerm: searchTerm
        })
        setCurrentPage(newPage);
    };

    const handleRowsPerPageChange = (pageSize) => {
        setPageSize(pageSize);
        setCurrentPage(1);
        fetchDataset({
            offset: 0,
            limit: pageSize,
            searchTerm: searchTerm
        });
    }

    const handleSearch = (value) => {
        setSearchTerm(value);
        fetchDataset({
            offset: "",
            limit: pageSize,
            searchTerm: value
        })
    }

    return (
        <ContentContainer title="Employee List" AdornmentComponent={
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Box sx={{ width: 300, background: theme.palette.common.white }}>
                    <FilterInput
                        name='employee-email'
                        handleSearch={handleSearch}
                    />
                </Box>
                <ChangeView setView={setView} />
            </Box>}>
            <StyledBox>
                {view === "listView" && (
                    <EmployeeListView
                        TABLE_DATA={TABLE_DATA}
                        handleClick={handleClick}
                        rows={data}
                        ActionComponent={ActionList}
                    />
                )}                

                {view === "gridView" && (
                    <EmployeeCardView
                        TABLE_DATA={TABLE_DATA}
                        handleClick={handleClick}
                        rows={data}
                        ActionComponent={ActionList}
                    />
                )}

                <Box sx={{ display: "flex", justifyContent: "center", ml: 2 }}>
                    <Pagination
                        count={Math.ceil(totalRows / pageSize)}
                        onChange={handleChangePage}
                        page={currentPage}
                        sx={{ display: 'flex', justifyContent: 'center' }}
                        isRowsPerPageVarient={true}
                        rowsPerPageProps={{
                            name: 'appliedLeaves',
                            onChange: handleRowsPerPageChange,
                            value: pageSize
                        }}
                        rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                    />
                </Box>
            </StyledBox>

        </ContentContainer>
    );
});

EmployeeList.propTypes = propTypes;
EmployeeList.defaultProps = defaultProps;

export default EmployeeList;
