import { apiRequest } from '@libs/apiRequest';
import { getFormValues } from 'redux-form';
import { call, put, select } from 'redux-saga/effects';
import { FORM_NAME } from 'roles/Roles/common';
import { setLoading, setSnack } from 'utilities/redux.actions';

function normalizePermissions(permissionsObj) {
    let permissions = [];
    Object.entries(permissionsObj).map(([category, values], i) => {
        Object.entries(values).forEach(([permission, isPermitted]) => {
            if (isPermitted)
                permissions.push({ name: category + "." + permission });
        })
    });
    return permissions;
}


export default function* (action) {
    const {
        meta: { handleComplete = () => { } },
        payload,
        ...rest
    } = action;
    yield put(setLoading(true));

    const allSelector = yield select(
        getFormValues(FORM_NAME)
    );

    const { name, ...restArgs } = allSelector;
    let permissions = normalizePermissions(restArgs);

    try {
        const { data } = yield call(
            apiRequest,
            {
                url: `role/update?name=${name}`,
                method: 'post',
                data: JSON.stringify({ name: name.trim(), permissions }),
            }
        );

        yield put(setLoading(false));
        yield put(setSnack({
            message: "Role Updated successfully",
            type: 'success',
        }));

        return handleComplete(data);

    } catch (error) {
        yield put(setLoading(false));
        yield put(setSnack({
            message: error?.response?.data?.message || error?.message,
            type: 'error',
        }));
        return handleComplete(error);
    }
}
