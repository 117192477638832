import PropTypes from 'prop-types';

// Material
import { FormLabel, styled } from '@mui/material';

const propTypes = {
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
};

const defaultProps = {
    required: false,
    disabled: false,
    error: false,
};

const StyledFormLabel = styled(FormLabel)(
    ({ theme: { spacing, palette, typography } }) => ({
        paddingTop: spacing(1.25),
        paddingBottom: spacing(1.5),
        marginBottom: spacing(1),
        height: spacing(1.25),
        fontSize: typography.subtitle2.fontSize,
        color: palette.text.main,
        fontWeight:500,
        '&.Mui-error': {
            color: palette.error.main,
            '&.Mui-focused': {
                color: palette.error.main,
            },
        },
        '&.Mui-disabled': {
            color: palette.text.disabled,
        },
        '&.Mui-focused': {
            color: palette.text.dark,
        },
    })
);

const LabelBase = ({ label, success, ...restProps }) => (
    <StyledFormLabel {...restProps}>{`${label}:`}</StyledFormLabel>
);

LabelBase.propTypes = propTypes;
LabelBase.defaultProps = defaultProps;

export { LabelBase as default, LabelBase };

