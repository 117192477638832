import PropTypes from 'prop-types';
import { useTheme } from '@mui/material';

import { DatePickerBase } from '@baseComponents/DateTimePicker';

const VIEWS = ['year', 'month'];

const maxDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    0
);

const propTypes = {
    name: PropTypes.string.isRequired,
    avatarUrl: PropTypes.string
};

const defaultProps = {
    name: 'timesheets-month-picker',
    views: VIEWS,
    openTo: 'month',
    maxDate,
    inputFormat: 'MMMM YYYY'
};

const TimesheetsMonthPicker = (props) => {
    const { spacing } = useTheme();

    return (
        <DatePickerBase
            {...props}
            styles={{ input: { height: spacing(4.5) } }}
        />
    );
};

TimesheetsMonthPicker.propTypes = propTypes;
TimesheetsMonthPicker.defaultProps = defaultProps;

export { TimesheetsMonthPicker as default, TimesheetsMonthPicker };
