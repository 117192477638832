import ContentContainer from '@libComponents/ContentContainer';
import PropTypes from 'prop-types';
import { Fragment, useEffect } from 'react';
import { FORM_NAME } from './common';
// Libs
import { GridContainer, GridItem, Input } from '@formComponents';
import { Button } from '@libComponents';
import { styled } from '@mui/system';
import { useDispatch } from 'react-redux';

import {
    initialize as formInitialize,
    reduxForm
} from 'redux-form';
import DatePickerBase from '@formComponents/DatePicker';
import { createShift, deleteShift, updateShift } from './redux.actions';
import { required } from '@libs/validationRules';
import { KeyboardBackspace } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

const propTypes = {
    mode: PropTypes.oneOf(['create', 'update']),
};
const defaultProps = {
    mode: 'create',
};

/**
 * ReduxForm stepper rules
 * * Connect each step with reduxForm() to the same form name.
 * * Specify the destroyOnUnmount: false flag to preserve form data across form component unmounts.
 */
const ButtonWrapper = styled('div')({
    position: 'absolute',
    bottom: 0,
    right: '50%',
    transform: 'translate(50%, 50%)',
    zIndex: 4,
});

const StyledButton = styled(Button)(({ theme: { spacing, typography } }) => ({
    height: spacing(4.75),
    width: spacing(25),
    fontSize: typography.subtitle1.fontSize,
    marginLeft: spacing(0.75),
    marginRight: spacing(0.75),
}));


const DeleteShift = reduxForm({
    form: FORM_NAME, // Form name
    destroyOnUnmount: false, // Preserve data
    onSubmit: (v) => v,
})(({ row, setViewType, ...rest }) => {
    const RXDispatch = useDispatch();

    const handleClose = () => {
        setViewType("default");
    }

    const handleDelete = () => {
        RXDispatch(deleteShift({ handleSubmit: handleClose, payload: row }));
    }

    return (
        <>
            <Typography variant='h6' sx={{ mb: 4, mt: 4, textAlign:"center" }}>
                Do you want to delete the shift?
            </Typography>

            <Box sx={{ mb:2, display: "flex", alignItems: "center", justifyContent: "center", gap: 2 }}>
                <Button onClick={handleClose} color="tertiary">Cancel</Button>
                <Button onClick={handleDelete}>Delete</Button>
            </Box>
        </>
    );
});

DeleteShift.propTypes = propTypes;
DeleteShift.defaultProps = defaultProps;

export { DeleteShift as default, DeleteShift };

