import apiRequest from '@libs/apiRequest';
import { DEPARTMENT } from 'common/redux.datasets';
import { getFormValues } from 'redux-form';
import { call, put, select } from 'redux-saga/effects';
import { FORM_NAME } from '../../common';
import { setLoading, setSnack } from 'utilities/redux.actions';

export default function* (action) {
    const {
        meta: { handleSubmit = () => { } },
        mode,
        ...rest
    } = action;

    yield put(setLoading(true));

    const allSelector = yield select(
        getFormValues(FORM_NAME)
    );

    let modifiedSelector = {
        branch: {
            name: allSelector.branch_name,
        },
        name: allSelector.name,
        description: allSelector.description,
    }

    const payloadData = JSON.stringify(modifiedSelector)
    try {
        const { data } = yield call(
            apiRequest,
            {
                url: mode === "create" ? `department/create` : `department/update?name=${allSelector.name}`,
                method: 'post',
                data: payloadData,
            }
        );
        yield put(setLoading(false));
        yield put(setSnack({
            message: `Department ${mode === "create" ? "created" : "updated"} successfully`,
            type: 'success',
        }));

        yield put({
            type: mode === "create" ? "handleCreate" : "handleUpdate",
            payload: {
                entity: DEPARTMENT,
                value: data,
                key: "name"
            },
        });
        return handleSubmit(data);
    } catch (error) {
        yield put(setLoading(false));
        yield put(setSnack({
            message: error?.response?.data?.message || error?.message,
            type: 'error',
        }));
        return handleSubmit(error);
    }
}
