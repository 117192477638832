import apiRequest from '@libs/apiRequest';
import { FORM_NAME } from 'leaveCategory/common';
import { getFormValues } from 'redux-form';
import { call, put, select } from 'redux-saga/effects';
import { setLoading, setSnack } from 'utilities/redux.actions';

export default function* (action) {
    const {
        meta: { handleSubmit = () => { } },
        payload: { mode = "create" }
    } = action;

    yield put(setLoading(true));

    const allSelector = yield select(
        getFormValues(FORM_NAME)
    );

    allSelector.preApproved = JSON.parse(allSelector.preApproved);
    allSelector.isPaidLeave = JSON.parse(allSelector.isPaidLeave);
    allSelector.description = "";
    if (allSelector.preApproved)
        allSelector.minimumApprovalsRequired = 0;

    const payloadData = JSON.stringify(allSelector);
    const method = mode === "create" ? "post" : "put";

    try {
        const leave_data = yield call(
            apiRequest,
            {
                url: "leave-category",
                method: method,
                data: payloadData,
            }
        );
        yield call(
            apiRequest,
            {
                url: "leave-category/approvers",
                method: 'put',
                data: payloadData,
            }
        );
        if(allSelector.eligibility){
            yield call(
                apiRequest,
                {
                    url: "leave-category/eligibility",
                    method: 'put',
                    data: payloadData,
                }
            );
        }
        yield put(setLoading(false));
        yield put(setSnack({
            message: `Leave Category ${mode} successfully`,
            type: 'success',
        }));

        return handleSubmit(leave_data);
    } catch (error) {
        yield put(setLoading(false));
        yield put(setSnack({
            message: error?.response?.data?.message || error?.message,
            type: 'error',
        }));
        return handleSubmit(error);
    }
}
