import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { connect } from 'react-redux';

import {
    datasetLoadingSelector,
    entitySelector,
    fetchDatasets
} from '@libs/datasets';
import createCachedSelector from 're-reselect';
import AutocompleteBase from './Autocomplete';
import AutocompleteMultiple from './AutocompleteMultiple';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    customselector: PropTypes.func
};

const defaultProps = {};

const dataSelector = createCachedSelector(
    entitySelector,
    (resp) => (resp && resp.data) ? (resp.data.map(({ id, name, ...datum }) =>({
        label: name,
        value: id,
    }))) : []
)((state, domain, entity) => `@data-${domain}-${entity}`);

const mapState = (state, { name, domain, entity, customselector }) => {
    return {
        data: customselector? customselector(state, domain, entity) : dataSelector(state, domain, entity),
        loading: datasetLoadingSelector(state, domain, entity)
    };
};

const mapDispatch = (dispatch, { entity }) => {
    return {
        fetchDataset: () => dispatch(fetchDatasets(entity))
    };
};

const AutocompleteFetch = connect(
    mapState,
    mapDispatch
)(({ fetchDataset, data, customselector, ...props }) => {
    useEffect(() => {
        fetchDataset();
    }, []);

    // remove multiple options with same label
    data = data.filter(
        (value, index, self) =>
            index === self.findIndex((t) => t.label === value.label)
    );

    if (props.multiple)
        return <AutocompleteMultiple {...props} options={data ? data : []} />;
    return <AutocompleteBase {...props} options={data ? data : []} />;
});

AutocompleteFetch.propTypes = propTypes;
AutocompleteFetch.defaultProps = defaultProps;

export default AutocompleteFetch;
