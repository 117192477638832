import PropTypes from 'prop-types';

import { styled, Step as MaterialStep, useTheme } from '@mui/material';

import StepLabel from './StepLabel';
import StepIcon from './StepIcon';
import useStepper from '../../StepContext';

// Hooks
import { useStepPadding } from '../hooks';
import { useColor } from './hooks';

const propTypes = {
    index: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    touched: PropTypes.bool.isRequired,
};

const StyledMaterialStep = styled(MaterialStep)({
    cursor: 'var(--cursor)',
    zIndex: 4,
    paddingLeft: 'var(--padding)',
    paddingRight: 'var(--padding)',
});

const Step = ({ index, label, touched }) => {
    const [{ activeStep }, dispatch] = useStepper();
    const theme = useTheme();

    const isActive = index === activeStep;

    const handleMove = (index) => () =>
        dispatch({ type: 'move', payload: index });

    const style = {
        ...useStepPadding(),
        '--cursor': touched ? 'pointer' : 'unset',
    };

    return (
        <StyledMaterialStep
            active={isActive}
            onClick={handleMove(index)}
            style={style}
        >
            <StepLabel
                disabled={!touched}
                style={useColor(index, theme)}
                StepIconComponent={StepIcon}
                StepIconProps={{
                    index,
                    activeStep,
                }}
            >
                {label}
            </StepLabel>
        </StyledMaterialStep>
    );
};

Step.propTypes = propTypes;

export { Step as default };
