import {
    initialize as formInitialize,
    reduxForm,
    destroy as destroyForm,
} from 'redux-form';
import { styled } from '@mui/system';

import { GridContainer, GridItem } from '@formComponents';
import { Button } from '@libComponents';
import { required } from '@libs/validationRules';

import TextArea from '@formComponents/TextArea';
import { REJECT_REASON_FORM_NAME } from '../common';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { hasFormErrorSelector } from 'common/redux.selectors';
import { handleCancelLeave } from 'leaveAndHolidays/redux.actions';

const StyledButton = styled(Button)(({ theme: { spacing, typography } }) => ({
    height: spacing(4.75),
    width: spacing(25),
    fontSize: typography.subtitle1.fontSize,
    marginLeft: spacing(0.75),
    marginRight: spacing(0.75),
}));


const CancelLeaveForm = reduxForm({
    form: REJECT_REASON_FORM_NAME,
    destroyOnUnmount: false,
    onSubmit: (v) => v,
})(({ handleSubmit: handleReduxFormSubmit, rejectPayload, onReject }) => {

    const RXDispatch = useDispatch();
    useEffect(() => {
        RXDispatch(formInitialize(REJECT_REASON_FORM_NAME, {}));
        return () => {
            return RXDispatch(destroyForm(REJECT_REASON_FORM_NAME));
        };
    }, []);

    const hasError = useSelector((state) =>
        hasFormErrorSelector(state, REJECT_REASON_FORM_NAME)
    );

    const handleReject = () => {
        if (hasError) {
            handleReduxFormSubmit();
            return;
        }
        RXDispatch(handleCancelLeave({ handleSubmit: onSubmit, payload: { row: rejectPayload } }));
    };

    const onSubmit = (data) => {
        onReject();
    };

    return (
        <Box>
            <GridContainer
                sx={{ margin: "unset", padding: "unset", width: "100%", }}
            >
                <GridItem md={12}
                    sx={{ margin: "unset", padding: "unset", textAlign: "left", mt: 2, mb: 4 }}>
                    <TextArea
                        name='comment'
                        label='Write a reason to cancel this leave'
                        rows='2'
                        required={true}
                        validate={[required]}
                    />
                </GridItem>
            </GridContainer>
            <StyledButton onClick={handleReject}>
                Cancel Leave
            </StyledButton>
        </Box>
    );
});

export { CancelLeaveForm as default, CancelLeaveForm };

