import { apiRequest } from '@libs/apiRequest';
import { getFormValues } from 'redux-form';
import { call, put, select } from 'redux-saga/effects';
import { FORM_NAME } from "user/common";
import { setLoading, setSnack } from 'utilities/redux.actions';

export default function* (action) {
    const {
        meta: { handleSubmit = () => { } },
        ...rest
    } = action;

    yield put(setLoading(true));

    const allSelector = yield select(
        getFormValues(FORM_NAME)
    );

    allSelector.isEmployee = JSON.parse(allSelector.isEmployee);
    allSelector.accountIsActive = allSelector.accountIsActive ? JSON.parse(allSelector.accountIsActive) : false;
    const payload = JSON.stringify(allSelector);
    try {
        const { data } = yield call(
            apiRequest,
            {
                url: `user/update?email=${allSelector.email}`,
                method: 'post',
                data: payload,
            }
        );
        yield put(setLoading(false));
        yield put(setSnack({
            message: "User updated successfully",
            type: 'success',
        }));

        return handleSubmit(data);
    } catch (error) {
        yield put(setLoading(false));
        yield put(setSnack({
            message: error?.response?.data?.message || error?.message,
            type: 'error',
        }));
        // return handleSubmit(error);
    }
}
