import { initializeDatasets } from '@libs/datasets';

import datasets from './redux.datasets';

const initialState = {
    ...initializeDatasets(datasets),
};

export default (state = initialState, { type, payload, ...action }) => {
    switch (type) {

        case "deleteUser": {
            const { entity, value } = payload;
            //initial data;
            const { data } = state[entity];
            let users = [...data];
            users = users.filter((item) => (item.email !== value.email));
            return { ...state, [entity]: { ...state[entity], data: users } };
        }
        default: {
            return state;
        }
    }
};
