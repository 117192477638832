const stagingBaseURL =
    'https://c1ty3pzsfa.execute-api.us-east-1.amazonaws.com/dev/';
const prodBaseURL =
    'https://84g8kt8ov3.execute-api.us-east-1.amazonaws.com/production';

const BUILD_ENV = process.env.REACT_APP_BUILD_ENV || 'development';

const baseURL = BUILD_ENV === 'production' ? prodBaseURL : stagingBaseURL;

const baseConfig = {
    baseURL,
    headers: {
        'Content-Type': 'application/json'
    },
    // Request will be cancel if network request take more than 10 seconds
    timeout: 30000
};

export { baseConfig as default, baseURL, BUILD_ENV };
