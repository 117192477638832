import { CREATE_AND_UPDATE_DESIGNATION, DELETE_DESIGNATION, UPDATE_DESIGNATION } from './redux.actionTypes';

export const createDesignation = ({ handleSubmit, mode, payload }) => ({
    type: CREATE_AND_UPDATE_DESIGNATION,
    meta: { handleSubmit },
    mode,
    payload,
});

export const createAndUpdateDesignation = ({ handleSubmit, mode, payload }) => ({
    type: CREATE_AND_UPDATE_DESIGNATION,
    meta: { handleSubmit },
    mode,
    payload,
});

export const deleteDesignation = ({ handleSubmit, payload }) => ({
    type: DELETE_DESIGNATION,
    meta: { handleSubmit },
    payload,
});

export const updateDesignation = ({ handleSubmit, mode, ...payload }) => ({
    type: UPDATE_DESIGNATION,
    meta: { handleSubmit },
    mode,
    payload,
});