import { initializeDatasets } from '@libs/datasets';

import datasets from './redux.datasets';

const initialState = {
    ...initializeDatasets(datasets),
};

export default (state = initialState, { type, payload, ...action }) => {
    switch (type) {
        case "deleteCategory": {
            const { entity, value } = payload;
            const { data } = state[entity];
            let categories = [...data];
            categories = categories.filter((item) => (item.name !== value.name));
            return { ...state, [entity]: { ...state[entity], data: categories } };
        }
        default: {
            return state;
        }
    }
};
