import { useCallback, useEffect, useMemo } from "react";
import { DOMAIN_NAME } from "dashboard/common";

import { DASHBOARD_ATTENDANCE } from "dashboard/redux.datasets";
import { useDispatch, useSelector } from "react-redux";
import { createFetchGridDataset, datasetLoadingSelector, fetchDatasets, gridDatasetSelector } from "@libs/datasets";
import { makeSideEffectCallback } from "@libs/makeContext";
import { AttendanceProvider } from "./useAttendanceProvider";
import DataTable from "./DataTable";
import { theme } from "app/Theme";
import { ContentCircularLoader, ContentContainer } from "@libComponents";
import Pagination from './Pagination';
import { Box } from "@mui/material";
import Filters from "./Filters";


const Attendance = (({ ...props }) => {

    const { data, currentPage, totalPages, pageSize } = useSelector((state) =>
        gridDatasetSelector(state, DOMAIN_NAME, DASHBOARD_ATTENDANCE)
    );

    let isLoading = useSelector((state) =>
        datasetLoadingSelector(state, DOMAIN_NAME, DASHBOARD_ATTENDANCE)
    );

    const dispatch = useDispatch();

    const fetchAllAttendance = useCallback(
        () => dispatch(fetchDatasets(DASHBOARD_ATTENDANCE)),
        [dispatch]
    );

    const fetchGridFilteredData = useCallback(
        (payload) =>
            dispatch(
                createFetchGridDataset(
                    DOMAIN_NAME,
                    DASHBOARD_ATTENDANCE
                )(payload)
            ),
        [dispatch]
    );

    const initialize = useCallback(
        (state) => ({
            ...state,
            data,
            totalPages,
            currentPage,
            pageSize
        }),
        [currentPage, totalPages, data, pageSize]
    );

    const middlewareProps = useMemo(
        () => ({
            fetchData: makeSideEffectCallback(fetchGridFilteredData)
        }),
        [fetchGridFilteredData]
    );

    useEffect(() => {
        fetchAllAttendance();
    }, []);

    return (
        <Box sx={{position:"relative"}}>
            {isLoading && <ContentCircularLoader />}

            <AttendanceProvider
                initialize={initialize}
                middlewareProps={middlewareProps}
            >
                <ContentContainer title="Attendance" headerBg={theme.palette.background.white}
                AdornmentComponent={<Filters/>}
                >
                    <DataTable />
                    <Pagination />
                </ContentContainer>
            </AttendanceProvider>
        </Box>
    )
});

export default Attendance
