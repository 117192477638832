const DOMAIN_NAME = 'holiday';
const HOLIDAY_ROUTE_ID = 26;
const CREATE_HOLIDAY_ROUTE_ID = 27;
const ROWS_PER_PAGE = 10;
const FORM_NAME = "create-upadate-holiday"



export {  DOMAIN_NAME, HOLIDAY_ROUTE_ID, ROWS_PER_PAGE, CREATE_HOLIDAY_ROUTE_ID, FORM_NAME  };

