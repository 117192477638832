import PropTypes from 'prop-types';

import { Box, Typography } from '@mui/material';

import { formatTime } from '@libs/dateTime';

const propTypes = {
    inTime: PropTypes.string,
    outTime: PropTypes.string
};
const TimeRangeBarTooltipContent = ({ inTime, outTime }) => {
    return (
        <Box>
            <Typography variant='body2'>
                In Time: {formatTime(inTime)}
            </Typography>
            {outTime && (
                <Typography variant='body2'>
                    Out Time: {formatTime(outTime)}
                </Typography>
            )}
        </Box>
    );
};

TimeRangeBarTooltipContent.propTypes = propTypes;

export default TimeRangeBarTooltipContent;
