import Label from '@baseComponents/Label';
import { InputBase } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';

const StyledInput = styled(InputBase)(
    ({ theme: { spacing, palette, typography, shape } }) => ({
        width: '100%',
        height: '35px',
        border: '1px solid',
        borderColor: palette.border.main,
        borderRadius: shape.borderRadius,
        paddingLeft: spacing(2),
        fontSize: typography.subtitle2.fontSize,
        '&:hover': {
            borderColor: palette.border.dark,
        },
        '&.Mui-focused': {
            borderColor: palette.grey[500],
            '&:hover': {
                borderColor: palette.grey[700],
            },
        },
        '&.Mui-error': {
            borderColor: `${palette.error.light}`,
            '&:hover': {
                borderColor: `${palette.error.dark}`,
            },
        },
        '&.Mui-disabled': {
            display: 'flex',
            alignItems: 'center',
            backgroundColor: palette.background.disabled,
            color: palette.secondary.main,

            '&:hover': {
                borderColor: palette[300],
            },
        },
    })
);

export const renderField = ({
    input,
    label,
    type,
    meta: { touched, error },
    ...restProps
}) => {
    return (
        <React.Fragment>
            <Label label={label} sx={{ display: 'block' }} />

            <StyledInput
                {...input}
                type={type}
                label={label}
                placeholder={label}
                {...restProps}
            />
            {touched && error && <span>{error}</span>}
        </React.Fragment>
    );
};
