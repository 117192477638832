import PropTypes from 'prop-types';

import { styled, StepIcon as MaterialStepIcon } from '@mui/material';

const propTypes = {
    index: PropTypes.number.isRequired,
    activeStep: PropTypes.number.isRequired,
};

const StyledMaterialStepIcon = styled(MaterialStepIcon)(
    ({ theme: { spacing, transitions, palette } }) => ({
        color: palette.background.dark,
        fontSize: spacing(3.5),
        transition: transitions.create(['color'], {
            easing: transitions.easing.easeOut,
        }),
        transitionDuration: 'var(--duration)',

        '&.Mui-active': {
            color: 'var(--color)',
        },
    })
);

const StepIcon = ({ index, activeStep }) => {
    return (
        <StyledMaterialStepIcon
            completed={false}
            active={index <= activeStep}
            icon={index + 1}
        />
    );
};

StepIcon.propTypes = propTypes;

export { StepIcon as default };
