import React from 'react';
import { Box, IconButton, Menu, styled } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { Button } from '@libComponents';
import EditIcon from '@mui/icons-material/EditOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import Resignation from './resignation';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router';

const StyledBox = styled(Box)(
    ({ theme: { spacing, palette, typography, shape } }) => ({
        display: 'flex',
        flexDirection: 'column',
        padding: spacing(2),
        gap: spacing(2)
    })
);

export default function ActionList({ row = {}, handleClick }) {
    const [anchorEl, setAnchorEl] = React.useState({});
    const navigate = useNavigate();

    const { email: id } = row;

    const handleMenuClick = (name, value) => {
        setAnchorEl((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleClose = (name) => {
        setAnchorEl((prevState) => ({
            ...prevState,
            [name]: null
        }));
    };

    const open = function (name) {
        return Boolean(anchorEl[name]);
    };

    const handleView = (row) => {
        navigate(`/profile?id=${row?.email}`);
    };

    return (
        <Box>
            <IconButton
                id={id}
                aria-controls={open(id) ? id : undefined}
                aria-haspopup='true'
                aria-expanded={open(id) ? 'true' : undefined}
                onClick={(event) => handleMenuClick(id, event.currentTarget)}
            >
                <MoreVert />
            </IconButton>

            <Menu
                id={id}
                anchorEl={anchorEl[id]}
                open={open(id)}
                onClose={() => handleClose(id)}
                MenuListProps={{
                    'aria-labelledby': `basic-button${id}`
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <StyledBox>
                    <Button
                        variant='outlined'
                        sx={{ cursor: 'pointer', justifyContent: "flex-start", pl: 3 }}
                        type='button'
                        onClick={() => {
                            handleClick({ action: "manage", row });
                        }}
                        size='small'
                        color='primary'                        
                        startIcon={<SettingsIcon color='primary' />}
                    >
                        Manage
                    </Button>

                    <Button
                        variant='outlined'
                        sx={{ cursor: 'pointer', justifyContent: "flex-start", pl: 3 }}
                        type='button'
                        onClick={() => {
                            handleClick({ action: "edit", row });
                        }}
                        size='small'
                        color='primary'
                        startIcon={<EditIcon color='primary' />}
                    >    
                        Edit
                    </Button>
                    <Button
                        variant='outlined'
                        sx={{ cursor: 'pointer', justifyContent: "flex-start", pl: 3 }}
                        type='button'
                        onClick={() => handleView(row)}
                        size='small'
                        color='primary'
                        startIcon={<VisibilityIcon color='primary' />}
                    >    
                        View
                    </Button>
                    {!row.resignationDate && <Resignation row={row} />}
                </StyledBox>
            </Menu>
        </Box>
    );
}
