import { apiRequest } from '@libs/apiRequest';
import { call, put } from 'redux-saga/effects';
import { setLoading, setSnack } from 'utilities/redux.actions';

export default function* (action) {
    const {
        meta: { handleSubmit = () => { } },
        payload
    } = action;
    yield put(setLoading(true));
    try {
        const { data: { calender, ...restPayload } } = yield call(
            apiRequest,
            {
                url: `holiday/get?id=${payload.id}`,
                method: 'get'
            }
        );

        yield put(setLoading(false));

        return handleSubmit({ calender: calender.name, ...restPayload });
    } catch (error) {
        yield put(setLoading(false));
        yield put(setSnack({
            message: error?.response?.data?.message || error?.message,
            type: 'error',
        }));

        return handleSubmit(error);
    }
}
