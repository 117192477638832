import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, List, ListItem, Typography, styled } from '@mui/material';
import dayjs from 'dayjs';

import UpdateDocumentForm from './UpdateDocument';
import { rowsToShowInDetails } from './common';
import PropTypes from 'prop-types';

const propTypes = {
    payload: PropTypes.object,
    handleClose: PropTypes.func,
};

const StyledListItem = styled(ListItem)(
    ({ theme: { palette } }) => ({
        borderBottom: "1px solid",
        borderColor: palette.divider
    })
);

const StyledIconButton = styled(IconButton)(
    ({ theme: { palette, spacing } }) => ({
        position: 'absolute',
        right: spacing(2),
        top: spacing(2),
        color: palette.grey[500],
    })
);

const StyledCloseIcon = styled(CloseIcon)(
    ({ theme: { palette } }) => ({
        color: palette.primary.main
    })
);

const LeaveDetails = (({ payload, handleClose }) => {
    console.log(payload)
    return (
        <Box>
            <Typography variant='h6' sx={{ mb: 2 }}>
                Leave Details
            </Typography>
            <StyledIconButton aria-label="close" onClick={handleClose}>
                <StyledCloseIcon />
            </StyledIconButton>

            <List dense={false} sx={{ mb: 3 }}>
                {
                    Object.entries(rowsToShowInDetails).map(([key, value], index) => {
                        let type = value.type;
                        switch (type) {
                            case 'object':
                                return (
                                    <StyledListItem key={index}>
                                        <Box sx={{ flex: 1 }}>{value.label} </Box>
                                        <Box sx={{ flex: 2 }}>{payload[key] && payload[key][value.key]}</Box>
                                    </StyledListItem>
                                )
                            case 'array':
                                return (
                                    <StyledListItem key={index}>
                                        <Box sx={{ flex: 1 }}>{value.label} </Box>
                                        <Box sx={{ flex: 2 }}>{payload[key] && payload[key].map(item => item?.firstname + " " + item?.middlename + " " + item?.lastname).join(", ")}</Box>
                                    </StyledListItem>
                                );
                            case 'file':
                                return (
                                    <StyledListItem key={index}>
                                        <Box sx={{ flex: 1 }}>{value.label} </Box>
                                        <Box sx={{ flex: 2 }}>
                                            <UpdateDocumentForm row={payload}/>
                                        </Box>

                                    </StyledListItem>
                                );
                            case 'date':
                                return (
                                    <StyledListItem key={index} >
                                        <Box sx={{ flex: 1 }}>{value.label} </Box>
                                        <Box sx={{ flex: 2 }}>{payload[key] ? dayjs(payload[key]).format("YYYY-MM-DD") : ""}</Box>
                                    </StyledListItem>
                                )
                            case 'boolean':
                                return (
                                    <StyledListItem key={index}>
                                        <Box sx={{ flex: 1 }}>{value.label} </Box>
                                        <Box sx={{ flex: 2 }}>{payload[key] && payload[key] ? "Yes" : "No"}</Box>
                                    </StyledListItem>
                                )
                            default:
                                return (
                                    <StyledListItem key={index}>
                                        <Box sx={{ flex: 1 }}>{value.label} </Box>
                                        <Box sx={{ flex: 2 }}>{payload[key]}</Box>
                                    </StyledListItem>
                                );
                        }
                    })
                }
            </List>
        </Box>
    );
});

LeaveDetails.propTypes = propTypes;

export default LeaveDetails;