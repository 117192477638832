import { takeLatest } from 'redux-saga/effects';

// Workers
import { CREATE_AND_UPDATE_DEPARTMENT, DELETE_DEPARTMENT } from '../redux.actionTypes';
import deleteDepartmentWorker from './deleteDepartmentWorker';
import createAndUpdateDepartmentWorker from './createAndUpdateDepartmentWorker';

// Watchers
export default function* () {
    yield takeLatest(DELETE_DEPARTMENT, deleteDepartmentWorker);
    yield takeLatest(CREATE_AND_UPDATE_DEPARTMENT, createAndUpdateDepartmentWorker);
}