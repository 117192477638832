import PropTypes from 'prop-types';

// Material
import {
    InputBase as MuiInputBase,
    InputAdornment,
    FormControl,
    styled
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';

// Local
import Label from './Label';
import HelperText from './HelperText';
import FieldProgressBar from './FieldProgressBar';

const propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    styles: PropTypes.object,
    staticValue: PropTypes.string
};

const defaultProps = {
    required: false,
    staticValue: '',
    styles: {},
    input: { onChange: (f) => f },
    meta: {}
};

const StyledFormControl = styled(FormControl)({
    width: '100%'
});

const StyledInputBase = styled(MuiInputBase)(
    ({ theme: { spacing, palette, typography, shape } }) => ({
        width: '100%',
        height: '100%',
        border: '1px solid',
        borderColor: palette.border.main,
        borderRadius: shape.borderRadius,
        paddingLeft: spacing(2),
        paddingRight: spacing(2),
        fontSize: typography.subtitle2.fontSize,
        '&:hover': {
            borderColor: palette.border.dark
        },
        '&.Mui-focused': {
            borderColor: palette.grey[500],
            '&:hover': {
                borderColor: palette.grey[700]
            }
        },
        '&.Mui-error': {
            borderColor: `${palette.error.light}`,
            '&:hover': {
                borderColor: `${palette.error.dark}`
            }
        },
        '&.Mui-disabled': {
            display: 'flex',
            alignItems: 'center',

            backgroundColor: palette.background.disabled,
            color: palette.secondary.main,

            '&:hover': {
                borderColor: palette[300]
            }
        }
    })
);

const Adornment = ({ disabled, ...props }) => {
    if (disabled) {
        return (
            <InputAdornment {...props} position='end'>
                <LockIcon color='disabled' />
            </InputAdornment>
        );
    }

    return null;
};

const InputBase = ({
    fieldName,
    input,
    disabled,
    required,
    label,
    loading,
    staticValue,
    meta: { form, touched, error = null, asyncValidating },
    styles,
    type,
    ...restProps
}) => {
    const id = `${form}-${fieldName}`;

    const isDisabled = disabled || loading;

    //const success = !isDisabled && !!input.value;
    const hasError = !isDisabled && touched && !!error;

    const inputBaseProps = {
        id,
        error: hasError,
        disabled: isDisabled,
        sx: styles?.input,
        type,
        endAdornment: <Adornment disabled={isDisabled} />,
        ...(staticValue ? { value: staticValue } : input),
        ...restProps
    };

    return (
        <StyledFormControl sx={styles?.control}>
            {label && (
                <Label
                    label={label}
                    disabled={isDisabled}
                    required={required}
                    error={hasError}
                    htmlFor={id}
                />
            )}
            <StyledInputBase {...inputBaseProps} />
            {(loading || asyncValidating) && (
                <FieldProgressBar asyncValidating={asyncValidating} />
            )}
            {hasError && <HelperText msg={error} required={required} />}
        </StyledFormControl>
    );
};
InputBase.propTypes = propTypes;
InputBase.defaultProps = defaultProps;

export default InputBase;
