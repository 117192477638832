import apiRequest from '@libs/apiRequest';
import { getFormValues } from 'redux-form';
import { call, put, select } from 'redux-saga/effects';
import { FORM_NAME } from "holidays/common";
import { setLoading, setSnack } from 'utilities/redux.actions';


export default function* (action) {
    const {
        meta: { handleSubmit = () => { } },
        payload
    } = action;

    const { calender, id, ...allSelector } = yield select(
        getFormValues(FORM_NAME)
    );

    let calenderInfo = payload.calenders.filter((cld) => cld.name === calender)[0];

    allSelector.calender = {
        name: calenderInfo.name,
        id: calenderInfo.id
    }

    const payloadData = JSON.stringify(allSelector)
    try {
        const { data } = yield call(
            apiRequest,
            {
                url: `holiday/update?id=${id}`,
                method: 'post',
                data: payloadData,
            }
        );
        yield put(setLoading(false));
        yield put(setSnack({
            message: "Holiday updated successfully",
            type: 'success',
        }));

        return handleSubmit(data);
    } catch (error) {
        yield put(setLoading(false));
        yield put(setSnack({
            message: error?.response?.data?.message || error?.message,
            type: 'error',
        }));
        return handleSubmit(error);
    }
}
