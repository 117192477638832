import { useEffect, useState } from 'react';

export default (activeStep, contents) => {
    const [complete, setComplete] = useState(false);
    const [proceedText, setProceedText] = useState('Next');

    useEffect(() => {
        setComplete(activeStep === contents.length - 1);
    }, [activeStep, contents.length]);

    useEffect(() => {
        setProceedText(activeStep === contents.length - 2 ? 'Submit' : 'Next');
    }, [activeStep, contents.length]);

    return { proceedText, complete };
};
