import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    destroy as destroyEmployeeForm, getFormValues,
    initialize as formInitialize
} from 'redux-form';

import { Paper, styled } from '@mui/material';

import Button from '@libComponents/Button';
import useStepperContainer from '@libHooks/useStepperContainer';
import useValidate from '@libHooks/useValidate';
import {
    formErrorsSelector, hasFormErrorSelector
} from 'common/redux.selectors';

import { employeePublish, getEmployee } from '../redux.actions';
import { FORM_NAME, INITIAL_VALUES, LABELS } from './common';
import { useHandles } from './hooks';
import { EmployeeProvider } from './useEmployee';
import { domainSelector } from 'auth/redux.selectors';

const StyledPaper = styled(Paper)(({ theme }) => ({
    width: '100%',
    position: 'relative',
    boxShadow: theme.shadows[0],
}));

const ButtonWrapper = styled('div')(({ theme: { spacing, typography } }) => ({
    position: 'absolute',
    bottom: spacing(1.5),
    left: 0,
    zIndex: 4,
    width: "100%",
    display:"flex",
    justifyContent:"center"
}));

const StyledButton = styled(Button)(({ theme: { spacing, typography } }) => ({
    height: spacing(4.75),
    width: spacing(25),
    fontSize: typography.subtitle1.fontSize,
    marginLeft: spacing(0.75),
    marginRight: spacing(0.75),
}));

const EmployeeContainer = ({
    children,
    stepReducer: [getState = () => ({}), dispatch = () => {}],
    mode,
    handleReduxFormSubmit,
    email,
}) => {
    const state = getState();
    const { activeStep, contents } = state;
    const formValues = useSelector((state) => getFormValues(FORM_NAME)(state));
    const formErrors = useSelector((state) =>
        formErrorsSelector(state, FORM_NAME)
    );
    const hasError = useSelector((state) =>
        hasFormErrorSelector(state, FORM_NAME)
    );

    const RXDispatch = useDispatch();
    const publish = (props) => RXDispatch(employeePublish(props));
    const publishPartial = (props) => RXDispatch(employeePublish(props));

    const { complete, proceedText } = useStepperContainer(activeStep, contents);

    const [handleNext, handleBack, handlePublish, reset, handleSaveAndExit] = useHandles({
        dispatch,
        mode,
        activeStep,
        publish,
        publishPartial
    });

    const areValid = useValidate(LABELS, activeStep, hasError);

    const handleNextWithSubmit = useCallback(() => {
        if (hasError) {
            handleReduxFormSubmit();
            return;
        }
        handleNext();
    }, [hasError, handleReduxFormSubmit, handleNext]);

    const handleSaveWithExit = useCallback(() => {
        if (hasError) {
            handleReduxFormSubmit();
            return;
        }
        handleSaveAndExit();
    }, [hasError, handleReduxFormSubmit, handleNext]);

    useEffect(() => {
        dispatch({ type: 'validate', payload: areValid });
    }, [activeStep, contents.length]);

    useEffect(() => {
        if (mode === 'new') {
            RXDispatch(formInitialize(FORM_NAME, INITIAL_VALUES));
        }
        return () => {
            return RXDispatch(destroyEmployeeForm(FORM_NAME));
        };
    }, [mode]);

    const domainName = useSelector(domainSelector);
    
    useEffect(()=>{
        if(mode === "update"){
            const emailInitial = window.location.pathname.split("/").pop();
            if(email){
                RXDispatch(getEmployee({ handleSubmit, email }));
            }else if(domainName && emailInitial){
                let email = emailInitial+"@"+domainName;
                RXDispatch(getEmployee({ handleSubmit, email }));
            }
        }
    },[email, mode]);
    
    const handleSubmit = (payload) => {
        RXDispatch(formInitialize(FORM_NAME, payload));
    }

    const initialize = useCallback(
        (state) => ({
            ...state,

            mode,
        }),
        [mode]
    );

    return (
        <StyledPaper>
            <EmployeeProvider initialize={initialize}>
                {children}
            </EmployeeProvider>
            {!complete && (
                <ButtonWrapper>
                    {activeStep !== 0 && (
                        <StyledButton onClick={handleBack} color='tertiary'>
                            Back
                        </StyledButton>
                    )}
                    <StyledButton onClick={handleNextWithSubmit}>
                        {proceedText}
                    </StyledButton>
                    {mode !== 'new' && (
                        <StyledButton onClick={handleSaveWithExit} color={"secondary"}>
                            Save And Exit
                        </StyledButton>
                    )}
                </ButtonWrapper>
            )}
        </StyledPaper>
    );
};

export default EmployeeContainer;
