import { Button } from '@libComponents';
import HourglassIcon from '@mui/icons-material/HourglassEmptyRounded';
import { Box, styled, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ROWS_PER_PAGE } from 'timeAndAttendance/common';
import { getInitialStatus, getUserAttendanceData, getWeeklyStatus, submitStatus } from 'timeAndAttendance/redux.actions';
import { setSnack } from 'utilities/redux.actions';

const StyledBox = styled(Box)(
    ({ theme: { } }) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center"

    }));

const StyledButton = styled(Button)(
    ({ theme: { } }) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center"

    }));

let hidden, visibilityChange;
if (typeof document.hidden !== 'undefined') {
    hidden = 'hidden';
    visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden';
    visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden';
    visibilityChange = 'webkitvisibilitychange';
}


const UserInout = (({ ...props }) => {
    const RXDispatch = useDispatch();
    const [status, setStatus] = React.useState("");
    const [duration, setDuration] = React.useState({});
    const intervalIDRef = React.useRef(null);

    useEffect(() => {
        return clearTimer; // to clean up on unmount
    }, []);

    function clearTimer() {
        clearInterval(intervalIDRef.current)
    }

    React.useEffect(() => {
        function handleVisibilityChange() {
            if (!document[hidden]) {
                RXDispatch(
                    getInitialStatus({
                        handleSubmit: setStatusAndDuration,
                        payload: {}
                    })
                );
            }
        }
        document.addEventListener(visibilityChange, handleVisibilityChange);
        return () => {
            document.removeEventListener(visibilityChange, handleVisibilityChange);
        }
    }, []);

    useEffect(() => {
        RXDispatch(
            getInitialStatus({
                handleSubmit: setStatusAndDuration,
                payload: {}
            })
        );
    }, []);

    const hourAndMinuteFormatter = (value) => {
        if (parseInt(value) < 10)
            return "0" + value.toString();
        return value.toString();
    }

    function secondsToTime(secs) {
        var hours = Math.floor(secs / (60 * 60));
        var divisor_for_minutes = secs % (60 * 60);
        var minutes = Math.floor(divisor_for_minutes / 60);
        var divisor_for_seconds = divisor_for_minutes % 60;
        var seconds = Math.ceil(divisor_for_seconds);
        var obj = {
            "hour": hourAndMinuteFormatter(hours),
            "min": hourAndMinuteFormatter(minutes),
            "sec": hourAndMinuteFormatter(seconds)
        };
        return obj;
    }

    const setStatusAndDuration = ({ status, createdAt }) => {
        clearTimer();
        let isHourExceed = dayjs().diff(createdAt, 'hour') > 23;
        if(isHourExceed){
            setStatus("out");
        }else{
            setStatus(status);
            let sec = dayjs().diff(createdAt, "second");
            setDuration(secondsToTime(sec));
            intervalIDRef.current = setInterval(() => {
                setDuration(secondsToTime(++sec));
            }, 1000);
        }
    }

    const handleClickSubmit = (data) => {
        setStatusAndDuration(data);
        if (status === "in") {
            RXDispatch(
                getWeeklyStatus({
                    payload: {}
                })
            );
        }

        RXDispatch(
            getUserAttendanceData({
                payload: {
                    pageSize: ROWS_PER_PAGE,
                    pageNumber: 1,
                }
            })
        );
    }

    const successCallback = ({ coords: { latitude, longitude } }) => {
        RXDispatch(
            submitStatus({
                handleSubmit: handleClickSubmit,
                payload: { latitude, longitude }
            })
        );
    };

    const errorCallback = (error) => {
        RXDispatch(
            setSnack({
                message: `Please allow location`,
                type: 'error',
            })
        )
    };

    const handleInOutClick = () => {
        navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    }


    return (
        <StyledBox >
            <Box sx={{ mr: 2 }}>
                <Typography variant="subtitle1" display="inline"> You are {status} {status === "in" && "for"} </Typography>
                {status === "in" && (
                    <Typography sx={{ fontWeight: "bold" }} variant="inherit" color='primary' display="inline"> {duration.hour}:{duration.min}:{duration.sec} </Typography>
                )}
            </Box>

            <StyledButton
                variant="contained"
                color="primary"
                startIcon={<HourglassIcon />}
                onClick={handleInOutClick}
            >
                In/Out
            </StyledButton>
        </StyledBox>
    )
});

export { UserInout as default, UserInout };

