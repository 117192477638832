import CreateAttendance from './CreateAttendance';
import { USER_TIMESHEET_DASHBOARD } from './common';
import UserTimesheetDashboard from './UserTimesheetDashboard';
import Timesheet from './Timesheet';
import MyTimesheets from './MyTimesheets';
import { CREATE_ATTENDANCE_ROUTE, TIMESHEET_ROUTE } from './Timesheet/common';
import { MY_TIMESHEET_ROUTE } from './MyTimesheets/common';

export default {
    [USER_TIMESHEET_DASHBOARD]: () => <UserTimesheetDashboard />,
    [TIMESHEET_ROUTE]: () => <Timesheet />,
    [CREATE_ATTENDANCE_ROUTE]: () => <CreateAttendance />,
    [MY_TIMESHEET_ROUTE]: () => <MyTimesheets />
};
