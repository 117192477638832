import { GridContainer, GridItem } from '@formComponents';
import { Button } from '@libComponents';
import { Typography } from '@mui/material';
import { Field } from 'redux-form';
import { renderField } from './renderFields/renderInputField';

const renderWorkExperienceFields = (member, index, fields) => (
    <GridContainer key={index} sx={{ p: 1, pl: 0,pr:0, m: 0 , width:"100%"}}>
        <GridItem
            md={12}
            sx={{
                pl: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
        >
            <Typography sx={{ textTransform: 'capitalize', width: 'auto' }}>
                Academic {fields.name} {index + 1}
            </Typography>
            <Button
                variant='outlined'
                sx={{ textTransform: 'capitalize', width: 'auto' }}
                type='button'
                onClick={() => fields.remove(index)}
                color="error"
            >
                Remove {fields.name} {index + 1}
            </Button>

        </GridItem>

        <GridItem md={3} sx={{ pl: 0 }}>
            <Field
                name={`${member}.companyName`}
                type='text'
                component={renderField}
                label='Company Name'
                placeholder='Company Name'
            />
        </GridItem>
        <GridItem md={3} sx={{ pl: 0 }}>
            <Field
                name={`${member}.location`}
                type='text'
                component={renderField}
                label='Location'
                placeholder='Location'
            />
        </GridItem>

        <GridItem md={3} sx={{ pl: 0 }}>
            <Field
                name={`${member}.designation`}
                type='text'
                component={renderField}
                label='Designation'
                placeholder='Designation'
            />
        </GridItem>
        <GridItem md={3} sx={{ pl: 0 }}>
            <Field
                name={`${member}.yearsOfExperience`}
                type='number'
                component={renderField}
                label='Years of Experience'
                placeholder='Years of Experience'
            />
        </GridItem>
        <GridItem md={6} sx={{ pl: 0 }}>
            <Field
                name={`${member}.skills`}
                type='text'
                component={renderField}
                label='Skills'
                placeholder='Please type skills in comma seperator'
            />
        </GridItem>
        <GridItem md={6} sx={{ pl: 0 }}>
            <Field
                name={`${member}.contributions`}
                type='text'
                component={renderField}
                label='Contributions'
                placeholder='Please type contributions in comma seperator'
            />
        </GridItem>
        <GridItem md={6} sx={{ pl: 0 }}>
            <Field
                name={`${member}.achievements`}
                type='text'
                component={renderField}
                label='Achievements'
                placeholder='Please type achievements in comma seperator'
            />
        </GridItem>
    </GridContainer>
);

export default renderWorkExperienceFields;
