import makeContext from '@libs/makeContext';

const initialState = {
    activeStep: 0,
    contents: [],
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'initialize': {
            const { payload: initialState } = action;

            // Escape
            if (!initialState.hasOwnProperty('contents')) return state;

            const composedContents = state.contents.map(
                ({ content, label }, index) => ({
                    content,
                    label,
                    ...initialState.contents[index],
                })
            );

            return {
                ...initialState,
                contents: composedContents,
            };
        }

        case 'next': {
            const { contents, activeStep } = state;
            const stepCount = contents.length === 0 ? 0 : contents.length - 1;

            if (activeStep < stepCount) {
                return {
                    ...state,
                    activeStep: activeStep + 1,
                    contents: contents.map((content, index) => {
                        if (index === activeStep + 1)
                            return { ...content, touched: true };
                        else if (index === activeStep)
                            return {
                                ...content,
                                valid: true,
                            };
                        else return content;
                    }),
                };
            } else return state;
        }

        // Safe -- checks for touched
        case 'move': {
            const { contents } = state;
            const { payload: activeStep } = action; // Index

            const { touched = false, locked = false } =
                contents[activeStep] || {};

            return !locked && touched ? { ...state, activeStep } : state;
        }

        case 'back': {
            const { activeStep } = state;
            return { ...state, activeStep: activeStep - 1 };
        }

        // Forces move without checks
        case 'forceMove': {
            const { contents } = state;
            const { payload: activeStep } = action; // Index

            const contentsAfterMove = contents.map((content, index) => ({
                ...content,
                ...(index <= activeStep ? { touched: true, valid: true } : {}),
            }));

            return { ...state, contents: contentsAfterMove, activeStep };
        }

        case 'validate': {
            const { contents } = state;
            const { payload: isValid } = action;

            return {
                ...state,
                contents: contents.map((content) => ({
                    ...content,
                    valid: isValid[content.label],
                })),
            };
        }

        case 'lock': {
            const { contents } = state;
            const { payload } = action;

            const contentsAfterLock = contents.map((content, index) => ({
                ...content,
                locked: payload[index],
            }));

            return { ...state, contents: contentsAfterLock };
        }

        case 'reset': {
            const { contents } = state;

            return {
                activeStep: 0,
                contents: contents.map(({ label, content }, index) => ({
                    label,
                    content,
                    touched: index === 0,
                    valid: false,
                    locked: false,
                })),
            };
        }

        default: {
            return state;
        }
    }
};

const middleware = (state, action, { storeState }) => {
    const { activeStep, contents } = reducer(state, action);

    const storableContents = contents.map(
        ({ content, label, ...restContent }) => restContent
    );

    storeState({ activeStep, contents: storableContents });

    return action;
};

const [StepperProvider, useStepperState, useStepperDispatch, useStepper] =
    makeContext(reducer, initialState, {
        name: 'stepper',
        middleware,
    });
export {
    useStepper as default,
    StepperProvider,
    useStepperState,
    useStepperDispatch,
};
