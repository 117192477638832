import PropTypes from 'prop-types';

export const renderedComponentPropType = PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.node,
]);

export const functionalComponentPropType = PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
]);
