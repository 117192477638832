// import { getFormValues } from 'redux-form';
import apiRequest from '@libs/apiRequest';
import { call, put } from 'redux-saga/effects';
import { setLoading } from 'utilities/redux.actions';

function nomimeePayloadNormalize(nominees) {
    if (!nominees || nominees.lenght < 1)
        return nominees;
    nominees.forEach(nominee => {
        const { information } = nominee;
        if (information) {
            const { firstname, email, address, relation, occupation, contact } = information;
            nominee.firstname = firstname;
            nominee.email = email;
            nominee.address = address?.address;
            nominee.relation = relation;
            nominee.occupation = occupation;
            nominee.contact = contact;
        }
    });
    return nominees;
}

function insurencePayloadNormalize(insurances) {
    if (!insurances || insurances.lenght < 1)
        return insurances;
    insurances.forEach(insurance => {
        const { information } = insurance;
        if (information) {
            const { firstname, relation, occupation } = information;
            insurance.firstname = firstname;
            insurance.relation = relation;
            insurance.occupation = occupation;
        }
    });
    return insurances;
}

function workExperiencesPayloadNormalize(experiences) {
    if (!experiences || experiences.lenght < 1)
        return experiences;
    experiences.forEach(insurance => {
        insurance.skills = insurance.skills.join();
        insurance.contributions = insurance.contributions.join();
        insurance.achievements = insurance.achievements.join();
    });
    return experiences;
}

function getCalenderName(calenders, id) {
    return id ? calenders.filter(item => item.id === id)[0].name : id;
}

function payloadNormalize(payload, userEmail) {
    const {
        employeeId,
        initial,
        firstname,
        middlename,
        lastname,
        nameInBengali,
        gender,
        religion,
        bloodGroup,
        placeOfBirth,
        dateOfBirth,
        joiningDate,
        father, // object
        mother, // object
        contact, // object
        family, // object
        relatedEmployees, // array
        identification, // object
        academic, // object
        nominees, // array
        references, // array
        insurances, // array
        // shiftName,
        bank, // object
        workExperiences, // array
        // departmentName,
        maritalStatus,
        // branchName,
        designation,
        branch, // object
        department, // object
        employeeCategory, // object
        shift, // object
        reportingManager,
        calenderId
    } = payload;

    let data = {
        employeeId,
        initial,
        firstname,
        lastname,
        nameInBengali,
        middlename,
        gender,
        bloodGroup,
        dateOfBirth,
        placeOfBirth,
        religion,
        maritalStatus,
        fatherNameInEng: father?.firstname,
        fatherNameInBn: father?.nameInBengali,
        fatherOccupation: father?.occupation,
        motherNameInEng: mother?.firstname,
        motherNameInBn: mother?.nameInBengali,
        motherOccupation: mother?.occupation,

        presentAddress: contact?.presentAddress?.address,
        presentThana: contact?.presentAddress?.thana,
        presentDistrict: contact?.presentAddress?.district,
        presentMobileNo: contact?.presentAddress?.phoneNumber,
        presentCountry: contact?.presentAddress?.country,

        permanentAddress: contact?.permanentAddress?.address,
        thana: contact?.permanentAddress?.thana,
        district: contact?.permanentAddress?.district,
        country: contact?.permanentAddress?.country,
        permanentMobileNo: contact?.permanentAddress?.phoneNumber,
        personalMobileNo: contact && contact.mobileNumber ? contact.mobileNumber : " ",
        personalEmail: contact && contact.personalEmail ? contact.personalEmail : " ",
        officialEmail: userEmail,

        emergencyName: contact?.emergencyContact?.firstname,
        emergencyMobileNo: contact?.emergencyContact?.contact,
        emergencyAddress: contact?.emergencyContact?.address?.address,
        emergencyRelationWithEmployee: contact?.emergencyContact?.relation,
        marriageDate: family?.marriageDate,
        spouseName: family?.spouse?.firstname,
        spouseOccupation: family?.spouse?.occupation,
        spouseLevelOfEducation: family?.spouse?.education?.institutions?.[0]?.qualification,
        children: family?.children,
        isMarried: family?.isMarried,
        relatedEmployees,
        passportNumber: identification?.passport?.number,
        passportIssuePlace: identification?.passport?.issuePlace,
        passportIssueDate: identification?.passport?.issueDate,
        passportExpiryDate: identification?.passport?.expiryDate,
        tin: identification?.tin,
        nationality: identification?.nationality,
        nidNumber: identification?.nidNumber,
        drivingLicenseNumber: identification?.drivingLicenseNumber,
        photograph: identification?.photograph,
        etin: identification?.etin,
        signature: identification?.signature,
        nid: identification?.nid,
        qualifications: academic?.institutions,
        bankName: bank?.name,
        branch: bank?.branch,
        acNo: bank?.accountNumber,
        employeeCategory: employeeCategory?.name,
        joiningDate,
        shift: shift?.name,
        reportingManager,
        department: department?.name,
        officeBranch: branch?.name,
        designation: designation?.name,
        references,
        nominees: nominees ? nomimeePayloadNormalize(nominees) : [],
        insurances: insurances ? insurencePayloadNormalize(insurances) : [],
        workExperiences: workExperiences ? workExperiencesPayloadNormalize(workExperiences) : [],
        calenderId: calenderId
    }

    return data;
}

export default function* (action) {
    const {
        meta: { handleSubmit = () => { } },
        payload
    } = action;

    yield put(setLoading(true));

    try {
        const { data } = yield call(
            apiRequest,
            {
                url: `employee/get?email=${payload.email}`,
                method: 'get'
            }
        );

        const { data: { data: calenders } } = yield call(
            apiRequest,
            {
                url: `calender/get/all`,
                method: 'get'
            }
        );
        data.calenderId = getCalenderName(calenders, data.calenderId);

        const pd = payloadNormalize(data ? data : {}, payload.email);
        yield put(setLoading(false));
        return handleSubmit(pd);
    } catch (error) {
        yield put(setLoading(false));
    }
}

