import { Fragment } from 'react';
import { reduxForm } from 'redux-form';

// Libs
import { GridContainer, GridItem, Input } from '@formComponents';
import ContentContainer from '@libComponents/ContentContainer';
import { email, maxLength, required, textOnly } from '@libs/validationRules';

import AutocompleteBase from '@formComponents/Autocomplete';
import AutocompleteFetch from '@formComponents/AutocompleteFetch';
import DatePickerBase from '@formComponents/DatePicker';
import { DOMAIN_NAME } from 'common/common';
import {
    BRANCH,
    DEPARTMENT,
    DESIGNATION,
    EMPLOYEE_CATEGORY,
    SHIFT,
    CALENDER
} from 'common/redux.datasets';
import dayjs from 'dayjs';
import {
    BLOODGROUP_OPTIONS,
    GENDER_OPTIONS,
    INITIALS,
    MARITAL_STATUS
} from 'employee/common';
import { FORM_NAME, LABELS } from '../../common';
import Shift from 'common/components/shift';
import Department from 'common/components/department';
import Branch from 'common/components/branch';
import Designation from 'common/components/designation';
import EmployeeCategory from 'common/components/employeeCategory';

const General = reduxForm({
    form: FORM_NAME, // Form name
    destroyOnUnmount: false // Preserve data
})(() => {
    return (
        <ContentContainer
            title={LABELS.general}
            AdornmentComponent={<Fragment />}
        >
            <GridContainer>
                <GridItem md={3}>
                    <AutocompleteBase
                        name='initial'
                        placeholder='Name Initial'
                        label='Initial: (Mr. /Ms. /Mrs.)'
                        validate={required}
                        required={true}
                        options={INITIALS}
                    />
                </GridItem>
                <GridItem md={3}>
                    <Input
                        name='firstname'
                        placeholder='First Name'
                        label='First Name (In English)'
                        validate={[required]}
                        required={true}
                    />
                </GridItem>
                <GridItem md={3}>
                    <Input
                        name='middlename'
                        placeholder='Middle Name'
                        label='Middle Name (In English)'
                    />
                </GridItem>
                <GridItem md={3}>
                    <Input
                        name='lastname'
                        placeholder='Last Name'
                        label='Last Name (In English)'
                    />
                </GridItem>
                <GridItem md={3}>
                    <Input
                        name='nameInBengali'
                        placeholder='Bangla Name'
                        label='Name (In Bengali)'
                        validate={[required]}
                        required={true}
                    />
                </GridItem>
                <GridItem md={3}>
                    <AutocompleteFetch
                        name='shift'
                        placeholder='Shift'
                        label='Shift'
                        domain={DOMAIN_NAME}
                        entity={SHIFT}
                        required={true}
                        validate={required}
                        ContainerComponent={Shift}
                    />
                </GridItem>
                <GridItem md={3}>
                    <Input
                        name='reportingManager'
                        placeholder='Reporting Manager'
                        label='Reporting Manager'
                        validate={email}
                    />
                </GridItem>
                <GridItem md={3}>
                    <AutocompleteFetch
                        name='department'
                        placeholder='Department'
                        label='Department'
                        domain={DOMAIN_NAME}
                        entity={DEPARTMENT}
                        required={true}
                        validate={required}
                        ContainerComponent={Department}
                    />
                </GridItem>
                <GridItem md={3}>
                    <AutocompleteFetch
                        name='officeBranch'
                        placeholder='Branch'
                        label='Branch'
                        required={true}
                        validate={required}
                        entity={BRANCH}
                        domain={DOMAIN_NAME}
                        ContainerComponent={Branch}
                    />
                </GridItem>
                <GridItem md={3}>
                    <AutocompleteFetch
                        name='designation'
                        placeholder='Designation'
                        label='Designation'
                        required={true}
                        validate={required}
                        entity={DESIGNATION}
                        domain={DOMAIN_NAME}
                        ContainerComponent={Designation}
                    />
                </GridItem>
                <GridItem md={3}>
                    <AutocompleteFetch
                        name='employeeCategory'
                        placeholder='Employee Category'
                        label='Employee Category'
                        required={true}
                        validate={required}
                        domain={DOMAIN_NAME}
                        entity={EMPLOYEE_CATEGORY}
                        ContainerComponent={EmployeeCategory}
                    />
                </GridItem>

                <GridItem md={3}>
                    <AutocompleteFetch
                        name='calenderId'
                        placeholder='Select Calender'
                        label='Select Calender'
                        required={true}
                        validate={required}
                        domain={DOMAIN_NAME}
                        entity={CALENDER}
                    />
                </GridItem>
                <GridItem md={3}>
                    <DatePickerBase
                        name='joiningDate'
                        placeholder='joiningDate'
                        label='Joining Date'
                        minDate='2000-01-01'
                        maxDate={dayjs().add(1, 'year')}
                        required={true}
                        validate={required}
                    />
                </GridItem>
                <GridItem md={3}>
                    <AutocompleteBase
                        name='gender'
                        placeholder='Select Gender'
                        label='Gender'
                        required={true}
                        validate={required}
                        options={GENDER_OPTIONS}
                    />
                </GridItem>
                <GridItem md={3}>
                    <AutocompleteBase
                        name='bloodGroup'
                        placeholder='Select Blood Group '
                        label='Blood Group'
                        options={BLOODGROUP_OPTIONS}
                    />
                </GridItem>
                <GridItem md={3}>
                    <DatePickerBase
                        name='dateOfBirth'
                        placeholder='Select Date of Birth'
                        label='Date of Birth'
                        minDate='1900-01-01'
                        required={true}
                        validate={required}
                    />
                </GridItem>
                <GridItem md={3}>
                    <Input name='placeOfBirth' label='Place of Birth' />
                </GridItem>
                <GridItem md={3}>
                    <Input
                        name='religion'
                        placeholder='Religion'
                        label='Religion'
                        required={true}
                        validate={required}
                    />
                </GridItem>
                <GridItem md={3}>
                    <AutocompleteBase
                        name='maritalStatus'
                        placeholder='Select Marital Status'
                        label='Marital Status'
                        required={true}
                        validate={required}
                        options={MARITAL_STATUS}
                    />
                </GridItem>
                <GridItem md={3}>
                    <Input
                        name='employeeId'
                        placeholder='Employee ID'
                        label='Employee ID'
                        required={true}
                        validate={required}
                    />
                </GridItem>
                <GridItem md={4}>
                    <Input
                        name='fatherNameInEng'
                        placeholder='English Name'
                        label='Father Name (In English)'
                    />
                </GridItem>
                <GridItem md={4}>
                    <Input
                        name='fatherNameInBn'
                        placeholder='Bangla Name'
                        label='Father Name (In Bengali)'
                    />
                </GridItem>
                <GridItem md={4}>
                    <Input name='fatherOccupation' label='Father Occupation' />
                </GridItem>
                <GridItem md={4}>
                    <Input
                        name='motherNameInEng'
                        placeholder='English Name'
                        label='Mother Name (In English)'
                    />
                </GridItem>
                <GridItem md={4}>
                    <Input
                        name='motherNameInBn'
                        placeholder='Bangla Name'
                        label='Mother Name (In Bengali)'
                    />
                </GridItem>
                <GridItem md={4}>
                    <Input name='motherOccupation' label='Mother Occupation' />
                </GridItem>
            </GridContainer>
        </ContentContainer>
    );
});

export default General;
