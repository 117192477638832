import { useCallback } from 'react';

import { useTheme } from '@mui/material';

import { DatePickerBase } from '@baseComponents/DateTimePicker';
import { GridContainer, GridItem } from '@formComponents';
import { normalizeIso8601DateOnly } from '@libs/dateTime';

import useDailyTimesheets, { SET_DATE } from './useDailyTimesheets';

const Filters = () => {
    const [{ date }, dispatch] = useDailyTimesheets();
    const { spacing } = useTheme();

    const handleDateChange = useCallback((value) => {
        try {
            if (value) {
                const normalizeValue = normalizeIso8601DateOnly(value);
                dispatch({
                    type: SET_DATE,
                    payload: normalizeValue
                });
            }
        } catch {}
    }, []);

    return (
        <GridContainer sx={{ paddingBottom: 0 }}>
            <GridItem md={3}>
                <DatePickerBase
                    input={{
                        value: date,
                        onChange: handleDateChange
                    }}
                    maxDate={new Date()}
                    name='daily-view-date-picker'
                    styles={{ input: { height: spacing(4.5) } }}
                    inputFormat='YYYY-MM-DD'
                />
            </GridItem>
        </GridContainer>
    );
};

export default Filters;
