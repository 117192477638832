import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { styled } from '@mui/system';
import { Box } from '@mui/material';

import { datasetLoadingSelector } from '@libs/datasets';

import { TimesheetsContainer } from '../components';
import { DOMAIN_NAME } from './common';
import {
    MY_DAILY_ATTENDANCE,
    MY_WEEKLY_ATTENDANCE,
    MY_MONTHLY_ATTENDANCE
} from '../redux.datasets';
import TimesheetsDailyView from './TimesheetsDailyView';
import TimesheetsWeeklyView from './TimesheetsWeeklyView';
import TimesheetsMonthlyView from './TimesheetsMonthlyView';

const StyledTimesheetsContainer = styled(Box)(({ theme: { spacing } }) => ({
    width: `100%`,
    position: 'relative',
    minHeight: spacing(20)
}));

const MyTimesheetsContent = ({ currentView }) => {
    return (
        <StyledTimesheetsContainer>
            {currentView === 'daily' && <TimesheetsDailyView />}
            {currentView === 'weekly' && <TimesheetsWeeklyView />}
            {currentView === 'monthly' && <TimesheetsMonthlyView />}
        </StyledTimesheetsContainer>
    );
};

MyTimesheetsContent.propTypes = {
    currentView: PropTypes.oneOf(['daily', 'weekly', 'monthly']).isRequired
};

const useAttendanceLoading = (type) => {
    return useSelector((state) =>
        datasetLoadingSelector(state, DOMAIN_NAME, type)
    );
};

const _MyTimesheets = () => {
    const isDailyAttendanceLoading = useAttendanceLoading(MY_DAILY_ATTENDANCE);
    const isWeeklyAttendanceLoading =
        useAttendanceLoading(MY_WEEKLY_ATTENDANCE);
    const isMonthlyAttendanceLoading = useAttendanceLoading(
        MY_MONTHLY_ATTENDANCE
    );

    const isAnyAttendanceLoading =
        isDailyAttendanceLoading ||
        isWeeklyAttendanceLoading ||
        isMonthlyAttendanceLoading;

    return (
        <TimesheetsContainer
            ContentComponent={MyTimesheetsContent}
            title='My Timesheets'
            loading={isAnyAttendanceLoading}
        />
    );
};

export default _MyTimesheets;
