import PropTypes from 'prop-types';

import { Button as MuiButton, useTheme } from '@mui/material';
import { styled } from '@mui/system';

import { useSwitchColor } from './hooks';
import Loader from './Loader';

const propTypes = {
    children: PropTypes.node,
    styles: PropTypes.object,
    variant: PropTypes.string,
    color: PropTypes.oneOf([
        'primary',
        'secondary',
        'success',
        'warning',
        'error',
        'tertiary',
        'quaternary'
    ]),
    loading: PropTypes.bool
};

const defaultProps = {
    styles: {},
    variant: 'contained',
    color: 'primary',
    loading: false
};

const CustomButton = styled(MuiButton)(
    ({ theme: { spacing, palette, typography, shadows } }) => ({
        height: spacing(4),
        width: spacing(15),
        fontWeight: typography.fontWeightRegular,
        textTransform: 'none',
        boxShadow: shadows[0],
        '&.MuiButton-outlined': {
            backgroundColor: palette.primary.contrastText,
            color: 'var(--color)',
            borderColor: 'var(--color)',
            '&:hover': {
                backgroundColor: 'var(--outlinedHoverColor)',
                borderColor: 'var(--hoverColor)'
            },
        },
        '&.MuiButton-contained': {
            color: palette.primary.contrastText,
            backgroundColor: 'var(--color)',
            '&:hover': {
                backgroundColor: 'var(--hoverColor)'
            },
        },
        '.MuiButton-text': {
            fontSize: '90%',
            fontWeight: typography.fontWeightRegular
        }
    })
);

const Button = ({ children, color, loading, disabled, ...restProps }) => {
    const theme = useTheme();
    let text = children?.props?.children || children;    

    //is array test? 

    if(Array.isArray(children)){
        text = text.join('');
    }

    return (
        <CustomButton
            aria-label={`${text && text?.toLowerCase()} button`}
            style={useSwitchColor(theme, color)}
            disabled={disabled || loading}
            data-testid={text && text?.toLowerCase().split(' ').join('-').concat('-button')}
            {...restProps}
        >
            {children}
            {!disabled && loading && <Loader />}
        </CustomButton>
    );
};

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export { Button as default, Button };
